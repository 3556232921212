// tslint:disable: variable-name
import { PeriodosMerenda } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { CardapioEtapaMerenda } from './cardapio-etapa-merenda.model';
import { EtapaSetorMerenda } from './etapa-setor-merenda.model';
import { EtapaItemMerenda } from './etapa-item-merenda.model';
import { EtapaItemSetor } from './etapa-item-setor.model';
import { Usuario } from '../comum/usuario.model';

export class EtapaMerenda extends BaseResourceModel {
  constructor(
    public id?: number,
    public nome?: string,
    public descricao?: string,
    public orgao?: Orgao,
    public motivo_alteracao?: string,
    public periodo_cardapio?: PeriodosMerenda,
    public setores?: EtapaSetorMerenda[],
    public itens?: EtapaItemMerenda[],
    public etapa_alterada?: EtapaMerenda,
    public itens_setor?: EtapaItemSetor[],
    public cardapios?: CardapioEtapaMerenda[],
    public motivo_inativacao?: string,
    public inativa?: boolean,
    public efetivada?: boolean,
    public usuario?: Usuario,
    public usuario_alteracao?: Usuario,
    public alteracoes?: string,
    public data_inativacao?: Date) {
    super();
  }
  static converteJson(json: any): EtapaMerenda {
    return Object.assign(new EtapaMerenda(), json);
  }
}
