import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ContratoService, MemorialService, PropostaService } from 'administrativo-lib';
import { AmparoLegalService, DateFormatPipe, FuncaoService, GlobalService, Licitacao, Login, Memorial, PaisesService, PNCPService, Proponente, Proposta, SituacaoProposta } from 'eddydata-lib';
import { Workbook, Worksheet } from 'exceljs';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoItemService } from '../contrato/service/contrato-item.service';

declare var $: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-proposta',
  templateUrl: './proposta.component.html',
  styleUrls: ['./proposta.component.css']
})
export class PropostaComponent implements OnInit, OnDestroy, OnChanges {
  @Input() edicao: boolean = true; // variavel para definir se opções de edições estarão disponiveis
  @Input() edicaoContratoExcluido: boolean = false;
  @Input() credenciamento: boolean = false;
  @Input() entidade: Licitacao;
  @Input() login: Login;
  @Input() dataAtualizacao: Date;
  @Input() verificarBotoesProp;
  @Output() julgamentoEvent: EventEmitter<any> = new EventEmitter();
  @Output() efetivarEvent: EventEmitter<any> = new EventEmitter();
  @Output() salvarPropostasEvent: EventEmitter<any> = new EventEmitter();

  private indexInicial = 5;
  public itemAtual: Proposta;
  public proponenteAtual: Proponente;
  public apenasCotacoes: boolean = false;
  public marcas: string[];
  public marcasFiltradas: string[];
  public erros: string[];
  public visualizarProponentes = false;
  public pregao: boolean = false;
  private situacoesProposta: { id: SituacaoProposta, nome: string, style: string, ordem: number }[];
  public propostaDesclassificada: Proposta;
  public apenasVisualizarMotivoDesclassificacao: boolean = false;
  public mostrarBtnAtualizar: boolean = false;
  public mostrarBtnEfetivar: boolean = false;
  public mostrarBtnJulgar: boolean = false;
  protected unsubscribe: Subject<void> = new Subject();
  public itemExtras: Proposta = new Proposta();
  public opcoesSimNaoPreferencia: { id: 'S' | 'N', nome: string }[];
  public opcoesSimNaoDesempate: { id: 'S' | 'N', nome: string }[];
  public preferencia: string = 'N';
  public desempate: string = 'N';
  public amparo_preferencia: any;
  public amparo_desempate: any;
  public amparoLegalListPreferencia: Array<{ codigo: number, lei: string }> = new Array<{ codigo: number, lei: string }>();
  public amparoLegalListDesempate: Array<{ codigo: number, lei: string }> = new Array<{ codigo: number, lei: string }>();
  public siglas: string[];
  public siglasFiltradas: string[];
  public moedas: string[];
  public moedasFiltradas: string[];

  public imaskConfig = {
    mask: Number,
    scale: 6,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };
  public imaskConfigMoeda = {
    mask: Number,
    scale: 4,
    // signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected memorialService: MemorialService,
    private contratoService: ContratoService,
    private contratoItemService: ContratoItemService,
    private amparoService: AmparoLegalService,
    private pncpService: PNCPService,
    private paisesService: PaisesService,
    protected propostaService: PropostaService,
  ) { }

  public async ngOnInit() {
    this.situacoesProposta = this.globalService.obterSituacoesProposta();
    this.pregao = this.memorialService.retornarRota().includes('/pregoes/')
    this.amparoLegalListPreferencia = await this.amparoService.buscarAmparosLocalPNCP(2, false);
    this.amparoLegalListDesempate = await this.amparoService.buscarAmparosLocalPNCP(3, false);
    this.opcoesSimNaoPreferencia = [
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
    ];

    this.opcoesSimNaoDesempate = [
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
    ];
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes['entidade'] || changes['dataAtualizacao']) {
      setTimeout(() => this.converterPropostas(), 1000);
    }
    if (changes['verificarBotoesProp']) {
      this.verificarBotoes()
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public carregarPropostas() {
    if (this.proponenteAtual) {
      if (!this.proponenteAtual.propostas) {
        this.proponenteAtual.propostas = [];
      }
      if (!this.apenasCotacoes) {
        const itensIds = this.proponenteAtual.propostas.map((p) => p.memorial.id);

        this.entidade.itens.forEach((item) => {
          if (!itensIds.includes(item.id)) {
            const prop = new Proposta();
            prop.situacao = 'NAO_JULGADO';
            prop.memorial = { ...item };
            delete prop.memorial.propostas;
            this.proponenteAtual.propostas.push(prop);
          }
        });
      }
      this.proponenteAtual.propostas.sort((a, b) => { return a.memorial.ordem - b.memorial.ordem });
      this.atualizarMarcas()
      this.converterPropostas();
    }
  }

  public atualizarMarcas() {
    this.marcas = [];
    if (this.entidade?.proponentes) {
      for (let i = 0; i < this.entidade.proponentes.length; i++) {
        const proponente = this.entidade.proponentes[i];
        if (proponente.propostas) {
          this.marcas = this.marcas.concat(proponente.propostas.filter(p => p.marca !== undefined).map((p) => String(p.marca).trim()));
        }
      }
      // remove duplicados
      this.marcas = [...new Set(this.marcas)].filter((marca) => marca !== undefined && marca !== null);
      this.marcasFiltradas = this.marcas;
    }
  }

  public alterarProposta(proposta) {
    if (proposta) {
      proposta.editavel = true;
    }
  }

  public async salvarMarcasPropostas(proposta, atualizar: boolean) {
    this.marcas = [];
    if (proposta) {
      if (atualizar) {
        for (const p of this.entidade?.proponentes) {
          for (const prop of p?.propostas) {
            if (prop.id == proposta.id) {
              prop.marca = proposta.marca
            }
          }
        }
      } else {
        for (const i of this.entidade?.itens) {
          for (const prop of i?.propostas) {
            if (prop.id == proposta.id) {
              prop.marca = proposta.marca
            }
          }
        }
      }
      await this.propostaService.atualizar(proposta).toPromise();

      proposta.editavel = false;
      // remove duplicados
      this.marcas = [...new Set(this.marcas)].filter((marca) => marca !== undefined && marca !== null);
      this.marcasFiltradas = this.marcas;
    }
  }

  public confirmar() {
    this.carregarPropostas();
    this.removerItensVazios();
    this.proponenteAtual = undefined;
    this.salvarPropostasEvent.emit();
    $('#dialogPropostaCad').modal('hide');
  }

  public removerItensVazios() {
    if (this.entidade?.proponentes) {
      for (let i = 0; i < this.entidade.proponentes.length; i++) {
        const proponente = this.entidade.proponentes[i];

        if (proponente.propostas) {
          for (let j = 0; j < proponente.propostas.length; j++) {
            const proposta = proponente.propostas[j];

            if (!proposta.valor_unitario || +proposta.valor_unitario <= 0) {
              if (proposta.id) {
                this.propostaService.remover(proposta.id).pipe(takeUntil(this.unsubscribe))
                  .subscribe((res) => {
                    proponente.propostas.splice(j, 1);
                    j--;
                  }, (err) => toastr.error(err.error.payload));
              } else {
                proponente.propostas.splice(j, 1);
                j--;
              }
            }
          }
        }
      }
    }
  }

  public toggleApenasCotacoes() {
    if (this.apenasCotacoes) {
      this.removerItensVazios();
    } else {
      this.carregarPropostas();
    }
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!this.proponenteAtual) {
      toastr.warning('Nenhum favorecido selecionado para importação!')
      return;
    }

    if (!event.files || event.files.length === 0) {
      toastr.warning('Nenhum arquivo selecionado!')
      return;
    }

    try {
      // variavel para guardar informacoes do mapa de precos
      const regXlsx: { ordem: number, marca: string, valor: number }[] = [];
      this.erros = [];

      // le mapa de preços enviado (xlsx)
      const file: File = event.files[0];
      const wb = new Workbook();
      await wb.xlsx.load(await file.arrayBuffer());
      if (!wb) throw new Error('Não foi possível carregar arquivo selecionado, verifique se o arquivo é compatível com o mapa de preços');
      const ws: Worksheet = wb.getWorksheet('Memorial');
      if (!ws) throw new Error('Não foi possível localizar informações do mapa de preços, verifique se o arquivo é compatível');

      const idXlsx = ws.getCell('C1').value ? +String(ws.getCell('C1').value) : undefined;
      if (!idXlsx || idXlsx !== this.entidade.id) {
        this.erros.push(`Código inválido no arquivo de mapa de preços, verifique se o arquivo pertence a outro memorial`);
      } else {
        let cnpjXlsx = ws.getCell('A3').value ? this.funcaoService.removerPontos(String(ws.getCell('A3').value)).trim() : undefined;

        if (!cnpjXlsx || cnpjXlsx === 'Informe o Documento') {
          this.erros.push(`Documento não informado no arquivo`);
        } else {
          if (cnpjXlsx.length > 11) cnpjXlsx = this.funcaoService.strZero(cnpjXlsx, 14);
          if (this.funcaoService.removerPontos(String(this.proponenteAtual.favorecido.cpf_cnpj)).trim() !== cnpjXlsx) {
            this.erros.push(`Documento informado no arquivo (${cnpjXlsx}) é diferente do proponente atual`);
          }
        }

        for (let linha = this.indexInicial; linha < this.entidade.itens.length + this.indexInicial; linha++) {
          const itemMemorial: Memorial = this.entidade.itens[linha - this.indexInicial];
          const itemXlsx = {
            ordem: +ws.getCell(`A${linha}`).value?.toString(),
            marca: ws.getCell(`E${linha}`).value?.toString(),
            valor: +ws.getCell(`F${linha}`).value?.toString()
          };

          // verifica a integridade do arquivo
          if (itemMemorial.ordem !== itemXlsx.ordem) {
            this.erros.push(`Célula A${linha} com valor inválido (esperado ${itemMemorial.ordem})`);
          }
          if (+itemXlsx.valor < 0) {
            this.erros.push(`Célula F${linha} com valor inválido (esperado valor positivo)`);
          }

          regXlsx.push(itemXlsx);
        }

        if (!regXlsx.find(x => +x.valor > 0)) {
          this.erros.push(`Nenhum item foi cotado no arquivo informado!`);
        }
      }

      if (this.erros.length > 0) {
        throw new Error(`${this.erros.length} erro(s) encontrado(s) no preenchimento do mapa de preços, verifique o conteúdo do arquivo e importe novamente!`);
      }

      await this.importarMapaPrecos(regXlsx);

      toastr.success('Mapa de preço importado com sucesso!');
      $('#dialogImportarMapaPreco').modal('hide');
      $('#dialogPropostaCad').modal('show');
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    } finally {
      fileUpload.clear();
    }
  }

  public fecharImportar() {
    this.erros = [];
    $('#dialogImportarMapaPreco').modal('hide');
    $('#dialogPropostaCad').modal('show');
  }

  private async importarMapaPrecos(registros: { ordem: number, marca: string, valor: number }[]) {
    this.carregarPropostas();

    for (let i = 0; i < this.entidade.itens.length; i++) {
      const itemProposta = this.proponenteAtual.propostas[i];
      const itemXlsx = registros[i];

      if (itemProposta.memorial.ordem !== itemXlsx.ordem) {
        throw new Error(`Ordem inválida para o item ${itemProposta.memorial.ordem}, valor preenchido é ${itemXlsx.ordem}`)
      }

      itemProposta.marca = itemXlsx.marca;
      itemProposta.valor_unitario = itemXlsx.valor;
    }

    this.removerItensVazios();
  }

  private async converterPropostas() {
    if (this.entidade?.itens && this.entidade?.proponentes) {
      this.entidade.itens.forEach(async (memorial) => {
        memorial.propostas = [];

        this.entidade.proponentes.forEach((proponente) => {
          if (proponente.propostas) {
            proponente.propostas
              .filter((p) => p.memorial.ordem === memorial.ordem && +p.valor_unitario > 0)
              .forEach((p) => {
                const prop = { ...p };
                prop.proponente = { ...proponente };
                delete prop.proponente.propostas;
                memorial.propostas.push(prop);
              });
          }
        });

        this.ordenarPropostas(memorial.propostas);

      });
      await this.verificarContratoItens()
    }
  }

  private async ordenarPropostas(propostas: Proposta[]) {
    // ordena propostas pela ordenação da situação e por valor, respectivamente
    propostas
      .sort((a, b) => { return a.valor_unitario - b.valor_unitario })
      .sort((a, b) => { return this.situacoesProposta.find(s => s.id === a.situacao).ordem - this.situacoesProposta.find(s => s.id === b.situacao).ordem });
  }

  public julgarPropostas() {
    this.julgamentoEvent.emit();
  }

  public efetivarPropostas() {
    this.efetivarEvent.emit();
  }

  public obterNomeProposta(item: Proposta): string {
    try {
      return this.situacoesProposta.find(p => p.id === item.situacao).nome;
    } catch (err) {
      return item.situacao;
    }
  }

  public obterEstiloProposta(item: Proposta): string {
    try {
      return this.situacoesProposta.find(p => p.id === item.situacao).style;
    } catch (err) {
      return 'secondary';
    }
  }

  public abrirDlgMotivoDesclassificacao(proposta: Proposta) {
    this.propostaDesclassificada = proposta;
    this.apenasVisualizarMotivoDesclassificacao = true;
  }

  public filtrarMarcas(event: any) {
    let query = event.query;

    if (!this.marcas) {
      this.atualizarMarcas()
    }

    this.marcasFiltradas = this.marcas.filter((marca) => marca.toLowerCase().startsWith(query.toLowerCase()));
  }

  public quantidadeProposta(memorial: Memorial, proposta?: Proposta): number {
    if (proposta?.quantidade_final && proposta?.situacao !== 'NAO_JULGADO') {
      return +proposta?.quantidade_final
    } else {
      return +memorial.quantidade
    }
  }

  public async verificarContratoItens() {
    let entrou: boolean = false;
    let propostaExiste: boolean = true;
    let proponenteTemProposta: boolean = true;
    let itemTemProposta: boolean = true;
    const contratoItens = (await this.contratoItemService.filtrar(1, -1,
      {
        'contrato.licitacao.id': this.entidade.id,
        'contrato.excluido': false,
        relations: 'contrato.licitacao,memorial,contrato.favorecido'
      }).toPromise()).content

    if (contratoItens.length > 0) {
      for (const i of this.entidade?.itens) {
        if (i?.propostas?.length > 0) {
          let haVencedor = false;
          for (const proposta of i?.propostas) {
            const p = contratoItens.find(ci => ci?.memorial?.id == proposta?.memorial?.id && ci?.contrato?.favorecido?.id == proposta?.proponente?.favorecido?.id)
            if (p) {
              proposta['habilitar'] = false;
              haVencedor = true;
              entrou = false;
            } else {
              if (!haVencedor) {
                proposta['habilitar'] = true;
                this.edicao = true;
                entrou = true;
              }
            }
          }
        } else {
          itemTemProposta = false;
        }
      }

      for (const prop of this.entidade?.proponentes) {
        if (prop?.propostas?.length > 0) {
          let haVencedor = false;
          for (const proposta of prop?.propostas) {
            const p = contratoItens.find(ci => ci?.memorial?.id == proposta?.memorial?.id && ci?.contrato?.favorecido?.id == proposta?.proponente?.favorecido?.id)

            if (p) {
              proposta['habilitar'] = false;
              haVencedor = true;
              entrou = false;
            } else {
              if (!haVencedor) {
                proposta['habilitar'] = true;
                this.edicao = true;
                entrou = true;
              }
            }
          }
        } else {
          proponenteTemProposta = false;
        }
      }
    } else {
      for (const i of this.entidade?.itens) {
        if (i?.propostas?.length > 0) {
          for (const proposta of i?.propostas) {
            proposta['habilitar'] = true;
            this.edicao = true;
            entrou = true;
            proponenteTemProposta = true;
          }
        } else { //senao tiver proposta
          propostaExiste = false;
        }
      }

      for (const prop of this.entidade?.proponentes) {
        if (prop?.propostas?.length > 0) {
          for (const proposta of prop?.propostas) {
            proposta['habilitar'] = true;
            this.edicao = true;
            entrou = true;
            proponenteTemProposta = true;
          }
        } else { //senao tiver proposta
          propostaExiste = false;
        }
      }
    }

    if (proponenteTemProposta && itemTemProposta && propostaExiste && !entrou) {
      this.edicao = false;
    } else {
      this.edicao = true;
    }
  }

  public async verificarBotoes() {
    // Todas as regras dos botões estão nessa função, chamada quando clicar na aba
    await this.verificarContratoItens()

    let listaPendentes: Proponente[] = this.contratoService.obterContratosPendentesLicitacao(this.entidade);

    if (this.edicao || listaPendentes.length > 0 || this.credenciamento) {
      this.mostrarBtnAtualizar = true;
    } else {
      this.mostrarBtnAtualizar = false;
    }

    if ((this.edicao) &&
      (this.entidade.modalidade?.codigo === 6 || this.credenciamento)) {
      this.mostrarBtnEfetivar = true;
    } else {
      this.mostrarBtnEfetivar = false;
    }

    if (this.edicao || listaPendentes.length > 0 && !this.pregao &&
      (this.entidade.modalidade?.codigo !== 6 && !this.credenciamento)) {
      this.mostrarBtnJulgar = true;
    } else {
      this.mostrarBtnJulgar = false;
    }
  }

  async camposExtras(item: Proposta) {
    this.itemExtras = item;
    if (item.aplicacao_preferencia) {
      this.preferencia = 'S';
      this.amparo_preferencia = +item.amparo_legal_preferencia;
    } else {
      this.preferencia = 'N';
      this.amparo_preferencia = 0;
      if (this.amparo_preferencia === 0) {
        this.amparoLegalListPreferencia = await this.amparoService.buscarAmparosLocalPNCP(2, true);
      }
    }

    if (item.aplicacao_desempate) {
      this.desempate = 'S';
      this.amparo_desempate = +item.amparo_legal_desempate;
    } else {
      this.desempate = 'N';
      this.amparo_desempate = 0;
      if (+this.amparo_desempate === 0) {
        this.amparoLegalListDesempate = await this.amparoService.buscarAmparosLocalPNCP(3, true);
      }
    }
    this.itemExtras.data_cotacao_moeda = new DateFormatPipe().transform(this.itemExtras.data_cotacao_moeda, ['datetime']);
  }

  cancelarExtras() {
    this.itemExtras = new Proposta();
  }

  async salvarExtras() {
    try {
      if (this.preferencia == 'S') {
        this.itemExtras.aplicacao_preferencia = true;
        if (!this.amparo_preferencia || +this.amparo_preferencia === 0) {
          this.amparo_preferencia = '0';
          throw ('Informe um amparo legal de preferência!');
        }
        if (!this.itemExtras.pais_origem_produto || this.itemExtras.pais_origem_produto.trim() === '') {
          throw ('Informe a silga do pais de origem do produto/serviço!');
        }
        this.itemExtras.amparo_legal_preferencia = +this.amparo_preferencia;
      } else {
        this.itemExtras.aplicacao_preferencia = false;
        this.itemExtras.amparo_legal_preferencia = 0;
      }

      if (this.desempate == 'S') {
        this.itemExtras.aplicacao_desempate = true;
        if (!this.amparo_desempate || +this.amparo_desempate === 0) {
          this.amparo_desempate = '0';
          throw ('Informe um amparo legal de desempate!')
        } else {
          this.itemExtras.amparo_legal_desempate = +this.amparo_desempate;
        }
      } else {
        this.itemExtras.aplicacao_desempate = false;
        this.itemExtras.amparo_legal_desempate = 0;
      }

      if (this.itemExtras.id) {
        const index = this.entidade.itens[this.itemExtras.memorial.ordem - 1].propostas.findIndex(proposta => proposta.id === this.itemExtras.id);
        if (index !== -1) {
          // Se encontrou a proposta correspondente
          this.entidade.itens[this.itemExtras.memorial.ordem - 1].propostas[index] = this.itemExtras;
        }
        //Faz uma copia da licitação, para salvar os campos extras sem afetar a liquidação atual.
        const aux = Object.assign({}, this.itemExtras.memorial);
        aux.itens = [];//Necessário para não dar loop de informações.

        this.itemExtras.memorial = aux;
        await this.propostaService.atualizar(this.itemExtras).toPromise();
        this.itemExtras = new Proposta();
        $('#dialogExtrasProposta').modal('hide');
      }
    } catch (error) {
      toastr.error(error)
    }
  }

  verificaSalvarExtras() {
    this.salvarExtras();
  }

  public filtrarSiglas(event: any) {
    let query = event.query;

    if (!this.siglas) {
      this.atualizarSiglas()
    }

    this.siglasFiltradas = this.siglas.filter((silga) => silga.toLowerCase().startsWith(query.toLowerCase()));
  }

  public atualizarSiglas() {
    this.siglas = [];
    const lista = this.paisesService.listaPaisesReinf();
    this.siglas = this.siglas.concat(lista.filter(p => p.sigla !== undefined).map((p) => String(p.sigla).trim()));

    this.siglas = [...new Set(this.siglas)].filter((sigla) => sigla !== undefined && sigla !== null);
    this.siglasFiltradas = this.siglas;

  }
  public filtrarMoedas(event: any) {
    let query = event.query;

    if (!this.moedas) {
      this.atualizarMoedas()
    }

    this.moedasFiltradas = this.moedas.filter((silga) => silga.toLowerCase().startsWith(query.toLowerCase()));
  }

  public atualizarMoedas() {
    this.moedas = [];
    const lista = this.paisesService.listaPaisesReinf();
    this.moedas = this.moedas.concat(lista.filter(p => p.moeda !== undefined).map((p) => String(p.moeda).trim()));

    this.moedas = [...new Set(this.moedas)].filter((moeda) => moeda !== undefined && moeda !== null);
    this.moedasFiltradas = this.moedas;

  }
}
