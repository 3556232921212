// tslint:disable: variable-name
import { ConservacaoPatrimonio } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Setor } from '../almoxarifado/setor.model';
import { BaixaPatrimonio } from './baixa-patrimonio.model';
import { Tombamento } from './tombamento.model';

export class BaixaItemPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public conservacao_anterior?: ConservacaoPatrimonio,
    public setor_anterior?: Setor,
    public baixa?: BaixaPatrimonio,
    public tombamento?: Tombamento,
    public editavel?: boolean,
    public data_anulacao?: Date,
    public motivo_anulacao?: string,
  ) {
    super();
  }

  static converteJson(json: any): BaixaItemPatrimonio {
    return Object.assign(new BaixaItemPatrimonio(), json);
  }
}
