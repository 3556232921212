import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProdutoService } from 'almoxarifado-lib';
import {
  BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, GlobalService, LoginContabil, Produto
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-servico-list',
  templateUrl: './servico-list.component.html'
})
export class ServicoListComponent extends BaseResourceListComponent<Produto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public inativo: 'T' | 'A' | 'I' = 'T';
  public filtro: string;
  public mes: string;
  seguir: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private activatedRoute: ActivatedRoute,
    private produtoService: ProdutoService,
    protected globalService: GlobalService) {
    super(produtoService, injector);
    this.inativo = sessionStorage.getItem(
      `/servicos_inativo`
    ) as 'T' | 'A' | 'I' || 'T'
    this.seguir = (sessionStorage.getItem('/servicos_seguir') === 'true' ? true : false)
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Produto): boolean {
    return true;
  }

  protected relations(): string {
    return 'material.sub_grupo.grupo';
  }

  protected condicoesGrid(): {} {
    let condicoesGrid = {
      'material.servico': true
    };
    if (this.inativo !== 'T')
      condicoesGrid['inativo'] = this.inativo === 'I';

    condicoesGrid['orgao_id'] = this.login.orgao.id

    sessionStorage.setItem(
      `/servicos_inativo`,
      String(this.inativo)
    );
    return condicoesGrid;
  }

  public onClick(valueEmitted?: string) {
    if (!this.usarFiltroPersonalizado)
      this.filtro = valueEmitted.replace('’', '').replace("'", '').replace('´', '');

    this.paginaCorrente = 1;
    this.preencherGrid();
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['nome', 'material.nome', 'codigo'],
    };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: Produto): Observable<Produto> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Material', coluna: 'material.nome' },
      { titulo: 'Código sub-grupo', coluna: 'material.sub_grupo.codigo' },
      { titulo: 'Nome sub-grupo', coluna: 'material.sub_grupo.nome' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'material.sub_grupo.grupo';
    parametros['orgao_id'] = this.login.orgao.id
    this.produtoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE SERVIÇOS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem serviços', ['auto', '*', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public salvarSessionStorage() {
    sessionStorage.setItem(
      `/servicos_seguir`,
      String(this.seguir)
    );
  }
}
