// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Favorecido } from '../compra/favorecido.model';
import { FichaExtra } from './ficha-extra.model';
import { Contrato } from '../compra/contrato.model';
import { Exercicio } from '../comum/exercicio.model';
import { Usuario } from '../comum/usuario.model';
import { Convenio } from '../compra/convenio.model';
import { Retencao } from './retencao.model';
import { RetencaoResto } from './retencao-resto.model';
import { PagamentoExtra } from '../tesouraria/pagamento-extra.model';
import { RetencaoExtra } from './retencao-extra.model';
export class EmpenhoExtra extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public parcela?: number,
    public valor_empenho?: number,
    public mes?: number,
    public data_empenho?: Date,
    public data_vencimento?: Date,
    public especie?: string,
    public processo?: string,
    public documento?: string,
    public impresso?: boolean,
    public retido?: boolean,
    public aux?: number,
    public historico?: string,
    public orgao?: Orgao,
    public ficha?: FichaExtra,
    public favorecido?: Favorecido,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public contrato?: Contrato,
    public exercicio?: Exercicio,
    public codigo_reinf?: number,
    public usuario_cadastro?: Usuario,
    public convenio?: Convenio,
    public retencao?: Retencao,
    public retencao_resto?: RetencaoResto,
    public retencao_extra?: RetencaoExtra,
    public empenho_anulado?: EmpenhoExtra,
    public retencoes?: RetencaoExtra[],
    public pagamentos?: PagamentoExtra[],
    public empenho_antigo?: EmpenhoExtra,
  ) {
    super();
  }

  static converteJson(json: any): EmpenhoExtra {
    return Object.assign(new EmpenhoExtra(), json);
  }
}
