import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FuncaoService, RcmsCotacao, RcmsFavorecido, RcmsItem } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'app-rcms-comparativo-preco',
  templateUrl: './rcms-comparativo-preco.component.html'
})
export class RcmsComparativoPreco implements OnInit, OnChanges {

  public imaskValor = {
    mask: Number,
    scale: 6,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public mediasItens: any[] = [];

  @Input() itens: RcmsItem[];

  @Input() favorecidos: RcmsFavorecido[];

  constructor(
    public funcaoService: FuncaoService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.itens?.length > 0) {
      this.calcularMedia();
    }
  }

  ngOnInit(): void { }

  public calcularMedia() {
    let rcms_cotacoes: RcmsCotacao[] = [];

    for (const cotacoes of this?.itens) {
      for (const item of cotacoes.cotacoes) {
        rcms_cotacoes.push(item);
      }
    }

    let cotacoesAgrupadas = rcms_cotacoes.reduce((acc, cotacao) => {
      let id = cotacao.rcmsItem?.produto_unidade?.id;
      let nomeProduto = cotacao.rcmsItem?.produto_unidade.produto?.nome;

      if (!acc[id]) {
        acc[id] = { valores: [], nomeProduto };
      }

      acc[id].valores.push(cotacao.valor_unitario);
      return acc;
    }, {});

    this.mediasItens = [];

    for (let id in cotacoesAgrupadas) {
      let valores = cotacoesAgrupadas[id].valores;
      let nomeProduto = cotacoesAgrupadas[id].nomeProduto;

      let soma = valores.reduce((soma, valor) => +soma + +valor, 0);
      let media = +soma / valores.length;

      let menorValor = Math.min(...valores);

      let resultado = (+menorValor / +media) * 100;

      this.mediasItens.push({
        id: id,
        nomeProduto: nomeProduto || 'Produto desconhecido',
        media: +media,
        menorValor: +menorValor,
        porcentagem: +resultado
      });
    }
  }
}