import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArquivoTransparencia, BaseResourceListComponent, Coluna, Filtro, GlobalService, GrupoArquivoTransparencia, LoginContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import { ArquivoTransparenciaService } from '../transparencia-arquivo/service/arquivo-transparencia.service';
import { GrupoArquivoTransparenciaService } from '../transparencia-arquivo/service/grupo-arquivo-transparencia.service';
import { TransparenciaStorageService } from '../transparencia-arquivo/service/transparencia-storage.service';

@Component({
  selector: 'app-transparencia-arquivo-view',
  templateUrl: './transparencia-arquivo-view.component.html',
  styleUrls: ['./transparencia-arquivo-view.component.css']
})
export class TransparenciaArquivoViewComponent extends BaseResourceListComponent<ArquivoTransparencia, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public datepipe: DatePipe;

  public listaGrupos: Array<GrupoArquivoTransparencia>;

  public entidade = new ArquivoTransparencia();
  public grupo: GrupoArquivoTransparencia;
  public uploadedFiles: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private globalService: GlobalService,
    private anexoService: ArquivoTransparenciaService,
    private grupoService: GrupoArquivoTransparenciaService,
    private transparenciaStorageService: TransparenciaStorageService) {
    super(anexoService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.preencherGrupo(params['grupo']);
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'grupo.orgao';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['grupo.orgao.id'] = this.login.orgao.id;
    parametros['grupo.id'] = this.grupo.id
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_anexo'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['referencia', 'tamanho'],
      text: ['descricao', 'nome', 'tipo', 'referencia', 'grupo.nome']
    };
  }

  public beforeInit(): void {
    this.entidade.grupo = new GrupoArquivoTransparencia();
  }

  public download(arquivo: any) {
    this.anexoService
      .download(arquivo.documento).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const blob = new Blob([res], { type: arquivo.tipo });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = arquivo.nome;
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      });
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
  }

  protected acaoRemover(model: ArquivoTransparencia): Observable<ArquivoTransparencia> {
    return null;
  }

  public obterParametros() {
    const parametros = super.obterParametros();
    parametros['relations'] = this.relations();

    switch (this.col) {
      case 1:
        parametros['orderBy'] = `referencia$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `descricao$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `grupo.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 5:
        parametros['orderBy'] = `caminho$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 6:
        parametros['orderBy'] = `tamanho$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }
    return parametros;
  }

  public preencherGrid() {
    if (!this.grupo) {
      return;
    }
    const parametros = this.obterParametros();
    this.anexoService
      .filtrar(
        this.paginaCorrente,
        this.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
      this.transparenciaStorageService
      .filtrar(
        this.paginaCorrente,
        this.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = this.lista.concat(lista.content);
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Descrição', coluna: 'descricao' },
      { titulo: 'Referência', coluna: 'referencia' },
      { titulo: 'Tamanho', coluna: 'tamanho' },
      { titulo: 'Grupo', coluna: 'grupo.nome' },
    ];
  }

  public imprimirPDF() {
    this.imprimir('LISTAGEM DE ANEXOS',
      this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
      'Listagem anexos', ['auto', '*', 'auto', 'auto', 'auto'], this.lista);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public exportarXLSX() {
    const listaItens = new Array();
    for (const item of this.lista) {
      const entity = {
        nome: item.nome,
        descricao: item.descricao,
        tamanho: item.tamanho,
        referencia: item.referencia,
        grupo: item.grupo.nome,
      };
      listaItens.push(entity);
    }
    tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('anexos');
  }

  public onSelectPeriodo() {
    this.preencherGrid();
  }

  public preencherGrupo(grupoId) {
    this.grupoService.obter({ id: grupoId, relations: 'orgao' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.grupo = res;
        this.preencherGrid();
      });

  }
}
