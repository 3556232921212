// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';
import { Empenho } from './empenho.model';
import { AdiantamentoDocumento } from 'eddydata-lib';

export class Adiantamento extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public mes?: number,
    public aux?: number,
    public data_adiantamento?: Date,
    public data_vencimento?: Date,
    public data_liberacao?: Date,
    public data_prestacao?: Date,
    public data_termino?: Date,
    public data_partida?: Date,
    public data_chegada?: Date,
    public data_aplicacao?: Date,
    public data_prazo_prestacao?: Date,
    public especie?: string,
    public situacao?: string,
    public parecer?: string,
    public descricao_parecer?: string,
    public valor_adiantado?: number,
    public valor_complemento?: number,
    public oficio?: string,
    public motivo?: String,
    public banco?: string,
    public agencia?: string,
    public numero_conta?: string,
    public beneficiario?: string,
    public tipo_beneficiario?: string,
    public cargo_beneficiario?: string,
    public destino?: string,
    public prorrogacao_adiantamento?: string,
    public empenho?: Empenho,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public motivacao_viagem?: string,
    public resumo_atividades?: string,
    public resultados_viagem?: string,
    public naoEscriturar?: boolean,
    public documentos?: AdiantamentoDocumento[]
  ) {
    super();
  }

  static converteJson(json: any): Adiantamento {
    return Object.assign(new Adiantamento(), json);
  }
}
