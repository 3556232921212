import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService, OrgaoAssinaturaService, Orgao, ProgressoService, FormatoExportacao } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { NotaExplicativaService } from '../service/nota-explicativa.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';

@Injectable({
  providedIn: 'root'
})
export class Anexo17Balanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected progressoService: ProgressoService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B17', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // tslint:disable: max-line-length
  public montarRelatorio(exercicio: Exercicio, orgaos: number[], listaOrgaos?: Orgao[], mes?: number, formato?: FormatoExportacao) {
    formato = formato ?? 'pdf';
    this.anexoServico.obterDividaFlutuante(exercicio.id, orgaos, mes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, async (dados) => {
          const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
          const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
          let orgaoNomes: string[] = [];
          for (const o of listaOrgaos) {
            if (orgaos.some(orgao => orgao === o.id)) {
              orgaoNomes.push(`${o.codigo} - ${o.nome}`);
            }
          }
          if (formato === 'pdf') {
            const dataLimiteAssinatura = `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
            Relatorio.imprimirPersonalizado(
              'ANEXO 17 - DEMONSTRAÇÃO DA DÍVIDA FLUTUANTE',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
              this.cabecalhoRelatorio(orgao, exercicio, mes)
                .concat(this.conteudo(dados, mes))
                .concat(await this.conteudoNotaExplicativa())
                .concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes, null, mes ? dataLimiteAssinatura : null)),
              'landscape', 'ANEXO 17 - DEMONSTRAÇÃO DA DÍVIDA FLUTUANTE',
              {
                linhas: {
                  hLineWidth() {
                    return 1;
                  },
                  vLineWidth() {
                    return 1;
                  },
                  hLineColor() {
                    return 'black';
                  },
                  paddingLeft() {
                    return 3;
                  },
                  paddingRight() {
                    return 3;
                  }
                }
              }, false, false, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`);
          } else if (formato === 'csv') {
            this.exportacaoCsv(dados);
          }
        });
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number): {}[] {
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 8 },
        { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
        { text: `Exercício:`, alignment: 'left', fontSize: 8 },
        { text: exercicio.ano, alignment: 'center', fontSize: 8 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 8 },
        { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
        { text: `Mês:`, alignment: 'left', fontSize: 8 },
        { text: mes ? this.globalService.obterMes(+mes) : 'ANUAL', alignment: 'center', fontSize: 8 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 8, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false] },
        '',
        '',
        '',
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    }];
  }

  private conteudo(dados: any, mes?: number): {}[] {
    // monta o cabecalho
    const registros: {}[] = [

      [{
        text: 'TÍTULOS',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'SALDO ANTERIOR',
        alignment: 'center',
        bold: true, fontSize: 8,
      }, {
        text: 'INSCRIÇÕES',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'BAIXAS',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'SALDO ATUAL',
        alignment: 'center',
        bold: true, fontSize: 8,
      }]
    ];

    // monta o agrupamento do relatório
    let totalGrupos = dados.length;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    for (let index = 0; index < totalGrupos; index++) {
      const item = dados[index];

      registros.push([
        {
          text: item ? `${item.codigo} ${item.nome}` : '', bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item ? this.funcaoService.convertToBrNumber(item.saldo_anterior) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item ? this.funcaoService.convertToBrNumber(item.inscricoes) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, false, false]
        },
        {
          text: item ? this.funcaoService.convertToBrNumber(item.baixas) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, true, false]
        },
        {
          text: item ? this.funcaoService.convertToBrNumber(+item.saldo_anterior + +item.inscricoes - +item.baixas) : '', alignment: 'right',
          bold: true, fontSize: 8, border: [true, false, true, false]
        }
      ]);

      // soma os registros do relatorio

      total1 += item ? +item.saldo_anterior : 0;
      total2 += item ? +item.inscricoes : 0;
      total3 += item ? +item.baixas : 0;
      total4 += item ? (+item.saldo_anterior + +item.inscricoes - +item.baixas) : 0;
    }

    registros.push([{
      text: 'TOTAL GERAL',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total3), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total4), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    }]);

    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private exportacaoCsv(dados: any[]) {

    // monta o cabecalho
    const registros: {}[][] = [
      [
        { text: 'TÍTULOS' },
        { text: 'SALDO ANTERIOR' },
        { text: 'INSCRIÇÕES' },
        { text: 'BAIXAS' },
        { text: 'SALDO ATUAL' }
      ]
    ];

    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let totalGrupos = +dados.length;
    for (let index = 0; index < totalGrupos; index++) {
      const item = dados[index];

      registros.push([
        { text: item ? `${item.codigo} ${item.nome}` : '' },
        { text: item ? this.funcaoService.convertToBrNumber(item.saldo_anterior) : '' },
        { text: item ? this.funcaoService.convertToBrNumber(item.inscricoes) : '' },
        { text: item ? this.funcaoService.convertToBrNumber(item.baixas) : '' },
        { text: item ? this.funcaoService.convertToBrNumber(+item.saldo_anterior + +item.inscricoes - +item.baixas) : '' }
      ]);


      total1 += item ? +item.saldo_anterior : 0;
      total2 += item ? +item.inscricoes : 0;
      total3 += item ? +item.baixas : 0;
      total4 += item ? (+item.saldo_anterior + +item.inscricoes - +item.baixas) : 0;
    }

    registros.push([
      { text: 'TOTAL GERAL' },
      { text: this.funcaoService.convertToBrNumber(total1) },
      { text: this.funcaoService.convertToBrNumber(total2) },
      { text: this.funcaoService.convertToBrNumber(total3) },
      { text: this.funcaoService.convertToBrNumber(total4) }
    ]);

    dados = null;
    let csv = '';
    for (let i = 0; i < registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x = 0; x < linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 17 - DEMONSTRAÇÃO DA DÍVIDA FLUTUANTE.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
