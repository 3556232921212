import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContratoRescisao, FuncaoService, GlobalService, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';
import { ContratoRescisaoService } from '../service/contrato-rescisao.service';

declare var $: any;

@Component({
  selector: 'app-contrato-rescisao-dlg',
  templateUrl: './contrato-rescisao-dlg.component.html'
})
export class ContratoRescisaoDlgComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() rescisao: ContratoRescisao;
  @Input() login: Login;
  @Output() callback: EventEmitter<void> = new EventEmitter();
  @Input() inclusao: boolean;
  @Output() inclusaoChange: EventEmitter<boolean> = new EventEmitter();

  public ptBR: any;
  protected datepipe: DatePipe;

  protected unsubscribe: Subject<void> = new Subject();

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };
  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    public funcaoService: FuncaoService,
    public rescisaoService: ContratoRescisaoService,
    private globalService: GlobalService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['inclusao'] && this.inclusao) {
        this.inclusao = false;
        this.inclusaoChange.emit(false);
      }
    }
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  public async salvar() {
    if (!this.rescisao.motivo) {
      toastr.error('Campo motivo não preenchido!')
      return;
    }
    if (!this.rescisao.data_rescisao) {
      toastr.error('Campo data da rescisão não preenchido!')
      return;
    }

    if (this.rescisao.id) { // alteração
      this.rescisao['atualizarPNCP'] = true;
      this.rescisao.usuario = this.login.usuario;
      this.rescisaoService.atualizar(this.rescisao).subscribe(res => {
        toastr.success('Rescisão realizada com sucesso!');

        this.callback.emit();
        $('#dialogRescisao').modal('hide');
      }, error => this.funcaoService.acaoErro(error));
    } else {
      this.rescisaoService.inserir(this.rescisao).subscribe(res => {
        toastr.success('Rescisão realizada com sucesso!');

        this.callback.emit();
        $('#dialogRescisao').modal('hide');
      }, error => this.funcaoService.acaoErro(error));
    }
  }

}
