import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { EstoqueService } from '../../almoxarifado/estoque/service/estoque.service';
import { SetorService } from '../../almoxarifado/setor/service/setor.service';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { Sistemas } from '../../components/types';
import { Estoque } from '../../entidade/almoxarifado/estoque.model';
import { Setor } from '../../entidade/almoxarifado/setor.model';
import { UsuarioEstoque } from '../../entidade/almoxarifado/usuario-estoque.model';
import { UsuarioSetor } from '../../entidade/almoxarifado/usuario-setor.model';
import { Favorecido } from '../../entidade/compra/favorecido.model';
import { Acesso } from '../../entidade/comum/acesso.model';
import { Pagina } from '../../entidade/comum/pagina';
import { Usuario } from '../../entidade/comum/usuario.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { Unidade } from '../../entidade/planejamento/unidade.model';
import { FavorecidoService } from '../../favorecido/service/favorecido.service';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { EddyAutoComplete } from '../../models/form-components';
import { UnidadeService } from '../../unidade/service/unidade.service';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { AcessoService } from '../service/acesso.service';
import { UsuarioService } from '../service/usuario.service';

declare var $: any;
@Component({
  selector: 'lib-usuario-view',
  templateUrl: './usuario-view.component.html',
  styleUrls: ['./usuario-view.component.css']
})
export class UsuarioViewComponent extends BaseResourceFormComponent<Usuario, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('tipo_') inputField: ElementRef;

  public setorAutoComplete: EddyAutoComplete<Setor>;
  public estoqueAutoComplete: EddyAutoComplete<Estoque>;
  public unidadeAutoComplete: EddyAutoComplete<Unidade>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public acessos: { sistema: Sistemas, acessos: Acesso[] }[];
  public opcoesAcesso: any[] = [
    { label: 'Sem acesso', value: 0, icon: 'lock_break.png' },
    { label: 'Visualizar', value: 1, icon: 'zoom.png' },
    { label: 'Incluir/Alterar', value: 2, icon: 'pencil.png' }
  ];
  public listaSistemas: { id: Sistemas, nome: string }[];
  public listaUsuariosCC: Usuario[];
  public acessosCC: Acesso[];
  public estoqueAtual: Estoque;
  public setorAtual: Setor;

  public indexSelecionado: number;
  public liberaCampoSetor = true;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected messageService: MessageService,
    public globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected acessoService: AcessoService,
    protected setorService: SetorService,
    protected estoqueService: EstoqueService,
    protected unidadeService: UnidadeService,
    protected favorecidoService: FavorecidoService,
    protected progressoService: ProgressoService,
    protected usuarioService: UsuarioService) {
    super(new Usuario(), injector, Usuario.converteJson, usuarioService);
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required, Validators.minLength(2)]],
      sobrenome: [null, [Validators.required, Validators.minLength(2)]],
      email: [null, [Validators.required, Validators.minLength(2)]],
      senha: [null],
      sistema: [null],
      cpf: [null, [Validators.required]],
      telefone: [null, [Validators.required]],
      ativo: [null],
      convidado: [null, [Validators.required]],
      administrador: [null, [Validators.required]],
      sabado: [true],
      domingo: [true],
      solicitacao: [null],
      setor: [null],
      estoque: [null],
      unidade: [null],
      favorecido: [null],
      horario_inicio: [null],
      horario_termino: [null],
      orgao: [null, [Validators.required]],
      acessos: [null],
      usuario_estoques: [[]],
      usuario_setores: [[]],
      login_audesp: [null],
      senha_audesp: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'orgao,acessos,setor,estoque,favorecido,unidade,usuario_estoques.estoque'
    };
  }

  protected afterLoad() {
    this.preencherAcessos(this.entidade.acessos);
    this.carregarAutoCompletes();

    this.entidadeForm.get('estoque').setValue(this.entidade.estoque);
    this.entidadeForm.get('usuario_estoques').setValue(this.entidade.usuario_estoques);

    this.usuarioService.filtrar(1, -1, {
      id: this.entidade.id,
      relations: 'usuario_setores.setor',
    }).subscribe(res => {
      const usuario_setores = res.content[0].usuario_setores as UsuarioSetor[];
      this.entidade.usuario_setores = usuario_setores.map<UsuarioSetor>(usuarioSetor => {
        const { id, nome, codigo } = usuarioSetor.setor;
        const setor: Setor = { id, nome, codigo };

        return {
          usuario: { id: this.entidade.id },
          setor
        }
      })
    });
    this.ativarSetores();
  }

  protected afterInit(): void {
    this.listaSistemas = this.globalService.obterListaSistemas();
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit(): void {
    this.entidadeForm.get('acessos').setValue(this.unificarPermissoes());
    this.entidadeForm.get('usuario_estoques').setValue(this.entidade.usuario_estoques);
    this.entidadeForm.get('usuario_setores').setValue(this.entidade.usuario_setores);

    const horario_inicio = this.entidadeForm.get('horario_inicio').value
    const horario_termino = this.entidadeForm.get('horario_termino').value
    const administrador = this.entidadeForm.get('administrador').value

    try {
      if (administrador && (horario_inicio || horario_termino)) {
        throw new Error('Administrador do Sistema não deve possuir restrição a horario de acesso')
      }
      if ((!horario_inicio && horario_termino) || (horario_inicio && !horario_termino)) {
        throw new Error('Preencha corretamente os dois campos de "Horário de acesso"')
      }
      if (horario_inicio > horario_termino) {
        throw new Error('O horário inicial não pode ser maior que o horario final')
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(entidade: Usuario): void {
    this.mensagemSucesso = `Registro salvo com sucesso!`;
    this.acessoService.filtrar(0, 0, { usuario_id: entidade.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data?.content) {
          this.entidade.acessos = data.content;
          this.afterLoad()
        }
      }, error => toastr.error(error.error.message));
    this.loadResource()
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public verificaCheck() {
    if (this.entidadeForm.get('administrador').value) {
      this.entidadeForm.get('convidado').setValue(false);
    }
    if (this.entidadeForm.get('convidado').value) {
      this.entidadeForm.get('administrador').setValue(false);
    }
    this.revelaHorarioAcesso()
  }

  public carregarAutoCompletes() {
    // autocomplete para setor

    this.setorAutoComplete = new EddyAutoComplete(this.entidadeForm.get('setor'), this.setorService,
      'id', ['codigo', 'nome'], this.carregarParametrosSetor(), { text: ['nome', 'codigo'] });

    // autocomplete para estoque
    this.estoqueAutoComplete = new EddyAutoComplete(this.entidadeForm.get('estoque'), this.estoqueService,
      'id', ['nome'],
      { orgao_id: this.login.orgao.id, ativo: true, orderBy: 'nome' }, { text: ['nome'] });

    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] });

    // autocomplete para unidade administrativa 3 setor
    this.unidadeAutoComplete = new EddyAutoComplete(this.entidadeForm.get('unidade'), this.unidadeService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] });
  }

  private carregarParametrosSetor() {
    let joinEstoques = "";
    if (this.entidade.usuario_estoques) {
      joinEstoques = this.entidade.usuario_estoques.map(ue => ue.estoque.id).join(",");
    }
    if (joinEstoques) {
      joinEstoques += "," + (this.entidadeForm.get('estoque').value ? this.entidadeForm.get('estoque').value?.id : this.entidade.estoque?.id);
    } else {
      joinEstoques += (this.entidadeForm.get('estoque').value ? this.entidadeForm.get('estoque').value?.id : this.entidade.estoque?.id);
    }
    let parametros = { orgao_id: this.login.orgao.id, relations: 'unidade,orgao,estoques', orderBy: 'nome' };
    parametros['estoques.estoque.id$in'] = joinEstoques;

    return parametros;

  }

  autorizar() {
    this.entidade = this.entidadeForm.value;

    const solicitacao = this.entidade.solicitacao;
    const ativo = this.entidade.ativo;

    this.entidade.solicitacao = false;
    this.entidade.ativo = true;

    this.usuarioService.autorizar(this.entidade).pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.entidadeForm.get('solicitacao').setValue(this.entidade.solicitacao);
        this.entidadeForm.get('ativo').setValue(this.entidade.ativo);
        this.submitForm();
      }, error => {
        this.entidade.solicitacao = solicitacao;
        this.entidade.ativo = ativo;
        toastr.error(error.error.message);
      });
  }

  private unificarPermissoes(): Acesso[] {
    const retorno: Acesso[] = [];

    // unifica os acessos dos sistemas em um só para ser salvo
    for (const acesso of this.acessos) {
      for (const acessoPagina of acesso.acessos) {
        retorno.push(acessoPagina);
      }
    }

    return retorno;
  }

  public carregarUsuariosCC() {
    this.usuarioService.filtrar(1, -1, {
      'orgao.id': this.login.orgao.id,
      id$ne: this.entidade.id,
      convidado: false,
      relations: 'acessos',
      orderBy: 'nome'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaUsuariosCC = res ? res.content : [];
      }, error => toastr.error('Atenção', error));
  }

  public importarPermissoes() {
    if (!this.acessosCC || this.acessosCC.length === 0) {
      toastr.warning('Selecione o usuário a ter as suas permissões importadas!');
      return;
    }

    const acessos: Acesso[] = this.unificarPermissoes();

    for (const acessoCC of this.acessosCC) {
      for (const acesso of acessos) {
        if (acessoCC.pagina === acesso.pagina) {
          acesso.permissao = acessoCC.permissao;
        }
      }
    }

    this.preencherAcessos(acessos);

    toastr.info('Permissões importadas!');
  }

  public preencherAcessos(acessosEntidade: Acesso[]) {
    const acessosTotal: Acesso[] = [];

    // unifica todas as paginas em um objeto para percorrer do banco de dados e criar os que nao existirem
    let paginas = this.acessoService.retornaTodasPaginas();

    for (const pagina of paginas) {
      let encontrou = false;
      if (acessosEntidade) {
        for (const acesso of acessosEntidade) {
          if (acesso.pagina === Pagina[pagina]) {
            acesso.usuario = this.login.usuario;
            acesso.nomePagina = pagina;
            acessosTotal.push(acesso);
            encontrou = true;
            break;
          }
        }
      }
      if (!encontrou) {
        const acesso = new Acesso();
        acesso.id = null;
        acesso.pagina = Pagina[pagina];
        acesso.permissao = 0;
        acesso.usuario = this.login.usuario;
        acesso.nomePagina = pagina;
        acessosTotal.push(acesso);
      }
    }

    // atribuir os acessos por sistema
    this.acessos = this.acessoService.agruparAcessosPorSistema(acessosTotal, this.login.usuario.sistemas_administrador ? this.login.usuario.sistemas_administrador : ' ');

    this.entidadeForm.get('acessos').setValue(this.acessos);
  }

  public adicionarUsuarioEstoque() {
    if (!this.estoqueAtual) return;

    if (!this.entidade.usuario_estoques) this.entidade.usuario_estoques = [];

    if (this.entidade.usuario_estoques.find(m => m.estoque.id === this.estoqueAtual.id)) {
      toastr.warning(`Estoque ${this.estoqueAtual.nome} já vinculado ao usuário!`);
    } else {
      this.entidade.usuario_estoques.push({ estoque: this.estoqueAtual });
    }
    this.estoqueAtual = undefined
    this.carregarAutoCompletes();
  }

  public removerUsuarioEstoque(item: UsuarioEstoque, index: number) {
    if (!item) return
    this.entidade.usuario_estoques.splice(index, 1);
    this.carregarAutoCompletes();
  }

  public adicionarUsuarioSetor() {
    if (!this.setorAtual) return;

    if (!this.entidade.usuario_setores) this.entidade.usuario_setores = [];

    if (this.entidade.usuario_setores.find(m => m.setor.id === this.setorAtual.id)) {
      toastr.warning(`Setor ${this.setorAtual.nome} já vinculado ao usuário!`);
    } else {
      this.entidade.usuario_setores.push({ setor: this.setorAtual });
    }
    this.setorAtual = undefined
  }

  public removerUsuarioSetor(item: UsuarioSetor, index: number) {
    if (!item) return

    this.entidade.usuario_setores.splice(index, 1);
  }

  public revelaHorarioAcesso() {
    const horario_inicio = this.entidadeForm.get('horario_inicio').value ? this.entidadeForm.get('horario_inicio').value : this.entidade.horario_inicio
    const horario_termino = this.entidadeForm.get('horario_termino').value ? this.entidadeForm.get('horario_termino').value : this.entidade.horario_termino
    const administrador = this.entidadeForm.get('administrador').value

    if (horario_inicio || horario_termino || !administrador) return true
    else return false
  }

  public desabilitar() {
    if (this.entidade.solicitacao) {
      return 'disabled';
    }
  }

  public vincularSetores(): void {
    try {
      if (this.liberaCampoSetor) {
        throw new Error('Preencha o estoque primeiro');
      }

      const parametros = Object.assign({}, this.carregarParametrosSetor());
      delete parametros.relations;

      this.setorService.filtrar(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          const setores = res.content as Setor[];

          const usuario = Object.assign({}, this.entidade);

          delete usuario.usuario_setores;

          const usuario_setores = setores.map<UsuarioSetor>(setor => {
            const { id, nome, codigo } = setor;
            const mappedSetor: Setor = { id, nome, codigo };
            return {
              setor: mappedSetor,
              usuario: { id: usuario.id }
            };
          });

          this.entidade.usuario_setores = usuario_setores;

          toastr.success('Setores atribuídos com sucesso');
        })
    } catch (err) {
      if (err instanceof Error) {
        toastr.error(err.message);
        console.error(err);
      } else {
        this.acaoErro(err);
      }
    }
  }

  public ativarSetores(): void {
    const estoqueForm = this.entidadeForm.get('estoque').value as Estoque;
    const estoquesForm = this.entidadeForm.get('usuario_estoques').value as Estoque[];

    this.liberaCampoSetor = (!estoquesForm.length && !estoqueForm);
  }

}
