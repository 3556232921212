import { Injectable, OnDestroy } from "@angular/core";
import { Exercicio, FuncaoService, GlobalService, LoginContabil, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AnexoLoaService } from "../service/anexo-loa.service";

@Injectable({
  providedIn: 'root'
})
export class QddOrcamento implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(exercicio: Exercicio, orgaos: number[], parametros: {}) {
    this.anexoServico.obterQddOrcamento(exercicio?.id, orgaos, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'QDD - QUADRO DETALHAMENTO DA DESPESA (Orçamento)',
          this.login.usuario.nome,
          this.login.usuario.sobrenome,
          this.login.orgao.nome,
          this.login.brasao,
          this.conteudo(dados, parametros),
          'landscape', 'QUADRO DETALHADO DA DESPESA (Orçamento)',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          }, undefined, undefined, undefined, `EXERCÍCIO: ${exercicio.ano}`);
      });
  }

  private conteudo(dados: any[], parametros: {}): {}[] {
    //11
    // monta o cabecalho
    if (parametros['agrupamento'] === 'T') {
      const registros: {}[] = [
        [
          // { text: `FICHA`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `CÓDIGO`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `ESPECIFICAÇÕES`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `Orç. Criança Adolescente`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `Publi. Institucional`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `Publi. Atos Legais`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `VALORES`, alignment: 'center', colSpan: 4, bold: true, border: [true, true, true, false] },
          '', '', '',
          { text: `%`, alignment: 'center', bold: true, border: [true, true, true, false] }
        ], [
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          // { text: '', border: [true, false, true, true] },
          { text: 'PROJETOS', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'ATIVIDADES', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'OPERAÇÕES ESPECIAIS', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'TOTAIS', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: '', border: [true, false, true, true] },
        ]
      ];

      //Totalizadores gerais
      let totalOrcado = +this.funcaoService.totalizar(dados, ['vl_orcado'])['vl_orcado'];
      let totalCrianca = 0;
      let totalInstitucional = 0;
      let totalAtosLegais = 0;
      let totalProjetos = 0;
      let totalAtividades = 0;
      let totalOperacoesEspeciais = 0;

      // monta o agrupamento por unidade (Principal)
      const grupoUnidade = this.funcaoService.agrupar(dados, ['id_executora', 'executora'], ['vl_orcado']);
      const totalUnidade = grupoUnidade.length;
      for (let indexUnidade = 0; indexUnidade < totalUnidade; indexUnidade++) {
        const unidade = grupoUnidade[indexUnidade];
        let percentualUnidade = 0;
        unidade.registros.forEach(und => {
          percentualUnidade += (+und.vl_orcado * 100) / +totalOrcado;
        });

        let unid_total_inst = unidade.registros.reduce((acc, item) => {
          if (item.publicidade_institucional) return +item.vl_orcado + acc
          else return acc
        }, 0)
        let unid_total_legal = unidade.registros.reduce((acc, item) => {
          if (item.publicidade_legal) return +item.vl_orcado + acc
          else return acc
        }, 0)

        let total_orc_crianca = unidade.registros.reduce((acc, item) => {
          if (item?.orcamento_crianca) {
            if (item?.exclusivo_crianca === 'S') {
              return +item.vl_orcado + +acc
            };
            if (+item?.orcamento_crianca > 0) {
              return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
            };
          };
          return acc
        }, 0)

        totalCrianca += total_orc_crianca
        totalInstitucional += unid_total_inst
        totalAtosLegais += unid_total_legal

        registros.push([
          // { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
          {
            text: unidade ? this.funcaoService.mascarar('##.##.##', unidade.grupo['id_executora']) : '',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          { text: unidade ? unidade.grupo['executora'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
          {
            text: this.funcaoService.convertToBrNumber(total_orc_crianca),
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(unid_total_inst),
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(unid_total_legal),
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: unidade ? this.funcaoService.convertToBrNumber(+unidade.totalizadores['vl_orcado']) : '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(percentualUnidade), alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, true, false]
          }
        ]);

        // monta o agrupamento por funcional (Secundário)
        const grupoFuncional = this.funcaoService.agrupar(unidade.registros, ['funcional', 'projeto'], ['vl_orcado']);
        const totalFuncional = grupoFuncional.length;
        for (let indexFuncional = 0; indexFuncional < totalFuncional; indexFuncional++) {
          const funcional = grupoFuncional[indexFuncional];
          let percentualFuncional = 0;
          funcional.registros.forEach(f => {
            percentualFuncional += (+f.vl_orcado * 100) / +totalOrcado;
          });

          let fun_total_inst = funcional.registros.reduce((acc, item) => {
            if (item.publicidade_institucional) return +item.vl_orcado + acc
            else return acc
          }, 0)
          let fun_total_legal = funcional.registros.reduce((acc, item) => {
            if (item.publicidade_legal) return +item.vl_orcado + acc
            else return acc
          }, 0)

          let total_orc_crianca = funcional.registros.reduce((acc, item) => {
            if (item?.orcamento_crianca) {
              if (item?.exclusivo_crianca === 'S') {
                return +item.vl_orcado + +acc
              };
              if (+item?.orcamento_crianca > 0) {
                return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
              };
            };
            return acc
          }, 0)

          registros.push([
            // { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['funcional'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['projeto'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
            {
              text: this.funcaoService.convertToBrNumber(total_orc_crianca),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(fun_total_inst),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(fun_total_legal),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['vl_orcado']) : '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(percentualFuncional), alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, true, false]
            }
          ]);

          // monta o agrupamento por recurso (Terciário)
          const grupoRecurso = this.funcaoService.agrupar(funcional.registros, ['id_recurso', 'recurso'], ['vl_orcado']);
          const totalRecurso = grupoRecurso.length;
          for (let indexRecurso = 0; indexRecurso < totalRecurso; indexRecurso++) {
            const recurso = grupoRecurso[indexRecurso];
            let percentualRecurso = 0;
            recurso.registros.forEach(rec => {
              percentualRecurso += (+rec.vl_orcado * 100) / +totalOrcado;
            });

            let rec_total_inst = recurso.registros.reduce((acc, item) => {
              if (item.publicidade_institucional) return +item.vl_orcado + acc
              else return acc
            }, 0)
            let rec_total_legal = recurso.registros.reduce((acc, item) => {
              if (item.publicidade_legal) return +item.vl_orcado + acc
              else return acc
            }, 0)

            let total_orc_crianca = recurso.registros.reduce((acc, item) => {
              if (item?.orcamento_crianca) {
                if (item?.exclusivo_crianca === 'S') {
                  return +item.vl_orcado + +acc
                };
                if (+item?.orcamento_crianca > 0) {
                  return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
                };
              };
              return acc
            }, 0)

            registros.push([
              // { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['id_recurso'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['recurso'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
              {
                text: this.funcaoService.convertToBrNumber(total_orc_crianca),
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(rec_total_inst),
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(rec_total_legal),
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['vl_orcado']) : '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(percentualRecurso), alignment: 'right',
                bold: true, fontSize: 8, border: [true, false, true, false]
              }
            ]);

            // monta o agrupamento por despesa (Quaternário)
            const grupoDespesa = this.funcaoService.agrupar(recurso.registros, ['id_despesa', 'despesa'], ['vl_orcado']);
            const totalDespesa = grupoDespesa.length;
            for (let indexDespesa = 0; indexDespesa < totalDespesa; indexDespesa++) {
              const despesa = grupoDespesa[indexDespesa];
              let percentualDespesa = 0;
              despesa.registros.forEach(rec => {
                percentualDespesa += (+rec.vl_orcado * 100) / +totalOrcado;
              });

              let rec_total_inst = despesa.registros.reduce((acc, item) => {
                if (item.publicidade_institucional) return +item.vl_orcado + acc
                else return acc
              }, 0);

              let rec_total_legal = despesa.registros.reduce((acc, item) => {
                if (item.publicidade_legal) return +item.vl_orcado + acc
                else return acc
              }, 0);

              let total_orc_crianca = despesa.registros.reduce((acc, item) => {
                if (item?.orcamento_crianca) {
                  if (item?.exclusivo_crianca === 'S') {
                    return +item.vl_orcado + +acc
                  };
                  if (+item?.orcamento_crianca > 0) {
                    return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
                  };
                };
                return acc
              }, 0)

              let projetos = 0;
              let atividades = 0;
              let operacoes_especiais = 0;
              despesa.registros
                .filter(item => ['1', '5', '7', '9'].includes(item.id_projeto.substring(0, 1)))
                .forEach(item => {
                  projetos += +item.vl_orcado;
                  totalProjetos += +item.vl_orcado;
                });
              despesa.registros
                .filter(item => ['2', '4', '6', '8'].includes(item.id_projeto.substring(0, 1)))
                .forEach(item => {
                  atividades += +item.vl_orcado;
                  totalAtividades += +item.vl_orcado;
                });
              despesa.registros
                .filter(item => ['3'].includes(item.id_projeto.substring(0, 1)))
                .forEach(item => {
                  operacoes_especiais += +item.vl_orcado;
                  totalOperacoesEspeciais += +item.vl_orcado;
                });

              registros.push([
                // { text: element1 ? element1.id_ficha : '', alignment: 'center', fontSize: 8, border: [true, false, false, false] },
                {
                  text: despesa ? this.funcaoService.mascarar('#.#.##.##.##', despesa.grupo['id_despesa']) : '',
                  fontSize: 8, border: [true, false, false, false]
                },
                { text: despesa ? despesa.grupo['despesa'] : '', fontSize: 8, border: [true, false, false, false] },
                {
                  text: despesa ? this.funcaoService.convertToBrNumber(total_orc_crianca) : '',
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                { text: this.funcaoService.convertToBrNumber(rec_total_inst), alignment: 'right', fontSize: 8, border: [true, false, true, false] },
                { text: this.funcaoService.convertToBrNumber(rec_total_legal), alignment: 'right', fontSize: 8, border: [true, false, true, false] },
                {
                  text: this.funcaoService.convertToBrNumber(projetos),
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: this.funcaoService.convertToBrNumber(atividades),
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: this.funcaoService.convertToBrNumber(operacoes_especiais),
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: despesa ? this.funcaoService.convertToBrNumber(despesa.totalizadores['vl_orcado']) : '',
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: this.funcaoService.convertToBrNumber(percentualDespesa), alignment: 'right',
                  fontSize: 8, border: [true, false, true, false]
                }
              ]);
            }
          }
        }
      }

      const totalGeral = totalOrcado;
      registros.push(
        [
          { text: 'TOTAL GERAL...', border: [true, true, true, true], fontSize: 8, bold: true, colSpan: 2 },
          { text: '', border: [false, true, false, true] },
          // { text: '', border: [false, true, false, true] },
          { text: this.funcaoService.convertToBrNumber(totalCrianca), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalInstitucional), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalAtosLegais), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalProjetos), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalAtividades), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalOperacoesEspeciais), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalGeral), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: '', alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true }
        ]
      );

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 2,
          widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: registros
        }
      }];
    } else {
      const registros: {}[] = [
        [
          { text: `FICHA`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `CÓDIGO`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `ESPECIFICAÇÕES`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `Orç. Criança Adolescente`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `Publi. Institucional`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `Publi. Atos Legais`, alignment: 'center', bold: true, border: [true, true, true, false] },
          { text: `VALORES`, alignment: 'center', colSpan: 4, bold: true, border: [true, true, true, false] },
          '', '', '',
          { text: `%`, alignment: 'center', bold: true, border: [true, true, true, false] }
        ], [
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: '', border: [true, false, true, true] },
          { text: 'PROJETOS', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'ATIVIDADES', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'OPERAÇÕES ESPECIAIS', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'TOTAIS', alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: '', border: [true, false, true, true] },
        ]
      ];

      //Totalizadores gerais
      let totalOrcado = +this.funcaoService.totalizar(dados, ['vl_orcado'])['vl_orcado'];
      let totalCrianca = 0;
      let totalInstitucional = 0;
      let totalAtosLegais = 0;
      let totalProjetos = 0;
      let totalAtividades = 0;
      let totalOperacoesEspeciais = 0;

      // monta o agrupamento por unidade (Principal)
      const grupoUnidade = this.funcaoService.agrupar(dados, ['id_executora', 'executora'], ['vl_orcado']);
      const totalUnidade = grupoUnidade.length;
      for (let indexUnidade = 0; indexUnidade < totalUnidade; indexUnidade++) {
        const unidade = grupoUnidade[indexUnidade];
        let percentualUnidade = 0;
        unidade.registros.forEach(und => {
          percentualUnidade += (+und.vl_orcado * 100) / +totalOrcado;
        });

        let unid_total_inst = unidade.registros.reduce((acc, item) => {
          if (item.publicidade_institucional) return +item.vl_orcado + acc
          else return acc
        }, 0)
        let unid_total_legal = unidade.registros.reduce((acc, item) => {
          if (item.publicidade_legal) return +item.vl_orcado + acc
          else return acc
        }, 0)
        let total_orc_crianca = unidade.registros.reduce((acc, item) => {
          if (item?.orcamento_crianca) {
            if (item?.exclusivo_crianca === 'S') {
              return +item.vl_orcado + +acc
            };
            if (+item?.orcamento_crianca > 0) {
              return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
            };
          };
          return acc
        }, 0)

        totalCrianca += total_orc_crianca
        totalInstitucional += unid_total_inst
        totalAtosLegais += unid_total_legal

        registros.push([
          { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
          {
            text: unidade ? this.funcaoService.mascarar('##.##.##', unidade.grupo['id_executora']) : '',
            bold: true, fontSize: 8, border: [true, false, false, false]
          },
          { text: unidade ? unidade.grupo['executora'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
          {
            text: this.funcaoService.convertToBrNumber(total_orc_crianca),
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(unid_total_inst),
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(unid_total_legal),
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: unidade ? this.funcaoService.convertToBrNumber(+unidade.totalizadores['vl_orcado']) : '',
            alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(percentualUnidade), alignment: 'right',
            bold: true, fontSize: 8, border: [true, false, true, false]
          }
        ]);

        // monta o agrupamento por funcional (Secundário)
        const grupoFuncional = this.funcaoService.agrupar(unidade.registros, ['funcional', 'projeto'], ['vl_orcado']);
        const totalFuncional = grupoFuncional.length;
        for (let indexFuncional = 0; indexFuncional < totalFuncional; indexFuncional++) {
          const funcional = grupoFuncional[indexFuncional];
          let percentualFuncional = 0;
          funcional.registros.forEach(f => {
            percentualFuncional += (+f.vl_orcado * 100) / +totalOrcado;
          });

          let fun_total_inst = funcional.registros.reduce((acc, item) => {
            if (item.publicidade_institucional) return +item.vl_orcado + acc
            else return acc
          }, 0)
          let fun_total_legal = funcional.registros.reduce((acc, item) => {
            if (item.publicidade_legal) return +item.vl_orcado + acc
            else return acc
          }, 0)
          let total_orc_crianca = funcional.registros.reduce((acc, item) => {
            if (item?.orcamento_crianca) {
              if (item?.exclusivo_crianca === 'S') {
                return +item.vl_orcado + +acc
              };
              if (+item?.orcamento_crianca > 0) {
                return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
              };
            };
            return acc
          }, 0)

          registros.push([
            { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['funcional'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['projeto'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
            {
              text: this.funcaoService.convertToBrNumber(total_orc_crianca),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(fun_total_inst),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(fun_total_legal),
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['vl_orcado']) : '',
              alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(percentualFuncional), alignment: 'right',
              bold: true, fontSize: 8, border: [true, false, true, false]
            }
          ]);

          // monta o agrupamento por recurso (Terciário)
          const grupoRecurso = this.funcaoService.agrupar(funcional.registros, ['id_recurso', 'recurso'], ['vl_orcado']);
          const totalRecurso = grupoRecurso.length;
          for (let indexRecurso = 0; indexRecurso < totalRecurso; indexRecurso++) {
            const recurso = grupoRecurso[indexRecurso];
            let percentualRecurso = 0;
            recurso.registros.forEach(rec => {
              percentualRecurso += (+rec.vl_orcado * 100) / +totalOrcado;
            });

            let rec_total_inst = recurso.registros.reduce((acc, item) => {
              if (item.publicidade_institucional) return +item.vl_orcado + acc
              else return acc
            }, 0)
            let rec_total_legal = recurso.registros.reduce((acc, item) => {
              if (item.publicidade_legal) return +item.vl_orcado + acc
              else return acc
            }, 0)
            let total_orc_crianca = recurso.registros.reduce((acc, item) => {
              if (item?.orcamento_crianca) {
                if (item?.exclusivo_crianca === 'S') {
                  return +item.vl_orcado + +acc
                };
                if (+item?.orcamento_crianca > 0) {
                  return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
                };
              };
              return acc
            }, 0)

            registros.push([
              { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['id_recurso'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['recurso'] : '', bold: true, fontSize: 8, border: [true, false, false, false] },
              {
                text: this.funcaoService.convertToBrNumber(total_orc_crianca),
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(rec_total_inst),
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(rec_total_legal),
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['vl_orcado']) : '',
                alignment: 'right', bold: true, fontSize: 8, border: [true, false, true, false]
              },
              {
                text: this.funcaoService.convertToBrNumber(percentualRecurso), alignment: 'right',
                bold: true, fontSize: 8, border: [true, false, true, false]
              }
            ]);

            // monta o agrupamento por despesa (Quaternário)
            const grupoDespesa = this.funcaoService.agrupar(recurso.registros, ['id_despesa', 'despesa', 'id_ficha'], ['vl_orcado']);
            const totalDespesa = grupoDespesa.length;
            for (let indexDespesa = 0; indexDespesa < totalDespesa; indexDespesa++) {
              const despesa = grupoDespesa[indexDespesa];
              let percentualDespesa = 0;
              despesa.registros.forEach(rec => {
                percentualDespesa += (+rec.vl_orcado * 100) / +totalOrcado;
              });

              let rec_total_inst = despesa.registros.reduce((acc, item) => {
                if (item.publicidade_institucional) return +item.vl_orcado + acc
                else return acc
              }, 0);

              let rec_total_legal = despesa.registros.reduce((acc, item) => {
                if (item.publicidade_legal) return +item.vl_orcado + acc
                else return acc
              }, 0);

              let total_orc_crianca = despesa.registros.reduce((acc, item) => {
                if (item?.orcamento_crianca) {
                  if (item?.exclusivo_crianca === 'S') {
                    return +item.vl_orcado + +acc
                  };
                  if (+item?.orcamento_crianca > 0) {
                    return (+item.vl_orcado * (+item.porcentagem_crianca / 100)) + +acc
                  };
                };
                return acc
              }, 0)

              let projetos = 0;
              let atividades = 0;
              let operacoes_especiais = 0;
              despesa.registros
                .filter(item => ['1', '5', '7', '9'].includes(item.id_projeto.substring(0, 1)))
                .forEach(item => {
                  projetos += +item.vl_orcado;
                  totalProjetos += +item.vl_orcado;
                });
              despesa.registros
                .filter(item => ['2', '4', '6', '8'].includes(item.id_projeto.substring(0, 1)))
                .forEach(item => {
                  atividades += +item.vl_orcado;
                  totalAtividades += +item.vl_orcado;
                });
              despesa.registros
                .filter(item => ['3'].includes(item.id_projeto.substring(0, 1)))
                .forEach(item => {
                  operacoes_especiais += +item.vl_orcado;
                  totalOperacoesEspeciais += +item.vl_orcado;
                });

              registros.push([
                { text: despesa ? despesa.grupo['id_ficha'] : '', alignment: 'center', fontSize: 8, border: [true, false, false, false] },
                {
                  text: despesa ? this.funcaoService.mascarar('#.#.##.##.##', despesa.grupo['id_despesa']) : '',
                  fontSize: 8, border: [true, false, false, false]
                },
                { text: despesa ? despesa.grupo['despesa'] : '', fontSize: 8, border: [true, false, false, false] },
                {
                  text: despesa ? this.funcaoService.convertToBrNumber(total_orc_crianca) : '',
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                { text: this.funcaoService.convertToBrNumber(rec_total_inst), alignment: 'right', fontSize: 8, border: [true, false, true, false] },
                { text: this.funcaoService.convertToBrNumber(rec_total_legal), alignment: 'right', fontSize: 8, border: [true, false, true, false] },
                {
                  text: this.funcaoService.convertToBrNumber(projetos),
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: this.funcaoService.convertToBrNumber(atividades),
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: this.funcaoService.convertToBrNumber(operacoes_especiais),
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: despesa ? this.funcaoService.convertToBrNumber(despesa.totalizadores['vl_orcado']) : '',
                  alignment: 'right', fontSize: 8, border: [true, false, true, false]
                },
                {
                  text: this.funcaoService.convertToBrNumber(percentualDespesa), alignment: 'right',
                  fontSize: 8, border: [true, false, true, false]
                }
              ]);
            }
          }
        }
      }

      const totalGeral = totalOrcado;
      registros.push(
        [
          { text: 'TOTAL GERAL...', border: [true, true, true, true], fontSize: 8, bold: true, colSpan: 2 },
          { text: '', border: [false, true, false, true] },
          { text: '', border: [false, true, false, true] },
          { text: this.funcaoService.convertToBrNumber(totalCrianca), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalInstitucional), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalAtosLegais), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalProjetos), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalAtividades), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalOperacoesEspeciais), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: this.funcaoService.convertToBrNumber(totalGeral), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
          { text: '', alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true }
        ]
      );

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 2,
          widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: registros
        }
      }];
    }
  }
}
