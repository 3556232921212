import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { CompraItemService } from 'compra-lib';
import { Contrato, FuncaoService, Login, ParametroRequisicaoService, Rcms, RcmsCotacao, RcmsFavorecido, RcmsItem } from 'eddydata-lib';
import { Workbook, Worksheet } from 'exceljs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;


type VisualizacaoItem = {
  descricao?: String,
  valor_unitario?: number,
  quantidade?: number,
  rcmsItem?: RcmsItem,
  contrato?: Contrato,
  contratoItens?
  rcmsFavorecido?: RcmsFavorecido,
  valor_referencia?: number
}

type Visualizacao = {
  descricao?: String,
  contrato_numero?: number,
  itens?: VisualizacaoItem[],
}

@Component({
  selector: 'app-rcms-cotacao',
  templateUrl: './rcms-cotacao.component.html'
})
export class RcmsCotacaoComponent implements OnInit, OnChanges {


  public imaskValor = {
    mask: Number,
    scale: 6,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public erro: boolean

  public ordenarOrdemAlfabetica: boolean = false
  private indexInicial = 5;
  public valorTotal = 0;

  @ViewChildren('valor_unitario_') valorInput: QueryList<ElementRef> = new QueryList();


  @Input() public login: Login;
  @Input() somenteVisualizar: boolean = false;

  @Input() rcms: Rcms;
  @Input() itensContrato: any;

  @Input() itens: RcmsItem[];
  @Output() itensChange: EventEmitter<RcmsItem[]> = new EventEmitter();

  @Input() favorecidos: RcmsFavorecido[];
  @Output() callback: EventEmitter<RcmsItem[]> = new EventEmitter();

  @Input() saldoFicha: number

  public favorecido: RcmsFavorecido;
  public visualizacoes: Visualizacao[] = [];

  public listTipos: any[];
  public listOpcoes: any[];

  public tipo: 'I' | 'F' = 'F';
  public opcao: 'T' | 'V' | 'P' | 'E' = 'T';

  public erros: string[];

  public editavel: boolean = false;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    private compraItemService: CompraItemService,
    private parametroRequisicaoService: ParametroRequisicaoService
  ) {
    this.listTipos = [
      { icon: 'fa fa-user-friends', nome: 'Fornecedor', tipo: 'F' },
      { icon: 'fa fa-boxes', nome: 'Itens', tipo: 'I' }
    ];
    this.listOpcoes = [
      { nome: 'TODOS', opcao: 'T' },
      { nome: 'VITORIAS', opcao: 'V' },
      // { nome: 'PERDAS', opcao: 'P' },
      // { nome: 'EMPATES', opcao: 'E' }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rcms'] && changes['rcms'].currentValue['submitSend']) return
    this.carregarCotacoes(true);
  }

  public ordenarItens() {
    if (this.favorecido) {
      if (this.ordenarOrdemAlfabetica === true) {
        let clone = Object.assign([], this.favorecido?.cotacoes)
        clone.sort((item1, item2) => {
          if (item1.rcmsItem.produto_unidade.produto.nome > item2.rcmsItem.produto_unidade.produto.nome) {
            return 1
          } else {
            return -1
          }
        })
        this.favorecido.cotacoes = clone
      } else {
        let clone = Object.assign([], this.favorecido?.cotacoes)
        clone.sort((item1, item2) => {
          return item1.rcmsItem.ordem - item2.rcmsItem.ordem
        })
        this.favorecido.cotacoes = clone
      }
    }
  }

  ngOnInit(): void { }

  public visulizarCotacao() {
    let visualizacoes: Visualizacao[] = [];
    if (!this.itens)
      return [];
    for (let rcmsItem of this.itens) {

      if (!rcmsItem.cotacoes)
        continue;
      if (!this.rcms) this.rcms = rcmsItem.rcms;
      for (let cotacao of rcmsItem.cotacoes) {
        let visualizacao: Visualizacao = {};
        visualizacao.contrato_numero = +this.rcms.contrato?.numero;

        visualizacao.itens = [];
        let item: VisualizacaoItem = {};
        item.contrato = this.rcms.contrato;

        if (!cotacao.valor_unitario)
          continue;

        item.rcmsItem = rcmsItem;
        item.rcmsFavorecido = cotacao.rcmsFavorecido;
        item.quantidade = rcmsItem.quantidade;
        item.valor_unitario = cotacao.valor_unitario;
        item['valorTotal'] = item.quantidade * item.valor_unitario;
        item.valor_referencia = cotacao?.valor_referencia ?? 0;
        item['vencedor'] = cotacao.vencedor

        if (this.tipo === 'F') {
          visualizacao.descricao = cotacao.rcmsFavorecido.favorecido.nome;
          item.descricao = `${rcmsItem.produto_unidade.produto.nome} - ${rcmsItem.produto_unidade.unidade.nome}`;
        } else {
          visualizacao.descricao = `${rcmsItem.produto_unidade.produto.nome} - ${rcmsItem.produto_unidade.unidade.nome}`;
          item.descricao = cotacao.rcmsFavorecido.favorecido.nome;
        }
        let visua = visualizacoes.find((v) => v.descricao === visualizacao.descricao);
        if (visua)
          visualizacao = visua;
        visualizacao.itens.push(item);
        if (!visua)
          visualizacoes.push(visualizacao);
      }
    }

    this.visualizacoes = visualizacoes;
  }

  public atualizarValorTotal(item: VisualizacaoItem) {
    item.quantidade = item['valorTotal'] / item.valor_unitario;
    this.itens.map(i => {
      if (item.rcmsItem.produto_unidade.produto.id === i.produto_unidade.produto.id) {
        i.quantidade = item.quantidade
      }
    })
    // this.callback.emit(this.itens)
  }

  public removerTodas() {
    if (this.visualizacoes) {
      this.visualizacoes.forEach((v) => this.removerLista(v.itens));
    }
  }


  public removerLista(itens: VisualizacaoItem[]) {
    if (itens) {
      itens.forEach((i) => this.remover(i.rcmsItem?.produto_unidade?.id, i.rcmsFavorecido.favorecido.id));
    }
  }

  public async remover(produtoUnidadeId: number, favorecidoId: number) {
    if (!produtoUnidadeId || !favorecidoId)
      return;
    await this.carregarCotacoes();
    if (!this.favorecidos)
      return;
    let favorecido = this.favorecidos.find((f) => f.favorecido.id === favorecidoId);
    if (!favorecido || !favorecido.cotacoes)
      return;
    let cotacao = favorecido.cotacoes.find((c) => c.rcmsItem.produto_unidade.id === produtoUnidadeId);
    if (!cotacao)
      return;
    cotacao.valor_unitario = 0;
    this.confirmar();
  }

  public async carregarCotacoes(visualizar?: boolean, confirm?: boolean, limparfavorecido: boolean = true) {
    if (limparfavorecido)
      this.favorecido = null;
    if (this.favorecidos && this.favorecidos.length > 0)
      for (let f of this.favorecidos) {
        f.cotacoes = [];
      }
    if (!this.favorecidos || this.favorecidos.length === 0 || !this.itens || this.itens.length === 0)
      return;
    for (let favorecido of this.favorecidos) {
      if (!favorecido.cotacoes)
        favorecido.cotacoes = [];
      for (let item of this.itens) {
        let cotacao = favorecido
          .cotacoes.find((c) => item.produto_unidade?.id ?
            c.rcmsItem.produto_unidade.id === item.produto_unidade.id
            : (c.rcmsItem.produto_unidade.produto.nome === item.produto_unidade.produto.nome ///ajuste de conversão serviços não possui cadastro
              && c.rcmsItem.produto_unidade.unidade.nome === item.produto_unidade.unidade.nome));
        let cotacaoItem = !item.cotacoes ? null : item.cotacoes.find((c) => c.rcmsFavorecido.favorecido.id === favorecido.favorecido.id);
        if (!cotacao && cotacaoItem) {
          cotacaoItem.rcmsItem = item;
          favorecido.cotacoes.push(cotacaoItem);
          // delete favorecido.cotacoes[favorecido.cotacoes.length - 1].rcmsFavorecido.cotacoes
        } else if (!cotacao) {
          cotacao = new RcmsCotacao();
          cotacao.rcmsItem = item;
          cotacao.valor_unitario = 0.0;
          favorecido.cotacoes.push(cotacao);
        }
      }
    }
    if (visualizar)
      this.visulizarCotacao();
    if (confirm)
      this.confirmar();
  }

  public async confirmar(redefinirVencedor?: boolean) {
    if (this.favorecidos) {
      for (let i of this.itens) {
        i.cotacoes = [];
      }
      for (let f of this.favorecidos) {
        if (f.cotacoes)
          for (let c of f.cotacoes) {
            if (redefinirVencedor) c.vencedor = false
            c.rcmsFavorecido = f;
            let item = this.itens.find((i) => c.rcmsItem.produto_unidade?.id ?
              i.produto_unidade.id === c.rcmsItem.produto_unidade.id
              : (i.produto_unidade?.produto?.nome == c.rcmsItem?.produto_unidade?.produto?.nome ///ajuste de conversão serviços não possui cadastro
                && i.produto_unidade?.unidade?.nome == c.rcmsItem?.produto_unidade?.unidade?.nome));
            if (item) {
              item.cotacoes.push(c);
            }
          }
      }
      if (redefinirVencedor) await this.definirVencedor(this.itens)
      this.visulizarCotacao();
      this.fechar();
      this.itensChange.emit(this.itens);
      this.callback.emit(this.itens);
    }
  }

  public async definirVencedor(itens: RcmsItem[]) {

    itens.forEach(item => {
      if (item.cotacoes.length > 1 && !item.cotacoes.find(c => c.vencedor)) {

        // ordeno as cotações pelo menor valor
        item.cotacoes.sort((a, b) => +a.valor_unitario - +b.valor_unitario)

        // define a posição do menor valor sendo ele maior que zero. Se não existir retorna -1
        let idxMenorValor: number = item.cotacoes.findIndex(c => +c.valor_unitario > 0)

        // verifica se a posição do vencedor é valida
        if (idxMenorValor !== -1) {

          // verifico se a posição do menor valor possui cotação depois dele. Se não existe cai no else e o idxMenorValor é o vencedor.
          // se há cotações depois de idxMenorValor verifico se o valor do proximo é igual a ele. Se não for, cai no else e idxMenorValor é o vencedor.
          if (idxMenorValor < (item.cotacoes.length - 1)
            && +item.cotacoes[idxMenorValor].valor_unitario === +item.cotacoes[idxMenorValor + 1].valor_unitario) {

            item.cotacoes.forEach(c => {

              // classifico todas as cotações com o mesmo valor do [idxMenorValor] como empate
              if (+c.valor_unitario === +item.cotacoes[idxMenorValor].valor_unitario) c['empate'] = true
            })

          } else {
            // se a próxima cotação não tem o mesmo valor da primeira e 
            // se o valor unitário não estiver zerado, significa que a primeira é o menor valor e o vencedor
            item.cotacoes[idxMenorValor].vencedor = true
          }
        }

      } else if (item.cotacoes.length === 1 && +item.cotacoes[0].valor_unitario > 0) {
        // se há apenas uma cotação e o valor unitário não estiver zerado, então ela é a vencedora
        item.cotacoes[0].vencedor = true
      }
    })

  }


  fechar() {
    this.favorecido = null;
    $('#dialogCotacoesCad').modal('hide');
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public async visualizar() {

    const parametros = await this.parametroRequisicaoService.obter({ orgao_id: this.login.orgao.id }).toPromise();

    if (this.saldoFicha <= 0 && !parametros.nao_usar_ficha_despesa) {
      toastr.warning(`Ficha sem saldo suficiente.`)
      return
    }

    this.favorecidos?.reverse()
    this.carregarCotacoes();
    $('#dialogCotacoesCad').modal('show');
  }

  public vencedor(visualizacaoItem: VisualizacaoItem): 'V' | 'P' | 'E' {
    if (!visualizacaoItem || visualizacaoItem.valor_unitario <= 0)
      return 'P';
    for (let cotacao of visualizacaoItem.rcmsItem.cotacoes
      .sort((a, b) => a.valor_unitario - b.valor_unitario)) {
      if (cotacao.valor_unitario <= 0)
        continue;
      if (cotacao.rcmsFavorecido.favorecido.id === visualizacaoItem.rcmsFavorecido.favorecido.id)
        continue;
      if (+cotacao.valor_unitario === +visualizacaoItem.valor_unitario)
        return 'E';
      if (+cotacao.valor_unitario < +visualizacaoItem.valor_unitario)
        return 'P';
    }
    return 'V';
  }

  public somaItens(): { quantidade: number, valor_unitario: number, valor_total: number } {
    let soma = { quantidade: 0, valor_unitario: 0, valor_total: 0 };
    if (!this.favorecidos || this.favorecidos.length === 0)
      return soma;
    for (let visualizacao of this.visualizacoes) {
      for (let item of visualizacao.itens) {
        if ((this.opcao === 'V' && this.vencedor(item) !== 'V')
          || (this.opcao === 'P' && this.vencedor(item) !== 'P')
          || (this.opcao === 'E' && this.vencedor(item) !== 'E'))
          continue;
        soma.quantidade += +item.quantidade;
        soma.valor_unitario += +item.valor_unitario;
        soma.valor_total += +item.valor_unitario * +item.quantidade;
      }
    }
    return soma;
  }


  public async onUpload(event: any, fileUpload: any) {
    if (!this.favorecido) {
      toastr.warning('Nenhum favorecido selecionado para importação!')
      return;
    }
    if (!event.files || event.files.length === 0) {
      toastr.warning('Nenhum arquivo selecionado!')
      return;
    }

    try {
      // variavel para guardar informacoes do mapa de precos
      const regXlsx: { ordem: number, valor: number }[] = [];
      this.erros = [];

      // le mapa de preços enviado (xlsx)
      const file: File = event.files[0];
      const wb = new Workbook();
      await wb.xlsx.load(await file.arrayBuffer());
      const ws: Worksheet = wb.getWorksheet('Requisição');

      const idXlsx = ws.getCell('C1').value ? +String(ws.getCell('C1').value) : undefined;
      if (!idXlsx || idXlsx !== this.rcms.id) {
        this.erros.push(`Código inválido no arquivo de mapa de preços, verifique se o arquivo pertence a outro memorial`);
      } else {
        let cnpjXlsx = ws.getCell('A3').value ? this.funcaoService.removerPontos(String(ws.getCell('A3').value)).trim() : undefined;
        if (!cnpjXlsx || cnpjXlsx === 'Informe o Documento') {
          this.erros.push(`Documento não informado no arquivo`);
        } else {
          if (cnpjXlsx.length > 11) cnpjXlsx = this.funcaoService.strZero(cnpjXlsx, 14);
          if (this.funcaoService.removerPontos(String(this.favorecido.favorecido.cpf_cnpj)).trim() !== cnpjXlsx) {
            this.erros.push(`Documento informado no arquivo (${cnpjXlsx}) é diferente do fornecedor atual`);
          }
        }

        for (let linha = this.indexInicial; linha < this.itens.length + this.indexInicial; linha++) {
          const item: RcmsItem = this.itens[linha - this.indexInicial];
          const itemXlsx = {
            ordem: +ws.getCell(`A${linha}`).value?.toString(),
            valor: +ws.getCell(`E${linha}`).value?.toString()
          };

          // verifica a integridade do arquivo
          if (item.ordem !== itemXlsx.ordem) {
            this.erros.push(`Célula A${linha} com valor inválido (esperado ${item.ordem})`);
          }
          if (+itemXlsx.valor < 0) {
            this.erros.push(`Célula F${linha} com valor inválido (esperado valor positivo)`);
          }

          regXlsx.push(itemXlsx);
        }

        if (!regXlsx.find(x => +x.valor > 0)) {
          this.erros.push(`Nenhum item foi cotado no arquivo informado!`);
        }
      }

      if (this.erros.length > 0) {
        throw new Error(`${this.erros.length} erro(s) encontrado(s) no preenchimento do mapa de preços, verifique o conteúdo do arquivo e importe novamente!`);
      }

      await this.importarMapaPrecos(regXlsx);

      toastr.success('Mapa de preço importado com sucesso!');
      $('#dialogImportarMapaPreco').modal('hide');
      $('#dialogCotacoesCad').modal('show');
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    } finally {
      fileUpload.clear();
    }
  }

  private async importarMapaPrecos(registros: { ordem: number, valor: number }[]) {
    this.carregarCotacoes(false, false, false);
    for (let i = 0; i < this.itens.length; i++) {
      const cotacao = this.favorecido.cotacoes[i];
      const itemXlsx = registros[i];

      if (cotacao.rcmsItem.ordem !== itemXlsx.ordem) {
        throw new Error(`Ordem inválida para o item ${cotacao.rcmsItem.ordem}, valor preenchido é ${itemXlsx.ordem}`)
      }

      cotacao.valor_unitario = itemXlsx.valor;
    }

    this.visulizarCotacao();
  }

  public mudarVisualizacao() {
    this.tipo = this.tipo === 'F' ? 'I' : 'F';
    this.visulizarCotacao();
  }

  public fecharImportar() {
    this.erros = [];
    $('#dialogImportarMapaPreco').modal('hide');
    $('#dialogCotacoesCad').modal('show');
  }


  public corOpcao() {
    return {
      'bg-info': this.opcao === 'T',
      'bg-success': this.opcao === 'V',
      'bg-danger': this.opcao === 'P',
      'bg-warning': this.opcao === 'E',
    };
  }

  public onEnterCotacao(index: number) {
    let value = this.valorInput.get(index + 1);
    if (value)
      value.nativeElement.select();

  }

  public salvarItem(item: VisualizacaoItem) {
    this.atualizarValorTotal(item);
    this.itensContrato.find(i => i.id === item.rcmsItem.id);
    if (+item.quantidade > this.itensContrato.find(i => i.id === item.rcmsItem.id).saldo_qtd) {
      toastr.warning("Quantidade está maior que do Contrato!");
      item['valorTotal'] = item['valorTotalAnterior'];
      item['editavel'] = true;
    } else {
      item['editavel'] = false;
      this.callback.emit(this.itens);
    }

  }

  public cancelarItem(item: VisualizacaoItem) {
    item['valorTotal'] = item['valorTotalAnterior'];
    item['editavel'] = false;
  }

  public editarItem(item: VisualizacaoItem) {
    item['valorTotalAnterior'] = item['valorTotal'];
    item['editavel'] = true;
  }

  public getValorReferenciaItem() {
    for (const cotacao of this.favorecido?.cotacoes) {
      this.compraItemService.itemBancoPreco(cotacao.rcmsItem?.produto_unidade?.produto?.id, cotacao.rcmsItem?.produto_unidade?.unidade?.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          cotacao.valor_referencia = res;
        });
    }
  }
}