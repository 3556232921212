import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LdoService } from '../../../../../planejamento/ldo/service/ldo.service';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class ResultadoNominalLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new Login();
  private bimestre = '1˚ BIMESTRE';
  private bimestreAnterior = '1˚ BIMESTRE';
  public valorLDO = 0;
  public valorAteBimestre = 0;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLRFService,
    protected ldoServico: LdoService,
    protected mes: number,
    protected metaFiscal: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // retorna o nome do bimestre -----------
  // tslint:disable: triple-equals
  private retornaNomeBimestre(mes: number) {
    if (mes == 3 || mes == 4) {
      this.bimestreAnterior = '1˚ BIMESTRE';
      this.bimestre = '2˚ BIMESTRE';
    } else if (mes == 5 || mes == 6) {
      this.bimestreAnterior = '2˚ BIMESTRE';
      this.bimestre = '3˚ BIMESTRE';
    } else if (mes == 7 || mes == 8) {
      this.bimestreAnterior = '3˚ BIMESTRE';
      this.bimestre = '4˚ BIMESTRE';
    } else if (mes == 9 || mes == 10) {
      this.bimestreAnterior = '4˚ BIMESTRE';
      this.bimestre = '5˚ BIMESTRE';
    } else if (mes == 11 || mes == 12) {
      this.bimestreAnterior = '5˚ BIMESTRE';
      this.bimestre = '6˚ BIMESTRE';
    }
  }

  public async obterResultadoNominal(orgaos: number[]) {
    const result = await this.anexoServico.obterResultadoNominal(this.mes, this.exercicio.id, orgaos).toPromise();
    // Obtenho o valor na LDO para imprimir no Resultado Nominal
    const res = await this.ldoServico.obter(
      {
        tipo: 'D10',
        ppa_id: this.login.ppa.id,
        ano: this.exercicio.ano,
        linha: 18,
        relations: 'ppa'
      }).pipe(takeUntil(this.unsubscribe)).toPromise();

    if (this.metaFiscal != null) {
      this.valorLDO = this.metaFiscal;
    } else {
      if (res) this.valorLDO = res.valor1;
    }

    return this.conteudo(result);
  }

  // tslint:disable: max-line-length
  public async montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {
    this.retornaNomeBimestre(this.mes);

    // Obtenho o valor na LDO para imprimir no Resultado Primario
    const res = await this.ldoServico.obter(
      {
        tipo: 'D10',
        ppa_id: this.login.ppa.id,
        ano: this.exercicio.ano,
        linha: 18,
        relations: 'ppa'
      }).pipe(takeUntil(this.unsubscribe)).toPromise();

    if (this.metaFiscal != null) {
      this.valorLDO = this.metaFiscal;
    } else {
      if (res) this.valorLDO = res.valor1;
    }

    this.anexoServico.obterResultadoNominal(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            `DEMONSTRATIVO DO RESULTADO NOMINAL`
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.conteudo(dados),
            'portrait', 'DEMONSTRATIVO DO RESULTADO NOMINAL',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados), 'DEMONSTRATIVO DO RESULTADO NOMINAL', this.colunas());
        }
      });
  }

  private async conteudo(dados: any[]): Promise<{}[]> {
    // monta o cabecalho
    const registros: {}[] = [
      [
        {
          text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`,
          alignment: 'center', bold: true, border: [false, false, false, false], colSpan: 4
        }, '', '', ''
      ], [
        { text: 'LRF, art 53, inciso III', alignment: 'left', bold: true, border: [false, false, false, false], colSpan: 4 }, '', '', ''
      ], [
        { text: 'ESPECIFICAÇÃO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2 },
        { text: 'SALDO', alignment: 'center', bold: true, fontSize: 7, colSpan: 3 }, '', ''
      ], [
        { text: '' },
        { text: `EM 31 DEZ ${+this.exercicio.ano - 1} (a)`, alignment: 'center', bold: true, fontSize: 7 },
        { text: `EM ${this.bimestreAnterior} (b)`, alignment: 'center', bold: true, fontSize: 7 },
        { text: `EM ${this.bimestre} (c)`, alignment: 'center', bold: true, fontSize: 7 }
      ]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    // monta o agrupamento do relatório
    for (const item of dados) {
      if (item.resultado) {
        this.montaResultadoNominal(registros);
        registros.push([
          { text: item.titulo, bold: true, fontSize: 7, colSpan: 2 },
          { text: '' },
          { text: +item.bim_anterior || +item.bim_anterior === 0 ? this.funcaoService.convertToBrNumber(item.bim_anterior) : '-', alignment: 'right', fontSize: 7, bold: true },
          { text: +item.bimestre || +item.bimestre === 0 ? this.funcaoService.convertToBrNumber(item.bimestre) : '-', alignment: 'right', fontSize: 7, bold: true },
        ]);
        continue;
      }
      registros.push([
        { text: item.titulo, bold: false, fontSize: 7 },
        { text: +item.anterior || +item.anterior === 0 ? this.funcaoService.convertToBrNumber(item.anterior) : '-', alignment: 'right', fontSize: 7 },
        { text: +item.bim_anterior || +item.bim_anterior === 0 ? this.funcaoService.convertToBrNumber(item.bim_anterior) : '-', alignment: 'right', fontSize: 7 },
        { text: +item.bimestre || +item.bimestre === 0 ? this.funcaoService.convertToBrNumber(item.bimestre) : '-', alignment: 'right', fontSize: 7 },
      ]);
      this.valorAteBimestre = item.bimestre;
    }

    this.montaMetaFiscal(registros);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 90, 90, 90],
        body: registros
      }
    }, {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }

  private montaResultadoNominal(registros: any) {
    registros.push([{ text: '', colSpan: 4, border: [true, false, true, false], margin: [0, 10, 0, 0] }]);
    registros.push(
      [
        { text: 'ESPECIFICAÇÃO', alignment: 'center', bold: true, fontSize: 7, rowSpan: 2, colSpan: 2 },
        { text: '' },
        { text: 'PERÍODO DE REFERÊNCIA', alignment: 'center', bold: true, fontSize: 7, colSpan: 2 }, ''
      ], [
        { text: '' },
        { text: '' },
        { text: `No Bimestre (c - b)`, alignment: 'center', bold: true, fontSize: 7 },
        { text: `Jan a ${this.bimestre}`, alignment: 'center', bold: true, fontSize: 7 }
      ]
    );
  }

  private montaMetaFiscal(registros: any) {
    registros.push([{ text: '', colSpan: 4, border: [true, false, true, false], margin: [0, 15, 0, 0] }]);
    registros.push(
      [
        { text: 'DISCRIMINAÇÃO DA META FISCAL', alignment: 'center', bold: true, fontSize: 7, colSpan: 3 },
        { text: '' },
        { text: '' },
        { text: 'VALOR', alignment: 'center', bold: true, fontSize: 7 }
      ], [
        { text: 'META DE RESULTADO NOMINAL FIXADA NO ANEXO DE METAS FISCAIS DA LDO P/ O EXERCÍCIO DE REFERÊNCIA', colSpan: 3 },
        { text: '' },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(this.valorLDO), alignment: 'center', bold: true, fontSize: 7 }
      ]
    );
  }

  public normalizar(lista: any[]) {
    const listaExportar = []

    for (const item of lista) {
      if (item.resultado) {
        const espaco = {
          receitas_fiscais: '',
          previsao_inicial: '',
          previsao_anual: '',
          receitas_bimestre: ''
        };
        listaExportar.push(espaco)

        const titulo_resultado_nominal = {
          especificacao: 'ESPECÍFICAÇÃO',
          a: '',
          b: 'No Bimestre (c - b)',
          c: `Jan a ${this.bimestre}`
        };
        listaExportar.push(titulo_resultado_nominal)

        const resultado_nominal = {
          especificacao: item.titulo,
          a: this.funcaoService.convertToBrNumber(''),
          b: this.funcaoService.convertToBrNumber(item.bim_anterior),
          c: this.funcaoService.convertToBrNumber(item.bimestre)
        };
        listaExportar.push(resultado_nominal)
      }

      if (!item.resultado) {
        const items_resultado_nominal = {
          especificacao: item.titulo,
          a: this.funcaoService.convertToBrNumber(item.anterior),
          b: this.funcaoService.convertToBrNumber(item.bim_anterior),
          c: this.funcaoService.convertToBrNumber(item.bimestre)
        };
        listaExportar.push(items_resultado_nominal)
      }
    }

    const espaco = {
      receitas_fiscais: '',
      previsao_inicial: '',
      previsao_anual: '',
      receitas_bimestre: ''
    };
    listaExportar.push(espaco)

    const titulo_discriminacao_meta_fiscal = {
      especificacao: 'DISCRIMINAÇÃO DA META FISCAL',
      a: '',
      b: '',
      c: 'VALOR'
    };
    listaExportar.push(titulo_discriminacao_meta_fiscal)

    const discriminacao_meta_fiscal = {
      especificacao: 'META DE RESULTADO NOMINAL FIXADA NO ANEXO DE METAS FISCAIS DA LDO P/ O EXERCÍCIO DE REFERÊNCIA',
      a: '',
      b: '',
      c: this.funcaoService.convertToBrNumber(this.valorLDO)
    };
    listaExportar.push(discriminacao_meta_fiscal)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'ESPECIFICAÇÃO', coluna: 'especificacao', bold: true },
      { titulo: `EM 31 DEZ ${+this.exercicio.ano - 1} (a)`, coluna: 'a' },
      { titulo: `EM ${this.bimestreAnterior} (b)`, coluna: 'b' },
      { titulo: `EM ${this.bimestre} (c)`, coluna: 'c' },
    ];
    return colunasDefault;
  }
}
