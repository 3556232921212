// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';

export class ParametroPatrimonio extends BaseResourceModel {
  constructor(
    public id?: number,
    public termo_responsabilidade?: string,
    public termo_devolucao?: string,
    public termo_manutencao?: string,
    public termo_transferencia?: string,
    public termo_baixa?: string,
    public termo_imovel_responsabilidade?: string,
    public termo_imovel_devolucao?: string,
    public termo_imovel_manutencao?: string,
    public termo_imovel_transferencia?: string,
    public termo_imovel_baixa?: string,
    public termo_inventario_decreto?: string,
    public mensagem_identificacao?: string,
    public mensagem_solicitacao_recusa?: string,
    public mensagem_solicitacao_aceite?: string,
    public mensagem_solicitacao?: string,
    public mensagem_transferencia?: string,
    public etiqueta_altura?: number,
    public etiqueta_largura?: number,
    public orgao?: Orgao,
    public bloquear_aquisicao?: Boolean,
    public bloquear_conservacao?: Boolean,
    public bloquear_inclusao?: Boolean,
    public solicitar_serie?: Boolean,
    public obrigar_campo_processo?: boolean,
    public incorporacao_lote?: boolean,
    public complemento_cadastro?: boolean,
    public alterar_subgrupo?: boolean,
    public controle_avancado?: boolean,
    public nao_exigir_empenho?: boolean,
    public editar_campos_incorporacao?: boolean,
    public habilitar_edicao_vida_util?: boolean,
    public inventariar_cessao_uso?: boolean,
    public usa_grupo_depreciacao?: boolean,
  ) {
    super();
  }

  static converteJson(json: any): ParametroPatrimonio {
    return Object.assign(new ParametroPatrimonio(), json);
  }
}
