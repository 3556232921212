import { Component, Injector, ElementRef, ViewChild } from '@angular/core';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OrgaoService } from '../service/orgao.service';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { Orgao } from '../../entidade/comum/orgao.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { GlobalService } from '../../util/global.service';
import { FuncaoService } from '../../util/funcao.service';
import { OrgaoAssinaturaService } from '../service/orgao-assinatura.service';
import { OrgaoConfiguracao } from '../../entidade/comum/orgao-configuracao.model';
import { OrgaoAssinatura } from '../../entidade/comum/orgao-assinatura.model';
import { OrgaoSuplementacaoService } from '../service/orgao-suplementacao.service';
import { OrgaoSuplementacao } from '../../entidade/comum/orgao-suplementacao.model';
import { Validators } from '@angular/forms';
import * as toastr from 'toastr';
import { PNCPService } from '../../util/pncp.service';

declare var $: any;

interface TipoOrgao {
  desc: string;
  value: string;
}

@Component({
  selector: 'lib-orgao-shw',
  templateUrl: './orgao-shw.component.html'
})
export class OrgaoShwComponent extends BaseResourceFormComponent<Orgao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public listaAssinaturas: Array<any>;
  public listaSuplementacoes: Array<any>;
  @ViewChild('codigo') inputField: ElementRef;

  public imageChangedEvent: any = '';
  public imageChangedBandeiraEvent: any = '';
  public listaEspecies: Array<any>;

  public configuracao: OrgaoConfiguracao = new OrgaoConfiguracao(null, 'FIREBASE', false);
  public integracao: Orgao = new Orgao();
  public tiposOrgao: TipoOrgao[] = [
    {
      desc: 'Executivo',
      value: 'EX'
    },
    {
      desc: 'Legislativo',
      value: 'LG'
    },
    {
      desc: 'Judiciário',
      value: 'JD'
    }, {
      desc: 'Não se Aplica',
      value: 'NA'
    }
  ];

  public tiposEsfera: TipoOrgao[] = [
    {
      desc: 'Federal',
      value: 'F'
    },
    {
      desc: 'Estadual',
      value: 'E'
    },
    {
      desc: 'Municípal',
      value: 'M'
    },
    {
      desc: 'Distrital',
      value: 'D'
    },
    {
      desc: 'Não se aplica',
      value: 'N'
    }
  ];

  precadastropncp: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected orgaoSuplementacaoService: OrgaoSuplementacaoService,
    protected pncpService: PNCPService,
    protected confirmationService: ConfirmationService,
    protected orgaoService: OrgaoService) {
    super(new Orgao(), injector, Orgao.converteJson, orgaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null],
      nome: [null],
      cnpj: [null],
      endereco: [null],
      bairro: [null],
      cep: [null],
      email: [null],
      siafi: [null],
      siconfi: [null],
      ativo: [null],
      site: [null],
      telefone: [null],
      ibge: [null],
      especie: [null],
      tribunal: [null],
      cidade: [null],
      brasao: this.fb.group({
        id: [null],
        brasao: [null],
        bandeira: [null],
        orgao: [this.entidade],
      }),
      configuracao: [null],
      mostrar_transparencia: [false, [Validators.required]],
      suplementacoes: [null],
      tipo_orgao_siconfi: [null, Validators.required],
      cadastrado_pncp: false,
      esfera_orgao: ['M'],
      tipo_integracao: [null],
      chave_acesso: [null],
      numero_endereco: [null]
    });

    this.entidadeForm.get('codigo').statusChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(status => status === 'VALID' && !this.entidade.id ?
          this.orgaoService.obterPorCodigo(this.entidadeForm.get('codigo').value, this.login.cidade.id)
          : of({})
        )
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => dados && !this.entidade.id ?
        toastr.error('Código já está cadastrado!') : {});

  }

  protected parametrosExtras(): {} {
    return { relations: 'cidade,brasao,configuracao' };
  }

  protected afterLoad() {
    if (this.entidade.configuracao) {
      if (this.entidade.configuracao.firebase_conta_servico)
        this.entidade.configuracao.firebase_conta_servico = JSON.stringify(this.entidade.configuracao.firebase_conta_servico);
      this.configuracao = this.entidade.configuracao;
    } else
      this.configuracao = new OrgaoConfiguracao(null, 'FIREBASE', false);

    this.integracao = this.entidade;
    this.loadAssinatura();
  }

  protected async afterInit(): Promise<void> {
    this.listaEspecies = this.globalService.obterListaTipoOrgaos();
    this.listaSuplementacoes = new Array<OrgaoSuplementacao>();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected async beforeSubmit(): Promise<void> {
    if (this.configuracao.firebase_conta_servico) {
      this.configuracao.firebase_conta_servico = JSON.parse(this.configuracao.firebase_conta_servico);
    }

    this.entidadeForm.get('configuracao').setValue(this.configuracao);
    this.entidadeForm.get('cidade').setValue(this.login.cidade);
    this.entidadeForm.get('suplementacoes').setValue(this.listaSuplementacoes);
    this.entidadeForm.get('tipo_integracao').setValue(this.integracao.tipo_integracao);
    this.entidadeForm.get('chave_acesso').setValue(this.integracao.chave_acesso);

    const orgaos: Orgao[] = (await this.orgaoService.filtrar(1, -1, {
      tipo_orgao_siconfi: 'EX'
    }).toPromise()).content;

    const tipoAtual = this.entidadeForm.get('tipo_orgao_siconfi').value;
    const orgaoAtual = orgaos.find(orgao => orgao.id === +this.entidadeForm.get('id').value);

    if (tipoAtual === 'EX' && orgaos.length >= 1 && !orgaoAtual) {
      const error = new Error('Já existe um orgão executivo cadastrado.');
      toastr
      toastr.options.timeOut = 10000;
      toastr.options.closeButton = true;
      toastr.options.tapToDismiss = false;
      toastr.error(error.message);
      throw error;
    }
  }

  protected afterSubmit(entidade: Orgao): void {
    if (entidade.configuracao) {
      if (entidade.configuracao.firebase_conta_servico)
        entidade.configuracao.firebase_conta_servico = JSON.stringify(entidade.configuracao.firebase_conta_servico);
      this.configuracao = entidade.configuracao;
    } else {
      this.loadAssinatura();
    }
  }

  fileChangeEvent(event: any, bandeira?: boolean): void {
    if (!bandeira)
      this.imageChangedEvent = event;
    else
      this.imageChangedBandeiraEvent = event;
  }

  imageCropped(event: ImageCroppedEvent, bandeira?: boolean) {
    if (!bandeira)
      this.entidadeForm.get('brasao').get('brasao').setValue(event.base64);
    else
      this.entidadeForm.get('brasao').get('bandeira').setValue(event.base64);
  }

  public loadAssinatura() {
    if (this.entidade.id) {
      this.assinaturaService.filtrar(1, -1,
        { relations: 'orgao', 'orgao.id': this.entidade.id, 'orderBy': 'data_limite' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaAssinaturas = res ? res.content : new Array<OrgaoAssinatura>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );

      this.orgaoSuplementacaoService.filtrar(1, -1,
        { relations: 'exercicio', 'orgao.id': this.entidade.id }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaSuplementacoes = res ? res.content : new Array<OrgaoSuplementacao>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  verificarPreCadastroPncp() {
    this.pncpService.consultarOrgaoCnpj(this.entidadeForm.get('cnpj').value.replace(/\D/g, '')).subscribe((res) => {
      this.entidadeForm.get('cadastrado_pncp').setValue(true);
      this.submitForm();
      this.precadastropncp = true;
      this.entidade.cadastrado_pncp = true;
    }, e => {
      if (+e.error.status == 404) {
        toastr.info('Ente não cadastrado ainda no PNCP');
        this.precadastropncp = true;
      } else {
        toastr.error(e.message)
      }
    });
  }

  cadastrarOrgaoPncp(atualizacao?: boolean) {
    //Linhas comentadas: Removidas da versão 2.2.6 do PNCP, mes 09/2023, apagar em até 6 meses se não reverterem o uso.
    //Rotina não testada, por não existir um cenário para teste.
    const cnpj: string = this.entidadeForm.get('cnpj').value.replace(/\D/g, '');

    if (!cnpj || cnpj.length < 14) { toastr.error('CNPJ inválido'); return; }

    if (atualizacao) {
      this.confirmationService.confirm({
        message: `Deseja atualizar o cadastro do Órgão no PNCP? Os dados são obtidos através de outros sistemas governamentais automaticamente pelo portal do PNCP.`,
        header: `Atualizaçao PNCP`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim, confirmo atualização.',
        rejectLabel: 'Não',
        accept: async () => {
          this.pncpService.atualizarOrgao(cnpj).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
            this.verificarPreCadastroPncp();
          }, e => {
            toastr.error(e.error.message)
          });
        }
      });
    } else {
      this.confirmationService.confirm({
        message: `Deseja cadastrar o orgão ao PNCP? Essa ação NÃO PODE SER REVERTIDA. Os dados serão buscados pelo PNCP diretamente com os sistemas do governo.`,
        header: `Cadastro PNCP`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim, confirmo envio.',
        rejectLabel: 'Não',
        accept: async () => {
          this.pncpService.inserirOrgao(cnpj).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
            this.verificarPreCadastroPncp();
          }, e => {
            toastr.error(e.error.message)
          });
        }
      });
    }
  }
}
