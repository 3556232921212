import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Coluna } from '../../components/types';
import { PncpRegistro } from '../../entidade/comum/registro-pncp.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceListComponent, Filtro } from '../../models/base-resource-list';
import { RegistroPncpService } from '../service/registro-pncp.service';

@Component({
  selector: 'app-pncp-list',
  templateUrl: './pncp.component.html'
})
export class PncpListComponent extends BaseResourceListComponent<PncpRegistro, LoginContabil> {

  totalContrato: number = 0;

  constructor(
    protected injector: Injector,
    private service: RegistroPncpService) {
    super(service, injector);
  }

  protected condicoesGrid(): {} {
    const parametros = {};

    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;
    parametros['relations'] = 'envio_ratificando'
    // parametros['ratificado'] = false;//Mostra apenas os atuais ou excluidos.

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_cadastro$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id_alvo', 'sequencial', 'id', 'id_usuario_pncp'],
      text: ['tabela_alvo']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: PncpRegistro): Observable<PncpRegistro> {
    throw new Error('Não é possível remover registros do PNCP');
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    throw new Error('Relatório para registros do PNCP não desenvolvido.');
  }

  protected relations(): string {
    return '';
  }

  protected obterNomeAlvo(tabela_alvo: string): string {
    if (tabela_alvo === 'licitacao') {
      return 'Licitação'
    } else if (tabela_alvo === 'licitacao_storage') {
      return 'Licitação - Arquivos'
    } else if (tabela_alvo === 'contrato') {
      return 'Contrato'
    } else if (tabela_alvo === 'contrato_storage') {
      return 'Contrato - Arquivos'
    } else if (tabela_alvo === 'contrato_aditamento') {
      return 'Contrato - Aditamento'
    } else if (tabela_alvo === 'contrato_rescisao') {
      return 'Contrato - Rescisão'
    } else if (tabela_alvo === 'plano-contratacao-anual') {
      return 'Plano de Contratação Anual - PCA'
    } else {
      return tabela_alvo;
    }
  }

}
