import { Usuario } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from './orgao.model';

export class PncpAcesso extends BaseResourceModel {
  constructor(
    public id?: number,
    public situacao?: 'FALHA' | 'SUCESSO',
    public retorno?: string,
    public data_acesso?: Date,
    public usuario?: Usuario,
    public orgao?: Orgao
  ) {
    super();
  }

  static converteJson(json: any): PncpAcesso {
    return Object.assign(new PncpAcesso(), json);
  }

}
