import { DatePipe } from '@angular/common';
import { FuncaoService, LoginContabil, Relatorio, Login, Adiantamento, OrgaoAssinaturaService, FavorecidoService, Favorecido } from 'eddydata-lib';

export class Devolucao {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;

  private log: LoginContabil;

  private beneficiario: Favorecido;

  constructor(
    protected favorecidoService: FavorecidoService
  ) {
    this.funcaoService = new FuncaoService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: Adiantamento[], login: LoginContabil) {
    this.log = login;

    this.beneficiario = await this.favorecidoService.obter({
      nome: model[0].beneficiario,
    }).toPromise();

    Relatorio.imprimirPersonalizado('', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'portrait', 'COMPROVAÇÃO DA DEVOLUÇÃO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, false);
  }

  private montarConteudo(lista: Adiantamento[]) {

    const conteudo = [];
    for (const entidade of lista) {
      if (conteudo?.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }

      conteudo.push(this.dadosCabecalho(this.log)
        .concat(this.dadosAdiantamento(entidade))
        .concat(this.dadosFavorecido(entidade))
        .concat(this.dadosOrgao(entidade))
        .concat(this.dadosEmpenho(entidade))
        .concat(this.dadosMotivo(entidade))
        .concat(entidade.documentos.length > 0 ? this.dadosDespesa(entidade) : [])
        .concat(this.dadosValores(entidade))
        .concat(this.dadosAssinatura(this.log, entidade))
      );
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'center',
        margin: [0, 30, 0, 0]
      };
    } else {
      brasaoImage = { margin: [0, 30, 0, 0], text: '' };
    }
    const conteudo = [
      [{ text: brasaoImage, border: [false, false, false, false] }],
      [{
        text: 'COMPROVAÇÃO DA DEVOLUÇÃO',
        bold: true, alignment: 'center', fontSize: 14, border: [false, false, false, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosAdiantamento(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        {
          text: `PROCESSO: ${this.funcaoService.strZero(dados.numero, 4)}`, fontSize: 14,
          alignment: 'left', bold: true, margin: [0, 3, 0, 3], border: [false, true, false, true]
        }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosFavorecido(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: 'BENEFICIÁRIO', colSpan: 6, fontSize: 11, bold: true, margin: [0, 3, 0, 3], border: [false, false, false, false] },
        '', '', '', '', ''
      ],
      [
        {
          text: `${dados.beneficiario}`, colSpan: 6,
          fontSize: 11, bold: true, border: [false, false, false, false]
        },
        '', '', '', '', ''
      ],
      [
        { text: 'CPF', fontSize: 9, border: [false, false, false, false] },
        { text: `:${this.cpfCnpj(this.beneficiario?.cpf_cnpj)}`, fontSize: 9, colSpan: 5, border: [false, false, false, false] },
        '', '', '', ''
      ],
      [
        { text: 'Nº CONTA', fontSize: 9, border: [false, false, false, false] },
        { text: `:${dados?.numero_conta}`, fontSize: 9, border: [false, false, false, false] },
        { text: `AGÊNCIA: ${dados.agencia}`, fontSize: 9, colSpan: 2, border: [false, false, false, false] }, '',
        { text: `BANCO: ${dados.banco}`, fontSize: 9, colSpan: 2, border: [false, false, false, false] }, ''
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [70, '*', 60, 60, 60, '*'],
        body: conteudo
      }
    }];
  }

  private dadosOrgao(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: 'ÓGÃO', margin: [0, 3, 0, 3], fontSize: 9, border: [false, true, false, false] },
        { text: `:${this.funcaoService.mascarar('##.##.##', dados?.orgao?.codigo)} ${dados?.orgao?.nome}`, fontSize: 9, border: [false, true, false, false] }
      ],
      [
        { text: 'UNIDADE', fontSize: 9, border: [false, false, false, false] },
        { text: `:${this.funcaoService.mascarar('##.##.##', dados.empenho?.ficha?.executora?.unidade?.codigo)} ${dados.empenho?.ficha?.executora?.unidade?.nome}`, fontSize: 9, border: [false, false, false, false] }
      ],
      [
        { text: 'NATUREZA', fontSize: 9, border: [false, false, false, false] },
        { text: `:${this.funcaoService.mascarar('#.#.##.##', dados.empenho?.ficha?.despesa?.codigo)} ${dados.empenho?.ficha?.despesa?.nome}`, fontSize: 9, border: [false, false, false, false] }
      ],
      [
        { text: 'SUB-ELEMENTO', fontSize: 9, border: [false, false, false, false] },
        { text: `:${this.funcaoService.mascarar('#.#.##.##.##.###', dados.empenho?.subelemento?.codigo)} ${dados.empenho?.subelemento?.nome} `, fontSize: 9, border: [false, false, false, false] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [70, '*'],
        body: conteudo
      }
    }];
  }

  private dadosEmpenho(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: `EMPENHO Nº: ${dados.empenho?.numero}`, fontSize: 9, colSpan: 2, bold: true, margin: [0, 3, 0, 3], border: [false, true, false, false] }, '',
        { text: 'SUB-EMPENHO: ', fontSize: 9, colSpan: 2, border: [false, true, false, false] }, '',
        { text: `DATA EMPENHO: ${this.funcaoService.converteDataBR(dados.empenho?.data_empenho)}`, fontSize: 9, colSpan: 2, border: [false, true, false, false] }, ''
      ],
      [
        { text: `DATA PAGAMENTO: ${this.funcaoService.converteDataBR(dados['data_ultimo_pagamento'])}`, fontSize: 9, colSpan: 2, border: [false, false, false, false] }, '',
        { text: `DATA VCTO: ${this.funcaoService.converteDataBR(dados?.data_vencimento)}`, fontSize: 9, colSpan: 2, border: [false, false, false, false] }, '',
        { text: `DATA TÉRMINO: ${this.funcaoService.converteDataBR(dados?.data_termino)}`, fontSize: 9, colSpan: 2, border: [false, false, false, false] }, ''
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', 60, '*', 60, '*', 60],
        body: conteudo
      }
    }];
  }

  private dadosMotivo(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: 'MOTIVO DO ADIANTAMENTO:', fontSize: 9, margin: [0, 3, 0, 3], border: [false, true, false, false] },
      ],
      [
        { text: `${dados?.empenho?.historico}`, fontSize: 9, border: [false, false, false, false] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosDespesa(dados: Adiantamento): {}[] {
    const conteudo = []

    conteudo.push([
      { text: 'DEMONSTRATIVOS DAS DESPESAS', fontSize: 9, colSpan: 5, border: [false, true, false, false], margin: [0, 3, 0, 0] },
      '', '', '', ''
    ])
    conteudo.push([
      { text: `DOC.`, fontSize: 9, border: [false, false, false, false] },
      { text: `DATA`, fontSize: 9, border: [false, false, false, false] },
      { text: `RAZÃO SOCIAL`, fontSize: 9, border: [false, false, false, false] },
      { text: `CPF/CNPJ`, fontSize: 9, border: [false, false, false, false] },
      { text: `VALOR`, fontSize: 9, border: [false, false, false, false] },
    ])

    for (const documento of dados?.documentos) {
      conteudo.push([
        { text: documento?.documento, fontSize: 9, margin: [0, 0, 0, 3], border: [false, false, false, false] },
        { text: this.funcaoService.converteDataBR(documento?.data_documento), fontSize: 9, border: [false, false, false, false] },
        { text: documento?.razao_social, fontSize: 9, border: [false, false, false, false] },
        { text: documento?.cnpj ? this.cpfCnpj(documento?.cnpj) : '', fontSize: 9, border: [false, false, false, false] },
        { text: documento?.valor_documento, fontSize: 9, border: [false, false, false, false] },
      ])
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private dadosValores(dados: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: `VALOR EMPENHADO: ${dados.empenho?.valor_empenho}`, fontSize: 9, bold: true, margin: [0, 3, 0, 0], border: [false, true, false, false] },
      ],
      [
        { text: `VALOR EFETIVADO: ${+dados['total_empenho_anulado'] + +dados.empenho?.valor_empenho}`, fontSize: 9, border: [false, false, false, false] },
      ],
      [
        { text: `ANULAÇÃO DESPESA: ${dados['total_empenho_anulado']}`, fontSize: 9, bold: true, margin: [0, 0, 0, 3], border: [false, false, false, true] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(log: Login, entidade: Adiantamento): {}[] {
    const conteudo = [
      [
        { text: 'Contador: ________________________________________________', alignment: 'left', fontSize: 9, border: [false, false, false, false], margin: [0, 20, 0, 0] },
      ],
      [
        { text: 'Tesoureiro: ________________________________________________', alignment: 'left', fontSize: 9, border: [false, false, false, false], margin: [0, 10, 0, 0] },
      ],
      [
        { text: 'Encarregado da Anulação: ________________________________________________', alignment: 'left', fontSize: 9, border: [false, false, false, false], margin: [0, 10, 0, 0] },
      ],
      [
        { text: 'Responsável Pelo Adiantamento: ________________________________________________', alignment: 'left', fontSize: 9, border: [false, false, false, false], margin: [0, 10, 0, 0] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private cpfCnpj(doc: string) {
    if (doc?.length > 11)
      return this.funcaoService.mascarar('##.###.###/####-##', doc);
    else
      return this.funcaoService.mascarar('###.###.###-##', doc);
  }

}
