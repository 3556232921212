import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MemorialService } from "administrativo-lib";
import { BaseResourceRptComponent, Coluna, Licitacao, LicitacaoPipe, Memorial, Relatorio } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-vencedores-rpt',
  templateUrl: './vencedores-rpt.component.html'
})
export class VencedoresRptComponent extends BaseResourceRptComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public licitacao: Licitacao;
  @Input() public memorial: Memorial;

  public agrupamento: 'MEMORIAL' | 'PROPONENTE' | 'VENCEDORES';
  public listAgrupamento: { id: 'MEMORIAL' | 'PROPONENTE' | 'VENCEDORES', nome: string }[];

  public opcao: 'DESC' | 'NOME' = 'NOME';
  public opcoes: { id: 'DESC' | 'NOME', nome: string }[] = [
    { id: 'NOME', nome: 'Nome do item' },
    { id: 'DESC', nome: 'Descrição do item' }
  ];

  private semProposta: Memorial[] = [];
  private fracassado: Memorial[] = [];

  constructor(
    private memorialService: MemorialService
  ) {
    super();
  }

  protected afterInit(): void {
    this.listAgrupamento = [
      { id: 'MEMORIAL', nome: 'Modelo 1' },
      { id: 'PROPONENTE', nome: 'Modelo 2' },
      { id: 'VENCEDORES', nome: 'Modelo 3' }
    ];
    this.agrupamento = 'MEMORIAL';
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome}`;
  }

  protected subTituloRelatorio(): string {
    return `Listagem de Vencedores
     Licitação: ${new LicitacaoPipe().transform(this.licitacao.numero)} - Processo: ${new LicitacaoPipe().transform(this.licitacao.processo)} - Modalidade: ${this.licitacao.modalidade.nome}`;
  }

  protected obterColunasRelatorio(): Coluna[] {
    let tipo_contrato = this.licitacao.contratos.find(c => c.tipo === 'CONTRATO');

    const retorno: Coluna[] = [];
    if (this.agrupamento === 'MEMORIAL') {
      if (this.licitacao.contratos.length > 0) {
        retorno.push({ titulo: 'VENCEDOR', coluna: 'vencedor.proponente.favorecido.nome', alignment: 'left' });
        tipo_contrato ? retorno.push({ titulo: 'Nº CONTRATO', coluna: 'contrato_numero' }) : retorno.push({ titulo: 'Nº ATA DE REGISTRO DE PREÇO', coluna: 'contrato_numero' });
        retorno.push({ titulo: 'ITEM', coluna: 'item' });
      } else {
        retorno.push({ titulo: 'VENCEDOR', coluna: 'vencedor.proponente.favorecido.nome', alignment: 'left' });
        retorno.push({ titulo: 'ITEM', coluna: 'item' });
      }
    } else {
      if (this.licitacao.contratos.length > 0) {
        retorno.push({ titulo: 'VENCEDOR', coluna: 'vencedor_contrato', alignment: 'left', tipo: 'String', agrupar: true, adicionaisBody: { margin: 3, border: [true, true, true, true] }, adicionaisBodyEspaco: { border: [false, false, false, false] } });
        retorno.push({ titulo: 'ITEM', coluna: 'ordem', alignment: 'center' });
        retorno.push({ titulo: 'CÓDIGO', coluna: 'produto_unidade.produto.codigo', alignment: 'center' });
        retorno.push({ titulo: 'DESCRIÇÃO', coluna: this.opcao === 'NOME' ? 'produto_unidade.produto.nome' : 'produto_unidade.produto.descricao' });
        retorno.push({ titulo: 'MARCA', coluna: 'vencedor.marca' });
      } else {
        retorno.push({ titulo: 'VENCEDOR', coluna: 'vencedor.proponente.favorecido.nome', alignment: 'left', tipo: 'String', agrupar: true, adicionaisBody: { margin: 3, border: [true, true, true, true] }, adicionaisBodyEspaco: { border: [false, false, false, false] } });
        retorno.push({ titulo: 'ITEM', coluna: 'ordem', alignment: 'center' });
        retorno.push({ titulo: 'CÓDIGO', coluna: 'produto_unidade.produto.codigo', alignment: 'center' });
        retorno.push({ titulo: 'DESCRIÇÃO', coluna: this.opcao === 'NOME' ? 'produto_unidade.produto.nome' : 'produto_unidade.produto.descricao' });
        retorno.push({ titulo: 'MARCA', coluna: 'vencedor.marca' });
      }
    }

    retorno.push({ titulo: 'QUANTIDADE', coluna: 'quantidade', decimais: 3, alignment: 'right' });
    retorno.push({ titulo: 'VL. UNITÁRIO', coluna: 'vencedor.valor_final', decimais: 4, alignment: 'right' });
    retorno.push({ titulo: 'VL. TOTAL', coluna: 'valor_total', decimais: 4, alignment: 'right' });

    return retorno.map((r) => {
      r = Object.assign(r, { adicionaisHeader: { margin: 3, fontSize: 8, border: [true, true, true, true], alignment: 'center' } });
      if (!r.agrupar)
        r.adicionaisBody = { margin: 2, border: [false, false, false, false] };
      return r;
    });
  }

  protected conteudoAdicional(): {}[] {
    const retorno = [];

      if(this.semProposta.find(a => a.ordem === 0)){
        this.semProposta = this.semProposta.sort((a, b) => +a.id- +b.id);
      } else  {
        this.semProposta = this.semProposta.sort((a, b) => +a.ordem - +b.ordem);
      }

      if(this.fracassado.find(a => a.ordem === 0)){
        this.fracassado = this.fracassado.sort((a, b) => +a.id- +b.id);
      } else  {
        this.fracassado = this.fracassado.sort((a, b) => +a.ordem - +b.ordem);
      }



    if (this.semProposta.length > 0) {
      retorno.push({ text: 'ITENS SEM PROPOSTAS', bold: true, alignment: 'left', fontSize: 10, margin: [0, 10, 0, 5] });
      for (let p of this.semProposta) {
        retorno.push({ text: `${p.ordem} - ${this.opcao === 'NOME' ? p.produto_unidade.produto.nome : p.produto_unidade.produto.descricao} - ${p.quantidade}`, margin: [5, 0, 0, 0] });
      }
    }

    retorno.push({ text: '' });
    retorno.push({ text: '' });

    if (this.fracassado.length > 0) {
      retorno.push({ text: 'ITENS FRACASSADOS', bold: true, alignment: 'left', fontSize: 10, margin: [0, 10, 0, 5] });
      for (let p of this.fracassado) {
        retorno.push({ text: `${p.ordem} - ${this.opcao === 'NOME' ? p.produto_unidade.produto.nome : p.produto_unidade.produto.descricao} - ${p.quantidade}`, margin: [5, 0, 0, 0] });
      }
    }

    return retorno;
  }

  protected larguraColunas(): (string | number)[] {
    return this.agrupamento === 'MEMORIAL' ? this.licitacao.contratos.length > 0 ? ['*', '*', '*', 60, 60, 60] : ['*', '*', 60, 60, 60] : [40, 60, '*', 'auto', 60, 60, 60];
  }

  protected totalizarColunas(): (string | {})[] {
    return [{ nome: 'valor_total', abaixo: true }];
  }

  protected carregarLista(): Promise<any[]> {

    const relations = ['propostas.proponente.favorecido', 'licitacao.contratos.favorecido', 'produto_unidade.unidade', 'produto_unidade.produto', 'produto_unidade.contrato_itens.contrato', 'produto_unidade.contrato_itens.produto_unidade.produto'
    ];

    let orderBy;

    if(this.agrupamento == 'MEMORIAL'){
       orderBy = ['ordem$ASC'];
    }else{
       orderBy = ['propostas.proponente.favorecido.nome$ASC'];
    }
  

    if (this.agrupamento == 'VENCEDORES') {

    }

    return new Promise((resolve, reject) => {
      this.memorialService.filtrar(1, -1, { 'licitacao.id': this.licitacao.id, relations: relations.join(','), orderBy: orderBy.join(',') })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (!res.content) {
            resolve(res.content);
            return;
          }

          this.semProposta = res.content.filter((c) => !c.propostas || c.propostas.length === 0);
          this.fracassado = res.content.filter((c) => c.propostas && c.propostas.length > 0 && c.propostas.filter((p) => p.situacao === 'VENCEDOR').length === 0);

          let vencedores = res.content.filter((c) => c.propostas && c.propostas.filter((p) => p.situacao === 'VENCEDOR').length > 0)
            .map((c) => {
              c['item'] = `${c.ordem} - ${this.opcao === 'NOME' ? c.produto_unidade.produto?.nome : c.produto_unidade.produto.descricao}`;
              c['vencedor'] = c.propostas.find((p) => p.situacao === 'VENCEDOR');
              c['valor_total'] = +c.quantidade * +c['vencedor'].valor_final;
              c.licitacao.contratos.forEach(contrato => {
                const itemEncontrado = c?.produto_unidade?.contrato_itens?.find(item =>
                  item.produto_unidade.produto.nome.trim() === c.produto_unidade.produto.nome.trim() &&
                  contrato.favorecido.id === c.vencedor.proponente.favorecido.id && contrato.excluido == false
                )
                if (itemEncontrado) {
                  c['contrato_numero'] = this.funcaoService.mascarar('#####/####', this.funcaoService.strZero(contrato?.numero,9));
                  c['cotrato_tipo'] = contrato.tipo;
                }
              });
              if (c['cotrato_tipo'] === 'CONTRATO') {
                c['vencedor_contrato'] = c['vencedor'].proponente?.favorecido?.nome + ' - Nº CONTRATO ' + c['contrato_numero']
              } else if (c['cotrato_tipo'] === 'REGISTRO_PRECO') {
                c['vencedor_contrato'] = c['vencedor'].proponente?.favorecido?.nome + ' - Nº ATA DE REGISTRO DE PREÇO ' + c['contrato_numero']
              } else {
                c['vencedor_contrato'] = c['vencedor'].proponente?.favorecido?.nome
                c['cotrato_tipo'] = this.licitacao.formato_contratacao == 'RESERVA_VALOR' ? 'CONTRATO' : 'REGISTRO_PRECO'
              }
              return c;
            });

        

          resolve(vencedores);
        });
    });
  }


  protected layout(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public async imprimirModeloPorVencedores() {

    const conteudo = this.montarConteudoPorVencedores(await this.carregarLista())

    Relatorio.imprimirPersonalizado(this.subTituloRelatorio() ? this.subTituloRelatorio() : this.tituloRelatorio().toUpperCase(), this.login.usuario.nome, this.login.usuario.sobrenome,
      this.login.orgao.nome, this.login.brasao, conteudo, 'portrait', this.subTituloRelatorio() ? this.tituloRelatorio() : this.login.orgao.nome,
      this.layout(), false, true, this.formato, this.subTitulo());
  }

  private montarConteudoPorVencedores(lista: any[]) {

    const retorno = [];

    const registros: {}[] = [];

    const grupos = this.funcaoService.agrupar(lista, 'vencedor.proponente.favorecido.nome', ['valor_total']);

    let totalGeral = 0

    for (const grupo of grupos) {
      registros.push([
        { text: 'ITEM', alignment: 'center', bold: true, margin: 3, fontSize: 8, border: [true, true, true, true] },
        { text: 'CÓDIGO', alignment: 'center', bold: true, margin: 3, fontSize: 8, border: [true, true, true, true] },
        { text: 'DESCRIÇÃO', alignment: 'center', bold: true, margin: 3, fontSize: 8, border: [true, true, true, true] },
        { text: 'MARCA', alignment: 'center', bold: true, margin: 3, fontSize: 8, border: [true, true, true, true] },
        { text: 'QUANTIDADE', alignment: 'center', bold: true, margin: 3, fontSize: 8, border: [true, true, true, true] },
        { text: 'VL. UNITÁRIO', alignment: 'center', bold: true, margin: 3, fontSize: 8, border: [true, true, true, true] },
        { text: 'VL. TOTAL', alignment: 'center', bold: true, margin: 3, fontSize: 8, border: [true, true, true, true] }
      ]);

      if (grupo.registros[0].cotrato_tipo === 'CONTRATO') {
        registros.push([{
          text: grupo.registros[0].contrato_numero
            ? `${grupo.registros[0].vencedor.proponente.favorecido.nome} - Nº CONTRATO ${grupo.registros[0].contrato_numero
            }` : `${grupo.registros[0].vencedor.proponente.favorecido.nome}`,
          alignment: 'left', colSpan: 7, margin: 3, fontSize: 8, border: [true, true, true, true]
        }
          , '', '', '', '', '', ''
        ]);
      } else {
        registros.push([{
          text: grupo.registros[0].contrato_numero ? `${grupo.registros[0].vencedor.proponente.favorecido.nome} - Nº ATA DE REGISTRO DE PREÇO ${grupo.registros[0].contrato_numero}` : `${grupo.registros[0].vencedor.proponente.favorecido.nome}`,
          alignment: 'left', colSpan: 7, margin: 3, fontSize: 8, border: [true, true, true, true]
        }
          , '', '', '', '', '', ''
        ]);
      }

      for (const item of grupo.registros) {
        const itens = [];

        itens.push({ text: `${item.ordem}`, fontSize: 8, border: [false, false, false, false], alignment: 'center' });
        itens.push({ text: `${item.produto_unidade.produto.codigo}`, fontSize: 8, border: [false, false, false, false], alignment: 'center' });
        itens.push({ text: `${this.opcao === 'NOME' ? item.produto_unidade.produto.nome : item.produto_unidade.produto.descricao}`, fontSize: 8, border: [false, false, false, false], alignment: 'left' });
        itens.push({ text: `${item?.vencedor?.marca ? item?.vencedor?.marca : ' '}`, fontSize: 8, border: [false, false, false, false], alignment: 'left' });
        itens.push({ text: `${this.funcaoService.convertToBrNumber(item.quantidade, 3)}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' });
        itens.push({ text: `${this.funcaoService.convertToBrNumber(item.vencedor.valor_final, 4)}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' });
        itens.push({ text: `${this.funcaoService.convertToBrNumber(item.valor_total, 4)}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' });

        registros.push(itens);

        if (grupo.registros[grupo.registros.length - 1].id === item.id) {
          const totalItem = [
            { text: " ", colSpan: 6, border: [false, false, false, false] }, '', '', '', '', '',
            { text: `${this.funcaoService.convertToBrNumber(grupo.totalizadores["valor_total"], 4)}`, border: [false, true, false, false], alignment: 'right' }
          ];

          registros.push(totalItem);

          totalGeral += +grupo.totalizadores["valor_total"];
          const ultimoGrupo = grupos[grupos.length - 1].grupo;
          if (ultimoGrupo === grupo.grupo) {
            const linhaTotalGeral = [
              { text: `TOTAL GERAL:`, colSpan: 6, border: [false, false, false, false], alignment: 'left', bold: true, decoration: 'underline', preserveLeadingSpaces: true, },
              '', '', '', '', '',
              { text: `${this.funcaoService.convertToBrNumber(totalGeral, 4)}`, alignment: 'right', border: [false, false, false, false], bold: true, decoration: 'underline', preserveLeadingSpaces: true, }
            ];
            registros.push(linhaTotalGeral)

            if (this.fracassado.length > 0 || this.semProposta.length > 0) {
              const pularLinha = [{ text: ' ', colSpan: 7, pageBreak: "after", border: [false, false, false, false] }, '', '', '', '', '', ''];
              registros.push(pularLinha);
            }
          } else {
            const pularLinha = [{ text: ' ', colSpan: 7, pageBreak: "after", border: [false, false, false, false] }, '', '', '', '', '', ''];
            registros.push(pularLinha);
          };
        };
      };

    };

    if (this.semProposta.length > 0) {
      if(this.semProposta.find(a => a.ordem === 0)){
        this.semProposta = this.semProposta.sort((a, b) => +a.id- +b.id);
      } else  {
        this.semProposta = this.semProposta.sort((a, b) => +a.ordem - +b.ordem);
      }

      registros.push([{ text: 'ITENS SEM PROPOSTAS', bold: true, alignment: 'left', fontSize: 10, margin: [0, 10, 0, 5], colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '']);
      for (let p of this.semProposta) {
        registros.push([
          { text: `${p.ordem} - ${this.opcao === 'NOME' ? p.produto_unidade.produto.nome : p.produto_unidade.produto.descricao} - ${p.quantidade}`, margin: [5, 0, 0, 0], colSpan: 7, border: [false, false, false, false] }
          , '', '', '', '', '', ''
        ]);
      };
    };

    retorno.push({ text: '', colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '');
    retorno.push({ text: '', colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '');

    if (this.fracassado.length > 0) {
      if(this.fracassado.find(a => a.ordem === 0)){
        this.fracassado = this.fracassado.sort((a, b) => +a.id- +b.id);
      } else  {
        this.fracassado = this.fracassado.sort((a, b) => +a.ordem - +b.ordem);
      }
  
      registros.push([{ text: 'ITENS FRACASSADOS', bold: true, alignment: 'left', fontSize: 10, margin: [0, 10, 0, 5], colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '']);
      for (let p of this.fracassado) {
        registros.push([{ text: `${p.ordem} - ${this.opcao === 'NOME' ? p.produto_unidade.produto.nome : p.produto_unidade.produto.descricao} - ${p.quantidade}`, margin: [5, 0, 0, 0], colSpan: 7, border: [false, false, false, false] }, '', '', '', '', '', '']);
      };
    };

    retorno.push({
      layout: 'linhas',
      dontBreakRows: true,
      headerRows: 2,
      table: {
        widths: [40, 60, '*', 'auto', 60, 60, 60],
        body: registros
      }, margin: [0, 0, 0, 4]
    });

    return retorno;
  };

  public async imprimirModelos() {
    if (this.agrupamento == 'VENCEDORES') {
      await this.imprimirModeloPorVencedores();
    } else {
      this.imprimir();
    }
  }
}