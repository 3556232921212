import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { PncpAcesso } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PncpAcessoService extends BaseResourceService<PncpAcesso> {

  constructor(
    protected injector: Injector
  ) {
    super(`pncp-acessos`, injector);
  }

}
