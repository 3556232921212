import { Component, OnInit, Input, AfterViewInit, OnDestroy, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { Subject } from 'rxjs';
import { Login, GlobalService, Compra, CompraItem, EmpenhoExtra, RetencaoExtra, FuncaoService } from 'eddydata-lib';
import { EmpenhoService } from 'administrativo-lib';
import { CompraItemService, CompraService } from 'compra-lib';
import { Inplace } from 'primeng/inplace';
import { EmpenhoExtraService } from '../service/empenho-extra.service';
import { RetencaoExtraService } from '../service/retencao-extra.service';
import { OrdemPagamentoItemService } from '../../tesouraria/service/ordem-item-pagamento.service';

declare var $: any;

@Component({
  selector: 'lib-empenho-extra-anulacao',
  templateUrl: './empenho-extra-anulacao.component.html'
})
export class EmpenhoExtraAnulacaoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() login: Login;
  @Input() empNumero: number;
  @Input() empenhoId: number;
  @Input() empenho: EmpenhoExtra;
  @Input() visualizar: boolean = false;
  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('inplaceQuant') inplaceQuant: Inplace;
  @ViewChild('inplaceValor') inplaceValor: Inplace;

  protected datepipe: DatePipe;
  public ptBR: any;
  public data: Date;
  public valor: number;
  public historico: string;
  public aviso: string;
  public totalEmpenhado = 0;
  public totalPago = 0;
  public saldo = 0;
  public totalRetido = 0;
  public anularRetencoes: boolean = false;
  public listAnulacoes: Array<EmpenhoExtra>;
  public listaRetencoesAnuladas: Array<RetencaoExtra>;
  protected unsubscribe: Subject<void> = new Subject();
  public retornoRetencoes = [];
  public valorRetencao: number = 0;
  public listaRetencoes: Array<RetencaoExtra>;
  public carregando: boolean = false;
  public contador: number = 0;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected itemService: OrdemPagamentoItemService,
    private funcaoService: FuncaoService,
    private globalService: GlobalService,
    private empenhoService: EmpenhoExtraService,
    private retencaoService: RetencaoExtraService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new Date();
    this.valor = 0;
    this.historico = '';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  ngOnChanges(changes: SimpleChanges) {
    const parametros = {};
    if (this.visualizar && !this.carregando) {
      this.carregando = true;
      this.totalEmpenhado = this.empenho.valor_empenho;

      if (this.empNumero) {
        this.empenhoService.extendido(0, -1, {
          'exercicio_id': this.login.exercicio.id, 'orgao_id': this.login.orgao.id,
          'numero': this.empNumero, 'especie': 'EME', relations: 'retencao_extra'
        }).subscribe((res) => {
          let emp: EmpenhoExtra = res.content[0];

          this.totalEmpenhado = emp['total_empenhado'];
          this.totalPago = emp['total_pago'];

          this.saldo = +(+this.totalEmpenhado - +this.totalPago).toFixed(2);

          this.valor = this.saldo;
          if (this.saldo < 0) {
            this.confirmationService.confirm({
              message: 'O empenho não possui saldo para anular, verifique se o pagamento foi anulado para prosseguir ou se esta totalmente anulado.',
              acceptLabel: "OK",
              rejectVisible: false,
              closeOnEscape: false,
              header: 'Aviso!',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.hide();
              },
            });
          }
        })
      }

      this.obterRetencoes(this.empenho.id);

      parametros['numero'] = this.empenho.numero;
      parametros['especie'] = 'EEA';
      parametros['exercicio_id'] = this.login.exercicio.id;
      parametros['orgao_id'] = this.login.orgao.id;
      this.empenhoService.filtrar(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(lista => {
          this.totalRetido = lista.content.reduce((acc, item) => +item.valor_empenho + acc, 0);
        })
    }
  }

  private obterRetencoes(id: number) {
    this.retornoRetencoes = [];
    this.listaRetencoes = [];
    this.listaRetencoesAnuladas = [];
    this.retencaoService.extendido(0, -1, {
      empenho_id: id,
      relations: 'ficha,ficha.plano,ficha.favorecido,empenho_extra.favorecido,empenho_extra.orgao,empenho_extra.exercicio'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async (data: any) => {
          this.valorRetencao = 0;
          const lst = data ? data.content : new Array<RetencaoExtra>();
          this.listaRetencoes = [];
          for (const item of lst) {
            if (item.anulado || item.valor_retido < 0) {
              continue;
            }

            if (item.valor_retido < 0) {
              this.listaRetencoesAnuladas.push(item);
              continue;
            }

            const obj = Object.assign(new RetencaoExtra(), item);
            //Valores originais para comparação
            obj['valor_original'] = item.valor_retido;
            obj['ret_codigo'] = item.id;
            obj.valor_retido = 0;

            this.valor = this.valorRetencao;
            await this.verificarPagamentoRetencao(obj);

            //Total de valor a ser anulado nas retenções
            this.valorRetencao += +obj['valor_anulando'];

            this.listaRetencoes.push(obj);
          }

          this.carregando = false;

        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public confirmarRemocao(item: RetencaoExtra, index: number) {
    this.confirmationService.confirm({
      message: `Deseja remover a retenção ${item.id} da anulação?`,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção de retenção da anulação',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmAnulacao',
      accept: () => {
        this.removerItem(item, index)
      },
    });
  }

  private removerItem(item: RetencaoExtra, index: number) {
    this.listaRetencoes.splice(index, 1);
    this.valorRetencao = 0;
    for (const r of this.listaRetencoes) {
      this.valorRetencao += +r['valor_anulando'];
    }
  }

  private async verificarPagamentoRetencao(retencao: RetencaoExtra) {
    const retorno = await this.retencaoService.verificarPagamentoRetencao(retencao.id, this.login.exercicio.id, this.login.orgao.id, false).toPromise();

    if (+retencao['disponivel_anulacao'] > + retencao['valor_original']) {
      retencao['disponivel_anulacao'] = +retencao['valor_original']
    }

    retencao['disponivel_anulacao'] = +Number(retencao['total_retido_atual']).toFixed(2);
    retencao['valor_anulando'] = retencao['disponivel_anulacao'];
    retencao.valor_retido = +(+retencao['total_retido_atual'] - +retencao['valor_anulando']).toFixed(2);

    if (retorno[0] != '' && !retorno[0].includes('já está paga e precisa antes ser anulada no pagamento')) this.retornoRetencoes.push(retorno);
  }

  public anular() {
    const saldoAtualizado: number = this.saldo + (this.anularRetencoes ? +this.totalRetido : 0) - (+this.totalPago == 0 ? +this.totalRetido : 0);
    const parametros = {};
    parametros['relations'] = 'ordem';
    parametros['numero'] = this.empNumero;
    parametros['baixado'] = false;
    parametros['ordem.exercicio.id'] = this.login.exercicio.id;
    parametros['ordem.orgao.id'] = this.login.orgao.id;

    this.itemService.filtrar(0, 0, parametros).pipe(takeUntil(this.unsubscribe)).subscribe(async dados => {
      if (dados.content.length) {
        for (const item of dados.content) {
          toastr.error(`não é possivel anular o empenho desejado, pois encontra vinculado na ordem de pagamento nº ${item.ordem.numero}`);
          return;
        }
      }
    }, (error) => this.funcaoService.acaoErro(error));

    if (this.valor > saldoAtualizado) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor da anulação ultrapassa o saldo !' });
      return;
    }
    this.anularRetencao();
  }

  public anularRetencao() {
    //Se tiver valor retido, não estiver marcado para anular as retenções, e for valor 100%
    if (this.totalRetido > 0 && !this.anularRetencoes && this.valor == this.totalEmpenhado) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: `Empenho está retido, e marcado para anular o valor total empenhado, para continuar é necessário marcar que se deseja anular todas as retenções.` });
    } else if (this.totalRetido == 0) {//Se não tiver valor retido, continua.
      this.anularEmpenho();
    } else if (this.anularRetencoes) {//Se tiver marcado para anular retenções, necessário confirmar antes.
      this.confirmationService.confirm({
        message: 'Anulação marcada para anular TODAS retenções desse empenho, deseja realmente continuar?',
        acceptLabel: "Continuar",
        rejectLabel: "Não",
        rejectVisible: true,
        closeOnEscape: false,
        header: 'Aviso!',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.anularEmpenho();
        },
      });
    } else {//Se não for valor total, e não tiver marcado, só continua.
      this.anularEmpenho();
    }
  }

  public anularEmpenho() {
    let retencoes = [];
    retencoes = this.listaRetencoes;
    retencoes = retencoes.concat(this.listaRetencoesAnuladas);

    this.route.paramMap
      .pipe(switchMap(params => this.empenhoService.anular(this.empenhoId, String(this.data), this.valor, this.historico, this.login.usuario, retencoes)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.hide();
          this.router.navigate(['/empenhos-extra']);
          toastr.success('Anulação realizada com sucesso!');
          this.onFinalizar.emit();
        }, error => {
          if (error.error && error.error.payload) {
            this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error.error.payload });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Atenção', detail: 'Ocorreu um erro ao processar a sua solicitação' });
          }
        });
  }

  public buscarAnulacoes() {
    this.route.paramMap
      .pipe(switchMap(params => this.empenhoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          especie: 'EOA',
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          numero: this.empNumero
        }
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.listAnulacoes = dados.content;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  public salvarItem(item: any, ajuste_total?: boolean) {
    if (item.quantidade > item['quantidade_base']) {
      item.quantidade = +item['quantidade_base'];
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Quantidade excede quantidade do item !' });
      return;
    }

    let valor_total_base = ((+item['quantidade_base'] * +item.valor_unitario) - +item.valor_desconto) + +item.valor_icmsipi;

    if (+item['valor_total'] > +valor_total_base.toFixed(2)) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor total excede o valor total do item !' });
      return;
    }

    if (!ajuste_total) {
      item.quantidade = +item['valor_total'] / +item.valor_unitario;
    }

    if (this.inplaceQuant) {
      this.inplaceQuant.deactivate();
    }
    if (this.inplaceValor) {
      this.inplaceValor.deactivate();
    }
  }

  public focusInput(input: any) {
    setTimeout(() => {
      input.select();
    }, 100);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }
}
