import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe,
  EmpenhoExtra, Filtro, FormatoExportacao, GlobalService, LoginContabil
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmpenhoExtraService } from '../service/empenho-extra.service';
import { PagamentoExtraService, PagamentoRestoService, PagamentoService } from 'administrativo-lib';

declare var $: any;

@Component({
  selector: 'app-empenho-extra-list',
  templateUrl: './empenho-extra-list.component.html'
})
export class EmpenhoExtraListComponent extends BaseResourceListComponent<EmpenhoExtra, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  public especieSelect = 'EME';
  public myGroup: FormGroup;
  public listaEspecies: Array<any>;
  public listaFichas: Array<any>;
  public listaFavorecidos: Array<any>;
  public filtro: string;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;
  public empenhoAnular: EmpenhoExtra;
  public empenhoParcelar: EmpenhoExtra;
  public visualizarAnulacao: boolean = false;
  public empenhoAtualizar: EmpenhoExtra
  public visualizarAtualizacao = false;
  public empenhosSelecionados: any[] = [];
  public numerosEmpenhos: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private globalService: GlobalService,
    private empenhoService: EmpenhoExtraService,
    private pagamentoOrcamentarioServico: PagamentoService,
    private pagamentoRestoServico: PagamentoRestoService,
    private pagamentoExtraServico: PagamentoExtraService,
  ) {
    super(empenhoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: EmpenhoExtra): boolean {
    return (item.especie === 'EME' || item.especie === 'SEE')
  }

  protected relations(): string {
    return 'favorecido,ficha,ficha.plano,ficha.recurso,ficha.aplicacao,'
      + 'retencao,retencao.liquidacao,retencao.liquidacao.empenho,retencao.liquidacao.exercicio,'
      + 'retencao_resto,retencao_resto.liquidacao,retencao_resto.liquidacao.empenho,'
      + 'retencao_extra,'
      + 'empenho_anulado.retencao_resto.liquidacao.empenho,empenho_anulado.retencao.liquidacao.empenho'
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['data_empenho$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_empenho$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id
    //parametros['anulado_total'] = false;

    sessionStorage.removeItem('/empenhos-extra_data1');
    sessionStorage.removeItem('/empenhos-extra_data2');

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'ficha.numero', 'valor_empenho', 'retencao_resto.liquidacao.empenho.numero', 'favorecido.cpf_cnpj'],
      date: ['data_empenho'],
      text: ['ficha.nome', 'favorecido.nome'],
    };
  }

  public beforeInit(): void {
    this.usarExtendido = true
    this.usarFiltroPersonalizado = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/empenhos-extra_data1');
    const dt2: string = sessionStorage.getItem('/empenhos-extra_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(new Date(), 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected afterInit(): void {
    this.listaEspecies = [
      { id: 'EMO', nome: 'ORÇAMENTÁRIO' },
      { id: 'EME', nome: 'EXTRA-ORÇAMENTARIO' },
      { id: 'EMR', nome: 'RESTOS A PAGAR' }
    ];
  }

  protected acaoRemover(model: EmpenhoExtra): Observable<EmpenhoExtra> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data', coluna: 'data_empenho', agrupar: true, bold: true },
      { titulo: 'Número', coluna: 'numero', bold: true },
      { titulo: 'Beneficiario', coluna: 'favorecido.nome' },
      { titulo: 'Conta', coluna: 'ficha.plano.nome' },
      { titulo: 'Historico', coluna: 'historico' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Retenção', coluna: 'total_retido', alignment: 'right', decimais: 2 },
      { titulo: 'Valor', coluna: 'valor_empenho', alignment: 'right', decimais: 2 },
      { titulo: 'Código Reinf', coluna: 'codigo_reinf' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'favorecido,ficha,ficha.plano';
    this.empenhoService
      .extendido(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          console.log(lista)
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE EMPENHOS EXTRA',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem empenhos', ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_empenho', 'total_retido']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Data Empenho', coluna: 'data_empenho', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Descrição Despesa', coluna: 'ficha.nome', tipo: 'String' });
    retorno.push({ titulo: 'Despesa', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Código Despesa', coluna: 'ficha.plano.codigo', alignment: 'center', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Histórico', coluna: 'historico', tipo: 'String' });
    retorno.push({ titulo: 'Favorecido', coluna: 'favorecido.nome', tipo: 'String', padrao: true });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', padrao: true });
    retorno.push({ titulo: 'Nº Empenho Origem', coluna: '', alignment: 'center', tipo: 'Number', colunasFiltro: ['retencao.liquidacao.empenho.numero', 'empenho_anulado.retencao.liquidacao.empenho.numero'] });
    retorno.push({ titulo: 'Nº Empenho Origem Resto', coluna: '', alignment: 'center', tipo: 'Number', colunasFiltro: ['retencao_resto.liquidacao.empenho.numero', 'empenho_anulado.retencao_resto.liquidacao.empenho.numero'] });
    retorno.push({ titulo: 'Valor', coluna: 'valor_empenho', alignment: 'center', tipo: 'Number' });

    return retorno;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public anular(item: EmpenhoExtra) {
    this.empenhoAnular = item;
    this.visualizarAnulacao = true;
  }

  public parcelar(item: EmpenhoExtra) {
    this.empenhoParcelar = item;
    $('#dialogParcelar').modal('show');
  }

  public onChangeEmpenho(value: any, especie: any) {
    if (especie === 'EMO') {
      this.router.navigate(['empenhos-orcamentario']);
    } else if (especie === 'EMR') {
      this.router.navigate(['empenhos-resto']);
    }
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public editarAnulacao(item: EmpenhoExtra) {
    return item.especie === 'EEA' && this.login.usuario.sistemas_administrador?.split(',').includes('contabil')
  }


  public alterarAnular(item: EmpenhoExtra) {
    this.empenhoAtualizar = item;
    this.visualizarAtualizacao = true;
  }

  public temRetencoes(empenho: EmpenhoExtra): boolean {
    return !!!(empenho.retencao?.id || empenho.retencao_resto?.id)
  }

  public ImprimirNotaPorEmpenhosSelecionados(item: EmpenhoExtra) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.empenhosSelecionados
      const index = this.empenhosSelecionados.findIndex(e => e.id === item.id);
      if (index > -1) {
        this.empenhosSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.empenhosSelecionados.some(e => e.id === item.id)) {
        this.empenhosSelecionados.push(item);
      }
    }
    this.numerosEmpenhos = [];
    for (const empenho of this.empenhosSelecionados) {
      this.numerosEmpenhos.push(empenho.numero)
    }
  }
}
