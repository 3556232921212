import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExercicioService, OrgaoService, GlobalService, FuncaoService, Relatorio, OrgaoAssinaturaService, OrgaoAssinatura, FormatoExportacao, Exercicio } from 'eddydata-lib';
import { FichaExtraService } from 'contabil-lib';

@Injectable({
  providedIn: 'root'
})
export class BalanceteExtraService {

  protected unsubscribe: Subject<void> = new Subject();
  private assinatura: OrgaoAssinatura;

  constructor(
    protected fichaService: FichaExtraService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
  ) { }

  async montarBalanceteExtra(formato: FormatoExportacao, mes: number, exercicioId: number, orgaos: number[], login: any, dtInicio: string, dtFim: string, sintetico?: boolean, exibirRecurso?: Boolean) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = dtInicio;
      parametros['dtFim'] = dtFim;
    }

    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }

    parametros['orgaos'] = orgaos.join();
    parametros['especie'] = 'E'
    parametros['sintetico'] = sintetico;
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();

    this.fichaService.balancete(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (mes) {
          subtitulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano
        } else {
          let dt = dtInicio.split("-");
          subtitulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
          dt = dtFim.split("-");
          subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
        }

        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA RECEITA E DESPESA EXTRA-ORÇAMENTÁRIA',
            login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
            this.balanceteDespesaConteudo(dados, sintetico, exibirRecurso)
              .concat(await this.conteudoAssinatura(login, ex, mes, dtFim)),
            'landscape', 'Balancete Extra Orçamentário',
            {
              linhas: {
                hLineWidth(i, node) {
                  return 1;
                },
                vLineWidth(i) {
                  return 1;
                },
                hLineColor(i) {
                  return 'black';
                },
                paddingLeft(i) {
                  return 3;
                },
                paddingRight(i, node) {
                  return 3;
                }
              }
            }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
        } else if (formato === 'csv') {
          this.balanceteDespesaCSV(dados, sintetico);
        }
      });
  }

  balanceteDespesaConteudo(dados: any[], sintetico: boolean, exibirRecurso?: Boolean): {}[] {
    const registros: {}[] = [
      // [{
      //   text: 'DEMONSTRAÇÃO DA RECEITA E DESPESA EXTRA-ORÇAMENTÁRIA',
      //   colSpan: 9, alignment: 'center', bold: true, fontSize: 11
      // },
      //   '', '', '', '', '', '', '', ''],
      !sintetico ? [
        { text: 'FICHA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, colSpan: exibirRecurso ? 1 : 2 },
        exibirRecurso ? { text: 'RECURSO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 } : {},
        { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'ANTERIOR', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'CANCELADO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'RECEITAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'DESPESAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 }
      ] : [
        { text: 'DESCRIÇÃO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, colSpan: exibirRecurso ? 2 : 3 }, '',
        exibirRecurso ? { text: 'RECURSO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 } : {},
        { text: 'ANTERIOR', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'CANCELADO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 },
        { text: 'RECEITAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'DESPESAS', alignment: 'center', colSpan: 2, bold: true, fontSize: 7 },
        { text: '' },
        { text: 'SALDO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7 }
      ],
      ['', '', '', '', '',
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true },
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADO', alignment: 'center', bold: true, fontSize: 7 }, '']
    ];

    const executora = {};
    let totalAnterior = 0.00;
    let totalCancelado = 0.00;
    let totalReceitaMes = 0.00;
    let totalReceitaAcumulado = 0.00;
    let totalDespesaMes = 0.00;
    let totalDespesaAcumulado = 0.00;
    let totalSaldo = 0.00;

    if (!sintetico) {
      for (const registro of dados) {
        const grupo = String(registro['p_codigo']).substring(0, 1);
        if (!executora['grupo'] || executora['grupo'] !== grupo) {
          if (executora['grupo']) {
            registros.push(this.retornarLinhaSomaExecutora(executora));
          }

          executora['nome'] = registro['fh_nome'];
          executora['grupo'] = grupo;
          executora['orcado'] = 0.00;
          executora['anterior'] = 0.00;
          executora['cancelado'] = 0.00;
          executora['receita_mes'] = 0.00;
          executora['receita_acumulado'] = 0.00;
          executora['despesa_mes'] = 0.00;
          executora['despesa_acumulado'] = 0.00;
          executora['saldo'] = 0.00;

          registros.push([
            {
              text: `${grupo === '1' ? 'ATIVO' : grupo === '2' ? 'PASSIVO' : 'RESTOS A PAGAR'}`, colSpan: 10,
              bold: true, fontSize: 8
            }, '', '', '', '', '', '', '', '', ''
          ]);
        }

        const anterior: number = registro['fh_saldo_anterior'];
        const cancelado: number = grupo == '0' ? +registro['cancelamento_resto'] + +registro['cancelamento_resto_nao_processado'] + +registro['cancelamento_resto_processado'] : 0;
        const despesaMes: number = grupo == '0' ? registro['pagto_resto_mes'] : registro['despesa_mes'];
        const despesaAcumulado: number = grupo == '0' ? registro['pagto_resto_acumulado'] : registro['despesa_acumulado'];
        const receitaMes: number = registro['receita_mes'];
        const receitaAcumulado: number = registro['receita_acumulado'];
        const saldo = grupo == '0' ? +anterior - (+cancelado + +despesaAcumulado) : (+anterior + +receitaAcumulado) - +despesaAcumulado;
        executora['anterior'] += +anterior;
        executora['cancelado'] += +cancelado;
        executora['despesa_mes'] += +despesaMes;
        executora['despesa_acumulado'] += +despesaAcumulado;
        executora['receita_mes'] += +receitaMes;
        executora['receita_acumulado'] += +receitaAcumulado;
        executora['saldo'] += +saldo;
        totalAnterior += +anterior;
        totalCancelado += +cancelado;
        totalDespesaMes += +despesaMes;
        totalDespesaAcumulado += +despesaAcumulado;
        totalReceitaMes += +receitaMes;
        totalReceitaAcumulado += +receitaAcumulado;
        totalSaldo += +saldo;

        registros.push([
          {
            text: this.funcaoService.strZero(registro['fh_numero'], 3),
            border: [true, false, true, false], alignment: 'center', fontSize: 7, colSpan: exibirRecurso ? 1 : 2
          },
          exibirRecurso ? {
            text: this.funcaoService.mascarar('## ### ####', registro['aplicacao']),
            border: [true, false, true, false], alignment: 'center', fontSize: 7
          } : {},
          {
            text: registro['p_codigo'] ?
              this.funcaoService.mascarar('#.#.#.#.#.##.##', registro['p_codigo']) + ' ' + registro['fh_nome'] : registro['fh_nome'],
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(anterior), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(cancelado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(receitaMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(receitaAcumulado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(despesaMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(despesaAcumulado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(saldo), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }

      if (executora['grupo']) {
        registros.push(this.retornarLinhaSomaExecutora(executora));
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 3,
            bold: true, fontSize: 8
          },
          '', '',
          { text: this.funcaoService.convertToBrNumber(totalAnterior), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalCancelado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalReceitaMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalReceitaAcumulado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDespesaMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDespesaAcumulado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 3,
          widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: registros
        }
      }];
    } else {
      const grupos = this.funcaoService.agrupar(dados, ['p_codigo', 'p_nome', 'aplicacao'],
        ['cancelamento_resto', 'despesa_acumulado', 'despesa_mes', 'fh_saldo_anterior',
          'pagto_resto_acumulado', 'pagto_resto_mes', 'receita_acumulado', 'receita_mes', 'cancelamento_resto_nao_processado', 'cancelamento_resto_processado']);

      for (const registro of grupos) {
        const grupo = String(registro.grupo['p_codigo']).substring(0, 1);
        if (!executora['grupo'] || executora['grupo'] !== grupo) {
          if (executora['grupo']) {
            registros.push(this.retornarLinhaSomaExecutora(executora));
          }

          executora['nome'] = grupo['p_nome'];
          executora['grupo'] = grupo;
          executora['orcado'] = 0.00;
          executora['anterior'] = 0.00;
          executora['cancelado'] = 0.00;
          executora['receita_mes'] = 0.00;
          executora['receita_acumulado'] = 0.00;
          executora['despesa_mes'] = 0.00;
          executora['despesa_acumulado'] = 0.00;
          executora['saldo'] = 0.00;

          registros.push([
            {
              text: `${grupo === '1' ? 'ATIVO' : grupo === '2' ? 'PASSIVO' : 'RESTOS A PAGAR'}`, colSpan: 10,
              bold: true, fontSize: 8
            }, '', '', '', '', '', '', '', '', ''
          ]);
        }

        const anterior: number = registro.totalizadores['fh_saldo_anterior'];
        const cancelado: number = grupo == '0' ? +registro.totalizadores['cancelamento_resto'] + +registro.totalizadores['cancelamento_resto_nao_processado']+ +registro.totalizadores['cancelamento_resto_processado'] : 0;
        const despesaMes: number = grupo == '0' ? registro.totalizadores['pagto_resto_mes'] : registro.totalizadores['despesa_mes'];
        const despesaAcumulado: number = grupo == '0' ? registro.totalizadores['pagto_resto_acumulado'] : registro.totalizadores['despesa_acumulado'];
        const receitaMes: number = registro.totalizadores['receita_mes'];
        const receitaAcumulado: number = registro.totalizadores['receita_acumulado'];
        const saldo = grupo == '0' ? +anterior - (+cancelado + +despesaAcumulado) : (+anterior + +receitaAcumulado) - +despesaAcumulado;
        executora['anterior'] += +anterior;
        executora['cancelado'] += +cancelado;
        executora['despesa_mes'] += +despesaMes;
        executora['despesa_acumulado'] += +despesaAcumulado;
        executora['receita_mes'] += +receitaMes;
        executora['receita_acumulado'] += +receitaAcumulado;
        executora['saldo'] += +saldo;
        totalAnterior += +anterior;
        totalCancelado += +cancelado;
        totalDespesaMes += +despesaMes;
        totalDespesaAcumulado += +despesaAcumulado;
        totalReceitaMes += +receitaMes;
        totalReceitaAcumulado += +receitaAcumulado;
        totalSaldo += +saldo;

        registros.push([
          {
            text: `${this.funcaoService.mascarar('#.#.#.#.#.##.##', registro.grupo['p_codigo'])} ${registro.grupo['p_nome']}`,
            border: [true, false, true, false], fontSize: 7, colSpan: exibirRecurso ? 2 : 3
          }, '',
          exibirRecurso ? {
            text: this.funcaoService.mascarar('## ### ####', registro.grupo['aplicacao']),
            border: [true, false, true, false], fontSize: 7,
          } : {},
          { text: this.funcaoService.convertToBrNumber(anterior), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(cancelado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(receitaMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(receitaAcumulado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(despesaMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          {
            text: this.funcaoService.convertToBrNumber(despesaAcumulado), alignment: 'right',
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(saldo), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }

      if (executora['grupo']) {
        registros.push(this.retornarLinhaSomaExecutora(executora));
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 3,
            bold: true, fontSize: 7
          },
          '', '',
          { text: this.funcaoService.convertToBrNumber(totalAnterior), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalCancelado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalReceitaMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalReceitaAcumulado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDespesaMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDespesaAcumulado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalSaldo), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 2,
          widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: registros
        }
      }];
    }
  }

  balanceteDespesaCSV(dados: any[], sintetico: boolean) {
    let registros: {}[][] = [];
    if (!sintetico) {
      registros.push([
        { text: 'FICHA' },
        { text: 'DESCRIÇÃO' },
        { text: 'ANTERIOR' },
        { text: 'CANCELADO' },
        { text: 'RECEITAS DO MÊS' },
        { text: 'RECEITAS ACUMULADO' },
        { text: 'DESPESAS DO MÊS' },
        { text: 'DESPESAS ACUMULADO' },
        { text: 'SALDO' }
      ]);
    } else {
      registros.push([
        { text: 'DESCRIÇÃO' },
        { text: 'ANTERIOR' },
        { text: 'CANCELADO' },
        { text: 'RECEITAS DO MÊS' },
        { text: 'RECEITAS ACUMULADO' },
        { text: 'DESPESAS DO MÊS' },
        { text: 'DESPESAS ACUMULADO' },
        { text: 'SALDO' }
      ]);
    }

    const executora = {};
    let totalAnterior = 0.00;
    let totalCancelado = 0.00;
    let totalReceitaMes = 0.00;
    let totalReceitaAcumulado = 0.00;
    let totalDespesaMes = 0.00;
    let totalDespesaAcumulado = 0.00;
    let totalSaldo = 0.00;

    if (!sintetico) {
      for (const registro of dados) {
        const grupo = String(registro['p_codigo']).substring(0, 1);
        if (!executora['grupo'] || executora['grupo'] !== grupo) {
          if (executora['grupo']) {
            registros.push([
              { text: 'SOMA' }, { text: '' },
              { text: this.funcaoService.convertToBrNumber(executora['anterior']) },
              { text: this.funcaoService.convertToBrNumber(executora['cancelado']) },
              { text: this.funcaoService.convertToBrNumber(executora['receita_mes']) },
              { text: this.funcaoService.convertToBrNumber(executora['receita_acumulado']) },
              { text: this.funcaoService.convertToBrNumber(executora['despesa_mes']) },
              { text: this.funcaoService.convertToBrNumber(executora['despesa_acumulado']) },
              { text: this.funcaoService.convertToBrNumber(executora['saldo']) }
            ]);
          }

          executora['nome'] = registro['fh_nome'];
          executora['grupo'] = grupo;
          executora['orcado'] = 0.00;
          executora['anterior'] = 0.00;
          executora['cancelado'] = 0.00;
          executora['receita_mes'] = 0.00;
          executora['receita_acumulado'] = 0.00;
          executora['despesa_mes'] = 0.00;
          executora['despesa_acumulado'] = 0.00;
          executora['saldo'] = 0.00;

          registros.push([{ text: `${grupo === '1' ? 'ATIVO' : grupo === '2' ? 'PASSIVO' : 'RESTOS A PAGAR'}` }]);
        }

        const anterior: number = registro['fh_saldo_anterior'];
        const cancelado: number = grupo == '0' ? +registro['cancelamento_resto'] + +registro['cancelamento_resto_nao_processado']+ +registro['cancelamento_resto_processado'] : 0;
        const despesaMes: number = grupo == '0' ? registro['pagto_resto_mes'] : registro['despesa_mes'];
        const despesaAcumulado: number = grupo == '0' ? registro['pagto_resto_acumulado'] : registro['despesa_acumulado'];
        const receitaMes: number = registro['receita_mes'];
        const receitaAcumulado: number = registro['receita_acumulado'];
        const saldo = grupo == '0' ? +anterior - (+cancelado + +despesaAcumulado) : (+anterior + +receitaAcumulado) - +despesaAcumulado;
        executora['anterior'] += +anterior;
        executora['cancelado'] += +cancelado;
        executora['despesa_mes'] += +despesaMes;
        executora['despesa_acumulado'] += +despesaAcumulado;
        executora['receita_mes'] += +receitaMes;
        executora['receita_acumulado'] += +receitaAcumulado;
        executora['saldo'] += +saldo;
        totalAnterior += +anterior;
        totalCancelado += +cancelado;
        totalDespesaMes += +despesaMes;
        totalDespesaAcumulado += +despesaAcumulado;
        totalReceitaMes += +receitaMes;
        totalReceitaAcumulado += +receitaAcumulado;
        totalSaldo += +saldo;

        registros.push([
          { text: this.funcaoService.strZero(registro['fh_numero'], 3) },
          { text: registro['p_codigo'] ? this.funcaoService.mascarar('#.#.#.#.#.##.##', registro['p_codigo']) + ' ' + registro['fh_nome'] : registro['fh_nome'] },
          { text: this.funcaoService.convertToBrNumber(anterior) },
          { text: this.funcaoService.convertToBrNumber(cancelado) },
          { text: this.funcaoService.convertToBrNumber(receitaMes) },
          { text: this.funcaoService.convertToBrNumber(receitaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(despesaMes) },
          { text: this.funcaoService.convertToBrNumber(despesaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(saldo) },
        ]);
      }

      if (executora['grupo']) {
        registros.push([
          { text: 'SOMA' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(executora['anterior']) },
          { text: this.funcaoService.convertToBrNumber(executora['cancelado']) },
          { text: this.funcaoService.convertToBrNumber(executora['receita_mes']) },
          { text: this.funcaoService.convertToBrNumber(executora['receita_acumulado']) },
          { text: this.funcaoService.convertToBrNumber(executora['despesa_mes']) },
          { text: this.funcaoService.convertToBrNumber(executora['despesa_acumulado']) },
          { text: this.funcaoService.convertToBrNumber(executora['saldo']) }
        ]);
        registros.push([
          { text: 'TOTAL GERAL:' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalAnterior) },
          { text: this.funcaoService.convertToBrNumber(totalCancelado) },
          { text: this.funcaoService.convertToBrNumber(totalReceitaMes) },
          { text: this.funcaoService.convertToBrNumber(totalReceitaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(totalDespesaMes) },
          { text: this.funcaoService.convertToBrNumber(totalDespesaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldo) },
        ]);
      }
    } else {
      const grupos = this.funcaoService.agrupar(dados, ['p_codigo', 'p_nome'],
       ['cancelamento_resto', 'despesa_acumulado', 'despesa_mes', 'fh_saldo_anterior',
        'pagto_resto_acumulado', 'pagto_resto_mes', 'receita_acumulado', 'receita_mes', 'cancelamento_resto_nao_processado', 'cancelamento_resto_processado']);

      for (const registro of grupos) {
        const grupo = String(registro.grupo['p_codigo']).substring(0, 1);
        if (!executora['grupo'] || executora['grupo'] !== grupo) {
          if (executora['grupo']) {
            registros.push([
              { text: 'SOMA' },
              { text: this.funcaoService.convertToBrNumber(executora['anterior']) },
              { text: this.funcaoService.convertToBrNumber(executora['cancelado']) },
              { text: this.funcaoService.convertToBrNumber(executora['receita_mes']) },
              { text: this.funcaoService.convertToBrNumber(executora['receita_acumulado']) },
              { text: this.funcaoService.convertToBrNumber(executora['despesa_mes']) },
              { text: this.funcaoService.convertToBrNumber(executora['despesa_acumulado']) },
              { text: this.funcaoService.convertToBrNumber(executora['saldo']) }
            ]);
          }

          executora['nome'] = grupo['p_nome'];
          executora['grupo'] = grupo;
          executora['orcado'] = 0.00;
          executora['anterior'] = 0.00;
          executora['cancelado'] = 0.00;
          executora['receita_mes'] = 0.00;
          executora['receita_acumulado'] = 0.00;
          executora['despesa_mes'] = 0.00;
          executora['despesa_acumulado'] = 0.00;
          executora['saldo'] = 0.00;

          registros.push([{ text: `${grupo === '1' ? 'ATIVO' : grupo === '2' ? 'PASSIVO' : 'RESTOS A PAGAR'}` }]);
        }

        const anterior: number = registro.totalizadores['fh_saldo_anterior'];
        const cancelado: number = grupo == '0' ? +registro.totalizadores['cancelamento_resto'] + +registro.totalizadores['cancelamento_resto_nao_processado'] + +registro.totalizadores['cancelamento_resto_processado']: 0;
        const despesaMes: number = grupo == '0' ? registro.totalizadores['pagto_resto_mes'] : registro.totalizadores['despesa_mes'];
        const despesaAcumulado: number = grupo == '0' ? registro.totalizadores['pagto_resto_acumulado'] : registro.totalizadores['despesa_acumulado'];
        const receitaMes: number = registro.totalizadores['receita_mes'];
        const receitaAcumulado: number = registro.totalizadores['receita_acumulado'];
        const saldo = grupo == '0' ? +anterior - (+cancelado + +despesaAcumulado) : (+anterior + +receitaAcumulado) - +despesaAcumulado;
        executora['anterior'] += +anterior;
        executora['cancelado'] += +cancelado;
        executora['despesa_mes'] += +despesaMes;
        executora['despesa_acumulado'] += +despesaAcumulado;
        executora['receita_mes'] += +receitaMes;
        executora['receita_acumulado'] += +receitaAcumulado;
        executora['saldo'] += +saldo;
        totalAnterior += +anterior;
        totalCancelado += +cancelado;
        totalDespesaMes += +despesaMes;
        totalDespesaAcumulado += +despesaAcumulado;
        totalReceitaMes += +receitaMes;
        totalReceitaAcumulado += +receitaAcumulado;
        totalSaldo += +saldo;

        registros.push([
          { text: `${this.funcaoService.mascarar('#.#.#.#.#.##.##', registro.grupo['p_codigo'])} ${registro.grupo['p_nome']}` },
          { text: this.funcaoService.convertToBrNumber(anterior) },
          { text: this.funcaoService.convertToBrNumber(cancelado) },
          { text: this.funcaoService.convertToBrNumber(receitaMes) },
          { text: this.funcaoService.convertToBrNumber(receitaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(despesaMes) },
          { text: this.funcaoService.convertToBrNumber(despesaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(saldo) },
        ]);
      }

      if (executora['grupo']) {
        registros.push([
          { text: 'SOMA' },
          { text: this.funcaoService.convertToBrNumber(executora['anterior']) },
          { text: this.funcaoService.convertToBrNumber(executora['cancelado']) },
          { text: this.funcaoService.convertToBrNumber(executora['receita_mes']) },
          { text: this.funcaoService.convertToBrNumber(executora['receita_acumulado']) },
          { text: this.funcaoService.convertToBrNumber(executora['despesa_mes']) },
          { text: this.funcaoService.convertToBrNumber(executora['despesa_acumulado']) },
          { text: this.funcaoService.convertToBrNumber(executora['saldo']) }
        ]);
        registros.push([
          { text: 'TOTAL GERAL:' },
          { text: this.funcaoService.convertToBrNumber(totalAnterior) },
          { text: this.funcaoService.convertToBrNumber(totalCancelado) },
          { text: this.funcaoService.convertToBrNumber(totalReceitaMes) },
          { text: this.funcaoService.convertToBrNumber(totalReceitaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(totalDespesaMes) },
          { text: this.funcaoService.convertToBrNumber(totalDespesaAcumulado) },
          { text: this.funcaoService.convertToBrNumber(totalSaldo) },
        ]);
      }
    }

    dados = null;
    let csv = '';
    for (let i = 0; i < registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x = 0; x < linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `Balancete Extra Orçamentário.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  retornarLinhaSomaExecutora(executora: {}) {
    return [
      {
        text: 'SOMA', colSpan: 3,
        bold: true, fontSize: 7
      },
      '', '',
      { text: this.funcaoService.convertToBrNumber(executora['anterior']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(+executora['cancelado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(executora['receita_mes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(executora['receita_acumulado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(executora['despesa_mes']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(executora['despesa_acumulado']), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(executora['saldo']), bold: true, alignment: 'right', fontSize: 7 }
    ];
  }

  private async conteudoAssinatura(login: any, exercicio: Exercicio, mes: number, dataFinal: string) {
    const conteudo = [];
    const data = dataFinal ?? `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
    this.assinatura = await this.assinaturaService.obter({ orgao_id: login.orgao.id, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    conteudo.push([
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      },
      {
        text: '_____________________________________________',
        border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 30, 0, 0]
      }
    ]);
    conteudo.push([
      { text: this.assinatura.ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.contador, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_ordenador_despesa, border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_contador + (this.assinatura.crc_contador ? ` - ${this.assinatura.crc_contador}` : ''), border: [false, false, false, false], bold: true, alignment: 'center' },
      { text: this.assinatura.cargo_tesoureiro, border: [false, false, false, false], bold: true, alignment: 'center' }
    ]);

    return [{
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }

}
