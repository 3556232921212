import { DatePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { Assinaturas, RecebimentoService } from 'administrativo-lib';
import { FuncaoService, Login, OrgaoAssinatura, ParametroContabilService, Relatorio } from 'eddydata-lib';

export class BoletimReceita {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected recebimentoService: RecebimentoService;

  private assinatura: OrgaoAssinatura;
  private log: Login;

  public assinatura_tesouraria: boolean;

  dataCaixa: Date;
  dataFinal: Date;
  numero: number;

  constructor(
    protected injector: Injector,
    protected parametroContabilidadeServico: ParametroContabilService,
  ) {
    this.funcaoService = new FuncaoService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(lista: any[], login: Login) {
    this.log = login;

    Relatorio.imprimirPersonalizado('BOLETIM ANALÍTICO DA RECEITA', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      await this.montarConteudo(lista),
      'portrait', 'BOLETIM ANALÍTICO DA RECEITA',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, true);
  }

  private async montarConteudo(lista: any[]) {
    const conteudo = [];

    conteudo.push(
      this.dadosCabecalho(this.log)
        .concat(await this.dadosDetalhe(lista))
    );

    return conteudo;
  }

  private dadosCabecalho(log: Login): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'center',
        margin: [0, 30, 0, 0]
      };
    } else {
      brasaoImage = { margin: [0, 30, 0, 0], text: '' };
    }
    const conteudo = [
      [{ text: brasaoImage, border: [true, true, true, false] }],
      [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false] }],
      [{
        text: `BOLETIM ANALÍTICO DA RECEITA Nº ${this.funcaoService.strZero(this.numero, 5)} `,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }],
      [{
        text: `DATA: ${this.datepipe.transform(this.dataCaixa, 'dd/MM/yyyy')}`,
        alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private async dadosDetalhe(dados: any): Promise<{}[]> {
    const conteudo = [
      [
        { text: 'FICHA', rowSpan: 2, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        { text: 'ESPECIFICAÇÃO', rowSpan: 2, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        { text: 'ORÇADA', rowSpan: 2, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
        { text: 'ARRECADADA', colSpan: 2, fontSize: 8, alignment: 'center', bold: true },
        { text: '' },
        { text: 'DIFERENÇA', rowSpan: 2, fontSize: 8, alignment: 'center', bold: true, margin: [0, 5, 0, 0] },
      ],
      [
        { text: '' },
        { text: '' },
        { text: '' },
        { text: 'DO DIA', fontSize: 8, alignment: 'center', bold: true, colSpan: 1 },
        { text: 'ACUMULADA', fontSize: 8, alignment: 'center', bold: true },
        { text: '' },
      ]
    ];

    let totalOrcada = 0;
    let totalDia = 0;
    let totalAcumulada = 0;
    let totalDiferenca = 0;
    for (const item of dados) {

      if (+item['pago_mes'] !== 0 || +item['pago_anulado'] !== 0) {
        const diferenca = +item['pago_acumulado'] - +item['fh_valor_orcado'];
        totalOrcada += +item['fh_valor_orcado'];
        totalDia += +item['pago_mes'];
        totalAcumulada += +item['pago_acumulado'];
        totalDiferenca += +diferenca;
        conteudo.push(
          [
            { text: this.funcaoService.strZero(item['fh_numero'], 4), fontSize: 8, alignment: 'center', bold: false },
            { text: item['d_codigo'] + ' ' + item['d_nome'] },
            { text: this.funcaoService.convertToBrNumber(item['fh_valor_orcado'], 2, true), fontSize: 8, alignment: 'right', bold: false },
            { text: this.funcaoService.convertToBrNumber(+item['pago_mes'], 2, true), fontSize: 8, alignment: 'right', bold: false },
            { text: this.funcaoService.convertToBrNumber(+item['pago_acumulado'], 2, true), fontSize: 8, alignment: 'right', bold: false },
            { text: this.funcaoService.convertToBrNumber(diferenca, 2, true), fontSize: 8, alignment: 'right', bold: false },
          ]
        );
      }
    }

    conteudo.push(
      [
        { text: 'TOTAL', colSpan: 2, fontSize: 9, alignment: 'left', bold: true },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(totalOrcada, 2, true), fontSize: 8, alignment: 'right', bold: true },
        { text: this.funcaoService.convertToBrNumber(totalDia, 2, true), fontSize: 8, alignment: 'right', bold: true },
        { text: this.funcaoService.convertToBrNumber(totalAcumulada, 2, true), fontSize: 8, alignment: 'right', bold: true },
        { text: this.funcaoService.convertToBrNumber(totalDiferenca, 2, true), fontSize: 8, alignment: 'right', bold: true },
      ]
    );

    this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.log.orgao.id }).toPromise()).assinatura_tesouraria;

    const ass = new Assinaturas(this.log.orgao, this.injector);
    const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria, this.funcaoService.converterDataSQL(this.dataFinal));

    return this.assinatura_tesouraria ? [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [30, '*', 60, 60, 60, 60],
        body: conteudo
      }
    }, {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [30, '*', 60, 60, 60, 60],
        body: conteudo
      }
    }];
  }

  public imprimirCSV(dados: any[]) {
    let conteudo: {}[][] = [[
      { text: 'FICHA' },
      { text: 'ESPECIFICAÇÃO' },
      { text: 'ORÇADA' },
      { text: 'ARRECADADA DO DIA' },
      { text: 'ARRECADADA ACUMULADA' },
      { text: 'DIFERENÇA' },
    ]];

    let totalOrcada = 0;
    let totalDia = 0;
    let totalAcumulada = 0;
    let totalDiferenca = 0;
    for (const item of dados) {
      if (+item['pago_mes'] !== 0) {
        const diferenca = +item['pago_acumulado'] - +item['fh_valor_orcado'];
        totalOrcada += +item['fh_valor_orcado'];
        totalDia += +item['pago_mes'];
        totalAcumulada += +item['pago_acumulado'];
        totalDiferenca += +diferenca;
        conteudo.push([
          { text: this.funcaoService.strZero(item['fh_numero'], 4) },
          { text: item['d_codigo'] + ' ' + item['d_nome'] },
          { text: this.funcaoService.convertToBrNumber(item['fh_valor_orcado'], 2, true) },
          { text: this.funcaoService.convertToBrNumber(+item['pago_mes'], 2, true) },
          { text: this.funcaoService.convertToBrNumber(+item['pago_acumulado'], 2, true) },
          { text: this.funcaoService.convertToBrNumber(diferenca, 2, true) },
        ]);
      }
    }

    conteudo.push([
      { text: 'TOTAL' }, { text: '' },
      { text: this.funcaoService.convertToBrNumber(totalOrcada, 2, true) },
      { text: this.funcaoService.convertToBrNumber(totalDia, 2, true) },
      { text: this.funcaoService.convertToBrNumber(totalAcumulada, 2, true) },
      { text: this.funcaoService.convertToBrNumber(totalDiferenca, 2, true) },
    ]);

    let csv = '';
    for (let i=0; i<conteudo.length; i++) {
      const linha = conteudo[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    conteudo = null;
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", 'BOLETIM ANALÍTICO DA RECEITA');
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
