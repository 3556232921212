import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, FuncaoService, Imovel } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeguradoraService extends BaseResourceService<Imovel> {

  constructor(
    protected injector: Injector
  ) {
    super(`seguradoras`, injector);
  }

  public obterFiltro(codigoSeguradora?: string, nomeSeguradora?: string,
    nomeContatoSeguradora?: string, telefoneSeguradora?: string,
    celularSeguradora?: string, cepSeguradora?: string, enderecoSeguradora?: string,
    numeroSeguradora?: string, bairroSeguradora?: string
  ): {} {
    const parametros = {};

    if (codigoSeguradora)
      parametros['id$like'] = `%${new FuncaoService().removerAcentos(codigoSeguradora)}%`;

    if (nomeSeguradora)
      parametros['nome$like'] = `%${new FuncaoService().removerAcentos(nomeSeguradora)}%`;

    if (nomeContatoSeguradora)
      parametros['nome_contato$like'] = `%${new FuncaoService().removerAcentos(nomeContatoSeguradora)}%`;

    if (telefoneSeguradora)
      parametros['telefone$like'] = `%${new FuncaoService().removerAcentos(telefoneSeguradora)}%`;

    if (celularSeguradora)
      parametros['celular$like'] = `%${new FuncaoService().removerAcentos(celularSeguradora)}%`;

    if (cepSeguradora)
      parametros['cep$like'] = `%${new FuncaoService().removerAcentos(cepSeguradora)}%`;

    if (enderecoSeguradora)
      parametros['endereco$like'] = `%${new FuncaoService().removerAcentos(enderecoSeguradora)}%`;

    if (numeroSeguradora)
      parametros['numero$like'] = `%${new FuncaoService().removerAcentos(numeroSeguradora)}%`;

    if (bairroSeguradora)
      parametros['bairro$like'] = `%${new FuncaoService().removerAcentos(bairroSeguradora)}%`;

    return parametros;
  }

  public proximoCodigo(): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proximo-codigo`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

}
