import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Credito, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditoService } from '../service/credito.service';

@Component({
  selector: 'app-credito-list',
  templateUrl: './credito-list.component.html'
})
export class CreditoListComponent extends BaseResourceListComponent<Credito, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public listaSituacoes: Array<any>;
  public filtroAtivo = true;
  public listaOcorrencias: Array<any>;
  public ocorrencia: { id: number, nome: string };

  public maskDecreto = {
    mask: [
      { mask: '0000/0000' },
      { mask: '00000/0000' }
    ]
  };
  public maskLei = {
    mask: [
      { mask: '0000/0000' },
      { mask: '00000/0000' }
    ]
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    public globalService: GlobalService,
    private creditoService: CreditoService) {
    super(creditoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(_entidade: Credito): boolean {
    return this.login.sistema != 'controle-interno'
  }

  protected relations(): string {
    return 'orgao,exercicio';
  }

  protected condicoesGrid(): {} {
    const parametros = {};

    parametros['exercicio.id'] = this.login.exercicio.id;
    parametros['ativo'] = this.filtroAtivo;
    parametros['ocorrencia$ne'] = '99';
    parametros['orgao_id'] = this.login.orgao.id;
    if (this.ocorrencia) {
      parametros['ocorrencia'] = this.ocorrencia.id;
    }

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_credito$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      date: ['data_credito'],
      text: ['decreto', 'lei', 'justificativa'],
    };
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
  }

  protected afterInit(): void {
    this.listaOcorrencias = [
      { id: '1', nome: 'ANULAÇÃO PARCIAL OU TOTAL DE DOTAÇÃO' },
      { id: '2', nome: 'EXCESSO DE ARRECADAÇÃO' },
      { id: '3', nome: 'SUPERÁVIT FINANCEIRO' },
      { id: '4', nome: 'OPERAÇÕES DE CRÉDITO' },
      { id: '5', nome: 'RESERVA DE CONTINGÊNCIA' },
      { id: '6', nome: 'PARAGRAFO 8˚ DO ARTIGO 166 DA CONSTITUIÇÃO FEDERAL' },
      { id: '7', nome: 'REMANEJAMENTO' },
      { id: '99', nome: 'TRANSFERÊNCIA INTERNA' }
    ];
    this.listaSituacoes = [
      { nome: 'CRÉDITOS ATIVOS', id: true },
      { nome: 'SOMENTE OS CRÉDIOS INATIVOS', id: false }
    ];
  }

  protected acaoRemover(model: Credito): Observable<Credito> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Decreto', coluna: 'decreto' }, // mask: this.maskDecreto.mask
      { titulo: 'Lei', coluna: 'lei' }, // mask: this.maskLei.mask
      { titulo: 'Crédito', coluna: 'data_credito' },
      { titulo: 'Ocorrência', coluna: 'ocorrencia' },
      { titulo: 'Justificativa', coluna: 'justificativa' },
      { titulo: 'Anulado', coluna: 'anulado', decimais: 2, alignment: 'right' },
      { titulo: 'Valor', coluna: 'valor', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao,exercicio,itens';
    this.creditoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          lista.content.forEach(c => {
            c.lei = c.lei.length === 8 ? `${this.funcaoService.mascarar('####/####', c.lei)}` : `${c.lei}`;
            c.decreto = c.decreto.length === 8 ? `0${this.funcaoService.mascarar('####/####', c.decreto)}` : `${this.funcaoService.mascarar('#####/####', c.decreto)}`;
            c.valor = 0;
            c.anulado = 0;
            if (c.itens?.length > 0) {
              for (const item of c.itens) {
                if (item.valor_credito > 0) {
                  c.valor += +item.valor_credito;
                } else {
                  c.anulado += +item.valor_credito;
                }
              }
            }
          });
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE CRÉDITOS ADICIONAIS', this.login.usuario.nome, this.login.usuario.sobrenome,
              this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem créditos', ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
              lista.content, ['anulado', 'valor']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        error => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public reload() {
    this.preencherGrid();
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Data', coluna: 'data_credito', alignment: 'center', tipo: 'Date', padrao: true });
    retorno.push({ titulo: 'Decreto', coluna: 'decreto', tipo: 'String', alignment: 'center', padrao: true, cols: 3, mask: this.maskDecreto.mask });
    retorno.push({ titulo: 'Lei', coluna: 'lei', tipo: 'String', padrao: true, cols: 3, mask:  this.maskLei.mask });
    retorno.push({ titulo: 'Tipo de crédito', coluna: 'tipo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'justificativa', coluna: 'justificativa', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'vl. suplementado', coluna: 'itens.valor_credito', alignment: 'center', tipo: 'Number', cols: 3 });
    retorno.push({ titulo: 'Ficha', coluna: 'itens.ficha.numero', alignment: 'center', tipo: 'Number', padrao: false, cols: 2 });

    return retorno;
  }
}
