import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { AdiantamentoService, ContratoService, ConvenioRecursoService, ConvenioService, DespesaService, EmpenhoAnexoService, EmpenhoService, LicitacaoService, LiquidacaoService, TipoConvenioService } from 'administrativo-lib';
import { CompraItemService, CompraService, ContratoAditamentoService, ContratoReajusteService, ModalidadeService, TipoContratacaoService } from 'compra-lib';
import {
  BaseResourceFormComponent, Compra, Contrato, Convenio, ContratoAditamento, ConvenioAditamento, DateFormatPipe, Despesa, EddyAutoComplete, Empenho, EmpenhoStorage, EmpenhoAnexo, Exercicio,
  Favorecido, FavorecidoService, FichaDespesa, FuncaoService, GlobalService, Licitacao, LoginContabil, Modalidade, OrgaoAssinaturaService, Precatorio, Page, ReservaDotacaoItem, Tabela1Reinf
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Calendar } from 'primeng/calendar';
import { InputNumber } from 'primeng/inputnumber';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import { PrecatorioService } from '../../precatorio/service/precatorio.service';
import { NotaEmpenho } from '../../relatorio/contabil/nota-empenho';
import { EmpenhoStorageService } from '../service/empenho-storage.service';
import { ReservaDotacaoItemService } from '../../reserva-dotacao/service/reserva-dotacao-item.service';

type SaldoFicha = {
  dotacao?: number,
  reservado?: number,
  saldo?: number,
  empenhado?: number,
  compra?: number,
  rcms?: number
};

declare var $: any;

@Component({
  selector: 'lib-empenho-form',
  templateUrl: './empenho-form.component.html'
})
export class EmpenhoFormComponent extends BaseResourceFormComponent<Empenho, LoginContabil> implements OnInit {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================
  @ViewChild('compra_') inputField: InputNumber;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public subElementoAutoComplete: EddyAutoComplete<Despesa>;
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public convenioAutoComplete: EddyAutoComplete<Convenio>;
  public precatorioAutoComplete: EddyAutoComplete<Precatorio>;
  public aditamentoAutoComplete: EddyAutoComplete<ContratoAditamento>;
  public listaModalidades: Array<Modalidade>;
  public listaTipos: Array<any>;
  public listaCompras: Array<any>;
  public listaLicitacoes: Array<any>;
  public listaContratos: Array<any>;
  public listaConvenios: Array<any>;
  public dotacao: number;
  public reservado: number;
  public anterior: number;
  public saldoFicha: SaldoFicha;
  public saldo: number;
  public saldoContrato: number = 0;
  public saldoConvenio: number = 0;
  public empenhado: number;
  public liquidado: number;
  public pago: number;
  private fichaOriginal: FichaDespesa;
  public subelementoVPD: Despesa;
  public naoExisteAditamento = false;
  private compraAtual: Compra;
  public numeroOF: number;
  public disponivel: number = 0;
  public listaAnexos = new Array<EmpenhoAnexo>();
  public uploadedFiles: any[] = [];
  public descricao: string = null;
  public validSalvarFechar = false;
  public txtAplicacaoVariavel: string;
  public objetoReinfTabela1: any = null;
  public saldoAtualFicha: number = 0
  public saldoDotacao: number = 0;
  public totalPreLiquidado: number = 0;
  public desabilitarValor: boolean = false;
  public empenhoAtualizado: Empenho;
  public empenhosInscritos: Empenho[] = [];
  public atualizar_precatorio: Precatorio;
  public reservaDotacaoItens: ReservaDotacaoItem[];

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected confirmationService: ConfirmationService,
    protected adiantamentoService: AdiantamentoService,
    protected anexoService: EmpenhoAnexoService,
    protected despesaService: DespesaService,
    protected fichaService: FichaDespesaService,
    protected compraService: CompraService,
    private reajusteService: ContratoReajusteService,
    protected licitacaoService: LicitacaoService,
    protected contratoService: ContratoService,
    private liquidacaoService: LiquidacaoService,
    protected convenioService: ConvenioService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected modalidadeService: ModalidadeService,
    protected precatorioService: PrecatorioService,
    private compraItemServico: CompraItemService,
    protected empenhoService: EmpenhoService,
    private tipoContratacaoService: TipoContratacaoService,
    private tipoConvenioService: TipoConvenioService,
    protected tabela1ReinfServico: Tabela1Reinf,
    protected empenhoStorageService: EmpenhoStorageService,
    protected convenioRecursoService: ConvenioRecursoService,
    private aditamentoService: ContratoAditamentoService,
    protected reservaDotacaoItemService: ReservaDotacaoItemService,
  ) {
    super(new Empenho(), injector, Empenho.converteJson, empenhoService);
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected async podeAlterar(_entidade: Empenho): Promise<boolean> {
    return !_entidade.inscrito_resto && _entidade.especie !== 'EOA'
      && this.login.sistema != 'controle-interno'
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      valor_empenho: null,
      data_empenho: [null, [Validators.required]],
      data_vencimento: [null],
      especie: ['EMO', [Validators.required]],
      tipo_empenho: ['O', [Validators.required]],
      fase4: [0, [Validators.required]],
      disponibilidade_anterior: [false, [Validators.required]],
      adiantamento: [false, [Validators.required]],
      processo: [null],
      documento: [null],
      modalidade: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      ficha: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      favorecido: [null, [Validators.required]],
      subelemento: [null, [Validators.required]],
      autorizado: [false, [Validators.required]],
      compra: [null],
      licitacao: [null],
      precatorio: [null],
      ata: [null],
      contrato: [null],
      convenio: [null],
      mes: [null],
      aux: [0],
      usuario_cadastro: [this.login.usuario, [Validators.required]],
      inscrito_resto: [false],
      disponivel: [false],
      contrato_aditamento: [null],
      parcelas: [null],
      sequencial_pncp: [null],
      atualizarPNCP: [false]
    });
  }

  protected campoFoco(): ElementRef | AutoComplete | Calendar {
    this.inputField.input.nativeElement.focus();
    return null;
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'ficha,modalidade,licitacao,subelemento,subelemento.tipo_contratacao,subelemento.vpd,compra,compra.rcms.reserva_dotacao.reserva,convenio,favorecido,favorecido.tipo,favorecido.contas.banco,favorecido.contas.orgao,contrato,'
        + 'ficha.despesa,ficha.acao,exercicio,orgao,precatorio,contrato.tipo_contratacao,contrato.aditamentos,convenio.tipo_convenio,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,ficha.executora,contrato_aditamento'
    };
  }

  protected async afterLoad() {
    this.saldoFicha = {
      rcms: 0
    };

    if (!this.podeAlterarAudesp(this.entidade.data_empenho)) {
      this.router.navigate(['/empenhos-orcamentario']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_empenho = new DateFormatPipe().transform(this.entidade.data_empenho, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);

    if (this.entidade?.subelemento?.codigo_reinf) {
      this.objetoReinfTabela1 = this.tabela1ReinfServico.carregarPorCodigo(this.entidade.subelemento.codigo_reinf, true);
    }

    await this.loadTotalizadores();

    this.favorecidoAutoComplete.id = this.entidade.favorecido ? this.entidade.favorecido.id : null;
    this.fichaAutoComplete.id = this.entidade.ficha ? this.entidade.ficha.numero : null;
    this.precatorioAutoComplete.id = this.entidade.precatorio ? this.entidade.precatorio.id : null;
    this.subElementoAutoComplete.id = this.entidade.subelemento ? this.entidade.subelemento.codigo : null;
    this.licitacaoAutoComplete.id = this.entidade.licitacao ? this.entidade.licitacao.id : null;
    this.contratoAutoComplete.id = this.entidade.contrato ? this.entidade.contrato.id : null;
    this.convenioAutoComplete.id = this.entidade.convenio ? this.entidade.convenio.id : null;
    this.compraAtual = this.entidade.compra;
    this.numeroOF = this.compraAtual ? this.compraAtual.numero : null;

    if (this.entidade.especie === 'EOA') {
      this.entidadeForm.get('valor_empenho').setValue(this.entidade.valor_empenho * -1);
    }

    this.anexoService.filtrar(1, -1, { 'empenho.id': this.entidade.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaAnexos = res.content;
      });
    this.empenhoStorageService.filtrar(1, -1, { 'empenho.id': this.entidade.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaAnexos = this.listaAnexos.concat(res.content);
        this.listaAnexos.forEach(item => {
          if (item.url.slice(-2, -1) === '/') {
            item.url = `${item.url.slice(0, -1)}${item.arquivo}`;
          }
        });
      });

    this.fichaOriginal = this.entidade.ficha;
    if (this.entidade.contrato) {
      this.obterSaldoContrato(this.entidade.contrato);
    }
    this.txtAplicacaoVariavel = this.entidade.ficha.aplicacao_variavel ? `${this.entidade.ficha.aplicacao_variavel?.variavel} ${this.entidade.ficha.aplicacao_variavel?.nome}` : '';
    if (this.entidade.convenio) {
      this.saldoConvenio = +(await this.convenioService.buscarSaldoConvenio(this.entidadeForm.get('convenio').value.id, this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value), this.login.orgao.id).toPromise())
      // this.obterSaldoConvenio();
    }
    const subelemento = this.entidadeForm.get('subelemento').value ? this.entidadeForm.get('subelemento').value : this.entidade.subelemento;
    this.verificaVPD(subelemento);

    this.carregarAditamentoAutoComplete();

    const adiantamentos = await this.findAditamentos();

    if (adiantamentos.content.length < 2) {
      this.naoExisteAditamento = true;
    }

    if (this.entidade.id) {
      await this.verificarDesabilitarValor(this.entidade)
    }
    this.entidadeForm.get('precatorio').enable();

    this.empenhosInscritos = (await this.empenhoService.filtrar(1, -1, {
      exercicio_id: this.login.exercicio.id,
      orgao_id: this.login.orgao.id,
      inscrito_resto: true
    }).toPromise()).content;
    if (this.empenhosInscritos.length > 0 && !this.soVisualizar) {
      toastr.warning('Já foram inscrito os resto a pagar do exercício, não será possível alterar o registro!')
      this.router.navigate([`/empenhos-orcamentario/${this.entidade.id}/visualizar`]);
    }

    this.setAditamento()
  }

  protected afterInit(): void {
    this.saldoFicha = {
      dotacao: 0,
      reservado: 0,
      saldo: 0,
      empenhado: 0,
      compra: 0,
      rcms: 0
    };
    this.modalidadeService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, orderBy: 'codigo', ativo: true }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaModalidades = res ? res.content : new Array<Modalidade>();
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    this.listaTipos = this.globalService.obterListaTiposEmpenhos();
    this.carregarAutoCompletes();
    if (this.currentActionRoute === 'novo') {
      this.empenhoService.proximoNumeroEmpenho(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get('numero').setValue(res);
        });
      this.compraService
        .obterDatasPosteriores(this.login.orgao.id, this.login.exercicio.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidade.data_empenho = new DateFormatPipe().transform(res.ultima, []);
          this.entidadeForm.get('data_empenho').setValue(this.entidade.data_empenho);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_empenho)?.split('-')?.[1]);
        });
    }
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('numero').value) {
        throw new Error('Informe o número do empenho');
      }
      if (!this.entidadeForm.get('exercicio').value) {
        throw new Error('Informe o exercício do empenho');
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error('Informe o órgão do empenho');
      }
      if (!this.entidadeForm.get('subelemento').value) {
        throw new Error('Informe a despesa do empenho');
      }
      if (!this.entidadeForm.get('favorecido').value) {
        throw new Error('Informe o favorecido do empenho');
      }
      if (!this.entidadeForm.get('ficha').value) {
        throw new Error('Informe a ficha do empenho');
      }
      if (!this.entidadeForm.get('historico').value) {
        throw new Error('Informe o histórico do empenho');
      }
      if (!this.entidadeForm.get('valor_empenho').value) {
        throw new Error('Informe o valor do empenho');
      }
      if (this.entidadeForm.get('contrato').value && this.saldoContrato <= 0) {
        throw new Error('Não há saldo no contrato para empenhar, veja se existe aditivos!');
      }
      if (!this.entidadeForm.get('data_vencimento').value) {
        throw new Error('Informe a data de pagamento');
      }

      if (this.entidadeForm.get('contrato').value) {
        const c: Contrato = this.entidadeForm.get('contrato').value;
        const s: Despesa = this.entidadeForm.get('subelemento').value;

        if (!c.tipo_contratacao) {
          throw new Error(`O contrato informado não possui tipo de contratação, por gentileza, verificar o cadastro do contrato numero ${c.numero}`);
        }
        if (c.tipo_contratacao.material && (!s.material)) {
          throw new Error('O tipo de contrato informado está marcado como Materiais, porém o subelemento da despesa informado não está habilitado para materiais!');
        }
        if (c.tipo_contratacao.servico && (!s.servico)) {
          throw new Error('O tipo de contrato informado está marcado como Serviços, porém o subelemento da despesa informado não está habilitado para serviços!');
        }
      }

      // if (this.entidadeForm.get('convenio').value && this.saldoConvenio <= 0) {
      //   throw new Error('Não há saldo no convênio para empenhar!');
      // }
      if (this.entidadeForm.get('valor_empenho').value <= 0) {
        this.entidadeForm.get('valor_empenho').setValue(this.entidadeForm.get('valor_empenho').value * -1);
      }
      // if (String(this.entidadeForm.get('historico').value).length > 1000) {
      //   throw new Error('O histórico não pode possuir mais de 1000 caracteres');
      // }

      if (this.entidadeForm.get('licitacao').value === '') {
        this.entidadeForm.get('licitacao').setValue(null);
      }
      if (this.entidadeForm.get('convenio').value === '') {
        this.entidadeForm.get('convenio').setValue(null);
      }

      const dtEmpenho: Date = this.entidadeForm.get('data_empenho').value;
      if (dtEmpenho > new Date()) {
        throw new Error('Data do empenho está maior que a data atual');
      }
      if (dtEmpenho.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('O ano da data do empenho está diferente do exercício logado');
      }
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value)?.split('-')?.[1]);

      if (!this.entidadeForm.get('modalidade').value) {
        throw new Error('Informe a modalidade!');
      }

      const modalidade: Modalidade = this.entidadeForm.get('modalidade').value;
      if (modalidade.exigir_contrato && !this.entidadeForm.get('contrato').value) {
        throw new Error(`A modalidade '${modalidade.nome}' exige um contrato`);
      }
      if (modalidade.exigir_convenio && this.entidadeForm.get('convenio').value === '') {
        throw new Error(`A modalidade '${modalidade.nome}' exige um convênio`);
      }
      if (modalidade.exigir_licitacao && this.entidadeForm.get('licitacao').value === '') {
        throw new Error(`A modalidade '${modalidade.nome}' exige uma licitação`);
      }
      if (this.entidadeForm.get('adiantamento').value && !this.subelementoVPD.vpd) {
        throw new Error('VPD é campo obrigatório para realizar adiantamento.\nAjuste o subelemento em Planejamento > Cadastros > Despesas orçamentárias');
      }
      if (this.entidadeForm.get('id').value) {
        this.entidadeForm.get('atualizarPNCP').setValue(true);
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(ent: Empenho) {
    this.mensagemSucesso = `Registro salvo com sucesso!\nEmpenho nº ${ent.numero}`;
    if (ent.adiantamento) {
      this.adiantamentoService.obter({ empenho_id: ent.id, exercicio_id: ent.exercicio.id, orgao_id: ent.orgao.id }).subscribe((res) => {
        if (!res) {
          this.confirmationService.confirm({
            message: `Deseja cadastrar o processo de adiantamento ?`,
            header: `Processo de Adiantamento`,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            key: 'adiantamentos',
            accept: async () => {
              this.inserirAdiantamento(ent, this.limparTela);
            },
            reject: () => {
              this.redirecionar(ent);
            }
          });
        } else {
          if (this.limparTela) {
            this.router.navigate([this.baseResourceService.retornarRota().replace(/[0-9].*\/editar/g, 'novo')]);
          }
        }
      });
    } else {
      this.redirecionar(ent);
    }
  }

  private redirecionar(ent: Empenho) {
    // if (!this.skipRedirect) {
    //   this.salvarLimparTela(entidade);
    // }
    if (this.validSalvarFechar) {
      this.validSalvarFechar = false;
      this.router.navigate(['/empenhos-orcamentario']);
    } else {
      if (this.currentActionRoute === 'novo' && this.login.redirecionar_liquidar_pagar
        && this.podeIncluir('/liquidacoes-orcamentaria')) {
        this.router.navigate(['/liquidacoes-orcamentaria', 'novo', ent.id]);
      } else if (this.currentActionRoute === 'novo' && !this.limparTela) {
        this.router.navigate(['/empenhos-orcamentario', ent.id, 'editar']);
      }
    }
    this.salvarLimparTela(ent);
  }

  protected acaoSucesso(entidade: Empenho) {
    this.afterSubmit(entidade);
    if (this.mensagemSucesso) toastr.success(this.mensagemSucesso);
    // if (!this.skipRedirect) {
    //   this.salvarLimparTela(entidade);
    // }
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  public salvarLimparTela(entidade: Empenho) {
    let url: string = this.baseResourceService.retornarRota();
    if (this.limparTela) { // salvar e novo
      if (url.includes('novo')) {
        const rota = this.baseResourceService.retornarRota();
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([rota]));
      } else {
        this.router.navigate([this.baseResourceService.retornarRota().replace(/[0-9].*\/editar/g, 'novo')]);
      }
    } else { // salvar
      if (url.includes('novo')) {
        let params = '';
        if (url.lastIndexOf('/novo') > 0) {
          params = url.substring(url.indexOf('/novo') + +'/novo'.length);
          url = url.substring(0, url.substring(1, url.length).indexOf('/novo') + 1);
        }
        url = url + '/' + entidade.id + '/editar' + params;
        this.router.navigate([url]);
      }
    }
    this.limparTela = false;
  }

  private inserirAdiantamento(model: Empenho, limpar?: boolean) {
    this.entidade = model;
    this.limparTela = limpar;
    $('#dlgAdiantamentoCadastro').modal('show');
  }

  private async loadTotalizadores() {
    this.empenhado = 0;
    this.liquidado = 0;
    this.pago = 0;
    this.dotacao = 0;
    this.anterior = 0;
    this.reservado = 0;

    const exercicio: Exercicio = this.entidadeForm.get('exercicio').value
      ? this.entidadeForm.get('exercicio').value
      : this.login.exercicio;

    let totalizadores: any;
    if (this.entidade.id) {
      this.buscarSaldoFicha();
      totalizadores = await this.empenhoService.totalizadores(exercicio.id, this.login.orgao.id, this.entidade.numero).toPromise();
    } else {
      const ficha: FichaDespesa = this.entidadeForm.get('ficha').value
        ? this.entidadeForm.get('ficha').value
        : this.entidade.ficha;
      const dataEmpenho: Date = this.entidadeForm.get('data_empenho').value
        ? this.entidadeForm.get('data_empenho').value
        : this.entidade.data_empenho;
      if (ficha && dataEmpenho) {
        this.buscarSaldoFicha();
        totalizadores = await this.empenhoService.totalizadoresFicha(
          exercicio.id, this.login.orgao.id, ficha.id,
          String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataEmpenho, ['local'])))).toPromise();
        totalizadores = { content: totalizadores };
      }
    }

    if (totalizadores && totalizadores.content) {
      totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
      this.empenhado = totalizadores.total_empenhado;
      this.liquidado = totalizadores.total_liquidado;
      this.pago = totalizadores.total_pago;
      this.reservado = +totalizadores.total_reservado;
      this.dotacao = (+totalizadores.total_dotacao_ficha + +totalizadores.total_creditado_ficha) - +totalizadores.total_reservado;
      this.saldoDotacao = (+totalizadores.total_dotacao_ficha + +totalizadores.total_creditado_ficha);
      const valorAnterior = totalizadores.total_empenhado_ficha_anterior ?
        +totalizadores.total_empenhado_ficha_anterior : +totalizadores.total_empenhado_ficha;

      this.anterior = +this.dotacao - +valorAnterior;

      const empenhoSalvo = (this.entidade.id ? +this.entidade.valor_empenho : 0);

      const saldo = this.anterior + empenhoSalvo;

      this.saldoAtualFicha = +saldo.toFixed(2);
      this.totalPreLiquidado = +totalizadores?.total_preliquidado;
    }
  }

  private buscarSaldoFicha(init?: boolean) {
    const exercicio: Exercicio = this.entidade.exercicio ? this.entidade.exercicio : this.entidadeForm.get('exercicio').value;
    const ficha: FichaDespesa = this.entidade.ficha ? this.entidade.ficha : this.entidadeForm.get('ficha').value;
    const dataEmpenho: Date = this.entidadeForm.get('data_empenho').value;

    if (!ficha || !dataEmpenho || !exercicio)
      return;

    this.fichaService.saldoAtualFicha(ficha.id, this.login.orgao.id, this.login.exercicio.id,
      String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataEmpenho, ['local']))),
      (this.entidade.id ? { compraId: this.entidade.id } : null), true).subscribe((data) => {
        if (data)
          this.carregarSaldoFicha(data, init);
      });
    this.buscarConvenio()
  }

  private carregarSaldoFicha(totalizadores: any, init?: boolean) {
    if (!totalizadores)
      return;

    if (totalizadores.saldo_atual <= 0 && !init && !this.entidade.id) {
      toastr.warning(`Ficha de '${this.entidadeForm.get('ficha').value.despesa.nome}' não possui saldo para estar realizando o empenho`);
    }

    this.saldoFicha = {};
    this.saldoFicha.empenhado = +totalizadores.total_empenhado;
    this.saldoFicha.rcms = +totalizadores.total_rcms_pendente;
    this.saldoFicha.compra = +totalizadores.total_compra_pendente;
    this.saldoFicha.dotacao = (+totalizadores.total_dotacao + +totalizadores.total_creditado) - +totalizadores.total_reservado;
    this.saldoFicha.reservado = +totalizadores.total_reservado;
    this.saldoFicha.saldo = +totalizadores.saldo_atual;
  }

  private carregarAutoCompletes() {
    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, inativo: false, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] },
      () => {
        if (+this.entidadeForm.get('favorecido')?.value?.tipo?.tce !== 1 && +this.entidadeForm.get('favorecido')?.value?.tipo?.tce !== 2 && +this.entidadeForm.get('favorecido')?.value?.tipo?.tce !== 10 && +this.entidadeForm.get('favorecido')?.value?.id) {
          toastr.warning('Verifique a espécie no cadastro de fornecedor');
          this.entidadeForm.get('favorecido').setValue(null); this.favorecidoAutoComplete.id = null;
          return;
        }
        if (!this.entidadeForm.get('favorecido')?.value?.autorizado) {
          this.confirmationService.confirm({
            message: `Deseja autorizar o beneficiário?`,
            header: `Autorização`,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            key: 'adiantamento',
            accept: async () => {
              var url = window.location.href
              window.open(url.replace('empenhos/novo', `favorecidos/${this.entidadeForm.get('favorecido').value.id}`) + '/editar')
            },
            reject: async () => {
              this.entidadeForm.get('favorecido').patchValue(null);
            }
          });
        }
        this.preencherDadosUltimoEmpenho();
        this.preencherAutoCompleteFicha();
      }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('ficha'), this.fichaService,
      'numero', ['ficha.despesa.codigo', 'ficha.despesa.nome'],
      {
        exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id,
        relations: 'despesa,recurso,aplicacao,aplicacao_variavel', orderBy: 'despesa.nome'
      },
      { number: ['numero'], text: ['despesa.codigo', 'despesa.nome'] },
      async () => {
        const ficha: FichaDespesa = this.entidadeForm.get('ficha').value;
        if (ficha && (!this.fichaOriginal || this.fichaOriginal.id !== ficha.id)) {
          this.fichaOriginal = undefined;
          this.subElementoFiltrar(ficha.despesa.codigo.substring(0, 6));
          this.txtAplicacaoVariavel = ficha.aplicacao_variavel ? `${ficha.aplicacao_variavel?.variavel} ${ficha.aplicacao_variavel?.nome}` : '';
        }

        // verifica se é um adiantamento
        // if (this.login.parametro['contabil'].utilizar_339030_como_adiantamento) {
        //   for (const despesa of this.login.parametro['contabil'].subelementos_como_adiantamento.split(',')) {
        //     if (ficha && ficha.despesa.codigo.startsWith(despesa)) {
        //       if (ficha.despesa?.vpd?.id) {
        //         this.entidadeForm.get('adiantamento').setValue(true);
        //       } else {
        //         toastr.warning(`Ficha ${ficha.numero} possui a despesa ${ficha.despesa.codigo} sem VPD adiantamento.`);
        //       }
        //       break;
        //     }
        //     this.entidadeForm.get('adiantamento').setValue(false);
        //   }
        // }

        this.alterarSubElemento();
        this.loadTotalizadores();
        this.preencherDadosUltimoEmpenho();
        if (this.entidadeForm.get('contrato').value) {
          this.obterSaldoContrato(this.entidadeForm.get('contrato').value);
        }
      }
    );
    this.buscarConvenio()
    // autocomplete para subelemento
    this.subElementoFiltrar();

    // autocomplete para precatorio
    this.precatorioAutoComplete = new EddyAutoComplete(this.entidadeForm.get('precatorio'), this.precatorioService, 'id', null,
      { 'orgao.id': this.login.orgao.id, relations: 'orgao,favorecido', orderBy: 'numero_acao' },
      { text: ['numero_acao', 'numero_precatorio', 'favorecido.cpf_cnpj', 'favorecido.nome', 'favorecido.id'] }, null, null,
      (entidade: Precatorio) => {
        return 'Nº Ação: ' + entidade.numero_acao + ' - Nº Precatório: ' + entidade.numero_precatorio;
      }
    );

    // autocomplete para licitacao
    this.licitacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('licitacao'), this.licitacaoService,
      'numero', ['numero'], { orgao_id: this.login.orgao.id, orderBy: 'numero' }, { number: ['numero'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('contrato'), this.contratoService,
      'numero', ['numero'], {
      orgao_id: this.login.orgao.id, orderBy: 'numero',
      relations: 'licitacao,tipo_contratacao,favorecido,favorecido.tipo,modalidade,aditamentos'
    }, { text: ['numero'] },
      () => {
        const favorecido_id = document.querySelector('#favorecido_id');
        let contrato: Contrato = this.entidadeForm.get('contrato').value
          ? this.entidadeForm.get('contrato').value
          : this.entidade.contrato;

        if (contrato?.excluido) {
          toastr.warning(`O contrato indicado está removido`);
          this.favorecidoAutoComplete.id = null;
          this.licitacaoAutoComplete.id = null;
          this.contratoAutoComplete.id = null;
          this.entidadeForm
            .get("contrato")
            .patchValue({ id: null, numero: null });
          this.entidadeForm
            .get("favorecido")
            .patchValue(null);
          this.entidadeForm
            .get("licitacao")
            .setValue(null);
          this.entidadeForm
            .get("processo")
            .patchValue(null);
          this.entidadeForm
            .get("modalidade")
            .patchValue(null);
          contrato = undefined
          return;
        }
        if (contrato) {
          this.entidadeForm.get('licitacao').setValue(null);
          this.entidadeForm.get('modalidade').setValue(null);
          this.entidadeForm.get('contrato').setValue(contrato);
          this.entidadeForm.get('modalidade').setValue(contrato.modalidade);
          this.entidadeForm.get('modalidade').disable();
          if (contrato.favorecido) {
            if (+contrato.favorecido.tipo.tce !== 1 && +contrato.favorecido.tipo.tce !== 2 && +contrato.favorecido.tipo.tce !== 10) {
              toastr.warning('Verifique a espécie no cadastro de fornecedor');
              this.entidadeForm
                .get("contrato")
                .patchValue({ id: null, numero: null });
              this.entidadeForm
                .get("licitacao")
                .patchValue({ id: null, numero: null });
              this.entidadeForm
                .get("processo")
                .patchValue(null);
              return;
            }
            this.entidadeForm.get('favorecido').setValue(contrato.favorecido);
            this.entidadeForm.get('favorecido').disable();
            favorecido_id['disabled'] = true;
            this.favorecidoAutoComplete.id = contrato.favorecido ? contrato.favorecido.id : null;
          }
          this.entidadeForm.get('processo').setValue(contrato.processo);
          this.entidadeForm.get('processo').disable();
          if (contrato.licitacao) {
            this.licitacaoService.obter({ id: contrato.licitacao.id, relations: 'modalidade' }).pipe(takeUntil(this.unsubscribe))
              .subscribe((res) => {
                this.entidadeForm.get('licitacao').setValue(res);
                this.entidadeForm.get('licitacao').disable();
              }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
          }

          this.obterSaldoContrato(contrato);
        } else {
          this.entidadeForm.get('modalidade').enable();
          this.entidadeForm.get('licitacao').enable();
          this.entidadeForm.get('favorecido').enable();
          this.entidadeForm.get('processo').enable();
          favorecido_id['disabled'] = false;
        }
      }
    );

    // autocomplete para convenio
    this.convenioAutoComplete = new EddyAutoComplete(this.entidadeForm.get('convenio'), this.convenioService,
      'numero', ['numero', 'ano'], { orgao_id: this.login.orgao.id, ativo: true, relations: 'tipo_convenio', orderBy: 'numero' }, { number: ['id'], text: ['numero_ano'] }
    );

    // Automcomplete de aditamento
    this.carregarAditamentoAutoComplete();
  }

  public async buscarConvenio() {
    const ficha: FichaDespesa = this.entidadeForm.get('ficha').value || null;
    if (ficha?.id) {
      const parametros = {
        'OR_1': `recurso_id=${ficha?.recurso?.id};!;!;recurso_contra_id=${ficha?.recurso?.id}`,
        'OR_2': `aplicacao_id=${ficha?.aplicacao?.id};!;!;aplicacao_contra_id=${ficha?.aplicacao?.id}`,
        relations: 'convenio.favorecido.tipo,convenio.aditamento,convenio.tipo_convenio'
      }

      if (ficha?.aplicacao_variavel?.id) {
        parametros['OR_3'] = `aplicacao_variavel_id=${ficha?.aplicacao_variavel?.id};!;!;aplicacao_variavel_contra_id=${ficha?.aplicacao_variavel?.id}`;
      }

      const convenioRecurso = (await this.convenioRecursoService.filtrar(1, -1, parametros).toPromise()).content[0];
      this.entidadeForm.get('convenio').setValue(convenioRecurso?.convenio || null);

      if (this.entidadeForm.get('convenio').value) {
        this.saldoConvenio = +(await this.convenioService.buscarSaldoConvenio(this.entidadeForm.get('convenio').value.id, this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value), this.login.orgao.id).toPromise())
        // this.obterSaldoConvenio();
      }

      if (convenioRecurso?.convenio?.tipo_fornecedor === 'F') {
        this.entidadeForm.get('favorecido').setValue(convenioRecurso?.convenio?.favorecido);
        this.favorecidoAutoComplete.id = convenioRecurso?.convenio?.favorecido?.id;
      }
    }
  }

  // Autocomplete do aditamento
  private carregarAditamentoAutoComplete(): void {
    let contrato_id = null;
    if (this.entidadeForm.get('contrato').value) {
      contrato_id = +this.entidadeForm.get('contrato').value.id
    } else {
      contrato_id = 0;
    }

    this.aditamentoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('contrato_aditamento'), this.aditamentoService,
      'id', ['numero'],
      {
        contrato_id,
        data_inicio$le: this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value),
        data_termino$ge: this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value),
      },
      {
        text: ['numero']
      });
  }

  private async findAditamentos(): Promise<Page> {
    let contrato_id = null;
    if (this.entidadeForm.get('contrato').value) {
      contrato_id = +this.entidadeForm.get('contrato').value.id
    } else {
      contrato_id = 0;
    }

    const data = this.entidadeForm.get('data_empenho').value;

    if (!data) {
      return { content: [] } as Page;
    }

    return await this.aditamentoService.filtrar(1, 0, {
      contrato_id,
      data_inicio$le: this.funcaoService.converteDataSQL(data),
      data_termino$ge: this.funcaoService.converteDataSQL(data),
    }).toPromise();
  }

  public async validarDataEmpenho(): Promise<void> {
    const dataEmpenho = this.funcaoService.converteDataBR(this.entidadeForm.get('data_empenho').value);
    try {
      if (this.entidade?.compra) {
        if ((new Date(this.entidade.compra.data_compra) > new Date(this.entidadeForm.get('data_empenho').value)) && dataEmpenho.length === 10) {
          toastr.error(`A data informada para o empenho, não pode ser anterior a data ${this.funcaoService.converteDataBR(this.entidade.compra.data_compra)} presente na OF!`)
          this.entidadeForm.get('data_empenho').setValue(this.entidade.data_empenho)
        }
      } else if (this.entidadeForm.get('compra').value) {
        if ((new Date(this.entidadeForm.get('compra').value.data_compra) > new Date(this.entidadeForm.get('data_empenho').value)) && dataEmpenho.length === 10) {
          toastr.error(`A data informada para o empenho, não pode ser anterior a data ${this.funcaoService.converteDataBR(this.entidadeForm.get('compra').value.data_compra)} presente na OF!`)
          this.entidadeForm.get('data_empenho').setValue(this.entidade.data_empenho)
        }
      }
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
  }

  public async setAditamento(): Promise<void> {
    try {
      // this.entidadeForm.get('contrato_aditamento').setValue(null);
      this.aditamentoAutoComplete.id = null;
      const aditamentos = await this.findAditamentos();

      if (!aditamentos.content.length) {
        this.naoExisteAditamento = true;
      } else if (aditamentos.content.length === 1) {
        this.naoExisteAditamento = true;
        this.carregarAditamentoAutoComplete();
        this.entidadeForm.get('contrato_aditamento').setValue(aditamentos.content[0]);
      } else {
        this.naoExisteAditamento = false;
        this.carregarAditamentoAutoComplete();
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
        toastr.error(err.message, 'Erro ao buscar aditamentos');
      }
    }
  }

  private subElementoFiltrar(codigo?: string) {
    const parametros = { exercicio_id: this.login.exercicio.id, nivel: 6, orderBy: 'codigo', ['codigo$like']: codigo + '%', 'relations': 'vpd', ativo: true }

    this.subElementoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('subelemento'), this.despesaService, 'codigo', ['nome'],
      parametros,
      { number: ['id', 'codigo'], text: ['nome'] },
      async () => {
        let subelemento = this.entidadeForm.get('subelemento').value;
        this.subelementoVPD = subelemento
        if (!this.subElementoAutoComplete.id)
          this.entidadeForm.get('subelemento').setValue(null);
        if (subelemento && !(await this.validarTipoContratacao())) {
          toastr.warning(`A ficha informada não é compatível com a contratação informada no contrato. Verifique na divisão de compras se a contratação informada é válida.`);
          this.entidadeForm.get('subelemento').setValue(null);
        }
        if (subelemento && !(await this.validarTipoConvenio())) {
          toastr.warning(`A ficha informada não é compatível com o tipo de convênio informado. Verifique na divisão de compras se o convênio informado é válido.`);
          this.entidadeForm.get('subelemento').setValue(null);
        }
        this.preencherHistoricoSubelemento();
        if (subelemento?.codigo_reinf) {
          this.objetoReinfTabela1 = this.tabela1ReinfServico.carregarPorCodigo(subelemento.codigo_reinf, true);
        }
        this.verificarAdiantamentoVPD(this.subelementoVPD);
      }, 1000
    );
  }

  public async verificaVPD(subelemento: Despesa) {
    this.despesaService.obter({ id: subelemento.id, 'relations': 'vpd' }).subscribe(res => {
      this.subelementoVPD = res;
      // this.verificarAdiantamentoVPD(this.subelementoVPD);
    });
  }

  private verificarAdiantamentoVPD(subelemento: Despesa) {
    if (this.login.parametro['contabil'].utilizar_339030_como_adiantamento) {
      for (const despesa of this.login.parametro['contabil'].subelementos_como_adiantamento.split(',')) {
        if (subelemento?.codigo?.startsWith(despesa)) {
          if (subelemento?.vpd?.id) {
            this.entidadeForm.get('adiantamento').setValue(true);
            this.entidadeForm.get('adiantamento').disable();
          } else {
            this.entidadeForm.get('adiantamento').setValue(false);
            toastr.warning(`Despesa ${subelemento.codigo} sem VPD adiantamento.`);
          }
          break;
        }
        this.entidadeForm.get('adiantamento').setValue(false);
      }
    } else {
      this.entidadeForm.get('adiantamento').enable();
    }
  }

  private async validarTipoContratacao(): Promise<boolean> {
    let subelemento = this.entidadeForm.get('subelemento').value;
    let contrato = this.entidadeForm.get('contrato').value;
    if (!subelemento || !contrato?.tipo_contratacao?.id) //Se o contrato for Outros não valida
      return true;

    const validar = await this.tipoContratacaoService.validarDespasa(contrato.tipo_contratacao.id, subelemento.id).toPromise();
    return validar.valido;
  }

  private async validarTipoConvenio(): Promise<boolean> {
    let subelemento = this.entidadeForm.get('subelemento').value;
    let convenio = this.entidadeForm.get('convenio').value;
    if (!subelemento || !convenio?.tipo_convenio?.id) {
      return true;
    }

    const validar = await this.tipoConvenioService.validarDespesa(convenio.tipo_convenio.id, subelemento.id).toPromise();
    return validar.valido;
  }

  private alterarSubElemento() {
    const ficha: FichaDespesa = this.entidadeForm.get('ficha').value;
    if (ficha) {
      const fichaSubElemento: string = ficha.despesa.codigo.substring(0, 6);
      const subElemento: Despesa = this.entidadeForm.get('subelemento').value;
      if (!subElemento || fichaSubElemento !== subElemento.codigo.substring(0, 6)) {
        this.subElementoAutoComplete.id = fichaSubElemento;
        this.entidadeForm.get('subelemento').setValue(null);
      } else {
        this.subElementoAutoComplete.id = subElemento.codigo;
      }
    }
  }

  private preencherDadosUltimoEmpenho() {
    if (this.login.repetir_dados_empenho && !this.entidade.id) {
      const favorecido: Favorecido = this.entidadeForm.get('favorecido').value;
      const ficha: FichaDespesa = this.entidadeForm.get('ficha').value;

      if (favorecido && ficha) {
        this.empenhoService
          .obter({
            orgao_id: this.login.orgao.id,
            exercicio_id: this.login.exercicio.id,
            favorecido_id: favorecido.id,
            ficha_id: ficha.id,
            orderBy: 'id$DESC',
            relations: 'subelemento,subelemento.vpd,licitacao,convenio,contrato,contrato.aditamentos,modalidade,contrato.tipo_contratacao,convenio.tipo_convenio'
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(ent => {
            if (ent) {
              if (ent.subelemento) {
                this.subElementoAutoComplete.id = ent.subelemento.codigo;
                this.entidadeForm.get('subelemento').setValue(ent.subelemento);
                this.subelementoVPD = ent.subelemento;
                this.entidadeForm.get('historico').setValue(ent.historico)
              }
              // if (ent.licitacao) {
              //   this.entidadeForm.get('licitacao').setValue(ent.licitacao);
              // }
              // if (ent.modalidade) {
              //   this.entidadeForm.get('modalidade').setValue(ent.modalidade);
              // }
              // if (ent.processo) {
              //   this.entidadeForm.get('processo').setValue(ent.processo);
              // }
              // if (ent.convenio) {
              //   this.entidadeForm.get('convenio').setValue(ent.convenio);
              // }
              // if (ent.contrato) {
              //   this.entidadeForm.get('contrato').setValue(ent.contrato);
              //   this.obterSaldoContrato(ent.contrato);
              // }
              // if (ent.historico) {
              //   this.entidadeForm.get('historico').setValue(ent.historico);
              // }
              if (ent.adiantamento) {
                this.entidadeForm.get('adiantamento').setValue(ent.adiantamento);
              }
            }
          });
      }
    }
  }

  private preencherHistoricoSubelemento() {
    if (!this.entidade.id && !this.login.repetir_dados_empenho && !this.entidadeForm.get('historico').value) {
      const subelemento: Despesa = this.entidadeForm.get('subelemento').value;
      if (subelemento) {
        this.entidadeForm.get('historico').setValue(subelemento.nome);
      }
    }
  }

  imprimir() {
    const parametros = {};

    let relations = 'ficha.executora.unidade,ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.programa,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.despesa,ficha.aplicacao_variavel,contrato_aditamento,';
    relations += 'licitacao,modalidade,subelemento,favorecido.contas.banco,favorecido.contas.orgao,favorecido.tipo,contrato,convenio,liquidacoes,compra,compra.rcms,compra.rcms.requerente,compra.rcms.prazo';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;
    parametros['orderBy'] = 'liquidacoes.parcela$ASC';
    this.empenhoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaEmpenho(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      this.empenhoStorageService
        .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id, this.descricao).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          for (const item of res) {
            item.data_cadastro = new Date();
            if (item.url.slice(-2, -1) === '/') {
              item.url = `${item.url.slice(0, -1)}${item.arquivo}`;
            }
            this.listaAnexos.unshift(item);
          }
        }, (res) => {
          toastr.error(res.error.payload);
          this.uploadedFiles.length = 0;
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.descricao = undefined;
    this.uploadedFiles = [];
    fileUpload.clear();
    $('#dialogUpload').modal('hide');
  }

  public download(arquivo: any) {
    this.anexoService
      .download(arquivo.documento).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const blob = new Blob([res], { type: arquivo.tipo });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = arquivo.nome;
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      });
  }

  public baixarXML(xml: any) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(xml.url)));
    element.setAttribute('download', `${xml.nome}`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public obterSaldoConvenio() {
    if (this.entidadeForm.get('convenio').value !== null) {
      this.empenhoService.totalEmpenhadoPorConvenio(this.entidadeForm.get('convenio').value.id, this.login.orgao.id)
        .subscribe((saldo) => {
          const convenio = this.entidadeForm.get('convenio').value
          let valorAditamentos = 0;
          if (convenio?.aditamento?.length > 0) {
            valorAditamentos = convenio.aditamento.reduce((acumulador: number, item: ConvenioAditamento) => acumulador += item.finalidade === '1' ? (+item.valor_transferencia * -1) : +item.valor_transferencia, 0);
          }
          this.saldoConvenio = (+convenio.valor_convenio + +valorAditamentos) - +saldo;
        });
    }
  }

  public async obterSaldoContrato(contrato: Contrato) {
    const reajustes = (await this.reajusteService.extendido(1, -1, {
      'contrato_item.contrato.id': contrato.id,
      'ajuste_saldo': true,
      relations: 'contrato_item.produto_unidade.produto.material,contrato_item.contrato.orgao'
    }).toPromise()).content

    this.empenhoService.totalEmpenhadoPorContrato(this.entidadeForm.get('contrato').value.id, this.login.orgao.id, this.entidade.id)
      .subscribe((saldo) => {
        const valorContrato = +this.entidadeForm.get('contrato').value.valor_contrato;
        let valorAditivo = 0;
        let valorReajuste = 0;

        if (contrato.aditamentos) {
          valorAditivo = contrato?.aditamentos.reduce((acc, aditivo) => acc += +aditivo.valor_total, 0);
        }

        if (reajustes.length > 0) {
          valorReajuste = reajustes.reduce((acc, reajuste) => acc += +reajuste.reajuste_valor > 0 ? +reajuste.reajuste_valor : (+reajuste.reajuste_valor * -1), 0);
        }

        this.saldoContrato = (valorContrato + valorAditivo + valorReajuste) - saldo;
      });
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public async salvarFechar() {
    this.validSalvarFechar = true;
    await this.submitForm(false);
  }

  public carregarDadosCompra() {
    if (!this.numeroOF || this.numeroOF === 0) {
      this.compraAtual = null;
      return;
    }
    if (this.compraAtual && this.compraAtual.numero === this.numeroOF) {
      return;
    }
    this.compraService.extendido(1, 1, {
      numero: this.numeroOF, 'exercicio.id': this.login.exercicio.id, 'orgao.id': this.login.orgao.id, simplificado: true,
      relations: ['empenho', 'empenho.exercicio', 'ficha.recurso', 'ficha.aplicacao', 'ficha.aplicacao_variavel', 'modalidade', 'licitacao', 'subelemento', 'subelemento.vpd',
        'convenio', 'favorecido', 'favorecido.tipo', 'ficha.despesa', 'exercicio', 'orgao', 'requerente', 'rcms', 'contrato.tipo_contratacao', 'contrato.entrega'
      ].join(',')
    }).subscribe(async (pagina) => {
      if (!pagina?.content || pagina.content.length === 0)
        return;
      let compra = pagina.content[0];
      if (compra.excluido) {
        this.messageService.add({ severity: 'warn', summary: 'Erro', detail: `Ordem de fornecimento ${compra.numero}/${compra.exercicio.ano} está excluido.` });
        this.entidadeForm.get('compra').setValue(null);
        return;
      }
      if (compra.empenho && compra.empenho.id) {
        this.messageService.add({ severity: 'warn', summary: 'Erro', detail: `Ordem de fornecimento ${compra.numero}/${compra.exercicio.ano} já foi vinculado ao empenho ${compra.empenho.numero}/${compra.empenho.exercicio.id}` });
        this.entidadeForm.get('compra').setValue(null);
        return;
      }
      if (+compra.favorecido.tipo.tce !== 1 && +compra.favorecido.tipo.tce !== 2 && +compra.favorecido.tipo.tce !== 10) {
        toastr.warning('Verifique a espécie no cadastro de fornecedor');
        this.entidadeForm
          .get("contrato")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("licitacao")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("processo")
          .patchValue(null);
        this.numeroOF = null;
        return;
      }
      this.compraAtual = compra;
      this.entidadeForm.get('compra').setValue(compra);
      this.entidadeForm.get('tipo_empenho').setValue(compra.tipo_compra);
      this.entidadeForm.get('processo').setValue(compra.processo);
      this.entidadeForm.get('licitacao').setValue(compra.licitacao);
      this.entidadeForm.get('contrato').setValue(compra.contrato);
      this.entidadeForm.get('convenio').setValue(compra.convenio);
      this.entidadeForm.get('documento').setValue(compra.documento);
      this.entidadeForm.get('valor_empenho').setValue(compra['total_compra']);
      this.entidadeForm.get('ficha').setValue(compra.ficha);
      this.entidadeForm.get('subelemento').setValue(compra.subelemento);
      this.entidadeForm.get('modalidade').setValue(compra.modalidade);
      this.entidadeForm.get('favorecido').setValue(compra.favorecido);
      this.favorecidoAutoComplete.atualizar(true);
      this.fichaAutoComplete.atualizar(true);
      this.subElementoAutoComplete.atualizar(true);

      this.compraItemServico.filtrar(0, -1, { 'compra.id': compra.id, relations: ['produto_unidade', 'produto_unidade.produto', 'produto_unidade.unidade'].join(',') }).
        subscribe((itens) => {
          let historico_completo = '';
          if (itens?.content)
            for (let item of itens.content) {
              let historico = this.funcaoService.convertToBrNumber(item.quantidade) + ' ' + item.produto_unidade.unidade.nome + ' ' + item.produto_unidade.produto.nome;
              if (historico_completo.length > 0)
                historico_completo += '; ';
              historico_completo += historico;
            }
          this.entidadeForm.get('historico').setValue(historico_completo);
        });

      if (this.entidadeForm.get('convenio').value) {
        this.saldoConvenio = +(await this.convenioService.buscarSaldoConvenio(this.entidadeForm.get('convenio').value.id, this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value), this.login.orgao.id).toPromise())
        // this.obterSaldoConvenio();
      }

      if (this.entidadeForm.get('compra').value && this.entidadeForm.get('contrato').value && this.entidadeForm.get('licitacao').value) {
        const dataEmpenho = new Date(this.entidadeForm.get('data_empenho').value);
        const dataVencimento = new Date(dataEmpenho);

        if (this.login.parametro['contabil'].considerar_data_contrato && compra.contrato) {

          switch (compra.contrato?.entrega?.nome) {
            case '12 HORAS':
              dataVencimento.setDate(dataEmpenho.getDate());
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case 'A vista':
              dataVencimento.setDate(dataEmpenho.getDate());
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case 'À VISTA':
              dataVencimento.setDate(dataEmpenho.getDate());
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case 'CONTRA APRESENTAÇÃO':
              dataVencimento.setDate(dataEmpenho.getDate());
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case 'Contra apresentação':
              dataVencimento.setDate(dataEmpenho.getDate());
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case 'MEDIÇÕES':
              dataVencimento.setDate(dataEmpenho.getDate());
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '1 DIA':
              dataVencimento.setDate(dataEmpenho.getDate() + 1);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '24 HORAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 1);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '48 HORAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 2);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '3 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 3);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '72 HORAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 3);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '5 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 5);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '7 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 7);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '10 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 10);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '10 dias':
              dataVencimento.setDate(dataEmpenho.getDate() + 10);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '15 dias':
              dataVencimento.setDate(dataEmpenho.getDate() + 15);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '15 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 15);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '20 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 20);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '45 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 45);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);;
              break;
            case '60 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 60);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);;
              break;
            case '90 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 90);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);;
              break;
            case '120 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 120);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '150 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 150);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '180 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 180);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '540 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 540);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '720 DIAS':
              dataVencimento.setDate(dataEmpenho.getDate() + 720);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
            case '5º DIA ÚTIL':
              const quintoDiaUtil = this.calcularQuintoDiaUtilProximoMes(dataEmpenho);
              this.entidadeForm.get('data_vencimento').setValue(quintoDiaUtil);
              break;
            default:
              dataVencimento.setDate(dataEmpenho.getDate() + 30);
              this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
              break;
          }
        } else {
          dataVencimento.setDate(dataEmpenho.getDate() + 30);
          this.entidadeForm.get('data_vencimento').setValue(dataVencimento);
        }
      }
    });
  }

  public calcularQuintoDiaUtilProximoMes(data: Date): Date {
    const ano = data.getFullYear();
    const mes = data.getMonth();

    // Avança para o próximo mês
    const primeiroDiaProximoMes = new Date(ano, mes + 1, 1);

    let diasUteisContados = 0;
    let quintoDiaUtil = primeiroDiaProximoMes;

    // Itera sobre os dias do próximo mês até encontrar o 5º dia útil
    while (diasUteisContados < 5) {
      const diaDaSemana = quintoDiaUtil.getDay();

      // Considera dias úteis de segunda a sexta-feira (0 = domingo, 6 = sábado)
      if (diaDaSemana !== 0 && diaDaSemana !== 6) {
        diasUteisContados++;
      }

      if (diasUteisContados < 5) {
        quintoDiaUtil.setDate(quintoDiaUtil.getDate() + 1);
      }
    }

    return quintoDiaUtil;
  }

  private preencherAutoCompleteFicha() {
    const parametros = {
      exercicio_id: this.login.exercicio.id,
      orgao_id: this.login.orgao.id,
      relations: 'despesa,recurso,aplicacao,aplicacao_variavel,despesa.vpd',
      orderBy: 'despesa.nome'
    };

    // const favorecido: Favorecido = this.entidadeForm.get('favorecido').value;

    this.fichaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('ficha'), this.fichaService,
      'numero', ['ficha.despesa.codigo', 'ficha.despesa.nome'],
      parametros, { number: ['numero'], text: ['despesa.codigo', 'despesa.nome'] },
      () => {
        const ficha: FichaDespesa = this.entidadeForm.get('ficha').value;
        if (ficha && (!this.fichaOriginal || this.fichaOriginal.id !== ficha.id)) {
          this.fichaOriginal = undefined;
          this.subElementoFiltrar(ficha.despesa.codigo.substring(0, 6));
          this.txtAplicacaoVariavel = ficha.aplicacao_variavel ? `${ficha.aplicacao_variavel?.variavel} ${ficha.aplicacao_variavel?.nome}` : '';
        }

        // verifica se é um adiantamento
        // if (this.login.parametro['contabil'].utilizar_339030_como_adiantamento) {
        //   for (const despesa of this.login.parametro['contabil'].subelementos_como_adiantamento.split(',')) {
        //     if (ficha && ficha.despesa.codigo.startsWith(despesa)) {
        //       if (ficha.despesa?.vpd?.id) {
        //         this.entidadeForm.get('adiantamento').setValue(true);
        //       } else {
        //         toastr.warning(`Ficha ${ficha.numero} possui a despesa ${ficha.despesa.codigo} sem VPD adiantamento.`);
        //       }
        //       break;
        //     }
        //     this.entidadeForm.get('adiantamento').setValue(false);
        //   }
        // }

        this.alterarSubElemento();
        this.loadTotalizadores();
        this.preencherDadosUltimoEmpenho();
        if (this.entidadeForm.get('contrato').value) {
          this.obterSaldoContrato(this.entidadeForm.get('contrato').value);
        }
      }
    );
    this.buscarConvenio()
  }

  public async remover(item: EmpenhoStorage) {
    if (!item) return
    this.confirmationService.confirm({
      message: 'Deseja realmente remover o item?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.empenhoStorageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.listaAnexos.splice(this.listaAnexos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  public async toggleTransparencia(item: EmpenhoStorage) {
    if (!item) return;
    item.visivel_transparencia = !item.visivel_transparencia;
    await new Promise((resolve, reject) => {
      this.empenhoStorageService
        .atualizar(item).subscribe(res => {
          toastr.success('Arquivo atualizado com sucesso');
          resolve(true);
        }, error => {
          toastr.error(error.error.payload);
          reject(error);
        });
    });
  }

  limpaPrec() {
    this.atualizar_precatorio = this.entidadeForm.get('precatorio').value;
    this.entidadeForm.get('precatorio').setValue('');
  }

  atualizarPrec() {
    if (this.entidadeForm.value.precatorio != null) {
      this.empenhoAtualizado = this.entidadeForm.value;
      this.entidade.precatorio = this.empenhoAtualizado.precatorio;
      this.entidade['atualizar_precatorio'] = true;
      this.entidade['atualizarPNCP'] = true;
      this.empenhoService.atualizar(this.entidade)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(dados => {
          toastr.success('Precatório do Empenho atualizado!')
          this.entidadeForm.get('precatorio').setValue(this.empenhoAtualizado.precatorio)
        }, error => toastr.error(error.error.payload));
    } else {
      this.entidadeForm.get('precatorio').setValue(this.atualizar_precatorio);
      toastr.error('Informe um Precatório válido');
      throw new Error('Informe um Precatório válido');
    }
  }

  manterPrec() {
    this.entidadeForm.get('precatorio').setValue(this.atualizar_precatorio);
  }

  public async verificarDesabilitarValor(entidade: Empenho) {
    //buscar adiantamentos
    const adt = (await this.adiantamentoService.filtrar(1, -1, { 'empenho.id': entidade.id, 'ignoreCondObrig': true }).toPromise()).content

    //buscar liquidacoes
    const liq = (await this.liquidacaoService.filtrar(1, -1, { 'empenho.id': entidade.id, 'ignoreCondObrig': true }).toPromise()).content

    if (liq.length > 0 || adt.length > 0) {
      //true bloqueia false mostra
      this.desabilitarValor = true
    }
  }

  public async vincularReserva() {
    if (!this.entidade?.id) {
      return;
    }

    if (!this.login.usuario.administrador) {
      toastr.warning('Operação permitida apenas para administradores.');
      return;
    }

    if (!this.funcaoService.podeAlterarAudesp(this.entidade?.data_empenho, this.login)) {
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    const processo = this.entidadeForm.get('processo').value;
    if (!processo) {
      toastr.warning('Informe o número do processo');
      return;
    }

    this.reservaDotacaoItens = (await this.reservaDotacaoItemService.filtrar(1, -1, {
      'reserva.processo': processo,
      'ficha.id': this.entidade.ficha.id
    }).toPromise()).content

    $('#dlgConsultaReservaDotacao').modal('show');
  }

  public async selecionarReserva(reserva: ReservaDotacaoItem): Promise<void> {
    this.empenhoService.vincularReserva(this.entidade.id, reserva.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        res => {
          this.submitForm();
        },
        err => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: err.error.payload })
      )

    $('#dlgConsultaReservaDotacao').modal('hide');
  }
}
