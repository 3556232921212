import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { IMaskModule } from 'angular-imask';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DividaFornecedorRoutingModule } from './divida-fornecedor-routing.module';
import { DividaFornecedorListComponent } from './divida-fornecedor-list/divida-fornecedor-list.component';
import { SharedModule } from 'eddydata-lib';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';

@NgModule({
  declarations: [DividaFornecedorListComponent],
  imports: [
    CommonModule,
    DividaFornecedorRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    CheckboxModule,
    FieldsetModule
  ],
  providers: [MessageService]
})
export class DividaFornecedorModule { }
