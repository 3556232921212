import { Injectable, OnDestroy } from "@angular/core";
import { Exercicio, FuncaoService, GlobalService, LoginContabil, Relatorio } from "eddydata-lib";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AnexoLoaService } from "../service/anexo-loa.service";

@Injectable({
  providedIn: 'root'
})
export class Qdd implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: AnexoLoaService) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(exercicio: Exercicio, orgaos: number[], parametros: {}) {
    this.anexoServico.obterQdd(exercicio?.id, orgaos, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'QDD - QUADRO DETALHAMENTO DA DESPESA',
          this.login.usuario.nome,
          this.login.usuario.sobrenome,
          this.login.orgao.nome,
          this.login.brasao,
          this.conteudo(dados, parametros),
          'landscape', 'QUADRO DETALHADO DA DESPESA',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados: any[], parametros: {}): {}[] {
    if (parametros['quadrimestre'] !== -1 && parametros['tipo_valor'] !== 'todos') {
      return this.conteudoQuadrimestral(dados, parametros['quadrimestre'])
    }

    if (parametros['trimestre'] !== -1 && parametros['tipo_valor'] !== 'todos') {
      return this.conteudoTrimestral(dados, parametros['trimestre'])
    }

    if (parametros['data_inicial'] !== -1 && parametros['data_final'] !== -1) {
      return this.conteudoPorPeriodo(dados, parametros['data_inicial'], parametros['data_final'], parametros['tipo_valor']);
    }

    if ((parametros['quadrimestre'] !== -1 || parametros['trimestre'] !== -1) && parametros['tipo_valor'] === 'todos') {
      return this.conteudoPorTodos(dados, parametros['quadrimestre'], parametros['trimestre'])
    }

    return [];
  }

  private conteudoQuadrimestral(dados: any[], quadrimestre: number): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [
        { text: `FICHA`, alignment: 'center', bold: true, border: [true, true, true, false] },
        { text: `CÓDIGO`, alignment: 'center', bold: true, border: [true, true, true, false] },
        { text: `ESPECIFICAÇÕES`, alignment: 'center', bold: true, border: [true, true, true, false] },
        { text: `VALORES`, alignment: 'center', colSpan: 5, bold: true, border: [true, true, true, false] },
        '', '', '', '',
        { text: `%`, alignment: 'center', bold: true, border: [true, true, true, false] }
      ], [
        { text: '', border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] },
        { text: this.getMesQuadrimestre(+quadrimestre, 1), alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: this.getMesQuadrimestre(+quadrimestre, 2), alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: this.getMesQuadrimestre(+quadrimestre, 3), alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: this.getMesQuadrimestre(+quadrimestre, 4), alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'TOTAIS', alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: '', border: [true, false, true, true] },
      ]
    ];

    //Totalizadores gerais
    var total1 = 0;
    var total2 = 0;
    var total3 = 0;
    var total4 = 0;
    var totalOrcado = 0;

    // monta o agrupamento por unidade (Principal)
    const grupoUnidade = this.funcaoService.agrupar(dados, ['id_executora', 'executora'], ['total1', 'total2', 'total3', 'total4', 'vl_orcado']);
    const totalUnidade = grupoUnidade.length;
    for (let indexUnidade = 0; indexUnidade < totalUnidade; indexUnidade++) {
      const unidade = grupoUnidade[indexUnidade];
      var percentualUnidade = 0;
      unidade.registros.forEach(und => {
        percentualUnidade += (+und.total1 + +und.total2 + +und.total3) / +und.vl_orcado;
      });

      registros.push([
        { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
        {
          text: unidade ? this.funcaoService.mascarar('##.##.##', unidade.grupo['id_executora']) : '',
          bold: true, fontSize: 9, border: [true, false, false, false]
        },
        { text: unidade ? unidade.grupo['executora'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total1']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total2']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total3']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total4']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(+unidade.totalizadores['total1'] + +unidade.totalizadores['total2'] + +unidade.totalizadores['total3'] + +unidade.totalizadores['total4']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(percentualUnidade), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, true, false]
        }
      ]);

      // monta o agrupamento por funcional (Secundário)
      const grupoFuncional = this.funcaoService.agrupar(unidade.registros, ['funcional', 'projeto'], ['total1', 'total2', 'total3', 'total4', 'vl_orcado']);
      const totalFuncional = grupoFuncional.length;
      for (let indexFuncional = 0; indexFuncional < totalFuncional; indexFuncional++) {
        const funcional = grupoFuncional[indexFuncional];
        var percentualFuncional = 0;
        funcional.registros.forEach(f => {
          percentualFuncional += (+f.total1 + +f.total2 + +f.total3) / +f.vl_orcado;
        });

        registros.push([
          { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: funcional ? funcional.grupo['funcional'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: funcional ? funcional.grupo['projeto'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total1']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total2']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total3']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total4']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(+funcional.totalizadores['total1'] + +funcional.totalizadores['total2'] + +funcional.totalizadores['total3'] + +funcional.totalizadores['total4']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(percentualFuncional), alignment: 'right',
            bold: true, fontSize: 9, border: [true, false, true, false]
          }
        ]);

        // monta o agrupamento por recurso (Terciário)
        const grupoRecurso = this.funcaoService.agrupar(funcional.registros, ['id_recurso', 'recurso'], ['total1', 'total2', 'total3', 'total4', 'vl_orcado']);
        const totalRecurso = grupoRecurso.length;
        for (let indexRecurso = 0; indexRecurso < totalRecurso; indexRecurso++) {
          const recurso = grupoRecurso[indexRecurso];
          var percentualRecurso = 0;
          recurso.registros.forEach(rec => {
            percentualRecurso += (+rec.total1 + +rec.total2 + +rec.total3) / +rec.vl_orcado;
          });

          registros.push([
            { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: recurso ? recurso.grupo['id_recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: recurso ? recurso.grupo['recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total1']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total2']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total3']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total4']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(+recurso.totalizadores['total1'] + +recurso.totalizadores['total2'] + +recurso.totalizadores['total3'] + +recurso.totalizadores['total4']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(percentualRecurso), alignment: 'right',
              bold: true, fontSize: 9, border: [true, false, true, false]
            }
          ]);

          // lista os registros do relatorio
          const totalRegistro = (recurso ? recurso.registros.length : 0);
          for (let idx = 0; idx < totalRegistro; idx++) {
            const element1 = recurso ? recurso.registros[idx] : null;
            registros.push([
              { text: element1 ? element1.id_ficha : '', alignment: 'center', fontSize: 8, border: [true, false, false, false] },
              { text: element1 ? this.funcaoService.mascarar('#.#.##.##.##', element1.id_despesa) : '', fontSize: 8, border: [true, false, false, false] },
              { text: element1 ? element1.despesa : '', fontSize: 8, border: [true, false, false, false] },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total1) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total2) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total3) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total4) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(+element1.total1 + +element1.total2 + +element1.total3 + +element1.total4) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber((+element1.total1 + +element1.total2 + +element1.total3 + +element1.total4) / +element1.vl_orcado) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              }
            ]);

            total1 += +element1.total1;
            total2 += +element1.total2;
            total3 += +element1.total3;
            total4 += +element1.total4;
            totalOrcado += +element1.vl_orcado;
          }
        }
      }
    }

    const totalGeral = total1 + total2 + total3 + total4;
    const totalPercentual = totalGeral / totalOrcado;
    registros.push(
      [
        { text: 'TOTAL GERAL...', border: [true, true, true, true], fontSize: 8, bold: true, colSpan: 3 },
        { text: '', border: [false, true, false, true] },
        { text: '', border: [false, true, false, true] },
        { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(totalGeral), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(totalPercentual), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true }
      ]
    );

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 90, '*', 70, 70, 70, 70, 70, 'auto'],
        body: registros
      }
    }];
  }

  private conteudoTrimestral(dados: any[], trimestre: number): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [
        { text: `FICHA`, alignment: 'center', bold: true, border: [true, true, true, false] },
        { text: `CÓDIGO`, alignment: 'center', bold: true, border: [true, true, true, false] },
        { text: `ESPECIFICAÇÕES`, alignment: 'center', bold: true, border: [true, true, true, false] },
        { text: `VALORES`, alignment: 'center', colSpan: 4, bold: true, border: [true, true, true, false] },
        '', '', '',
        { text: `%`, alignment: 'center', bold: true, border: [true, true, true, false] }
      ], [
        { text: '', border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] },
        { text: '', border: [true, false, true, true] },
        { text: this.getMesTrimestre(trimestre, 1), alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: this.getMesTrimestre(trimestre, 2), alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: this.getMesTrimestre(trimestre, 3), alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'TOTAIS', alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: '', border: [true, false, true, true] },
      ]
    ];

    //Totalizadores gerais
    var total1 = 0;
    var total2 = 0;
    var total3 = 0;
    var totalOrcado = 0;

    // monta o agrupamento por unidade (Principal)
    const grupoUnidade = this.funcaoService.agrupar(dados, ['id_executora', 'executora'], ['total1', 'total2', 'total3', 'vl_orcado']);
    const totalUnidade = grupoUnidade.length;
    for (let indexUnidade = 0; indexUnidade < totalUnidade; indexUnidade++) {
      const unidade = grupoUnidade[indexUnidade];
      var percentualUnidade = 0;
      unidade.registros.forEach(und => {
        percentualUnidade += (+und.total1 + +und.total2 + +und.total3) / +und.vl_orcado;
      });

      registros.push([
        { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
        {
          text: unidade ? this.funcaoService.mascarar('##.##.##', unidade.grupo['id_executora']) : '',
          bold: true, fontSize: 9, border: [true, false, false, false]
        },
        { text: unidade ? unidade.grupo['executora'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total1']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total2']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total3']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(+unidade.totalizadores['total1'] + +unidade.totalizadores['total2'] + +unidade.totalizadores['total3']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(percentualUnidade), alignment: 'right',
          bold: true, fontSize: 9, border: [true, false, true, false]
        }
      ]);

      // monta o agrupamento por funcional (Secundário)
      const grupoFuncional = this.funcaoService.agrupar(unidade.registros, ['funcional', 'projeto'], ['total1', 'total2', 'total3', 'vl_orcado']);
      const totalFuncional = grupoFuncional.length;
      for (let indexFuncional = 0; indexFuncional < totalFuncional; indexFuncional++) {
        const funcional = grupoFuncional[indexFuncional];
        var percentualFuncional = 0;
        funcional.registros.forEach(f => {
          percentualFuncional += (+f.total1 + +f.total2 + +f.total3) / +f.vl_orcado;
        });

        registros.push([
          { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: funcional ? funcional.grupo['funcional'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: funcional ? funcional.grupo['projeto'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total1']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total2']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total3']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(+funcional.totalizadores['total1'] + +funcional.totalizadores['total2'] + +funcional.totalizadores['total3']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: this.funcaoService.convertToBrNumber(percentualFuncional), alignment: 'right',
            bold: true, fontSize: 9, border: [true, false, true, false]
          }
        ]);

        // monta o agrupamento por recurso (Terciário)
        const grupoRecurso = this.funcaoService.agrupar(funcional.registros, ['id_recurso', 'recurso'], ['total1', 'total2', 'total3', 'vl_orcado']);
        const totalRecurso = grupoRecurso.length;
        for (let indexRecurso = 0; indexRecurso < totalRecurso; indexRecurso++) {
          const recurso = grupoRecurso[indexRecurso];
          var percentualRecurso = 0;
          recurso.registros.forEach(rec => {
            percentualRecurso += (+rec.total1 + +rec.total2 + +rec.total3) / +rec.vl_orcado;
          });
          registros.push([
            { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: recurso ? recurso.grupo['id_recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: recurso ? recurso.grupo['recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total1']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total2']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total3']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(+recurso.totalizadores['total1'] + +recurso.totalizadores['total2'] + +recurso.totalizadores['total3']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(percentualRecurso), alignment: 'right',
              bold: true, fontSize: 9, border: [true, false, true, false]
            }
          ]);

          // lista os registros do relatorio
          const totalRegistro = (recurso ? recurso.registros.length : 0);
          for (let idx = 0; idx < totalRegistro; idx++) {
            const element1 = recurso ? recurso.registros[idx] : null;
            registros.push([
              { text: element1 ? element1.id_ficha : '', alignment: 'center', fontSize: 8, border: [true, false, false, false] },
              { text: element1 ? this.funcaoService.mascarar('#.#.##.##.##', element1.id_despesa) : '', fontSize: 8, border: [true, false, false, false] },
              { text: element1 ? element1.despesa : '', fontSize: 8, border: [true, false, false, false] },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total1) : '', alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total2) : '', alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total3) : '', alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(+element1.total1 + +element1.total2 + +element1.total3) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber((+element1.total1 + +element1.total2 + +element1.total3) / +element1.vl_orcado) : '',
                alignment: 'right', fontSize: 8, border: [true, false, true, false]
              }
            ]);

            total1 += +element1.total1;
            total2 += +element1.total2;
            total3 += +element1.total3;
            totalOrcado += +element1.vl_orcado;
          }
        }
      }
    }

    const totalGeral = total1 + total2 + total3;
    const totalPercentual = totalGeral / totalOrcado;
    registros.push(
      [
        { text: 'TOTAL GERAL...', border: [true, true, true, true], fontSize: 8, bold: true, colSpan: 3 },
        { text: '', border: [false, true, false, true] },
        { text: '', border: [false, true, false, true] },
        { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(totalGeral), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(totalPercentual), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true }
      ]
    );

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 95, '*', 75, 75, 75, 75, 'auto'],
        body: registros
      }
    }];
  }

  private conteudoPorPeriodo(dados: any[], data_inicial: string, data_final: string, tipo_valor: string): {}[] {
    // monta o cabecalho
    const registros: {}[] = [];

    if (tipo_valor === 'todos') {
      registros.push([
        { text: `FICHA`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: `CÓDIGO`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: `ESPECIFICAÇÕES`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'EMPENHADO', alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'LIQUIDADO', alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'PAGO', alignment: 'center', bold: true, border: [true, true, true, true] },
      ]);
      registros.unshift([
        {
          text: `Período: ${this.funcaoService.converteDataBR(data_inicial)} - ${this.funcaoService.converteDataBR(data_final)}`,
          alignment: 'center', bold: true, fontSize: 10, colSpan: 6, border: [false, false, false, false]
        }, '', '', '', '', ''
      ]);
  
      //Totalizadores gerais
      var total1 = 0;
      var total2 = 0;
      var total3 = 0;
  
      // monta o agrupamento por unidade (Principal)
      const grupoUnidade = this.funcaoService.agrupar(dados, ['id_executora', 'executora'], ['total1', 'total2', 'total3']);
      const totalUnidade = grupoUnidade.length;
      for (let indexUnidade = 0; indexUnidade < totalUnidade; indexUnidade++) {
        const unidade = grupoUnidade[indexUnidade];
        registros.push([
          { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: unidade ? this.funcaoService.mascarar('##.##.##', unidade.grupo['id_executora']) : '', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: unidade ? unidade.grupo['executora'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total1']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total2']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] },
          { text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total3']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] }
        ]);
  
        // monta o agrupamento por funcional (Secundário)
        const grupoFuncional = this.funcaoService.agrupar(unidade.registros, ['funcional', 'projeto'], ['total1', 'total2', 'total3']);
        const totalFuncional = grupoFuncional.length;
        for (let indexFuncional = 0; indexFuncional < totalFuncional; indexFuncional++) {
          const funcional = grupoFuncional[indexFuncional];
          registros.push([
            { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['funcional'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['projeto'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total1']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total2']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] },
            { text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total3']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] }
          ]);
  
          // monta o agrupamento por recurso (Terciário)
          const grupoRecurso = this.funcaoService.agrupar(funcional.registros, ['id_recurso', 'recurso'], ['total1', 'total2', 'total3']);
          const totalRecurso = grupoRecurso.length;
          for (let indexRecurso = 0; indexRecurso < totalRecurso; indexRecurso++) {
            const recurso = grupoRecurso[indexRecurso];
            registros.push([
              { text: '', bold: true, fontSize: 9, border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['id_recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false] },
              { text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total1']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
              { text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total2']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] },
              { text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total3']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] }
            ]);
  
            // lista os registros do relatorio
            const totalRegistro = (recurso ? recurso.registros.length : 0);
            for (let idx = 0; idx < totalRegistro; idx++) {
              const element1 = recurso ? recurso.registros[idx] : null;
              registros.push([
                { text: element1 ? element1.id_ficha : '', alignment: 'center', fontSize: 8, border: [true, false, false, false] },
                { text: element1 ? this.funcaoService.mascarar('#.#.##.##.##', element1.id_despesa) : '', fontSize: 8, border: [true, false, false, false] },
                { text: element1 ? element1.despesa : '', fontSize: 8, border: [true, false, false, false] },
                { text: element1 ? this.funcaoService.convertToBrNumber(element1.total1) : '', alignment: 'right', fontSize: 8, border: [true, false, true, false] },
                { text: element1 ? this.funcaoService.convertToBrNumber(element1.total2) : '', alignment: 'right', fontSize: 8, border: [true, false, true, false] },
                { text: element1 ? this.funcaoService.convertToBrNumber(element1.total3) : '', alignment: 'right', fontSize: 8, border: [true, false, true, false] }
              ]);
  
              total1 += +element1.total1;
              total2 += +element1.total2;
              total3 += +element1.total3;
            }
          }
        }
      }
  
      registros.push([
        { text: 'TOTAL GERAL...', border: [true, true, true, true], fontSize: 8, bold: true, colSpan: 3 },
        { text: '', border: [false, true, false, true] },
        { text: '', border: [false, true, false, true] },
        { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true }
      ]);
    } else {
      let tipo = '';
      if (tipo_valor === 'empenhado') {
        tipo = 'EMPENHADO';
      } else if (tipo_valor === 'liquidado') {
        tipo = 'LIQUIDADO';
      } else if (tipo_valor === 'pago') {
        tipo = 'PAGO';
      }

      registros.push([
        { text: `FICHA`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: `CÓDIGO`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: `ESPECIFICAÇÕES`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: tipo, alignment: 'center', bold: true, border: [true, true, true, true] },
      ]);

      registros.unshift([
        {
          text: `Período: ${this.funcaoService.converteDataBR(data_inicial)} - ${this.funcaoService.converteDataBR(data_final)}`,
          alignment: 'center', bold: true, fontSize: 10, colSpan: 4, border: [false, false, false, false]
        }, '', '', ''
      ]);

      var total = 0;

      // monta o agrupamento por unidade (Principal)
      const grupoUnidade = this.funcaoService.agrupar(dados, ['id_executora', 'executora'], ['total1', 'total2', 'total3']);
      const totalUnidade = grupoUnidade.length;
      for (let indexUnidade = 0; indexUnidade < totalUnidade; indexUnidade++) {
        const unidade = grupoUnidade[indexUnidade];

        const listaUnidade = [
          { text: '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: unidade ? this.funcaoService.mascarar('##.##.##', unidade.grupo['id_executora']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
          { text: unidade ? unidade.grupo['executora'] : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
        ];

        if (tipo_valor === 'empenhado') {
          listaUnidade.push({ text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total1']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
        } else if (tipo_valor === 'liquidado') {
          listaUnidade.push({ text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total2']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
        } else if (tipo_valor === 'pago') {
          listaUnidade.push({ text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total3']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
        }

        registros.push(listaUnidade);

        // monta o agrupamento por funcional (Secundário)
        const grupoFuncional = this.funcaoService.agrupar(unidade.registros, ['funcional', 'projeto'], ['total1', 'total2', 'total3']);
        const totalFuncional = grupoFuncional.length;
        for (let indexFuncional = 0; indexFuncional < totalFuncional; indexFuncional++) {
          const funcional = grupoFuncional[indexFuncional];

          const listaFuncional = [
            { text: '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['funcional'] : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
            { text: funcional ? funcional.grupo['projeto'] : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
          ];

          if (tipo_valor === 'empenhado') {
            listaFuncional.push({ text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total1']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
          } else if (tipo_valor === 'liquidado') {
            listaFuncional.push({ text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total2']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
          } else if (tipo_valor === 'pago') {
            listaFuncional.push({ text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total3']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
          }

          registros.push(listaFuncional);

          // monta o agrupamento por recurso (Terciário)
          const grupoRecurso = this.funcaoService.agrupar(funcional.registros, ['id_recurso', 'recurso'], ['total1', 'total2', 'total3']);
          const totalRecurso = grupoRecurso.length;
          for (let indexRecurso = 0; indexRecurso < totalRecurso; indexRecurso++) {
            const recurso = grupoRecurso[indexRecurso];

            const listaRegistro = [
              { text: '', bold: true, fontSize: 9, alignment: 'right', border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['id_recurso'] : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
              { text: recurso ? recurso.grupo['recurso'] : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false] },
            ];

            if (tipo_valor === 'empenhado') {
              listaRegistro.push({ text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total1']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
            } else if (tipo_valor === 'liquidado') {
              listaRegistro.push({ text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total2']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
            } else if (tipo_valor === 'pago') {
              listaRegistro.push({ text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total3']) : '', alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false] });
            }

            registros.push(listaRegistro);

            // lista os registros do relatorio
            const totalRegistro = (recurso ? recurso.registros.length : 0);
            for (let idx = 0; idx < totalRegistro; idx++) {
              const element1 = recurso ? recurso.registros[idx] : null;

              const listaTotal = [
                { text: element1 ? element1.id_ficha : '', alignment: 'center', fontSize: 8, border: [true, false, false, false] },
                { text: element1 ? this.funcaoService.mascarar('#.#.##.##.##', element1.id_despesa) : '', fontSize: 8, border: [true, false, false, false] },
                { text: element1 ? element1.despesa : '', fontSize: 8, border: [true, false, false, false] },
              ];

              if (tipo_valor === 'empenhado') {
                listaTotal.push({ text: element1 ? this.funcaoService.convertToBrNumber(element1.total1) : '', alignment: 'right', fontSize: 8, border: [true, false, true, false] });
                total += +element1.total1;
              } else if (tipo_valor === 'liquidado') {
                listaTotal.push({ text: element1 ? this.funcaoService.convertToBrNumber(element1.total2) : '', alignment: 'right', fontSize: 8, border: [true, false, true, false] });
                total += +element1.total2;
              } else if (tipo_valor === 'pago') {
                listaTotal.push({ text: element1 ? this.funcaoService.convertToBrNumber(element1.total3) : '', alignment: 'right', fontSize: 8, border: [true, false, true, false] });
                total += +element1.total3;
              }

              registros.push(listaTotal);
            }
          }
        }
      }
  
      registros.push([
        { text: 'TOTAL GERAL...', border: [true, true, true, true], fontSize: 8, bold: true, colSpan: 3 },
        { text: '', border: [false, true, false, true] },
        { text: '', border: [false, true, false, true] },
        { text: this.funcaoService.convertToBrNumber(total), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true }
      ]);
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: tipo_valor === 'todos' ? ['auto', 95, '*', 80, 80, 80] : ['auto', 95, '*', 80],
        body: registros
      }
    }];
  }

  private conteudoPorTodos(dados: any[], quadrimestre: number, trimestre: number): {}[] {
    // monta o cabecalho
    const registros: {}[] = [
      [
        { text: `FICHA`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: `CÓDIGO`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: `ESPECIFICAÇÕES`, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'EMPENHADO', alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'LIQUIDADO', alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'PAGO', alignment: 'center', bold: true, border: [true, true, true, true] },
      ]
    ];

    registros.unshift([
      {
        text: `${quadrimestre !== -1 ? 'QUADRIMESTRE SELECIONADO: ' + quadrimestre : 'TRIMESTRE SELECIONADO: ' + trimestre}`,
        alignment: 'center', bold: true, fontSize: 10, colSpan: 6, border: [false, false, false, false]
      },
      '', '', '', '', ''
    ])

    //Totalizadores gerais
    var total1 = 0;
    var total2 = 0;
    var total3 = 0;

    // monta o agrupamento por unidade (Principal)
    const grupoUnidade = this.funcaoService.agrupar(dados, ['id_executora', 'executora'], ['total1', 'total2', 'total3']);
    const totalUnidade = grupoUnidade.length;
    for (let indexUnidade = 0; indexUnidade < totalUnidade; indexUnidade++) {
      const unidade = grupoUnidade[indexUnidade];
      registros.push([
        {
          text: '', bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: unidade ? this.funcaoService.mascarar('##.##.##', unidade.grupo['id_executora']) : '', bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: unidade ? unidade.grupo['executora'] : '', bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total1']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total2']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        },
        {
          text: unidade ? this.funcaoService.convertToBrNumber(unidade.totalizadores['total3']) : '',
          alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
        }
      ]);

      // monta o agrupamento por funcional (Secundário)
      const grupoFuncional = this.funcaoService.agrupar(unidade.registros, ['funcional', 'projeto'], ['total1', 'total2', 'total3']);
      const totalFuncional = grupoFuncional.length;
      for (let indexFuncional = 0; indexFuncional < totalFuncional; indexFuncional++) {
        const funcional = grupoFuncional[indexFuncional];
        registros.push([
          {
            text: '', bold: true, fontSize: 9, border: [true, false, false, false]
          },
          {
            text: funcional ? funcional.grupo['funcional'] : '', bold: true, fontSize: 9, border: [true, false, false, false]
          },
          {
            text: funcional ? funcional.grupo['projeto'] : '', bold: true, fontSize: 9, border: [true, false, false, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total1']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total2']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          },
          {
            text: funcional ? this.funcaoService.convertToBrNumber(funcional.totalizadores['total3']) : '',
            alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
          }
        ]);

        // monta o agrupamento por recurso (Terciário)
        const grupoRecurso = this.funcaoService.agrupar(funcional.registros, ['id_recurso', 'recurso'], ['total1', 'total2', 'total3']);
        const totalRecurso = grupoRecurso.length;
        for (let indexRecurso = 0; indexRecurso < totalRecurso; indexRecurso++) {
          const recurso = grupoRecurso[indexRecurso];
          registros.push([
            {
              text: '', bold: true, fontSize: 9, border: [true, false, false, false]
            },
            {
              text: recurso ? recurso.grupo['id_recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false]
            },
            {
              text: recurso ? recurso.grupo['recurso'] : '', bold: true, fontSize: 9, border: [true, false, false, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total1']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, false, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total2']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            },
            {
              text: recurso ? this.funcaoService.convertToBrNumber(recurso.totalizadores['total3']) : '',
              alignment: 'right', bold: true, fontSize: 9, border: [true, false, true, false]
            }
          ]);

          // lista os registros do relatorio
          const totalRegistro = (recurso ? recurso.registros.length : 0);
          for (let idx = 0; idx < totalRegistro; idx++) {
            const element1 = recurso ? recurso.registros[idx] : null;
            registros.push([
              { text: element1 ? element1.id_ficha : '', alignment: 'center', fontSize: 8, border: [true, false, false, false] },
              { text: element1 ? this.funcaoService.mascarar('#.#.##.##.##', element1.id_despesa) : '', fontSize: 8, border: [true, false, false, false] },
              { text: element1 ? element1.despesa : '', fontSize: 8, border: [true, false, false, false] },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total1) : '', alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total2) : '', alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              },
              {
                text: element1 ? this.funcaoService.convertToBrNumber(element1.total3) : '', alignment: 'right',
                fontSize: 8, border: [true, false, true, false]
              }
            ]);

            total1 += +element1.total1;
            total2 += +element1.total2;
            total3 += +element1.total3;
          }
        }
      }
    }

    registros.push(
      [
        { text: 'TOTAL GERAL...', border: [true, true, true, true], fontSize: 8, bold: true, colSpan: 3 },
        { text: '', border: [false, true, false, true] },
        { text: '', border: [false, true, false, true] },
        { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true },
        { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', border: [true, true, true, true], fontSize: 8, bold: true }
      ]
    );

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 95, '*', 80, 80, 80],
        body: registros
      }
    }];
  }

  private getMesQuadrimestre(quadrimestre: number, referencia: 1 | 2 | 3 | 4): string {
    switch (quadrimestre) {
      case 1:
        switch (referencia) {
          case 1: return 'JANEIRO';
          case 2: return 'FEVEREIRO';
          case 3: return 'MARÇO';
          case 4: return 'ABRIL';
        }
      case 2:
        switch (referencia) {
          case 1: return 'MAIO';
          case 2: return 'JUNHO';
          case 3: return 'JULHO';
          case 4: return 'AGOSTO';
        }
      case 3:
        switch (referencia) {
          case 1: return 'SETEMBRO';
          case 2: return 'OUTUBRO';
          case 3: return 'NOVEMBRO';
          case 4: return 'DEZEMBRO';
        }
    }
  }

  private getMesTrimestre(trimestre: number, referencia: 1 | 2 | 3): string {
    switch (trimestre) {
      case 1:
        switch (referencia) {
          case 1: return 'JANEIRO';
          case 2: return 'FEVEREIRO';
          case 3: return 'MARÇO';
        }
      case 2:
        switch (referencia) {
          case 1: return 'ABRIL';
          case 2: return 'MAIO';
          case 3: return 'JUNHO';
        }
      case 3:
        switch (referencia) {
          case 1: return 'JULHO';
          case 2: return 'AGOSTO';
          case 3: return 'SETEMBRO';
        }
      case 4:
        switch (referencia) {
          case 1: return 'OUTUBRO';
          case 2: return 'NOVEMBRO';
          case 3: return 'DEZEMBRO';
        }
    }
  }

}
