import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, FuncaoService, LicitacaoAudesp, LicitacaoAudespPacote, LoginContabil } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { LicitacaoAudespPacoteService } from '../service/licitacao-audesp-pacote.service';
import { LicitacaoAudespService } from '../service/licitacao-audesp.service';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-pacote',
  templateUrl: './licitacao-audesp-pacote.component.html'
})
export class LicitacaoAudespPacoteComponent extends BaseResourceListComponent<any, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public pacoteAtual: LicitacaoAudespPacote;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private funcaoService: FuncaoService,
    private audespService: LicitacaoAudespService,
    private pacoteService: LicitacaoAudespPacoteService) {
    super(pacoteService, injector);
    this.usarFiltroPersonalizado = true;
    if (!this.limite) {
      this.limite = 20;
    }
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'arquivos.licitacao';
  }

  protected condicoesGrid(): {} {
    return {
      orgao_id: this.login.orgao.id,
    };
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Identificação', coluna: 'arquivos.identificacao', tipo: 'String', cols: 4, padrao: true });
    retorno.push({ titulo: 'Número do Processo', coluna: 'arquivos.licitacao.processo', tipo: 'String', cols: 4 });
    retorno.push({ titulo: 'Número da Licitação', coluna: 'arquivos.licitacao.numero', tipo: 'String', cols: 4 });
    retorno.push({ titulo: 'Código ', coluna: 'arquivos.codigo', tipo: 'String', cols: 4 });
    retorno.push({
      titulo: 'Tipo', coluna: 'arquivos.tipo', tipo: 'Selection', opcoesSelecao: [
        { valor: 'AJUSTE_EMPENHO', label: 'Ajuste de Empenho' },
        { valor: 'AJUSTE_CONTRATO', label: 'Ajuste de Contrato' },
      ]
    });
    /*retorno.push({
      titulo: 'Telefone', coluna: 'telefone', tipo: 'String', cols: 2,
      mask: [{ mask: '0000-0000' }, { mask: '00000-0000' }, { mask: '(00)0000-0000' }, { mask: '(00)00000-0000' }]
    });
    retorno.push({
      titulo: 'CPF/CNPJ', coluna: 'cpf_cnpj', tipo: 'String', cols: 4,
      mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }]
    });
    retorno.push({ titulo: 'Descrição CNAE', coluna: 'cnaes.cnae.nome', tipo: 'String', cols: 4 });
    retorno.push({
      titulo: 'Situação', coluna: 'inativo', tipo: 'Selection', opcoesSelecao: [
        { valor: 'false', label: 'ATIVO' },
        { valor: 'true', label: 'INATIVO' }
      ]
    });*/

    return retorno;
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: LicitacaoAudesp): Observable<LicitacaoAudesp> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Cadastro', coluna: 'data_cadastro' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    this.baseResourceService
      .filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`AUDESP - FASE 4`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Audesp Fase 4', ['auto', '*', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public visualizar(id: number) {
    this.pacoteService
      .obter({ id, relations: 'arquivos', orderBy: 'arquivos.codigo' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        if (!dados) return;
        this.pacoteAtual = dados;
        $('#dialogAudespPacoteForm').modal('show');
      })
  }

  public enviarPacote() {
    if (!this.pacoteAtual) return
    this.pacoteService
      .enviarPacote(this.pacoteAtual.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        toastr.success(`Pacote ${this.pacoteAtual.enviado ? 'estornado' : 'enviado'} com sucesso!`);
        this.pacoteAtual.enviado = !this.pacoteAtual.enviado;
        this.visualizar(this.pacoteAtual.id);
      }, error => toastr.error(error.message ? error.message : error))
  }

  public armazenarPacote(estornar: boolean) {
    if (!this.pacoteAtual) return
    this.pacoteService
      .armazenarPacote(this.pacoteAtual.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        toastr.success(`Pacote ${estornar ? 'estornado' : 'armazenado'} com sucesso!`);
        this.visualizar(this.pacoteAtual.id);
      }, error => toastr.error(error.message ? error.message : error))
  }

  public baixarPacote() {
    if (!this.pacoteAtual) return
    this.audespService.baixarPacote(this.pacoteAtual.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(async (xml) => {
        if (!xml) return;
        let contratos
        if (this.pacoteAtual.tipo === 'AJUSTE_CONTRATO' || this.pacoteAtual.tipo === 'LIC_REG_NAO3') {
          contratos = this.pacoteAtual.arquivos.map(item => item.identificacao).map(item => item.split(' ')[1].replace('/', '_'))
        } else {
          let parentes: LicitacaoAudesp[] = (await this.audespService.filtrar(1, -1, { 'id$in': this.pacoteAtual.arquivos.map(item => item.id), relations: 'parente' }).toPromise()).content
          contratos = parentes.map(item => item.parente.identificacao.split(' ')[1].replace('/', '_'))
        }

        const nomeArquivo = `PACOTE_${this.pacoteAtual.id}_${this.pacoteAtual.tipo}`

        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(xml)));
        element.setAttribute('download', `${nomeArquivo}.xml`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }, error => {
        let mensagemXML = String(error.error.payload).split('\n');
        if (mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          const xmlInvalido = mensagemXML.splice(mensagemXML.indexOf(mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
        }
        toastr.error(mensagemXML.join('\n'), 'Não foi possível baixar o pacote!');
      });
  }

  public gerarXML(item) {
    this.audespService.gerarXML(item.id).pipe(takeUntil(this.unsubscribe)).subscribe(async (xml) => {
      let parentes: LicitacaoAudesp[] = (await this.audespService.filtrar(1, -1, { id: item.id, relations: 'parente' }).toPromise()).content
      let contratos = parentes.map(item => item.parente.identificacao.split(' ')[1])

      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(xml)))
      element.setAttribute('download', `${this.funcaoService.removerPontos(item.codigo)}_${item.tipo}_${contratos.join('_')}.xml`);
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    })
  }
}
