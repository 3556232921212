import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { AdiantamentoService } from 'administrativo-lib';
import { Adiantamento, AdiantamentoDocumento, BaseResourceFormComponent, DateFormatPipe, FuncaoService, GlobalService, LoginContabil, OrgaoAssinatura, OrgaoAssinaturaService, ParecerControleInterno, Relatorio,AdiantamentoStorage, FavorecidoService } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { AdiantamentoDocumentoService } from '../service/adiantamento-documento.service';
import { NotaAdiantamento } from '../relatorio/nota-adiantamento';
import { ParecerControleInternoService } from 'projects/contabil/src/app/parecer-controle-interno/service/parecer-controle-interno.service';
import * as toastr from 'toastr';
import { AdiantamentoStorageService } from '../service/adiantamento-storage.service';
import { Devolucao } from '../relatorio/devolucao';

@Component({
  selector: 'lib-adiantamento-view',
  templateUrl: './adiantamento-view.component.html'
})
export class AdiantamentoViewComponent extends BaseResourceFormComponent<Adiantamento, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  public assinatura: OrgaoAssinatura;
  public parecer: ParecerControleInterno;
  public listaDocumentos: Array<AdiantamentoDocumento>;

  public listaArquivos: AdiantamentoStorage[];
  public listaFotos: AdiantamentoStorage[];

  public saldo: number;
  public vlEfetivado: number;
  public vlAnulado: number;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected globalService: GlobalService,
    protected favorecidoService: FavorecidoService,
    public funcaoService: FuncaoService,
    public parecerService: ParecerControleInternoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected storageService: AdiantamentoStorageService,
    protected documentoService: AdiantamentoDocumentoService,
    protected adiantamentoService: AdiantamentoService) {
    super(new Adiantamento(), injector, Adiantamento.converteJson, adiantamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      mes: [null, [Validators.required]],
      data_adiantamento: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      data_liberacao: [null],
      data_prestacao: [null],
      data_partida: [null],
      data_chegada: [null],
      data_aplicacao: [null],
      data_prazo_prestacao: [null],
      parecer: [null],
      descricao_parecer: [null],
      especie: [null, [Validators.required]],
      situacao: [null, [Validators.required]],
      valor_adiantado: [null, [Validators.required]],
      valor_complemento: [null],
      oficio: [null, [Validators.required]],
      motivo: [null, [Validators.required]],
      numero_conta: [null],
      agencia: [null],
      banco: [null],
      beneficiario: [null, [Validators.required]],
      tipo_beneficiario: [null, [Validators.required]],
      cargo_beneficiario: [null, [Validators.required]],
      destino: [null],
      empenho: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio]
    });
  }

  protected afterLoad() {
    this.entidade.data_adiantamento = new DateFormatPipe().transform(this.entidade.data_adiantamento, ['local']);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, ['local']);
    this.entidade.data_liberacao = new DateFormatPipe().transform(this.entidade.data_liberacao, ['local']);
    this.entidade.data_aplicacao = new DateFormatPipe().transform(this.entidade.data_aplicacao, []);
    this.entidade.data_prazo_prestacao = new DateFormatPipe().transform(this.entidade.data_prazo_prestacao, []);
    this.entidade.data_partida = new DateFormatPipe().transform(this.entidade.data_partida, ['local']);
    this.entidade.data_chegada = new DateFormatPipe().transform(this.entidade.data_chegada, ['local']);
    this.entidade.data_prestacao = new DateFormatPipe().transform(this.entidade.data_prestacao, ['local']);
    this.entidade.data_termino = new DateFormatPipe().transform(this.entidade.data_termino, ['local']);
    this.preencherDocumento();
    this.calcularSaldo();
    this.storageService.filtrar(1, -1, { 'adiantamento.id': this.entidade.id, foto: false, orderBy: 'data_cadastro$DESC' })
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((res) => {
      this.listaArquivos = res.content;
    });
  this.storageService.filtrar(1, -1, { 'adiantamento.id': this.entidade.id, foto: true, orderBy: 'data_cadastro$DESC' })
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((res) => {
      this.listaFotos = res.content;
    });
  }

  public async calcularSaldo() {
    if (this.entidadeForm.get('empenho')) {
      // this.vlEfetivado = await this.empenhoService.obterValorEmpenho(
      //   this.entidade.empenho.numero, this.entidade.exercicio.id, this.entidade.orgao.id
      // ).toPromise();
      this.vlEfetivado = +this.entidade.empenho.valor_empenho;
    } else {
      this.vlEfetivado = this.entidadeForm.get('valor_adiantado').value ?
        +this.entidadeForm.get('valor_adiantado').value : +this.entidade.valor_adiantado;
    }

    this.vlAnulado = await this.adiantamentoService.totalAnuladoPorAdiantamento(this.entidade.empenho.numero, this.login.exercicio.id, this.login.orgao.id).toPromise();
    this.saldo = this.vlEfetivado - this.vlAnulado;
  }

  protected parametrosExtras(): {} {
    return { relations: 'empenho,empenho.exercicio,exercicio,orgao,empenho.subelemento.vpd'};
  }

  protected async afterInit(): Promise<void> {
    this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id, 'data_limite$ge': this.funcaoService.converteDataSQL(new Date()), 'orderBy': 'data_limite$DESC' }).toPromise();
    this.parecer = await this.parecerService.obter({ orgao_id: this.login.orgao.id, exercicio_id: this.login.exercicio.id, orderBy: 'id$DESC' }).toPromise();
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(entidade: Adiantamento): void {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public obterSituacao(idx: string) {
    switch (idx) {
      case 'A':
        return 'ATIVO';
      case 'I':
        return 'INATIVO';
      default:
        return '-';
    }
  }

  public obterTipo(idx: string) {
    switch (idx) {
      case 'S':
        return 'SERVIDOR PÚBLICO';
      case 'A':
        return 'AGENTE POLÍTICO';
      case 'V':
        return 'VEREADOR';
      default:
        return '-';
    }
  }

  public obterEspecie(idx: string) {
    switch (idx) {
      case 'N':
        return 'NUMERARIAS';
      case 'D':
        return 'DIÁRIAS';
      default:
        return '-';
    }
  }

  public obterParecer(idx: string) {
    switch (idx) {
      case 'F':
        return 'FAVORÁVEL';
      case 'D':
        return 'DESFAVORÁVEL';
      default:
        return '-';
    }
  }

  public preencherDocumento() {
    // documentos do adiantamento
    this.documentoService.obterPorAdiantamento(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaDocumentos = data ? data.content : new Array<AdiantamentoDocumento>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public imprimirAdiantamento() {
    const parametros = {};

    let relations = '';
    relations += 'empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,empenho.ficha.subfuncao,';
    relations += 'empenho.ficha.programa,empenho.ficha.acao,empenho.ficha.aplicacao,';
    relations += 'empenho.licitacao,empenho.modalidade,empenho.subelemento,empenho.favorecido';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidadeForm.get('id').value;
    // parametros['impresso'] = this.selectNaoImpresso;
    this.adiantamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        const nota = new NotaAdiantamento(this.assinaturaService);
        nota.imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public imprimirDevolucao() {
    const parametros = {};

    let relations = '';
    relations += 'empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,empenho.ficha.subfuncao,';
    relations += 'empenho.ficha.programa,empenho.ficha.acao,empenho.ficha.aplicacao,documentos,';
    relations += 'empenho.licitacao,empenho.modalidade,empenho.subelemento,empenho.favorecido';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidadeForm.get('id').value;
    // parametros['impresso'] = this.selectNaoImpresso;
    this.adiantamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        const nota = new Devolucao(this.favorecidoService);
        nota.imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public async imprimirParecer() {
    if (!this.assinatura?.almoxarife || !this.assinatura?.cargo_almoxarife) {
      toastr.warning('Cadastre Assinatura de Controle Interno para prosseguir!');
      return;
    }

    if (!this.parecer) {
      toastr.warning('É necessário cadastrar parecer interno para prosseguir!');
      return;
    }

    Relatorio.imprimirPersonalizado('', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.montarConteudoParecer(this.entidade), 'portrait', this.tituloRelatorio(), this.layoutRelatorioParecer(), false, false, 'pdf');
  }

  public montarConteudoParecer(adiantamento: Adiantamento) {
    const conteudo = [];

    conteudo.push([{ text: 'PARECER', fontSize: 16, bold: true, alignment: 'center', border: [false, false, false, false] }]);
    conteudo.push([{ text: '', margin: [0, 10, 0, 10], border: [false, false, false, false] }]);
    conteudo.push([{
      text: `A ${this.login.orgao.nome} de acordo com o comunicado Nº${this.funcaoService.mascarar('####/####', this.parecer.numero_comunicado)}, Lei Municipal Nº${this.funcaoService.mascarar('####/####', this.parecer.lei)}, do Regimento de Adiantamento do Numerário, através do Departamento de Controle Interno, vem dar parecer ${adiantamento.parecer === 'F' ? 'Favorável'.toUpperCase() : adiantamento.parecer === 'D' ? 'Desfavorável'.toUpperCase() : ''}, sobre a regularidade da prestação de contas do empenho de adiantamento n° ${adiantamento.empenho?.numero}/${adiantamento.empenho?.exercicio?.ano}, já elaboradas e em nosso poder.`,
      fontSize: 12, border: [false, false, false, false]
    }]);
    conteudo.push([{ text: '', margin: [0, 10, 0, 10], border: [false, false, false, false] }]);
    conteudo.push([{ text: `${this.login.cidade.nome} - ${this.login.cidade.estado.uf}, ${this.funcaoService.formatarDataExtenso(new Date)}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }]);
    conteudo.push([{ text: '', fontSize: 12, margin: [0, 10, 0, 10], border: [false, false, false, false] }])
    conteudo.push([{ text: '_________________________________________________', fontSize: 12, alignment: 'center', border: [false, false, false, false] }]);
    conteudo.push([{ text: `${this.assinatura.almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }]);
    conteudo.push([{ text: `${this.assinatura.cargo_almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }]);

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          fontSize: 12,
          headerRows: 0,
          widths: ['*'],
          body: conteudo
        }
      }
    ];
  }

  public tituloRelatorio() {
    return 'PARECER';
  }

  public layoutRelatorioParecer() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }

}
