import { Component, Injector, OnInit } from '@angular/core';
import { LicitacaoService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, FuncaoService, GlobalService, Licitacao, LoginContabil, Modalidade, SituacaoLicitacao } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';

@Component({
  selector: 'app-licitacao-list',
  templateUrl: './licitacao-list.component.html'
})
export class LicitacaoListComponent extends BaseResourceListComponent<Licitacao, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public listaSituacoes: { id: SituacaoLicitacao | 'TODAS', nome: string }[];
  public listaModalidades: Modalidade[];

  public filtroSituacao: SituacaoLicitacao | 'TODAS';
  public filtroModalidade: Modalidade;
  public filtroAvancado: {
    numero: string, processo: string, fornecedor: string, codigoItem: number, nomeItem: string, exercicio: number
  };

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    public modalidadeService: ModalidadeService,
    private licitacaoService: LicitacaoService) {
    super(licitacaoService, injector);
    this.limite = 10;
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================
  
  protected relations(): string {
    return 'modalidade';
  }

  protected condicoesGrid(): {} {
    const param = { 'orgao.id': this.login.orgao.id };

    if (this.filtroModalidade && +this.filtroModalidade.id !== 0)
      param['modalidade_id'] = this.filtroModalidade.id;
    if (this.filtroSituacao && this.filtroSituacao !== 'TODAS')
      param['situacao'] = this.filtroSituacao;
    if (this.filtroAvancado) {
      if (this.filtroAvancado.numero)
        param['OR'] = `numero=${this.filtroAvancado.numero};!;!;numero=${this.filtroAvancado.numero.split('/').join('')}`
      if (this.filtroAvancado.processo)
        param['OR'] = `processo=${this.filtroAvancado.processo};!;!;processo=${this.filtroAvancado.processo.split('/').join('')}`
      if (this.filtroAvancado.fornecedor)
        param['proponentes.favorecido.nome$like'] = '%' + this.funcaoService.removerAcentos(this.filtroAvancado.fornecedor).split(',').join('') + '%';
      if (this.filtroAvancado.codigoItem)
        param['itens.produto_unidade.produto.codigo$like'] = '%' + String(this.filtroAvancado.codigoItem).split(',').join('') + '%';
      if (this.filtroAvancado.nomeItem)
        param['itens.produto_unidade.produto.nome$like'] = '%' + this.funcaoService.removerAcentos(String(this.filtroAvancado.nomeItem)).split(',').join('') + '%';
      if (this.filtroAvancado.exercicio)
        param['exercicio.ano'] = this.filtroAvancado.exercicio;
    }

    return param;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_abertura$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'processo', 'valor_estimado', 'valor_licitado'],
      date: ['data_abertura', 'data_edital', 'data_homologacao', 'data_julgamento', 'data_parecer', 'data_abertura_env', 'data_entrega_env'],
      text: ['numero', 'processo', 'modalidade.nome', 'situacao', 'objeto'],
    };
  }

  protected afterInit(): void {
    this.limparFiltros();
    this.filtroSituacao = 'TODAS';
    this.filtroModalidade = { id: 0, nome: 'TODAS MODALIDADES' };

    this.listaSituacoes = [];
    this.listaSituacoes.push({ id: 'TODAS', nome: 'TODAS' });
    this.listaSituacoes = this.listaSituacoes.concat(this.globalService.obterSituacoesLicitacao());

    this.listaModalidades = [this.filtroModalidade]
    this.modalidadeService.filtrar(1, -1, { 'cidade_id': this.login.cidade.id, orderBy: 'codigo' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaModalidades = this.listaModalidades.concat(res.content);
      }, error => this.funcaoService.acaoErro(error));
  }

  protected acaoRemover(model: Licitacao): Observable<Licitacao> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero', bold: true },
      { titulo: 'Processo', coluna: 'processo' },
      { titulo: 'Modalidade', coluna: 'modalidade.nome' },
      { titulo: 'Situação', coluna: 'situacao' },
      { titulo: 'Objeto', coluna: 'objeto' },
      { titulo: 'Vl. Estimado', coluna: 'valor_estimado', decimais: 2, alignment: 'right' },
      { titulo: 'Vl. Licitado', coluna: 'valor_licitado', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    this.licitacaoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE LICITAÇÕES',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem licitação', ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected podeAlterar(item: Licitacao): boolean {
    return this.login.sistema != 'controle-interno';
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  public limparFiltros() {
    this.filtroAvancado = {
      numero: '', processo: '', fornecedor: '', codigoItem: undefined, nomeItem: '',exercicio: undefined 
    };
  }

  public obterQtdFiltros(): number {
    if (!this.filtroAvancado) return 0;

    let retorno = 0;
    if (this.filtroAvancado.numero) retorno++
    if (this.filtroAvancado.processo) retorno++
    if (this.filtroAvancado.fornecedor) retorno++
    if (this.filtroAvancado.codigoItem) retorno++
    if (this.filtroAvancado.nomeItem) retorno++
    if (this.filtroAvancado.exercicio) retorno++

    return retorno;
  }

}
