import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LdoService } from '../../../../../planejamento/ldo/service/ldo.service';
import { Assinaturas } from '../../../assinaturas/assinaturas';
import { AnexoLRFService } from '../../service/anexo-lrf.service';

@Directive()
export class ResultadoPrimarioLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  public resultadoReceitaInicial = 0;
  public resultadoReceitaPrevisao = 0;
  public resultadoReceita = 0;
  public resultadoDespesaInicial = 0;
  public resultadoDespesaPrevisao = 0;
  public resultadoDespesa = 0;
  public resultadoLdo = 0;

  constructor(
    protected anexoServico: AnexoLRFService,
    protected ldoService: LdoService,
    protected mes: number,
    protected metaFiscal: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean) {
    this.globalService = new GlobalService();
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async obterResultadoPrimario(orgaos: number[]) {
    const result = await this.anexoServico.obterResultadoPrimario(this.mes, this.exercicio.id, orgaos).toPromise();

    // Obtenho o valor na LDO para imprimir no Resultado Primario
    const res = await this.ldoService.obter(
      {
        tipo: 'D10',
        ppa_id: this.login.ppa.id,
        ano: this.exercicio.ano, linha: 4, relations: 'ppa'
      }).pipe(takeUntil(this.unsubscribe)).toPromise();
    if (res)
      this.resultadoLdo = res.valor1;

    return (await (this.conteudoReceita(result[0])))
      .concat(this.conteudoDespesa(result[1]))
      .concat(this.resultadoPrimario());
  }


  // tslint:disable: max-line-length
  public async montarRelatorio(orgaos: number[], formato: FormatoExportacao = 'pdf') {

    // Obtenho o valor na LDO para imprimir no Resultado Primario
    const res = await this.ldoService.obter({ tipo: 'D10', ppa_id: this.login.ppa.id, ano: this.exercicio.ano, linha: 4, relations: 'ppa' }).pipe(takeUntil(this.unsubscribe)).toPromise();
    if (res)
      this.resultadoLdo = res.valor1;

    this.anexoServico.obterResultadoPrimario(this.mes, this.exercicio.id, orgaos)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            `DEMONSTRATIVO DO RESULTADO PRIMÁRIO`
            , this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            (await this.conteudoReceita(dados[0]))
              .concat(this.conteudoDespesa(dados[1]))
              .concat((await this.resultadoPrimario())),
            'portrait', 'DEMONSTRATIVO DO RESULTADO PRIMÁRIO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, true, false);
        } else {
          this.funcaoService.exportar(formato, this.normalizar(dados[0], dados[1]), 'DEMONSTRATIVO DO RESULTADO PRIMÁRIO', this.colunas());
        }
      });
  }

  private async conteudoReceita(dados: any[]): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: this.login.orgao.nome,
        alignment: 'center',
        bold: true,
        colSpan: 4,
        fontSize: 14,
        border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: 'DEMONSTRATIVO DO RESULTADO PRIMÁRIO',
        alignment: 'center',
        bold: true,
        colSpan: 4,
        fontSize: 14,
        border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: `REFERÊNCIA: ` +
          this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano,
        alignment: 'center',
        bold: true,
        colSpan: 4,
        fontSize: 13,
        border: [false, false, false, false]
      }, '', '', ''],
      [{
        text: 'LRF, art 53, inciso III',
        alignment: 'left',
        bold: true,
        colSpan: 3,
        border: [false, false, false, false]
      }, '', '', {
        text: 'Valores expressos em R$',
        alignment: 'right',
        fontSize: 5,
        border: [false, false, false, false]
      }],
      [{
        text: 'RECEITAS FISCAIS (B8)',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO ANUAL INICIAL',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'PREVISÃO ANUAL ATUALIZADA',
        alignment: 'center',
        bold: true, fontSize: 7
      }, {
        text: 'RECEITAS REALIZADAS ATÉ O BIMESTRE',
        alignment: 'center',
        bold: true, fontSize: 7
      }]
    ];

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, 'titulo',
      ['previsao', 'atualizado', 'arrecadado']);
    for (const grupo of grupos) {

      if (grupo.grupo === 'RECEITAS FISCAIS DE CAPITAL (VII)=(II-III-IV-V-VI)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        continue;
      }
      if (grupo.grupo === 'DEDUÇÕES DA RECEITA (VIII)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        continue;
      }
      if (grupo.grupo === 'RECEITAS FISCAIS LÍQUIDAS (IX)=(I+VII-VIII)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        this.resultadoReceitaInicial = +grupo.totalizadores['previsao'];
        this.resultadoReceitaPrevisao = +grupo.totalizadores['atualizado'];
        this.resultadoReceita = +grupo.totalizadores['arrecadado'];
        continue;
      }
      //
      registros.push([
        {
          text: grupo.grupo, bold: true, fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
      ]);

      const subGrupos = this.funcaoService.agrupar(grupo.registros, 'grupo',
        ['previsao', 'atualizado', 'arrecadado']);

      for (const sub of subGrupos) {
        registros.push([
          {
            text: sub.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(sub.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(sub.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(sub.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);

        if (sub.registros && !sub.registros[0].agrupado) {
          // lista os registros do relatorio
          for (const registro of sub.registros) {
            registros.push([
              {
                text: `${registro.nome}`, fontSize: 7, margin: [10, 0, 0, 0]
              },
              { text: this.funcaoService.convertToBrNumber(registro.previsao), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.atualizado), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.arrecadado), alignment: 'right', fontSize: 7 },
            ]);
          }
        }
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 3,
        widths: ['*', 80, 80, 80],
        body: registros
      }
    }];
  }

  private conteudoDespesa(dados: any[]): {}[] {

    // monta o cabecalho
    const registros: {}[] = [
      [{
        text: 'DESPESAS FISCAIS',
        alignment: 'center',
        fontSize: 7,
        bold: true, border: [true, false, true, true]
      }, {
        text: 'DOTAÇÃO ANUAL INICIAL',
        alignment: 'center',
        fontSize: 7,
        bold: true, border: [true, false, true, true]
      }, {
        text: 'DOTAÇÃO ANUAL ATUALIZADA',
        alignment: 'center',
        fontSize: 7,
        bold: true, border: [true, false, true, true]
      }, {
        text: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE',
        alignment: 'center',
        fontSize: 7,
        bold: true, border: [true, false, true, true]
      }]
    ];

    const grupos = this.funcaoService.agrupar(dados, 'titulo',
      ['previsao', 'atualizado', 'arrecadado']);
    for (const grupo of grupos) {
      if (grupo.grupo === 'DESP. FISCAIS CORRENTES(XII)=(X-XI)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        continue;
      }
      if (grupo.grupo === 'DESPESAS FISCAIS DE CAPITAL (XVII)=(XIII-XIV-XV-XVI)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        continue;
      }
      if (grupo.grupo === 'RESERVA DE CONTINGÊNCIA (XVIII)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        continue;
      }
      if (grupo.grupo === 'DESPESAS FISCAIS LÍQUIDAS (XIX)=(XII+XVII+XVIII)') {
        registros.push([
          {
            text: grupo.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);
        this.resultadoDespesaInicial = +grupo.totalizadores['previsao'];
        this.resultadoDespesaPrevisao = +grupo.totalizadores['atualizado'];
        this.resultadoDespesa = +grupo.totalizadores['arrecadado'];
        continue;
      }
      //
      registros.push([
        {
          text: grupo.grupo, bold: true, fontSize: 7
        },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
      ]);

      const subGrupos = this.funcaoService.agrupar(grupo.registros, 'grupo',
        ['previsao', 'atualizado', 'arrecadado']);

      for (const sub of subGrupos) {
        registros.push([
          {
            text: sub.grupo, bold: true, fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(sub.totalizadores['previsao']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(sub.totalizadores['atualizado']), alignment: 'right', bold: true, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(sub.totalizadores['arrecadado']), alignment: 'right', bold: true, fontSize: 7 },
        ]);

        if (sub.registros && !sub.registros[0].agrupado) {
          // lista os registros do relatorio
          for (const registro of sub.registros) {
            registros.push([
              {
                text: `${registro.nome}`, fontSize: 7, margin: [10, 0, 0, 0]
              },
              { text: this.funcaoService.convertToBrNumber(registro.previsao), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.atualizado), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(registro.arrecadado), alignment: 'right', fontSize: 7 },
            ]);
          }
        }
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80, 80, 80],
        body: registros
      }
    },];
  }

  private async resultadoPrimario(): Promise<{}[]> {

    // monta o cabecalho
    const registros: {}[] = [
      [
        { text: '', margin: [0, 15, 0, 0], colSpan: 4, border: [true, false, true, false] },
        { text: '', margin: [0, 15, 0, 0] },
        { text: '', margin: [0, 15, 0, 0] },
        { text: '', margin: [0, 15, 0, 0] }
      ]
    ];

    registros.push([
      {
        text: 'RESULTADO PRIMÁRIO (IX-XIX)', bold: true, fontSize: 7
      },
      { text: this.funcaoService.convertToBrNumber(+this.resultadoReceitaInicial - +this.resultadoDespesaInicial), alignment: 'right', bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(+this.resultadoReceitaPrevisao - +this.resultadoDespesaPrevisao), alignment: 'right', bold: true, fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(+this.resultadoReceita - +this.resultadoDespesa), alignment: 'right', bold: true, fontSize: 7 },
    ]);
    registros.push([
      { text: '', margin: [0, 15, 0, 0], colSpan: 4, border: [true, false, true, false] },
      { text: '', margin: [0, 15, 0, 0] },
      { text: '', margin: [0, 15, 0, 0] },
      { text: '', margin: [0, 15, 0, 0] },
    ]);

    registros.push([
      {
        text: 'DISCRIMINAÇÃO DA META FISCAL', bold: true, fontSize: 7
      },
      {
        text: 'VALOR', bold: true, fontSize: 7, colSpan: 3, alignment: 'center'
      },
      { text: '' },
      { text: '' },
    ]);
    registros.push([
      {
        text: 'META DE RESULTADO PRIMÁRIO FIXADA NO ANEXO DE METAS FISCAIS DA LDO P/ O EXERCÍCIO DE REFERÊNCIA', bold: true, fontSize: 7
      },
      {
        text: this.funcaoService.convertToBrNumber(+this.metaFiscal), bold: true, fontSize: 7, colSpan: 3, alignment: 'center'
      },
      { text: '' },
      { text: '' },
    ]);
    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(50, false, null, this.assinaturaControleInterno);
    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 80, 80, 80],
        body: registros
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }
    ];
  }

  public normalizar(lista1: any[], lista2: any[]) {
    const listaExportar = []

    let previsao = 0;
    let atualizado = 0;
    let arrecadado = 0;

    let previsaoSub = 0;
    let atualizadoSub = 0;
    let arrecadadoSub = 0;

    const gruposReceita = this.funcaoService.agrupar(lista1, 'titulo',
      ['previsao', 'atualizado', 'arrecadado']);
    for (const grupo of gruposReceita) {
      previsao = grupo.totalizadores['previsao'];
      atualizado = grupo.totalizadores['atualizado'];
      arrecadado = grupo.totalizadores['arrecadado'];

      // if (grupo.grupo === 'RECEITAS FISCAIS DE CAPITAL (VII)=(II-III-IV-V-VI)') {
      //   const receitas_fiscais = {
      //     receitas_fiscais: grupo.grupo,
      //     previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
      //     previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
      //     receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
      //   }
      //   listaExportar.push(receitas_fiscais)

      //   continue
      // }

      // if (grupo.grupo === 'DEDUÇÕES DA RECEITA (VIII)') {
      //   const deducoes_receita = {
      //     receitas_fiscais: grupo.grupo,
      //     previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
      //     previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
      //     receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
      //   }
      //   listaExportar.push(deducoes_receita)

      //   continue
      // }

      if (grupo.grupo === 'RECEITAS FISCAIS LÍQUIDAS (IX)=(I+VII-VIII)') {
        // const receitas_fiscais2 = {
        //   receitas_fiscais: grupo.grupo,
        //   previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
        //   previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
        //   receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
        // }
        // listaExportar.push(receitas_fiscais2)

        this.resultadoReceitaInicial = +grupo.totalizadores['previsao'];
        this.resultadoReceitaPrevisao = +grupo.totalizadores['atualizado'];
        this.resultadoReceita = +grupo.totalizadores['arrecadado'];

        continue
      }

      const receitas_fiscais = {
        receitas_fiscais: grupo.grupo,
        previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
        previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
        receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
      }
      listaExportar.push(receitas_fiscais)

      const subGrupos = this.funcaoService.agrupar(grupo.registros, 'grupo',
        ['previsao', 'atualizado', 'arrecadado']);

      for (const sub of subGrupos) {
        previsaoSub = sub.totalizadores['previsao'];
        atualizadoSub = sub.totalizadores['atualizado'];
        arrecadadoSub = sub.totalizadores['arrecadado'];
        if (sub.grupo !== grupo.grupo) {
          const itens_receitas_fiscais = {
            receitas_fiscais: sub.grupo,
            previsao_inicial: this.funcaoService.convertToBrNumber(previsaoSub),
            previsao_anual: this.funcaoService.convertToBrNumber(atualizadoSub),
            receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadadoSub)
          }
          listaExportar.push(itens_receitas_fiscais)
        }

        for (const item of sub.registros) {
          if (item.nome !== sub.grupo) {
            if (item.nome !== '') {
              const itens_receitas_fiscais = {
                receitas_fiscais: item.nome,
                previsao_inicial: this.funcaoService.convertToBrNumber(item.previsao),
                previsao_anual: this.funcaoService.convertToBrNumber(item.atualizado),
                receitas_bimestre: this.funcaoService.convertToBrNumber(item.arrecadado)
              }
              listaExportar.push(itens_receitas_fiscais)
            }
          }
        }
      }

    }

    const gruposDespesa = this.funcaoService.agrupar(lista2, 'titulo',
      ['previsao', 'atualizado', 'arrecadado']);

    const primeira_linha_despesa = {
      receitas_fiscais: 'DESPESAS FISCAIS',
      previsao_inicial: 'DOTAÇÃO ANUAL INICIAL',
      previsao_anual: 'DOTAÇÃO ANUAL ATUALIZADA',
      receitas_bimestre: 'DESPESAS LIQUIDADAS ATÉ O BIMESTRE'
    }
    listaExportar.push(primeira_linha_despesa)

    for (const grupo of gruposDespesa) {
      previsao = grupo.totalizadores['previsao'];
      atualizado = grupo.totalizadores['atualizado'];
      arrecadado = grupo.totalizadores['arrecadado'];

      // if (grupo.grupo === 'DESP. FISCAIS CORRENTES(XII)=(X-XI)') {
      //   const receitas_fiscais = {
      //     receitas_fiscais: grupo.grupo,
      //     previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
      //     previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
      //     receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
      //   }
      //   listaExportar.push(receitas_fiscais)

      //   continue
      // }

      // if (grupo.grupo === 'DESPESAS FISCAIS DE CAPITAL (XVII)=(XIII-XIV-XV-XVI)') {
      //   const deducoes_receita = {
      //     receitas_fiscais: grupo.grupo,
      //     previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
      //     previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
      //     receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
      //   }
      //   listaExportar.push(deducoes_receita)

      //   continue
      // }

      // if (grupo.grupo === 'RESERVA DE CONTINGÊNCIA (XVIII)') {
      //   const deducoes_receita = {
      //     receitas_fiscais: grupo.grupo,
      //     previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
      //     previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
      //     receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
      //   }
      //   listaExportar.push(deducoes_receita)

      //   continue
      // }

      if (grupo.grupo === 'DESPESAS FISCAIS LÍQUIDAS (XIX)=(XII+XVII+XVIII)') {
        // const receitas_fiscais2 = {
        //   receitas_fiscais: grupo.grupo,
        //   previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
        //   previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
        //   receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
        // }
        // listaExportar.push(receitas_fiscais2)

        this.resultadoDespesaInicial = +grupo.totalizadores['previsao'];
        this.resultadoDespesaPrevisao = +grupo.totalizadores['atualizado'];
        this.resultadoDespesa = +grupo.totalizadores['arrecadado'];

        continue
      }

      const despesas_fiscais = {
        receitas_fiscais: grupo.grupo,
        previsao_inicial: this.funcaoService.convertToBrNumber(previsao),
        previsao_anual: this.funcaoService.convertToBrNumber(atualizado),
        receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadado)
      }
      listaExportar.push(despesas_fiscais)

      const subGrupos = this.funcaoService.agrupar(grupo.registros, 'grupo',
        ['previsao', 'atualizado', 'arrecadado']);

      for (const sub of subGrupos) {
        previsaoSub = sub.totalizadores['previsao'];
        atualizadoSub = sub.totalizadores['atualizado'];
        arrecadadoSub = sub.totalizadores['arrecadado'];
        if (sub.grupo !== grupo.grupo) {
          const itens_despesas_fiscais = {
            receitas_fiscais: sub.grupo,
            previsao_inicial: this.funcaoService.convertToBrNumber(previsaoSub),
            previsao_anual: this.funcaoService.convertToBrNumber(atualizadoSub),
            receitas_bimestre: this.funcaoService.convertToBrNumber(arrecadadoSub)
          }
          listaExportar.push(itens_despesas_fiscais)
        }

        for (const item of sub.registros) {
          if (item.nome !== sub.grupo) {
            if (item.nome !== '') {
              const itens__sub_despesas_fiscais = {
                receitas_fiscais: item.nome,
                previsao_inicial: this.funcaoService.convertToBrNumber(item.previsao),
                previsao_anual: this.funcaoService.convertToBrNumber(item.atualizado),
                receitas_bimestre: this.funcaoService.convertToBrNumber(item.arrecadado)
              }
              listaExportar.push(itens__sub_despesas_fiscais)
            }
          }
        }
      }

    }

    const espaco = {
      receitas_fiscais: '',
      previsao_inicial: '',
      previsao_anual: '',
      receitas_bimestre: ''
    }
    listaExportar.push(espaco)

    const resiltado_primario = {
      receitas_fiscais: 'RESULTADO PRIMÁRIO (IX-XIX)',
      previsao_inicial: this.funcaoService.convertToBrNumber(+this.resultadoReceitaInicial - +this.resultadoDespesaInicial),
      previsao_anual: this.funcaoService.convertToBrNumber(+this.resultadoReceitaPrevisao - +this.resultadoDespesaPrevisao),
      receitas_bimestre: this.funcaoService.convertToBrNumber(+this.resultadoReceita - +this.resultadoDespesa)
    }
    listaExportar.push(resiltado_primario)

    listaExportar.push(espaco)

    const discriminacao_fiscal = {
      receitas_fiscais: 'DISCRIMINAÇÃO DA META FISCAL',
      previsao_inicial: '',
      previsao_anual: '',
      receitas_bimestre: 'VALOR'
    }
    listaExportar.push(discriminacao_fiscal)

    const meta_de_resultado = {
      receitas_fiscais: 'META DE RESULTADO PRIMÁRIO FIXADA NO ANEXO DE METAS FISCAIS DA LDO P/ O EXERCÍCIO DE REFERÊNCIA',
      previsao_inicial: '',
      previsao_anual: '',
      receitas_bimestre: this.funcaoService.convertToBrNumber(+this.metaFiscal)
    }
    listaExportar.push(meta_de_resultado)


    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'RECEITAS FISCAIS (B8)', coluna: 'receitas_fiscais', bold: true },
      { titulo: 'PREVISÃO ANUAL INICIAL', coluna: 'previsao_inicial' },
      { titulo: 'PREVISÃO ANUAL ATUALIZADA', coluna: 'previsao_anual' },
      { titulo: 'RECEITAS REALIZADAS ATÉ O BIMESTRE', coluna: 'receitas_bimestre' },
    ];
    return colunasDefault;
  }

}
