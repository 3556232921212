import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseResourceFormDlgComponent, FuncaoService, GlobalService, LoginContabil, ModeloRelatorio } from 'eddydata-lib';
import { ModeloRelatorioService } from '../service/modelo-relatorio.service';
import { TagsModeloRelatorio } from '../tags-modelo-relatorio.enum';

declare var $: any;

@Component({
  selector: 'lib-modelo-relatorio-dlg',
  templateUrl: './modelo-relatorio-dlg.component.html'
})
export class ModeloRelatorioDlgComponent extends BaseResourceFormDlgComponent<ModeloRelatorio, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('modelo_') campoModelo: ElementRef;

  public tags: { tag: string, descricao: string }[];
  public tipos: { id: 'LICITACAO' | 'CONTRATO' | 'ADITAMENTO', nome: string }[];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected modeloService: ModeloRelatorioService,
  ) {
    super(injector, ModeloRelatorio.converteJson, modeloService, "/modelos-relatorio");
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      fixo: [false, [Validators.required]],
      tipo: ['LICITACAO', [Validators.required]],
      modelo: [null, [Validators.required]],
      usuario: [this.login.usuario, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'usuario,orgao' };
  }

  protected afterLoad() {
    setTimeout(() => this.campoModelo ? this.campoModelo.nativeElement.focus() : null, 500);
  }

  protected afterInit(): void {
    this.carregarTags();
    this.tipos = [
      { id: 'LICITACAO', nome: 'Licitação' },
      { id: 'CONTRATO', nome: 'Contratos' },
      { id: 'ADITAMENTO', nome: 'Aditivos' },
    ];
  }

  protected campoFoco(): ElementRef {
    return this.campoModelo;
  }

  protected beforeSubmit() {

  }

  protected afterSubmit(modelo: ModeloRelatorio) {
    this.sair();
  }

  public sair(): void {
    $('#dlgModeloRelatorioCad').modal('hide');
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  public carregarTags() {
    this.tags = [
      { tag: 'COMISSAO_INICIO', descricao: TagsModeloRelatorio.COMISSAO_INICIO },
      { tag: 'COMISSAO_MEMBROS', descricao: TagsModeloRelatorio.COMISSAO_MEMBROS },
      { tag: 'COMISSAO_NOME', descricao: TagsModeloRelatorio.COMISSAO_NOME },
      { tag: 'COMISSAO_PORTARIA', descricao: TagsModeloRelatorio.COMISSAO_PORTARIA },
      { tag: 'COMISSAO_PREGOEIRO', descricao: TagsModeloRelatorio.COMISSAO_PREGOEIRO },
      { tag: 'COMISSAO_PRESIDENTE', descricao: TagsModeloRelatorio.COMISSAO_PRESIDENTE },
      { tag: 'COMISSAO_PUBLICACAO', descricao: TagsModeloRelatorio.COMISSAO_PUBLICACAO },
      { tag: 'COMISSAO_SECRETARIO', descricao: TagsModeloRelatorio.COMISSAO_SECRETARIO },
      { tag: 'COMISSAO_TERMINO', descricao: TagsModeloRelatorio.COMISSAO_TERMINO },
      { tag: 'DATA_ABERTURA', descricao: TagsModeloRelatorio.DATA_ABERTURA },
      { tag: 'DATA_ASSINATURA', descricao: TagsModeloRelatorio.DATA_ASSINATURA },
      { tag: 'DATA_EDITAL', descricao: TagsModeloRelatorio.DATA_EDITAL },
      { tag: 'DATA_HOMOLOGACAO', descricao: TagsModeloRelatorio.DATA_HOMOLOGACAO },
      { tag: 'DATA_INICIO', descricao: TagsModeloRelatorio.DATA_INICIO },
      { tag: 'DATA_JULGAMENTO', descricao: TagsModeloRelatorio.DATA_JULGAMENTO },
      { tag: 'DATA_PARECER', descricao: TagsModeloRelatorio.DATA_PARECER },
      { tag: 'DATA_PUBLICACAO', descricao: TagsModeloRelatorio.DATA_PUBLICACAO },
      { tag: 'DATA_TERMINO', descricao: TagsModeloRelatorio.DATA_TERMINO },
      { tag: 'INICIO_PROPOSTA', descricao: TagsModeloRelatorio.INICIO_PROPOSTA },
      { tag: 'TERMINO_PROPOSTA', descricao: TagsModeloRelatorio.TERMINO_PROPOSTA },
      { tag: 'DATA_ESCLARECIMENTO', descricao: TagsModeloRelatorio.DATA_ESCLARECIMENTO },
      { tag: 'JUSTIFICATIVA', descricao: TagsModeloRelatorio.JUSTIFICATIVA },
      { tag: 'MODALIDADE', descricao: TagsModeloRelatorio.MODALIDADE },
      { tag: 'NUMERO', descricao: TagsModeloRelatorio.NUMERO },
      { tag: 'OBJETO', descricao: TagsModeloRelatorio.OBJETO },
      { tag: 'PRAZO_ENTREGA', descricao: TagsModeloRelatorio.PRAZO_ENTREGA },
      { tag: 'PRAZO_PAGAMENTO', descricao: TagsModeloRelatorio.PRAZO_PAGAMENTO },
      { tag: 'PROCESSO', descricao: TagsModeloRelatorio.PROCESSO },
      { tag: 'SETOR_NOME', descricao: TagsModeloRelatorio.SETOR_NOME },
      { tag: 'TIPO_CONTRATACAO', descricao: TagsModeloRelatorio.TIPO_CONTRATACAO },
      { tag: 'AMPARO_LEGAL', descricao: TagsModeloRelatorio.AMPARO_LEGAL },
      { tag: 'VALOR_ESTIMADO', descricao: TagsModeloRelatorio.VALOR_ESTIMADO },
      { tag: 'VALOR_LICITACAO', descricao: TagsModeloRelatorio.VALOR_LICITACAO },
      { tag: 'NOME_VENCEDOR_LICITACAO', descricao: TagsModeloRelatorio.NOME_VENCEDOR_LICITACAO },
      { tag: 'CPF_VENCEDOR_LICITACAO', descricao: TagsModeloRelatorio.CPF_VENCEDOR_LICITACAO },
    ]

    if (this.entidadeForm.get('tipo').value === 'LICITACAO') {
      this.tags.unshift(
        { tag: 'FISCAL', descricao: TagsModeloRelatorio.FISCAL },
        { tag: 'CPF_FISCAL', descricao: TagsModeloRelatorio.CPF_FISCAL },
        { tag: 'TESTEMUNHA', descricao: TagsModeloRelatorio.TESTEMUNHA },
        { tag: 'CPF_TESTEMUNHA', descricao: TagsModeloRelatorio.CPF_TESTEMUNHA },
        { tag: 'FICHA_DESPESA', descricao: TagsModeloRelatorio.FICHA_DESPESA },
      );
    }

    if (this.entidadeForm.get('tipo').value === 'CONTRATO') {
      this.tags.unshift(
        { tag: 'DATA_ASSINATURA_CONTRATO', descricao: TagsModeloRelatorio.DATA_ASSINATURA_CONTRATO },
        { tag: 'DATA_INICIO_CONTRATO', descricao: TagsModeloRelatorio.DATA_INICIO_CONTRATO },
        { tag: 'DATA_TERMINO_CONTRATO', descricao: TagsModeloRelatorio.DATA_TERMINO_CONTRATO },
        { tag: 'FORNECEDOR_CPF', descricao: TagsModeloRelatorio.FORNECEDOR_CPF },
        { tag: 'FORNECEDOR_NOME', descricao: TagsModeloRelatorio.FORNECEDOR_NOME },
        { tag: 'NUMERO_CONTRATO', descricao: TagsModeloRelatorio.NUMERO_CONTRATO },
        { tag: 'OBJETO_CONTRATO', descricao: TagsModeloRelatorio.OBJETO_CONTRATO },
        { tag: 'PRAZO_CONTRATO', descricao: TagsModeloRelatorio.PRAZO_CONTRATO },
        { tag: 'VALOR_CAUCAO', descricao: TagsModeloRelatorio.VALOR_CAUCAO },
        { tag: 'VALOR_CONTRATO', descricao: TagsModeloRelatorio.VALOR_CONTRATO },
        { tag: 'VALOR_GARANTIA', descricao: TagsModeloRelatorio.VALOR_GARANTIA },
        { tag: 'UNIAO_NOME', descricao: TagsModeloRelatorio.UNIAO_NOME },
        { tag: 'UNIAO_CNPJ', descricao: TagsModeloRelatorio.UNIAO_CNPJ },
        { tag: 'UNIAO_ENDERECO', descricao: TagsModeloRelatorio.UNIAO_ENDERECO },
        { tag: 'UNIAO_CIDADE', descricao: TagsModeloRelatorio.UNIAO_CIDADE },
        { tag: 'UNIAO_ESTADO', descricao: TagsModeloRelatorio.UNIAO_ESTADO },
        { tag: 'PREFEITO_NOME', descricao: TagsModeloRelatorio.PREFEITO_NOME },
        { tag: 'PREFEITO_CPF', descricao: TagsModeloRelatorio.PREFEITO_CPF },
        { tag: 'FORNECEDOR_ENDERECO', descricao: TagsModeloRelatorio.FORNECEDOR_ENDERECO },
        { tag: 'FORNECEDOR_NUMERO_ENDERECO', descricao: TagsModeloRelatorio.FORNECEDOR_NUMERO_ENDERECO},
        { tag: 'FORNECEDOR_COMPLEMENTO_ENDERECO', descricao: TagsModeloRelatorio.FORNECEDOR_COMPLEMENTO_ENDERECO},
        { tag: 'FORNECEDOR_BAIRRO', descricao: TagsModeloRelatorio.FORNECEDOR_BAIRRO},
        { tag: 'FORNECEDOR_CEP', descricao: TagsModeloRelatorio.FORNECEDOR_CEP},
        { tag: 'FORNECEDOR_CIDADE_UF', descricao: TagsModeloRelatorio.FORNECEDOR_CIDADE_UF },
        { tag: 'FORNECEDOR_CIDADE', descricao: TagsModeloRelatorio.FORNECEDOR_CIDADE },
        { tag: 'FORNECEDOR_ESTADO', descricao: TagsModeloRelatorio.FORNECEDOR_ESTADO },
        { tag: 'FORNECEDOR_REPRESENTANTE', descricao: TagsModeloRelatorio.FORNECEDOR_REPRESENTANTE },
        { tag: 'FORNECEDOR_REPRESENTANTE_FUNCAO', descricao: TagsModeloRelatorio.FORNECEDOR_REPRESENTANTE_FUNCAO },
        { tag: 'FORNECEDOR_TELEFONE', descricao: TagsModeloRelatorio.FORNECEDOR_TELEFONE },
        { tag: 'FORNECEDOR_EMAIL', descricao: TagsModeloRelatorio.FORNECEDOR_EMAIL },
        { tag: 'MATRICULA_COMISSAO', descricao: TagsModeloRelatorio.MATRICULA_COMISSAO },
        { tag: 'SECRETARIA_NOME', descricao: TagsModeloRelatorio.SECRETARIA_NOME },
        { tag: 'SECRETARIO_NOME', descricao: TagsModeloRelatorio.SECRETARIO_NOME },
        { tag: 'SECRETARIO_CPF', descricao: TagsModeloRelatorio.SECRETARIO_CPF },
        { tag: 'SECRETARIO_EMAIL', descricao: TagsModeloRelatorio.SECRETARIO_EMAIL },
        { tag: 'VALOR_MENSAL', descricao: TagsModeloRelatorio.VALOR_MENSAL },
        { tag: 'INDICE_REAJUSTE', descricao: TagsModeloRelatorio.INDICE_REAJUSTE },
        { tag: 'PRAZO_PRORROGACAO', descricao: TagsModeloRelatorio.PRAZO_PRORROGACAO },
        { tag: 'PRAZO_RESPOSTA', descricao: TagsModeloRelatorio.PRAZO_RESPOSTA },
        { tag: 'PRAZO_TERMINO', descricao: TagsModeloRelatorio.PRAZO_TERMINO },
        { tag: 'TIPO_CONTRATO', descricao: TagsModeloRelatorio.TIPO_CONTRATO },
        { tag: 'MULTA_MORATORIA', descricao: TagsModeloRelatorio.MULTA_MORATORIA },
        { tag: 'DIAS_VIGENCIA_CONTRATO', descricao: TagsModeloRelatorio.DIAS_VIGENCIA_CONTRATO },
        { tag: 'LOCAL_DATA', descricao: TagsModeloRelatorio.LOCAL_DATA },
        { tag: 'FISCAL_CONTRATO', descricao: TagsModeloRelatorio.FISCAL },
        { tag: 'CPF_FISCAL_CONTRATO', descricao: TagsModeloRelatorio.CPF_FISCAL },
        { tag: 'TESTEMUNHA_CONTRATO', descricao: TagsModeloRelatorio.TESTEMUNHA },
        { tag: 'CPF_TESTEMUNHA_CONTRATO', descricao: TagsModeloRelatorio.CPF_TESTEMUNHA },
        { tag: 'RESPONSAVEL_CONTRATADO_CONTRATO', descricao: TagsModeloRelatorio.RESPONSAVEL_CONTRATADO },
        { tag: 'CPF_CONTRATADO_CONTRATO', descricao: TagsModeloRelatorio.CPF_CONTRATADO },
        { tag: 'E-MAIL_CONTRATADO_CONTRATO', descricao: TagsModeloRelatorio.EMAIL_CONTRATADO },
        { tag: 'TABELA_ITENS_FORNECEDOR', descricao: 'Tabela de itens do fornecedor' },
        { tag: 'RESPONSAVEL_CONTRATANTE_CONTRATO', descricao: TagsModeloRelatorio.RESPONSAVEL_CONTRATANTE },
        { tag: 'CPF_RESPONSAVEL_CONTRATANTE_CONTRATO', descricao: TagsModeloRelatorio.CPF_RESPONSAVEL_CONTRATANTE },
      );

      this.tags = this.tags.sort((a, b) => { return a.tag.localeCompare(b.tag) })
    }

    if (this.entidadeForm.get('tipo').value === 'ADITAMENTO') {
      this.tags.unshift(
        { tag: 'ADITIVO_DATA_ASSINATURA', descricao: TagsModeloRelatorio.ADITIVO_DATA_ASSINATURA },
        { tag: 'ADITIVO_DATA_INICIO', descricao: TagsModeloRelatorio.ADITIVO_DATA_INICIO },
        { tag: 'ADITIVO_DATA_TERMINO', descricao: TagsModeloRelatorio.ADITIVO_DATA_TERMINO },
        { tag: 'ADITIVO_NUMERO', descricao: TagsModeloRelatorio.ADITIVO_NUMERO },
        { tag: 'ADITIVO_TIPO', descricao: TagsModeloRelatorio.ADITIVO_TIPO },
        { tag: 'ADITIVO_FINALIDADE', descricao: TagsModeloRelatorio.ADITIVO_FINALIDADE },
        { tag: 'ADITIVO_VALOR_TOTAL', descricao: TagsModeloRelatorio.ADITIVO_VALOR_TOTAL },
        { tag: 'ADITIVO_RESPONSAVEL_CONTRATADO', descricao: TagsModeloRelatorio.RESPONSAVEL_CONTRATADO },
        { tag: 'ADITIVO_CPF_CONTRATADO', descricao: TagsModeloRelatorio.CPF_CONTRATADO },
        { tag: 'ADITIVO_E-MAIL_CONTRATADO', descricao: TagsModeloRelatorio.EMAIL_CONTRATADO },
        { tag: 'ADITIVO_VALOR_CONTRATO', descricao: TagsModeloRelatorio.VALOR_CONTRATO },
        { tag: 'ADITIVO_RESPONSAVEL_CONTRATANTE', descricao: TagsModeloRelatorio.RESPONSAVEL_CONTRATANTE },
        { tag: 'ADITIVO_CPF_RESPONSAVEL_CONTRATANTE', descricao: TagsModeloRelatorio.CPF_RESPONSAVEL_CONTRATANTE },
      )

      this.tags = this.tags.sort((a, b) => { return a.tag.localeCompare(b.tag) })
    }
  }

  public incluirTag(tag: string) {
    if (!tag) return;
    let modelo: string = this.entidadeForm.get('modelo').value;
    if (!modelo) modelo = '';

    const pos: number = this.campoModelo.nativeElement.selectionStart;
    modelo = `${modelo.substring(0, pos)} <${tag}> ${modelo.substring(pos)}`

    this.entidadeForm.get('modelo').setValue(modelo);
    this.campoModelo.nativeElement.selectionEnd = pos + tag.length + 3;
    setTimeout(() => this.campoModelo ? this.campoModelo.nativeElement.focus() : null, 500);
  }
}
