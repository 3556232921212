import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { tsXLXS } from 'ts-xlsx-export';
import {
  BaseResourceListComponent,
  Exercicio, ExercicioService, Filtro, Coluna, LoginPublico, DespesaPrevista, FichaDespesa, TransparenciaParametro,
  FuncaoService
} from 'eddydata-lib';
import { DespesaPrevistaService } from 'administrativo-lib';
import { FichaDespesaService } from 'contabil-lib';
import { TransparenciaParametroService } from '../transparencia-parametro/service/transparencia-parametro.service';

@Component({
  selector: 'app-transparencia-despesa-prevista',
  templateUrl: './transparencia-despesa-prevista.component.html'
})
export class TransparenciaDespesaPrevistaComponent extends BaseResourceListComponent<DespesaPrevista, LoginPublico> {

  /**
   * Declaração de variáveis
   */
  public tipo: string;
  public programacaoId: number;
  public listaPlanilha: Array<any>;
  public parametro: TransparenciaParametro;

  public listaPrevisao: Array<DespesaPrevista>;
  public ficha: FichaDespesa;
  public exercicio: Exercicio;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    private fichaService: FichaDespesaService,
    private exercicioService: ExercicioService,
    protected parametroService: TransparenciaParametroService,
    private previstoService: DespesaPrevistaService) {
    super(previstoService, injector);
  }

  public preencherGrid() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.exercicioService.obterPorAno(params['ano'], this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.exercicio = res.content[0];
              this.fichaService.planilhaPrevisao(this.paginaCorrente,
                this.limite, this.exercicio.id, this.login.orgao.id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((lista) => {
                  this.listaPlanilha = lista.content;
                  this.paginaTotal = lista.totalPages;
                });
            }
          );
      });
  }


  protected relations(): string {
    return 'despesa';
  }

  protected condicoesGrid(): {} {
    return { ['exercicio.id']: this.login.exercicio.id };
  }

  protected ordenacaoGrid(): string[] {
    return ['fh_numero$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return;
  }

  protected afterInit(): void {
    this.parametroService.obter({ orgao_id: this.login.orgao.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.parametro = res;
        });
  }

  protected acaoRemover(model: DespesaPrevista): Observable<DespesaPrevista> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'r_codigo' },
      { titulo: 'Descrição', coluna: 'r_nome' },
      { titulo: 'Janeiro', coluna: 'previsto_jan', decimais: 2, alignment: 'right' },
      { titulo: 'Fevereiro', coluna: 'previsto_fev', decimais: 2, alignment: 'right' },
      { titulo: 'Março', coluna: 'previsto_mar', decimais: 2, alignment: 'right' },
      { titulo: 'Abril', coluna: 'previsto_abr', decimais: 2, alignment: 'right' },
      { titulo: 'Maio', coluna: 'previsto_mai', decimais: 2, alignment: 'right' },
      { titulo: 'Junho', coluna: 'previsto_jun', decimais: 2, alignment: 'right' },
      { titulo: 'Julho', coluna: 'previsto_jul', decimais: 2, alignment: 'right' },
      { titulo: 'Agosto', coluna: 'previsto_ago', decimais: 2, alignment: 'right' },
      { titulo: 'Setembro', coluna: 'previsto_set', decimais: 2, alignment: 'right' },
      { titulo: 'Outubro', coluna: 'previsto_out', decimais: 2, alignment: 'right' },
      { titulo: 'Novembro', coluna: 'previsto_nov', decimais: 2, alignment: 'right' },
      { titulo: 'Dezembro', coluna: 'previsto_dez', decimais: 2, alignment: 'right' }
    ];
  }

  public imprimirPDF() {
    this.fichaService
      .planilhaPrevisao(1, 9999,
        this.login.exercicio.id, this.login.orgao.id
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.imprimir('CRONOGRAMA DESEMBOLSO MENSAL',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
            'Listagem previsao despesa', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto',
            'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content, ['previsto_jan', 'previsto_fev', 'previsto_mar', 'previsto_abr', 'previsto_mai', 'previsto_jun',
            'previsto_jul', 'previsto_ago', 'previsto_set', 'previsto_out', 'previsto_nov', 'previsto_dez']);
        });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public exportarXLSX() {
    let total_janeiro = 0;
    let total_fevereiro = 0;
    let total_marco = 0;
    let total_abril = 0;
    let total_maio = 0;
    let total_junho = 0;
    let total_julho = 0;
    let total_agosto = 0;
    let total_setembro = 0;
    let total_outubro = 0;
    let total_novembro = 0;
    let total_dezembro = 0;

    this.fichaService
      .planilhaPrevisao(1, 9999,
        this.login.exercicio.id, this.login.orgao.id
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {

            const entity = {
              codigo: item.r_codigo,
              descricao: item.r_nome,
              janeiro: item.previsto_jan,
              fevereiro: item.previsto_fev,
              marco: item.previsto_mar,
              abril: item.previsto_abr,
              maio: item.previsto_mai,
              junho: item.previsto_jun,
              julho: item.previsto_jul,
              agosto: item.previsto_ago,
              setembro: item.previsto_set,
              outubro: item.previsto_out,
              novembro: item.previsto_nov,
              dezembro: item.previsto_dez
            };
            listaItens.push(entity);

            total_janeiro += +item.previsto_jan;
            total_fevereiro += +item.previsto_fev;
            total_marco += +item.previsto_mar;
            total_abril += +item.previsto_abr;
            total_maio += +item.previsto_mai;
            total_junho += +item.previsto_jun;
            total_julho += +item.previsto_jul;
            total_agosto += +item.previsto_ago;
            total_setembro += +item.previsto_set;
            total_outubro += +item.previsto_out;
            total_novembro += +item.previsto_nov;
            total_dezembro += +item.previsto_dez;
          }

          const total = {
            codigo: 'Total',
            descricao: '',
            janeiro: this.funcaoService.formatarMoedaPtBr(total_janeiro),
            fevereiro: this.funcaoService.formatarMoedaPtBr(total_fevereiro),
            marco: this.funcaoService.formatarMoedaPtBr(total_marco),
            abril: this.funcaoService.formatarMoedaPtBr(total_abril),
            maio: this.funcaoService.formatarMoedaPtBr(total_maio),
            junho: this.funcaoService.formatarMoedaPtBr(total_junho),
            julho: this.funcaoService.formatarMoedaPtBr(total_julho),
            agosto: this.funcaoService.formatarMoedaPtBr(total_agosto),
            setembro: this.funcaoService.formatarMoedaPtBr(total_setembro),
            outubro: this.funcaoService.formatarMoedaPtBr(total_outubro),
            novembro: this.funcaoService.formatarMoedaPtBr(total_novembro),
            dezembro: this.funcaoService.formatarMoedaPtBr(total_dezembro)
          };
          listaItens.push(total);

          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('planilha-previsao-despesa');
        });
  }

}
