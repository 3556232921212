import { Observable, Subject } from "rxjs";
import { NotaExplicativaService } from "./service/nota-explicativa.service";
import { FuncaoService, Orgao, OrgaoAssinaturaService } from "eddydata-lib";

export abstract class BaseResourceNotaExplicativa {

  protected unsubscribe: Subject<void> = new Subject();
  protected funcaoService: FuncaoService;

  constructor(
    protected tipo_relatorio: string,
    protected notaExplicativaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    this.funcaoService = new FuncaoService();
  }

  protected async conteudoNotaExplicativa(): Promise<{}[]> {
    const registros: {}[] = [];
    const imagens = [];

    const nota = await this.notaExplicativaService.obter({
      tipo_relatorio: this.tipo_relatorio,
      relations: 'arquivos'
    }).toPromise();
    if (nota) {
      if (nota.nota_explicativa.length > 0) {
        registros.push([
          {
            text: 'NOTA EXPLICATIVA:',
            bold: true,
            fontSize: 7,
            border: [false],
            margin: [0, 15, 0, 0]
          }
        ], [
          {
            text: `${nota.nota_explicativa}`,
            alignment: 'justify',
            fontSize: 7,
            border: [false]
          }
        ]);
      }

      if (nota.arquivos.length > 0) {
        for (const item of nota.arquivos) {
          if (item.base64) {
            if (this.tipo_relatorio === 'B121' || this.tipo_relatorio === 'B122' || this.tipo_relatorio === 'B14B') {
              imagens.push([
                {
                  image: item.base64,
                  width: 200,
                  margin: [0, 0, 0, -15],
                  border: [false, false, false, false]
                }
              ])
            } else {
              imagens.push([
                {
                  image: item.base64,
                  width: 200,
                  // margin: [0, 0, 0, -15],
                  border: [false, false, false, false]
                }
              ])
            }
          }
        }
      }
    }

    if (registros.length !== 0) {
      const result = [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: registros
        }
      }];

      if (imagens.length > 0) {
        result.push({
          layout: 'noBorders',
          table: {
            dontBreakRows: true,
            headerRows: 0,
            widths: ['*', '5%', '*', '5%', '*'],
            body: imagens.reduce((rows, key, index) => {
              if (index % 3 === 0) rows.push([{ text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }]);
              rows[rows.length - 1][(index % 3) * 2] = key;
              return rows;
            }, [])
          }
        });
      }
      return result;
    }
    return [];
  }

  protected async conteudoAssinatura(orgao: Orgao, orgaosNome?: string[], margem?: number, dataLimite?: string): Promise<{}[]> {
    const margemTop = margem ? margem : 60;
    const parametros = {
      'orgao_id': orgao.id,
      'data_limite$ge': dataLimite ?? this.funcaoService.converteDataSQL(new Date()),
      'orderBy': 'data_limite'
    }
    const assinatura = await this.assinaturaService.obter(parametros).toPromise();

    if (!assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    const conteudo = [
      [
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, margemTop, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, margemTop, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, margemTop, 0, 0]
        }
      ], [
        { text: assinatura?.assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: assinatura?.assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: assinatura?.assinatura3, alignment: 'center', border: [false, false, false, false] }
      ], [
        { text: assinatura?.cargo_assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: assinatura?.cargo_assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: assinatura?.cargo_assinatura3, alignment: 'center', border: [false, false, false, false] }
      ],
    ];

    return [{
      layout: 'linhas',
      // pageBreak: 'before',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }, { text: '\n\n' }, { text: 'Entidade\n', fontSize: 12 }, { text: orgaosNome.join('\n'), fontSize: 7 }];
  }

}