import * as extenso from 'extenso';
import { FuncaoService, Login, Relatorio, GlobalService, Empenho, OrgaoAssinaturaService, LicitacaoPipe, EmpenhoResto } from 'eddydata-lib';
import JsBarcode from 'jsbarcode';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from "pdfmake/interfaces";

export class EtiquetaEmpenhoResto {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;


  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
  }



  private retornaLayout() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        },
      }
    };
  }


  public async imprimir(lista: EmpenhoResto[], login: Login, modelo: String) {
    this.log = login;
    if (modelo == 'mod1') {
      await Relatorio.imprimirPersonalizado('ETIQUETA DE EMPENHO', login.usuario.nome, login.usuario.sobrenome,
        login.orgao.nome, login.brasao, this.montarConteudo(lista, modelo), 'portrait', 'ETIQUETA EMPENHO', this.retornaLayout(), true, true)
    } else if (modelo == 'mod2') {
      const dd = {
        info: {
          title: 'ETIQUETA DE EMPENHO'
        },
        pageSize: {
          width: 700,
          height: 500,
        },
        content: [],
        pageMargins: <any>[40, 0, 40, 0],
        pageOrientation: "landscape" as PageOrientation
      };

      dd.content = this.montarConteudo(lista, modelo);

      pdfMake.createPdf(dd, null, null, pdfFonts.pdfMake.vfs).open();
    } else {
      const dd = {
        info: {
          title: 'ETIQUETA DE EMPENHO'
        },
        pageSize: {
          width: 700,
          height: 350,
        },
        content: [],
        pageMargins: <any>[40, 0, 40, 0],
        pageOrientation: "landscape" as PageOrientation
      };

      dd.content = this.montarConteudo(lista, modelo);

      pdfMake.createPdf(dd, null, null, pdfFonts.pdfMake.vfs).open();
    }
  }

  private montarConteudo(lista: EmpenhoResto[], modelo: String) {
    const conteudo = [];
    if (modelo == 'mod1') {
      for (let i = 0; i < lista.length; i = i + 2) {
        conteudo.push(this.dadosEmpenho(lista[i], lista[i + 1] ? lista[i + 1] : new EmpenhoResto()));

      }
    } else if (modelo == 'mod2') {
      for (let i = 0; i < lista.length; i++) {
        conteudo.push(this.dadosEmpenhoModelo2(lista[i]));

      }

    } else {
      for (let i = 0; i < lista.length; i++) {
        conteudo.push(this.dadosEmpenhoModelo3(lista[i]));

      }
    }

    return conteudo;
  }

  private dadosEmpenhoModelo2(dados: EmpenhoResto): {}[] {
    const body: {}[] = [];




    body.push([{
      text: `EMPENHO: ${dados.numero}`, alignment: 'left',
      margin: [0, 0, 0, 0],
      border: [false, false, false, false],
      fontSize: 10,
      bold: true
    }, {
      text: `FICHA: ${dados['ficha_empenho']}`, alignment: 'right',
      margin: [0, 0, 0, 0],
      border: [false, false, false, false],
      fontSize: 10,
    }]);
    body.push([
      {
        text: dados.favorecido.nome, alignment: 'left',
        border: [false, true, false, false],
        margin: [0, 10, 0, 10],
        fontSize: 15,
        bold: true,
        colspan: 2
      },
      { text: '', border: [false, true, false, false], }
    ]);
    body.push([{
      text: `${dados.executora ? dados.executora + '-' : ''} ${dados.unidade_executora}`, alignment: 'left', colSpan: 2,
      margin: [0, 10, 0, 10],
      border: [false, false, false, false],
      fontSize: 14,
    }, { text: '' }]);
    body.push([{
      text: `${dados.recurso_variavel} - ${dados.recurso_variavel_nome}`, alignment: 'left', colSpan: 2,
      margin: [0, 10, 0, 10],
      border: [false, false, false, true],
      fontSize: 14,
    }, { text: '' }]);
    body.push([{
      text: `DATA EMPENHO: ${this.funcaoService.converteDataBR(dados.data_empenho)}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      border: [false, true, true, true],
    }, {
      text: `VENCIMENTO: ${this.funcaoService.converteDataBR(dados.data_vencimento)}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      bold: true,
      border: [false, true, true, true],
    }]);
    body.push([{
      text: `VL PRINCIPAL: ${this.funcaoService.convertToBrNumber(+dados.valor_empenho)}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      border: [false, false, true, false],
    }, {
      text: `IRRF ${this.funcaoService.convertToBrNumber(+dados['valor_irrf'])}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      border: [false, false, false, false],
    }]);
    body.push([{
      text: `ESTORNO: ${this.funcaoService.convertToBrNumber(+dados['total_empenho_anulado'])}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      border: [false, false, true, false],
    }, {
      text: `INSS ${this.funcaoService.convertToBrNumber(+dados['valor_inss'])}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      border: [false, false, false, false],
    }]);
    body.push([{
      text: 'COMPLEMENTO: 0,00', alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      border: [false, false, true, false],
    }, {
      text: `OUTROS ${this.funcaoService.convertToBrNumber(+dados['valor_ret_outros'])}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      border: [false, false, false, false],
    }]);
    body.push([{
      text: `TOTAL BRUTO: ${this.funcaoService.convertToBrNumber(+(+dados.valor_empenho + +dados['total_empenho_anulado']))}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      bold: true,
      border: [false, false, true, true],
    }, {
      text: `LIQ. RECEBER ${this.funcaoService.convertToBrNumber(+(+dados.valor_empenho + +dados['total_empenho_anulado']) - +(+dados['valor_iss'] + +dados['valor_ret_outros'] + +dados['valor_inss'] + +dados['valor_irrf']))}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 14,
      bold: true,
      border: [false, false, false, true],
    }
    ]);
    body.push([{
      text: 'OBSERVAÇÃO', alignment: 'center',
      margin: [0, 10, 0, 10],
      fontSize: 10,
      width: '100%',
      colspan: 2,
      border: [false, false, false, false],
    }, { text: '', border: [false, false, false, false], alignment: 'center', }
    ]);

    return [{
      table: {
        body: body,
        widths: ['*', '*'],
        margin: [50, 50, 50, 50],
        layout: this.retornaLayout(),
      },
      pageBreak: 'after'
    }];
  }

  private dadosEmpenhoModelo3(dados: EmpenhoResto): {}[] {
    const body: {}[] = [];



    body.push([
      {
        text: ``,
        border: [false, false, false, false],
      },
      {
        text: ``,
        border: [false, false, false, false],
      }
    ]);
    body.push([{
      columns: [{
        text: `NE Nº.: ${dados.numero}`, alignment: 'left',
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
        fontSize: 12,
        bold: true
      }, {
        text: `FH Nº : ${dados['ficha_empenho']}`, alignment: 'right',
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
        fontSize: 10,
      }], border: [false, true, false, false],
    }, {
      text: `VENCIMENTO: ${this.funcaoService.converteDataBR(dados.data_vencimento)}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 12,
      bold: true,
      border: [true, true, false, true],
    }]);
    body.push([
      {
        text: `DATA EMPENHO: ${this.funcaoService.converteDataBR(dados.data_empenho)}`, alignment: 'left',
        margin: [0, 10, 0, 10],
        fontSize: 10,
        border: [false, false, false, false],
      },
      {
        text: `VL BRUTO: ${this.funcaoService.convertToBrNumber(+(+dados.valor_empenho + +dados['total_empenho_anulado']))}`, alignment: 'left',
        margin: [0, 10, 0, 10],
        fontSize: 12,
        bold: true,
        border: [true, false, false, false],
      }
    ]);
    body.push([{
      text: `O.F.: ${dados?.compra?.numero}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      border: [false, false, false, false],
      fontSize: 12,
      bold: true
    }, { text: `( - ) IRRF ${this.funcaoService.convertToBrNumber(+dados['valor_irrf'])}` ,
      border: [true, false, false, false],
    }]);
    body.push([{
      text: `${dados.favorecido.nome}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      border: [false, false, false, false],
      fontSize: 12,
      bold: true
    }, {
      text: `( - ) INSS ${this.funcaoService.convertToBrNumber(+dados['valor_inss'])}`,
      border: [true, false, false, false],
    }]);
    body.push([{
      text: `${dados.executora ? dados.executora : ''} - ${dados.unidade_executora}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 10,  
      border: [false, false, false, false],
    }, {
      text: `( - ) ISS ${this.funcaoService.convertToBrNumber(+dados['valor_iss'])}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 12,
      border: [true, false, false, false],
    }]);
    body.push([{
      text: `${dados.recurso_variavel} - ${dados.recurso_variavel_nome}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 10,
      border: [false, false, false, false],
    }, {
      text: `OUTROS ${this.funcaoService.convertToBrNumber(+dados['valor_ret_outros'])}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 10,
      border: [true, false, false, false],
    }]);
    body.push([{
      text: '',
      border: [false, false, false, true],
    }, {
      text: `LIQ. RECEBER ${this.funcaoService.convertToBrNumber(+(+dados.valor_empenho + +dados['total_empenho_anulado']) - +(+dados['valor_iss'] + +dados['valor_ret_outros'] + +dados['valor_inss'] + +dados['valor_irrf']))}`, alignment: 'left',
      margin: [0, 10, 0, 10],
      fontSize: 12,
      bold: true,
      border: [true, false, false, true],
    }]);



    return [{
      table: {
        body: body,
        widths: ['*', '*'],
        margin: [50, 50, 50, 50],
        layout: this.retornaLayout(),
      },
      pageBreak: 'after'
    }];
  }




  private dadosEmpenho(dados: EmpenhoResto, dados1: EmpenhoResto): {}[] {
    const conteudo = [];
    conteudo.push([{
      columns: [
        {
          columns: [
            {
              stack: [
                [{ text: `EMPENHO Nº: ${this.funcaoService.strZero(dados.numero, 4)}`, alignment: 'left', bold: true }],
                `${dados.favorecido.nome}`,
                `${dados.executora ? dados.executora : ''} - ${dados.unidade_executora}`,
                `VALOR: ${this.funcaoService.convertToBrNumber(+dados.valor_empenho)}`,
              ]
              , width: '70%'
            }, {
              stack: [
                [{ text: `DATA: ${this.funcaoService.converteDataBR(dados.data_empenho)}`, alignment: 'right', bold: true }],
                [{ text: ' ', alignment: 'right' }],
                [{ text: ' ', alignment: 'right' }],
                [{ text: `FICHA Nº: ${dados['ficha_empenho']}`, alignment: 'right' }],
              ]
              , width: '30%'
            }
          ], width: '50%'
        }

        , {

          columns: [
            {
              stack: [
                [{ text: dados1.numero ? `EMPENHO Nº: ${this.funcaoService.strZero(dados1.numero, 4)}` : '', alignment: 'left', bold: true }],
                dados1.numero ? `${dados1.favorecido.nome}` : '',
                dados1.numero ?  `${dados1.executora ? dados1.executora : ''} - ${dados1.unidade_executora}` : '',
                dados1.numero ? `VALOR: ${this.funcaoService.convertToBrNumber(+dados1.valor_empenho)}` : '',
              ]
              , width: '70%'
            }, {
              stack: [
                [{ text: dados1.numero ? `DATA: ${this.funcaoService.converteDataBR(dados1.data_empenho)}` : '', alignment: 'right', bold: true }],
                [{ text: ' ', alignment: 'right' }],
                [{ text: ' ', alignment: 'right' }],
                [{ text: dados1.numero ? `FICHA Nº: ${dados1['ficha_empenho']}` : '', alignment: 'right' }],
              ]
              , width: '30%',
            },
          ], width: '50%'
        }

      ], columnGap: 10, margin: [0, 10, 0, 0]
    }
    ],);

    return [{
      layout: 'noBorders',
      dontBreakRows: true,
      table: {
        widths: ['*'],
        body: conteudo,
        dontBreakRows: true,
      }
    }]
  }


}