import { DatePipe } from '@angular/common';
import { PagamentoService } from 'administrativo-lib';
import { FormatoExportacao, FuncaoService, GlobalService, Login, ProgressoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import * as toastr from 'toastr';

export class ConferenciaRetencoesRelatorio {
    protected datepipe: DatePipe;
    protected funcaoService: FuncaoService;
    protected globalService: GlobalService;
    protected unsubscribe: Subject<void> = new Subject();

    constructor(
        private pagamentoService: PagamentoService,
        protected progressoService: ProgressoService,
    ) {
        this.funcaoService = new FuncaoService();
        this.globalService = new GlobalService();
        this.datepipe = new DatePipe('pt');
    }

    public async gerarRelatorio(login: Login, data: Date, formato: FormatoExportacao) {
        if (!data) {
            toastr.error(`Informe uma data válida`);
            return;
        }

        const parametros = {};
        parametros['data'] = this.funcaoService.converteDataSQL(data);
        parametros['orgao_id'] = login.orgao.id;

        console.log(data);
        this.pagamentoService.conferenciaRetencoes(parametros)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async idTransacao => {
                this.progressoService.show(idTransacao, async (dados) => {
                    if (formato === 'pdf') {
                        Relatorio.imprimirPersonalizado(`Conferência de retenções ${this.funcaoService.converteDataBR(data)}`,
                            login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
                            this.montarConteudo(dados),
                            'landscape', 'CONFERENCIA RETENÇÕES',
                            {
                                linhas: {
                                    hLineWidth() {
                                        return 1;
                                    },
                                    vLineWidth() {
                                        return 1;
                                    },
                                    hLineColor() {
                                        return 'black';
                                    },
                                    paddingLeft() {
                                        return 3;
                                    },
                                    paddingRight() {
                                        return 3;
                                    }
                                }
                            }, false, false, 'pdf'
                        );
                    } else if (formato === 'csv') {
                        const listaItens = new Array();

                        for (const item of dados) {
                            const entity = {
                                'TIPO PAGAMENTO': item.tipo,
                                'NÚMERO EMPENHO': item.numero,
                                'PARCELA': item.parcela,
                                'VALOR LIQUIDAÇÃO': this.funcaoService.convertToBrNumber(item.valor_liquidado, 2),
                                'VALOR PAGAMENTO': this.funcaoService.convertToBrNumber(item.valor_pago, 2),
                                'RETIDO LIQUÍDO': this.funcaoService.convertToBrNumber(item.retencao_liquidacao, 2),
                                'RETIDO PAGAMENTO': this.funcaoService.convertToBrNumber(item.valor_retido, 2),
                                'RETIDO RECEBIMENTO': this.funcaoService.convertToBrNumber(item.retencao_recebimento, 2),
                            };

                            listaItens.push(entity);
                        }

                        const total = {
                            'TIPO PAGAMENTO': 'TOTALIZADOR',
                            'NÚMERO EMPENHO': '',
                            'PARCELA': '',
                            'VALOR LIQUIDAÇÃO': this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.valor_liquidado, 0), 2),
                            'VALOR PAGAMENTO': this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.valor_pago, 0), 2),
                            'RETIDO LÍQUIDO': this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.retencao_liquidacao, 0), 2),
                            'RETIDO PAGAMENTO': this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.valor_retido, 0), 2),
                            'RETIDO RECEBIMENTO': this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.retencao_recebimento, 0), 2),
                        };

                        listaItens.push(total);

                        tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('CONFERENCIA RETENCOES');
                    }
                });
            });
    }

    private montarConteudo(dados: any[]) {
        const conteudo = [];
        conteudo.push([
            { text: 'TIPO PAGAMENTO', fontSize: 9, alignment: 'center', bold: true },
            { text: 'NÚMERO EMPENHO', fontSize: 9, alignment: 'center', bold: true },
            { text: 'PARCELA', fontSize: 9, alignment: 'center', bold: true },
            { text: 'VALOR LIQUIDAÇÃO', fontSize: 9, alignment: 'center', bold: true },
            { text: 'VALOR PAGAMENTO', fontSize: 9, alignment: 'center', bold: true },
            { text: 'RETIDO LÍQUIDO', fontSize: 9, alignment: 'center', bold: true },
            { text: 'RETIDO PAGAMENTO', fontSize: 9, alignment: 'center', bold: true },
            { text: 'RETIDO RECEBIMENTO', fontSize: 9, alignment: 'center', bold: true },
        ]);

        for (const item of dados) {
            conteudo.push([
                { text: item.tipo, alignment: 'center' },
                { text: item.numero, alignment: 'center' },
                { text: item.parcela, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(item.valor_liquidado, 2), alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(item.valor_pago, 2), alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(item.retencao_liquidacao, 2), alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(item.valor_retido, 2), alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(item.retencao_recebimento, 2), alignment: 'center' }
            ]);
        }

        conteudo.push([
            { text: 'TOTALIZADOR', colSpan: 3, alignment: 'center' },
            { text: '', alignment: 'center' },
            { text: '', alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.valor_liquidado, 0), 2), alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.valor_pago, 0), 2), alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.retencao_liquidacao, 0), 2), alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.valor_retido, 0), 2), alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(dados.reduce((acc, item) => acc += +item.retencao_recebimento, 0), 2), alignment: 'center' }
        ]);

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                headerRows: 2,
                widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: conteudo
            }
        }];
    }
}
