import { DespesaService } from 'administrativo-lib';
import { RequisicaoExcessaoService } from '../service/requisicao-excecao.service';
import { Component, ElementRef, Injector, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  BaseResourceFormComponent, DateFormatPipe, Despesa, EddyAutoComplete, FuncaoService,
  GlobalService, LoginContabil, OrgaoService, ParametroRequisicao, ParametroRequisicaoService,
  RequisicaoExcecao, RequisicaoSubelemento, Usuario, UsuarioService
} from 'eddydata-lib';
import { switchMap, takeUntil } from 'rxjs/operators';
import { RequisicaoSubelementoService } from '../service/requisicao-subelemento.service';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-parametro-requisicao-form',
  templateUrl: './parametro-requisicao-form.component.html'
})
export class ParametroRequisicaoFormComponent extends BaseResourceFormComponent<ParametroRequisicao, LoginContabil> {

  /**
   * Declaração de variáveis
   */

  @ViewChild('btnAdicionarDespesa') public btnAdicionarDespesa: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public usuarioAutoComplete: EddyAutoComplete<Usuario>;
  public usuarioNovo: Usuario;
  public subelementoNovo: Despesa;
  public listaExcecoes: RequisicaoExcecao[] = []
  public listaSubelementos: RequisicaoSubelemento[] = [];
  public bloquearNovaRequisicao: boolean
  public tipoBloqueioData: { nome: string, id: string }[] = [];
  public bloqueioData: string = 'DATA_ATUAL'

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected orgaoService: OrgaoService,
    protected despesaService: DespesaService,
    private service: ParametroRequisicaoService,
    private requisicaoExcecaoService: RequisicaoExcessaoService,
    private requisicaoSubelementoService: RequisicaoSubelementoService,
    private usuarioService: UsuarioService) {
    super(new ParametroRequisicao(), injector, ParametroRequisicao.converteJson, service);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nao_usar_ficha_despesa: [false],
      minimo_tres_cotacoes: [false],
      exigir_responsavel_cotacao: [false],
      exigir_dados_fiscalizacao: [false],
      bloquear_data: [false],
      tipo_bloqueio_data: ['DATA_ATUAL'],
      orgao: [this.login.orgao],
      bloquear_nova_requisicao: [false],
      habilitar_desbloqueio_automatico: [false],
      exigir_finalidade_fundeb: [false],
      requisicao_alfabetico: [false],
      requisicao_licitacao_alfabetico: [false],
      exigir_ficha_despesa: [false],
      verificar_saldo_ficha_requisicao: [false],
      reserva_dotacao_rpls: [false],
      calculo_medio: [0],
      usuarios_excecao: [[]],
      requisicao_subelementos: [[]],
      permitir_cadastro_exercicio_posterior: [false],
      obrigar_indicar_placa: [false],
      subelementos_placas_obrigatorias: [false],
      data_limite_pca: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'orgao,usuarios_excecao.usuario_excecao,requisicao_subelementos.subelemento' };
  }

  protected afterInit(): void {
    this.activatedRoute
      .paramMap
      .pipe(switchMap(params => this.service.obter(
        Object.assign({}, { 'orgao.id': this.login.orgao.id }, this.parametrosExtras())
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (entidade) => {
          if (entidade) {
            this.entidade = entidade;
            this.afterLoad();
            this.entidadeForm.patchValue(entidade);
            this.bloquearNovaRequisicao = entidade.bloquear_nova_requisicao;
          }
        }, (error) => this.sair());
    this.carregarAutocomplete();
    this.tipoBloqueioData = [
      { id: 'DATA_ATUAL', nome: 'Data atual' },
      { id: 'DATA_RCMS', nome: 'Data última requisição' }
    ]
    this.bloqueioData = this.tipoBloqueioData[0].id
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
    this.entidadeForm.get('usuarios_excecao').patchValue(this.listaExcecoes);
    this.entidadeForm.get('bloquear_nova_requisicao').patchValue(this.bloquearNovaRequisicao);
    this.entidadeForm.get('requisicao_subelementos').setValue(this.listaSubelementos);
    this.atualizarTipodeDataBloqueio();
    this.entidadeForm.get('tipo_bloqueio_data').setValue(this.bloqueioData);

  }

  protected afterSubmit(entidade: ParametroRequisicao) {
    this.login.parametro['requisicao'] = entidade;
    GlobalService.salvarSessaoLogin(this.login);
  }

  protected async afterLoad() {
    this.listaExcecoes = this.entidade.usuarios_excecao;
    this.listaSubelementos = this.entidade.requisicao_subelementos;
    this.entidade.data_limite_pca = new DateFormatPipe().transform(this.entidade.data_limite_pca, []);
    this.bloqueioData = this.entidade?.id ? this.entidade.tipo_bloqueio_data : this.tipoBloqueioData[0].id
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public carregarAutocomplete() {
    this.usuarioAutoComplete = new EddyAutoComplete(null, this.usuarioService, 'id', ['id', 'nome', 'sobrenome'], { orgao_id: this.login.orgao.id, ativo: true }, { text: ['nome', 'sobrenome', 'email', 'cpf'], number: ['id'] });
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService, 'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] });
  }

  public adicionarUsuario() {
    if (this.listaExcecoes.find(item => item.usuario_excecao.email === this.usuarioNovo.email)) {
      toastr.warning(`Usuário ${this.usuarioNovo.nome} já está na lista de exceções.`);
      return;
    } else {
      let reqExc = new RequisicaoExcecao();
      reqExc.usuario_excecao = this.usuarioNovo;

      this.listaExcecoes.push(reqExc);
      this.usuarioNovo = new Usuario();
    }
  }

  public removerUsuario(item: RequisicaoExcecao, idx: number) {
    if (item.id) {
      this.requisicaoExcecaoService.remover(item.id).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
        this.listaExcecoes.splice(idx, 1);
        toastr.success('Usuário removido das exceções!');
      }, err => toastr.error(err));
    } else {
      this.listaExcecoes.splice(idx, 1);
      toastr.success('Usuário removido das exceções!');
    }
  }

  public alterouExigirFichaDespesa() {
    if (!this.entidadeForm.get('exigir_ficha_despesa').value) {
      this.entidadeForm.get('verificar_saldo_ficha_requisicao').setValue(false)
      this.entidadeForm.get('reserva_dotacao_rpls').setValue(false)
    }

    if (this.entidadeForm.get('reserva_dotacao_rpls').value) {
      this.entidadeForm.get('verificar_saldo_ficha_requisicao').setValue(false)
    }

    if (this.entidadeForm.get('verificar_saldo_ficha_requisicao').value) {
      this.entidadeForm.get('reserva_dotacao_rpls').setValue(false)
    }
  }

  public verificarCampo() {
    if (this.entidadeForm.get('exigir_ficha_despesa').value) {
      return false
    } else {
      return true
    }
  }

  public adicionarDespesa() {
    if (this.listaSubelementos.find(item => item.subelemento.codigo === this.subelementoNovo.codigo)) {
      toastr.warning(`Subelemento ${this.subelementoNovo.codigo} já foi adicionado.`);
      this.subelementoNovo = new Despesa();
      return;
    } else {
      let reqSub = new RequisicaoSubelemento();
      reqSub.subelemento = this.subelementoNovo;
      this.listaSubelementos.push(reqSub);
      this.subelementoNovo = new Despesa();
    }
  }

  public confirmarRemocao(item: RequisicaoSubelemento, index: number) {
    if (item.id) {
      this.requisicaoSubelementoService.remover(item.id).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
        this.listaSubelementos.splice(index, 1);
        toastr.success('Subelemento removido!');
      }, err => toastr.error(err));
    } else {
      this.listaSubelementos.splice(index, 1);
      toastr.success('Subelemento removido!');
    }
  }

  public hide() {
    $('#dialogDespesas').modal('hide');
  }

  public atualizarTipodeDataBloqueio() {
    if (!this.entidadeForm.get('bloquear_data').value) {
      this.bloqueioData = 'DATA_ATUAL';
    }
  }
}
