import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmpenhoService } from 'administrativo-lib';
import { NotaEmpenho } from 'contabil-lib';
import {
  BaseResourceListComponent, Coluna, DateFormatPipe,
  Empenho, Filtro, FormatoExportacao, GlobalService, LoginContabil,
  OrgaoAssinaturaService
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

// declare var $: any;

@Component({
  selector: 'lib-empenho-list',
  templateUrl: './empenho-list.component.html'
})
export class EmpenhoListComponent extends BaseResourceListComponent<Empenho, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public especieSelect = 'EMO';
  public listaEspecies: Array<any>;
  public listaFiltros: Array<any>;
  public listaFuncoes: Array<any>;
  public listaDespesas: Array<any>;
  public listaFavorecidos: Array<any>;
  public empenhoAnular: Empenho;
  public empenhoAtualizar: Empenho;
  public ptBR: any;
  public datepipe: DatePipe;
  public data1: Date;
  public data2: Date;
  public visualizarAnulacao: boolean = false;
  public visualizarAtualizacao: boolean = false;
  public empenhosInscritos: Empenho[] = [];
  public empenhosSelecionados: any[] = [];
  public numerosEmpenhos: any[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private assinaturaService: OrgaoAssinaturaService,
    private empenhoService: EmpenhoService) {
    super(empenhoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Empenho): boolean {
    return !item.inscrito_resto && item.especie !== 'EOA'
      && +item.total_empenho_anulado == 0 && this.login.sistema != 'controle-interno'
  }

  protected relations(): string {
    return 'favorecido,subelemento,compra,ficha,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.executora,liquidacoes';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['data_empenho$ge'] = this.datepipe.transform(this.data1, 'yyyy-MM-dd');
    parametros['data_empenho$le'] = this.datepipe.transform(this.data2, 'yyyy-MM-dd');
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id

    sessionStorage.removeItem('/empenhos_data1');
    sessionStorage.removeItem('/empenhos_data2');

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['numero$DESC', 'id$DESC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'subelemento.codigo', 'valor_empenho', 'favorecido.cpf_cnpj'],
      date: ['data_empenho'],
      text: ['favorecido.nome', 'subelemento.nome'],
    };
  }

  public anular(item) {
    this.empenhoAnular = item;
    this.visualizarAnulacao = true;
  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;
    this.usarExtendido = true;
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    const dt1: string = sessionStorage.getItem('/empenhos_data1');
    const dt2: string = sessionStorage.getItem('/empenhos_data2');
    if (!dt1) {
      this.data1 = new DateFormatPipe().transform(this.datepipe.transform(new Date(`01/01/${this.login.exercicio.ano}`), 'yyyy-MM-dd'), []);
    } else {
      this.data1 = new DateFormatPipe().transform(dt1, []);
    }
    if (!dt2) {
      const dt2 = new Date();
      dt2.setDate(dt2.getDate() + 10);
      this.data2 = new DateFormatPipe().transform(this.datepipe.transform(dt2, 'yyyy-MM-dd'), []);
    } else {
      this.data2 = new DateFormatPipe().transform(dt2, []);
    }
  }

  protected async afterInit(): Promise<void> {
    this.listaEspecies = [
      { id: 'EMO', nome: 'ORÇAMENTÁRIO' },
      { id: 'EME', nome: 'EXTRA-ORÇAMENTÁRIO' },
      { id: 'EMR', nome: 'RESTOS A PAGAR' }
    ];
    this.listaFiltros = [
      { id: 'todas', nome: 'TODAS' },
      { id: 'repasses', nome: 'REPASSES' },
      { id: 'viagens', nome: 'VIAGENS' },
      { id: 'equipamentos', nome: 'EQUIPAMENTOS' },
      { id: 'covid', nome: 'COVID-19' },
    ];
    this.empenhosInscritos = (await this.empenhoService.filtrar(1, -1, {
      exercicio_id: this.login.exercicio.id,
      orgao_id: this.login.orgao.id,
      inscrito_resto: true
    }).toPromise()).content;
  }

  protected acaoRemover(model: Empenho): Observable<Empenho> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Data do empenho:', coluna: 'data_empenho', agrupar: true, bold: true },
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Beneficiário', coluna: 'favorecido.nome' },
      { titulo: 'Cód. Despesa', coluna: 'subelemento.codigo' },
      { titulo: 'Despesa', coluna: 'subelemento.nome' },
      { titulo: 'Histórico', coluna: 'historico' },
      { titulo: 'Vencimento', coluna: 'data_vencimento' },
      { titulo: 'Valor', coluna: 'valor_empenho', alignment: 'right', decimais: 2 },
      { titulo: 'Saldo a Liquidar', coluna: 'saldo_liquidar', alignment: 'right', decimais: 2 }
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'favorecido,subelemento,compra,ficha,ficha.acao';
    this.empenhoService
      .extendido(1,
        -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          for (const liquidar of lista.content) {
            liquidar['saldo_liquidar'] = this.saldoLiquidar(liquidar);
          }
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE EMPENHOS ORÇAMENTÁRIOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem empenhos', ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_empenho']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({
      titulo: 'Contrato', coluna: 'contrato.numero', alignment: 'center', tipo: 'String', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }]
    });
    retorno.push({ titulo: 'Data Empenho', coluna: 'data_empenho', tipo: 'Date', alignment: 'center', padrao: true, filtro2: new Date() });
    retorno.push({ titulo: 'Descrição Despesa', coluna: 'subelemento.nome', tipo: 'String' });
    retorno.push({ titulo: 'Código Despesa', coluna: 'subelemento.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Ficha', coluna: 'ficha.numero', alignment: 'center', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'Licitação', coluna: 'licitacao.numero', tipo: 'String' });
    retorno.push({ titulo: 'Histórico', coluna: 'historico', tipo: 'String' });
    retorno.push({ titulo: 'Favorecido', coluna: 'favorecido.nome', tipo: 'String', padrao: false });
    retorno.push({ titulo: 'Modalidade', coluna: 'modalidade.nome', tipo: 'String' });
    retorno.push({ titulo: 'Nº Empenho', coluna: 'numero', alignment: 'center', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Nº Compra', coluna: 'compra.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Valor', coluna: 'valor_empenho', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Convênio', coluna: 'convenio.numero', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Unidade Executora', coluna: 'ficha.executora.codigo', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Recurso', coluna: 'ficha.recurso.codigo', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Aplicação', coluna: 'ficha.aplicacao.codigo', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'Variável', coluna: 'ficha.aplicacao_variavel.variavel', alignment: 'center', tipo: 'String' });
    retorno.push({ titulo: 'RCMS', coluna: 'compra.rcms.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({
      titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'Processo', cols: 2,
      mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: true
    });
    return retorno;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public onChangeEmpenho(value: any, especie: any) {
    if (especie === 'EME') {
      this.router.navigate(['empenhos-extra']);
    } else if (especie === 'EMR') {
      this.router.navigate(['empenhos-resto']);
    } else if (especie === 'MES') {
      this.router.navigate(['/despesa-nivel', this.login.exercicio.ano, this.login.usuario.sistema]);
    }
  }

  public saldoLiquidar(empenho: Empenho) {
    if (empenho.especie === 'EOA') return;

    return +empenho.valor_empenho + +empenho.total_empenho_anulado - +empenho.total_liquidado;
  }

  public editarAnulacao(item: Empenho) {
    return item.especie === 'EOA' && this.login.usuario.sistemas_administrador?.split(',').includes('contabil')
  }

  public alterarAnular(item: Empenho) {
    this.empenhoAtualizar = item;
    this.visualizarAtualizacao = true;
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public possuiInscricaoDeRestoNoExercicio(item: Empenho) {
    if (this.empenhosInscritos.length > 0) {
      toastr.warning('Já foram inscrito os resto a pagar do exercício, não será possível alterar o registro!')
      this.router.navigate([`/empenhos-orcamentario/${item.id}/visualizar`]);
    }
  }

  public ImprimirNotaPorEmpenhosSelecionados(item: Empenho) {
    if (item['selecionado'] === true) {
      item['selecionado'] = false;
      // Encontre o índice do item na lista this.empenhosSelecionados
      const index = this.empenhosSelecionados.findIndex(e => e.id === item.id);
      if (index > -1) {
        this.empenhosSelecionados.splice(index, 1);
      }
    } else {
      item['selecionado'] = true;
      // Verifique se o item já não está na lista
      if (!this.empenhosSelecionados.some(e => e.id === item.id)) {
        this.empenhosSelecionados.push(item);
      }
    }
    this.numerosEmpenhos = [];
    for (const empenho of this.empenhosSelecionados) {
      this.numerosEmpenhos.push(empenho.numero)
    }
  }

  public selecionar(empenho: Empenho) {
    const parametros = {};

    let relations = 'ficha.executora.unidade,ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.programa,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.despesa,ficha.aplicacao_variavel,contrato_aditamento,';
    relations += 'licitacao,modalidade,subelemento,favorecido.contas.banco,favorecido.contas.orgao,favorecido.tipo,contrato,convenio,liquidacoes,compra,compra.rcms,compra.rcms.requerente,compra.rcms.prazo';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = empenho.id;
    parametros['orderBy'] = 'liquidacoes.parcela$ASC';
    this.empenhoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaEmpenho(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => toastr.error(error.error && error.error.payload ? error.error.payload : error, "Atenção")
      );
  }
}