import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FavorecidoTipoService } from 'administrativo-lib';
import { Favorecido, FavorecidoEnquadramento, FavorecidoService, FavorecidoSocio, FavorecidoTipo, FuncaoService, GlobalService, Login, Proponente, SituacaoProponente, TipoContato, ViaCEPService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-favorecido-cadastro-dlg',
  templateUrl: './favorecido-cadastro-dlg.component.html'
})
export class FavorecidoCadastroDlgComponent implements OnInit, OnChanges {

  public listaTipos: FavorecidoTipo[];
  public listaCnpjRepetidos: Favorecido[];
  public visualizarFavorecidosCnpj: boolean = false;
  public listaEnquadramento: { id: FavorecidoEnquadramento, nome: string }[];

  // dlg padrão
  @Input() public login: Login;
  @Input() public entidade: Favorecido;
  @Output() callback: EventEmitter<Favorecido> = new EventEmitter();

  @Input() parametros: any;

  // dlg para proponentes
  public proponenteEpp: boolean;
  public proponenteSituacao: SituacaoProponente;
  public proponenteMotivo: string;
  public listaSituacoes: { id: SituacaoProponente, nome: string }[];
  @Input() public origem_proponente: boolean = false;
  @Input() public proponente: Proponente;
  @Output() callbackProponente: EventEmitter<Proponente> = new EventEmitter();

  public entidadeForm: FormGroup;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected fb: FormBuilder;
  protected unsubscribe: Subject<void> = new Subject();
  public buscarPorNome: boolean = true;
  public ativarFild: any = 1;
  public selectedOpt: string;
  public listaEstados: any[];
  public listaSocios: FavorecidoSocio[] = [];
  public uf: any = {}

  public nomeDlgGestores = 'dlgGestoresFavorecidoDlg';

  constructor(
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected favorecidoTipoService: FavorecidoTipoService,
    protected viacepService: ViaCEPService,
    private favorecidoService: FavorecidoService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (!this.entidadeForm)
        this.ngOnInit();
      if (changes['entidade']) {
        this.entidadeForm.patchValue(this.entidade);
      } else if (changes['proponente']) {
        this.limparProponente();
        if (this.proponente) {
          if (this.entidadeForm) {
            if (this.proponente.favorecido.id) {
              this.entidadeForm.patchValue(this.proponente.favorecido);
            } else {
              this.criarCamposForm();
            }
            this.buscar();
          }
          this.proponenteEpp = this.proponente.epp_me;
          this.proponenteSituacao = this.proponente.situacao;
          this.proponenteMotivo = this.proponente.motivo;
        }
      }
      if (this.login)
        this.buscarPorNome = this.login.parametro ? !this.login.parametro[(this.login.sistema === 'requisicao' ? 'compras' : this.login.sistema)]?.bloqueio_razaosocial : true;
    }
  }

  ngOnInit(): void {
    this.ativarFild = 1;
    this.selectedOpt = 'opt1';
    this.router = this.injector.get(Router);
    this.fb = this.injector.get(FormBuilder);
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.criarCamposForm();

    this.favorecidoTipoService.filtrar(1, -1,
      { 'cidade.id': this.login.cidade.id, orderBy: 'id' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaTipos = res ? res.content : new Array<FavorecidoTipo>();
      });

    this.listaEnquadramento = this.globalService.obterListaEnquadramentoFornecedores();
    this.listaSituacoes = this.globalService.obterSituacoesProponente();
    this.limparProponente();
    this.listaEstados = this.globalService.listaEstados();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public submitForm() {
    this.entidadeForm.get('uf').setValue(this.uf.uf)
    this.entidadeForm.get('socios').setValue(this.listaSocios)

    let controls = this.entidadeForm.controls;
    Object.entries(controls).forEach(([key, value]) => {
      if (value.invalid) console.log(key);
    });
    if (this.entidadeForm.invalid) {
      toastr.error('Há campos obrigatórios não informados!');
      return;
    }

    if (+this.entidadeForm.get('tipo').value.tce !== 1 && +this.entidadeForm.get('tipo').value.tce !== 2 && +this.entidadeForm.get('tipo').value.tce !== 10) {
      toastr.warning('Verifique a espécie no cadastro de fornecedor');
      this.criarCamposForm();
      this.uf = {}
      this.listaSocios = []
      this.ativarFild = 1;
      this.selectedOpt = 'opt1';
      return;
    }

    if (!this.funcaoService.validarCNPJ(this.entidadeForm.get('cpf_cnpj').value)) {
      toastr.warning('O documento informado está inválido!')
      return;
    }

    if (this.origem_proponente) {
      if (!this.proponenteSituacao) this.proponenteSituacao = 'HABILITADO';
      else if (this.proponenteSituacao !== 'HABILITADO' && !this.proponenteMotivo) {
        toastr.error('Informe o motivo para a situação do proponente!');
        return;
      }
    }

    this.salvar();
  }

  private salvar() {
    const entidade: Favorecido = Favorecido.converteJson(this.entidadeForm.value);
    entidade['orgao'] = this.login.orgao;
    entidade['proponente'] = true;
    if (entidade.id) {
      if (entidade.cpf_cnpj === this.funcaoService.removerPontos(this.login?.orgao?.cnpj)) {
        entidade['cnpjPrefeitura'] = true;
      }
      this.favorecidoService.atualizar(entidade).pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.acaoSucesso(res);
          this.criarCamposForm();
          this.uf = {}
          this.listaSocios = []
          this.ativarFild = 1;
          this.selectedOpt = 'opt1';
        }, error => this.acaoErro(error));
    } else {
      this.favorecidoService.inserir(entidade).pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.acaoSucesso(res);
          this.criarCamposForm();
          this.uf = {}
          this.listaSocios = []
          this.ativarFild = 1;
          this.selectedOpt = 'opt1';
        }, error => this.acaoErro(error));
    }
  }

  private acaoSucesso(entidade: Favorecido) {
    // toastr.success('Registro salvo com sucesso!');
    if (this.origem_proponente) {
      const prop = new Proponente();
      prop.epp_me = this.proponenteEpp;
      prop.situacao = this.proponenteSituacao;
      prop.motivo = this.proponenteMotivo;
      prop.favorecido = entidade;
      this.callbackProponente.emit(prop);
    } else {
      this.callback.emit(entidade);
    }
    this.fechar();
  }

  private acaoErro(error: any) {
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  private criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      cpf_cnpj: [null, [Validators.required]],
      nome_fantasia: ['-', [Validators.required]],
      responsavel: [null, [Validators.required]],
      cep: [null, [Validators.required]],
      endereco: [null, [Validators.required]],
      num_endereco: [null, [Validators.required]],
      bairro: [null, [Validators.required]],
      municipio: [null, [Validators.required]],
      uf: [null, [Validators.required]],
      complemento: [null],
      ddd_fone: [null],
      telefone: [null, [Validators.required]],
      pis: [null],
      email: [null, [Validators.required]],
      url: [null],
      enquadramento: [null, [Validators.required]],
      autarquia: [false, [Validators.required]],
      autorizado: [true, [Validators.required]],
      servidor: [false, [Validators.required]],
      simples_nacional: [false, [Validators.required]],
      gasto_fixo: [false, [Validators.required]],
      terceiro_setor: [false, [Validators.required]],
      aux: [null],
      tipo: [null, [Validators.required]],
      cidade: [this.login.cidade, [Validators.required]],
      contatos: [null],
      cnaes: [null],
      socios: [null],
      funcao_cargo: [null],
    });
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public buscar() {
    const cpf_cnpj: string = this.entidadeForm.get('cpf_cnpj').value;
    if (cpf_cnpj) {
      this.criarCamposForm();
      this.entidadeForm.get('cpf_cnpj').setValue(cpf_cnpj);
      if (!this.funcaoService.validarCNPJ(cpf_cnpj)) {
        toastr.warning('O documento informado é inválido!')
        return;
      }
      if (cpf_cnpj.length > 11) {
        this.entidadeForm.get('tipo').setValue(this.listaTipos.find((t) => +t.tce === 1));
      } else {
        this.entidadeForm.get('tipo').setValue((this.listaTipos.find((t) => +t.tce === 2)));
      }

      let parametros = this.parametros ? Object.assign({ cpf_cnpj, cidade_id: this.login.cidade.id, relations: 'cidade,tipo,contatos.orgao,socios.pessoa,socios.orgao' }, this.parametros) :
        { cpf_cnpj, cidade_id: this.login.cidade.id, relations: 'cidade,tipo,contatos.orgao,socios.pessoa,socios.orgao' };

      this.favorecidoService.filtrar(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res.content.length > 0) {
            for (let item of res.content) {
              if (item.inativo && !item.autorizado) {
                toastr.warning('Favorecido está inativo e não autorizado!');
                return;
              }
              if (item.inativo) {
                toastr.warning('Favorecido está inativo!');
                return;
              }
              if (!item.autorizado) {
                toastr.warning('Favorecido não está autorizado!');
                return;
              }
            }
            if (res.content.length > 1) {
              this.listaCnpjRepetidos = res.content;
              this.visualizarFavorecidosCnpj = true;
            } else {
              let fav: Favorecido = res.content[0];
              if (fav?.socios?.length) {
                fav.socios = fav?.socios.filter(s => s?.orgao?.id === this.login?.orgao?.id);
              }
              this.entidadeForm.patchValue(fav);
              this.uf = this.listaEstados.find((e) => e.uf === this.entidadeForm.get('uf').value)
              this.listaSocios = this.entidadeForm.get('socios').value
              this.verificarEPP();
            }
          } else {
            this.buscarReceitaFederal();
          }
        });
    }
  }

  public abrirConsultaFavorecido() {
    if (!this.buscarPorNome) return;
    $('#dialogFavorecidoBusca').modal('show');
  }

  public callbackFavorecido(fav: Favorecido) {
    if (!this.buscarPorNome) return;
    if (fav) {
      this.entidadeForm.get('cpf_cnpj').setValue(fav.cpf_cnpj);
      this.buscar();
    }
  }

  public selecionarFavorecido(fav: Favorecido) {
    if (fav?.socios?.length > 0) {
      fav.socios = fav.socios.filter(s => s.orgao.id === this.login.orgao.id)
    }
    this.entidadeForm.patchValue(fav);
    this.listaSocios = this.entidadeForm.get('socios').value;
    this.uf = this.listaEstados.find((e) => e.uf === fav.uf)
    this.verificarEPP();
    this.visualizarFavorecidosCnpj = false;
    this.listaCnpjRepetidos = [];
  }

  public buscarReceitaFederal() {
    let cnpj = this.entidadeForm.get('cpf_cnpj').value;
    if (!this.entidadeForm.get('tipo').value || this.entidadeForm.get('tipo').value.tce !== '01'
      || !cnpj)
      return;
    cnpj = cnpj.replace(/[.]*[-]*[(]*[)]*/g, '');
    this.favorecidoService
      .buscarReceitaWS(cnpj, this.login.cidade.id).subscribe((data) => {
        if (!data) {
          toastr.warning('Dados não foram entrados no web service da Receita Federal!');
          return;
        }
        toastr.success(`Fornecedor ${data.nome} foi encontrado nos cadastros da Receita Federal!`);
        this.carregarDadosReceitaWs(data);
      });
  }

  private carregarDadosReceitaWs(fav: Favorecido) {
    let favorecido = this.entidadeForm.value;

    let campos = Object.keys(favorecido).filter((k) => favorecido[k] === '-');

    favorecido = Object.assign(favorecido, fav);
    this.entidadeForm.patchValue(favorecido);
    this.uf = this.listaEstados.find((e) => e.uf === this.entidadeForm.get('uf').value)

    if (fav.contatos) {
      let tel = fav.contatos.find((c) => c.tipo === TipoContato.TELEFONE && c.principal);
      if (tel)
        this.entidadeForm.get('telefone').setValue(tel.contato);

      let email = fav.contatos.find((c) => c.tipo === TipoContato.EMAIL && c.principal);
      if (email)
        this.entidadeForm.get('email').setValue(email.contato);
    }

    let controls = this.entidadeForm.controls;
    Object.entries(controls).forEach(([key, value]) => {
      let campo = campos.find((c) => c === key);
      if (value.invalid && campo) {
        this.entidadeForm.get(campo).setValue('-');
      }
    });
  }

  public verificarEPP() {
    const enquadramento: FavorecidoEnquadramento = this.entidadeForm.get('enquadramento').value;
    if (enquadramento && this.origem_proponente) {
      // this.proponenteEpp = (enquadramento === 'EPP' || enquadramento === 'ME')
      this.proponenteEpp = false;
    }
  }

  private limparProponente() {
    this.proponenteEpp = false;
    this.proponenteSituacao = 'HABILITADO';
    this.proponenteSituacao = undefined;
  }

  public fechar() {
    // this.limparProponente();
    this.criarCamposForm();
    this.uf = {}
    this.listaSocios = []
    this.ativarFild = 1;
    this.selectedOpt = 'opt1';
    $('#dialogFavorecidoCadastro').modal('hide');
  }

  public ativarCol(n: number) {
    if (n == 1) {
      this.ativarFild = 1;
      this.selectedOpt = 'opt1';
    }
    if (n == 2) {
      this.ativarFild = 2;
      this.selectedOpt = 'opt2';
    }
    if (n == 3) {
      this.ativarFild = 3;
      this.selectedOpt = 'emp1';
    }
    if (n == 4) {
      this.ativarFild = 4;
      this.selectedOpt = 'cqe1';
    }
    if (n == 5)
      this.ativarFild = 5;
  }

  public buscarCEP() {
    let cep = this.entidadeForm.get('cep').value;
    if (!cep)
      return;
    this.viacepService.obterPorCPF(cep)
      .subscribe((endereco) => {
        if (!endereco) {
          toastr.warning('Endereço não foi encontrado')
          return;
        }
        this.entidadeForm.get('endereco').setValue(endereco.logradouro);
        this.entidadeForm.get('bairro').setValue(endereco.bairro);
        this.entidadeForm.get('municipio').setValue(endereco.localidade);
        this.entidadeForm.get('uf').setValue(endereco.uf);
        this.entidadeForm.get('complemento').setValue(endereco.complemento);

        let uf = this.entidadeForm.get('uf').value;
        this.uf = this.listaEstados.find((e) => e.uf === uf);
      }, (err) => toastr.warning('Endereço não foi encontrado'));

    //this.funcaoService.focarCampo(this.numeroField);
  }


  public filterEstado(event) {
    let query: string = event.query;
    this.listaEstados = this.globalService.listaEstados()
      .filter((e) => this.funcaoService.removerAcentos(e.nome)
        .toLowerCase().includes(this.funcaoService.removerAcentos(query).toLowerCase()));
  }
}
