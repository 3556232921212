import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { EddyAutoComplete, Favorecido, FavorecidoService, GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EtiquetaEmpenhoResto } from '../../relatorio/contabil/etiqueta-empenho-resto';
import { EmpenhoRestoService } from 'contabil-lib';

@Component({
  selector: 'lib-etiqueta-empenho-dlg',
  templateUrl: './etiqueta-empenho-resto-dlg.component.html'
})
export class EtiquetaEmpenhoRestoDlgComponent implements OnInit, OnDestroy {


  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedModelo: string;
  public selectFornecedor: boolean;
  public selectPeriodo: boolean = true;
  public selectFicha: boolean;
  public selectEmpenho: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  public fichaInicio: number;
  public fichaFinal: number;
  public favorecido: Favorecido;
  protected unsubscribe: Subject<void> = new Subject();

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;
  @Input() empenhosSelecionados: any[];

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private empenhoService: EmpenhoRestoService,
    private assinaturaService: OrgaoAssinaturaService,
    private favorecidoService: FavorecidoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedModelo = 'mod1';
    this.dataInicio = new Date();
    this.dataFinal = new Date();

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
      this.gerarImpressaoEmpenhos();
  }

  gerarImpressaoEmpenhos() {
    let relations = '';
    const parametros = {};
    if (this.selectPeriodo) {
      if (+this.dataFinal < +this.dataInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Data final do empenho está maior que a inicial' });
        return;
      }
      if (!this.dataInicio || !this.dataFinal || +this.dataFinal === 0 || +this.dataInicio === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe a data inicial e final para imprimir' });
        return;
      }
    }  
    
    if (this.selectEmpenho) {
      if (+this.numeroFinal < +this.numeroInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Número final do empenho está maior que o inicial' });
        return;
      }
      if (!this.numeroInicio || !this.numeroFinal || +this.numeroFinal === 0 || +this.numeroInicio === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe o número inicial e final para imprimir' });
        return;
      }
    }
    
    if (this.selectFicha) {
      if (+this.fichaFinal < +this.fichaInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Número final da ficha está maior que o inicial' });
        return;
      }
      if (!this.fichaInicio || !this.fichaFinal || +this.fichaFinal === 0 || +this.fichaInicio === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe o número inicial e final para imprimir' });
        return;
      }
    } 

    relations += 'licitacao,modalidade,favorecido.contas.banco,favorecido.contas.orgao,favorecido.tipo,contrato,convenio,liquidacoes,compra.rcms.requerente,compra.rcms.prazo';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['especie'] = 'EMR';
    switch(this.selectedOrdem){
      case 'ord1':
        parametros['orderBy'] = 'data_empenho$ASC';
      break;
      case 'ord3':
        parametros['orderBy'] = 'valor_empenho$ASC';
      break;
    }

    if (this.selectPeriodo) {
      parametros['data_empenho$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_empenho$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    } 
    
    if (this.selectEmpenho) {
      parametros['numero$ge'] = +this.numeroInicio;
      parametros['numero$le'] = +this.numeroFinal;
    }  
    if (this.selectFicha) {
      parametros['ficha_empenho$ge'] = +this.fichaInicio;
      parametros['ficha_empenho$le'] = +this.fichaFinal;
    } 

    if (this.selectFornecedor) {
      parametros['favorecido.nome$like'] = this.favorecido.nome;
    }

    this.empenhoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        // let parametros_despesa = {};
        // for (let itemLista of lista.content) {
        //   console.log(itemLista)
        //   parametros_despesa['ficha.despesa.codigo'] = itemLista.subelemento.codigo;

        // }
        // this.empenhoService.filtrar(1, -1, parametros_despesa)
        // .pipe(takeUntil(this.unsubscribe))
        // .subscribe((res) => {
        //   res.content
        // });
        new EtiquetaEmpenhoResto(this.assinaturaService).imprimir(lista.content, this.login,this.selectedModelo);
        if (this.marcarComoImpresso) {
          this.empenhoService.marcarComoImpresso(parametros).subscribe(() => {
          });
        }
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

}
