import { DatePipe } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { BaseResourceService, Exercicio, ExercicioService, FuncaoService, Relatorio, GlobalService, OrgaoAssinaturaService, OrgaoAssinatura, Login, OrgaoService, Orgao } from "eddydata-lib";
import { Observable, Subject } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";

export interface ParametroEmpenhosNaoLiquidados {
  exercicio_id: number;
  orgao_id: number;
  mes?: number;
  data_fim?: string;
  funcao_governo?: number;
  recurso?: number;
  aplicacao?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProgramaFinanceiraService extends BaseResourceService<Exercicio> {

  protected unsubscribe: Subject<void> = new Subject();
  protected datepipe: DatePipe;
  private assinatura: OrgaoAssinatura;

  constructor(
    protected exercicioService: ExercicioService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected orgaoSevice: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected injector: Injector
  ) {
    super('contabil-relatorio', injector);
  }

  obterProgramaFinanceiro(exercicio: number, data1: string, data2: string, mes: number): Observable<any> {
    let params = '';
    params += `?data1=${data1}&data2=${data2}&mes=${mes}`;
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/programa-financeiro/${exercicio}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  async montarBalanceteProgramaFinanceiro(exercicio: number, data1: string, data2: string, mes: number, login: Login, notaExplicativa: string) {
    const ex = await this.exercicioService.obterId(exercicio).toPromise();
    const assinatura = await this.assinaturaService.obter({
      orgao_id: login.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    }).toPromise();

    const orgaos = await (await this.orgaoSevice.filtrar(1, -1, { cidade_id: login.cidade.id, 'orderBy': 'id' }).toPromise()).content;

    this.obterProgramaFinanceiro(exercicio, data1, data2, mes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        console.log(dados)

        Relatorio.imprimirPersonalizado(
          'PROGRAMAÇÃO FINANCEIRA PARA ' + ex.ano,
          login.usuario.nome,
          login.usuario.sobrenome,
          login.orgao.nome,
          login.brasao,
          notaExplicativa !== undefined ?
            this.conteudoCabecalho(notaExplicativa, ex, assinatura)
              .concat(this.conteudoMunicipioConsolidado(dados, ex, assinatura))
              .concat(this.conteudoPrefeituraMunicipal(dados))
              .concat(this.conteudoRecursosProprios(dados, orgaos))
              .concat(this.conteudoReceitaDespesaOrgaos(dados)) :
            this.conteudoMunicipioConsolidado(dados, ex, assinatura)
              .concat(this.conteudoPrefeituraMunicipal(dados))
              .concat(this.conteudoRecursosProprios(dados, orgaos))
              .concat(this.conteudoReceitaDespesaOrgaos(dados))
          //.concat(this.conteudoDespesa(despesas, gastos))
          //.concat(await this.conteudoAssinatura())
          ,
          'landscape',
          'PROGRAMAÇÃO FINANCEIRA PARA ' + ex.ano,
          {
            linhas: {
              hLineWidth(i, node) {
                return 1;
              },
              vLineWidth(i) {
                return 1;
              },
              hLineColor(i) {
                return 'black';
              },
              paddingLeft(i) {
                return 3;
              },
              paddingRight(i, node) {
                return 3;
              }
            }
          }, true, false
        );
      });
  }

  private conteudoCabecalho(notaExplicativa: string, exercicio: Exercicio, assinatura: OrgaoAssinatura): {}[] {

    const conteudo = [];
    conteudo.push([
      { text: `PREFEITURA MUNICIPAL DE ${this.login.cidade?.nome}, ${new Date().getDate()} de ${this.globalService.obterMes(new Date().getMonth() + 1)} de ${exercicio.ano}.`, bold: true, fontSize: 10, alignment: 'center', colSpan: 2, border: [false, false, false, false] },
      ''
    ]);

    conteudo.push([
      { text: assinatura.assinatura3, bold: true, fontSize: 10, alignment: 'center', colSpan: 2, border: [false, false, false, false] },
      ''
    ]);

    conteudo.push([
      { text: assinatura.cargo_assinatura3, bold: true, fontSize: 10, alignment: 'center', colSpan: 2, border: [false, false, false, false] },
      ''
    ]);

    conteudo.push([{ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 780, y2: 1, lineWidth: 0.5 }], colSpan: 2, border: [false, false, false, false] }, '']);

    conteudo.push([
      { text: notaExplicativa, alignment: 'justify', fontSize: 10, border: [false, false, false, false], margin: [0, 1, 0, 0], colSpan: 2 },
      ''
    ]);

    conteudo.push([
      { text: '', alignment: 'justify', fontSize: 10, border: [false, false, false, false], margin: [0, 1, 0, 0], colSpan: 2 },
      ''
    ]);

    return [{
      //pageBreak: !receita ? 'before' : 'null',
      layout: 'linhas',
      table: {
        widths: [50, '*'],
        body: conteudo
      }
    }];
  }

  private conteudoMunicipioConsolidado(dados, exercicio: Exercicio, assinatura: OrgaoAssinatura): {}[] {
    const conteudo = [];
    conteudo.push([
      { text: `PREFEITURA MUNICIPAL DE ${this.login.cidade?.nome}, ${new Date().getDate()} de ${this.globalService.obterMes(new Date().getMonth() + 1)} de ${exercicio.ano}.`, bold: true, fontSize: 10, alignment: 'center', colSpan: 6, border: [false, false, false, false] },
      '',
      '',
      '',
      '',
      ''
    ]);

    conteudo.push([
      { text: assinatura.assinatura3, bold: true, fontSize: 10, alignment: 'center', colSpan: 6, border: [false, false, false, false] },
      '',
      '',
      '',
      '',
      ''
    ]);

    conteudo.push([
      { text: assinatura.cargo_assinatura3, bold: true, fontSize: 10, alignment: 'center', colSpan: 6, border: [false, false, false, false] },
      '',
      '',
      '',
      '',
      ''
    ]);

    conteudo.push([
      { text: 'ANEXO I', bold: true, fontSize: 10, alignment: 'center', colSpan: 6, border: [false, false, false, false] },
      '',
      '',
      '',
      '',
      ''
    ]);

    conteudo.push([
      { text: 'PROGRAMAÇÃO FINANCEIRA PARA 2023', bold: true, fontSize: 10, alignment: 'center', colSpan: 6, border: [false, false, false, false] },
      '',
      '',
      '',
      '',
      ''
    ]);

    conteudo.push([
      { text: 'MUNICÍPIO (CONSOLIDADO)', bold: true, fontSize: 10, alignment: 'center', colSpan: 6 },
      '',
      '',
      '',
      '',
      ''
    ]);

    conteudo.push([
      { text: 'MÊS', bold: true, fontSize: 10, alignment: 'center' },
      { text: 'RECEITA', bold: true, fontSize: 10, alignment: 'center' },
      { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
      { text: 'DESPESA', bold: true, fontSize: 10, alignment: 'center' },
      { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
      { text: 'SALDO', bold: true, fontSize: 10, alignment: 'center' },
    ]);
    let acumulado_receita = 0;
    let acumulado_despesa = 0;
    for (const receita of dados[0]['receita']) {
      for (const despesa of dados[0]['despesa']) {
        if (receita.referencia === despesa.referencia) {
          acumulado_receita += +receita.valor_previsto;
          acumulado_despesa += +despesa.valor_previsto;
          conteudo.push([
            { text: this.globalService.obterMes(+receita.referencia), fontSize: 10, alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(+receita.valor_previsto), fontSize: 10, alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(+acumulado_receita), fontSize: 10, alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(+despesa.valor_previsto), fontSize: 10, alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(+acumulado_despesa), fontSize: 10, alignment: 'center' },
            { text: this.funcaoService.convertToBrNumber(+acumulado_receita - +acumulado_despesa), fontSize: 10, alignment: 'center' },
          ]);
        }
      }
    }

    conteudo.push([
      { text: 'Acumulado', bold: true, fontSize: 10, alignment: 'center' },
      { text: this.funcaoService.convertToBrNumber(acumulado_receita), bold: true, fontSize: 10, alignment: 'center' },
      { text: '', fontSize: 10, alignment: 'center' },
      { text: this.funcaoService.convertToBrNumber(acumulado_despesa), bold: true, fontSize: 10, alignment: 'center' },
      { text: '', fontSize: 10, alignment: 'center' },
      { text: '', fontSize: 10, alignment: 'center' },
    ]);

    return [{
      table: {
        dontBreakRows: true,
        //headerRows: 1,
        widths: [120, 120, 120, 120, 120, 120],
        body: conteudo
      }
    }];
  }

  private conteudoPrefeituraMunicipal(dados): {}[] {
    const conteudo = [];

    conteudo.push([{ text: ``, bold: true, fontSize: 10, alignment: 'center', colSpan: 7, border: [false, false, false, false], margin: [0, 10, 0, 10] }, '', '', '', '', '', ''])

    conteudo.push([{ text: `PREFEITURA MUNICIPAL DE ${this.login.cidade?.nome.toUpperCase()}`, bold: true, fontSize: 10, alignment: 'center', colSpan: 7, }, '', '', '', '', '', ''])

    conteudo.push([
      { text: 'MÊS', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
      { text: 'RECEITA', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
      { text: 'ACUMULADA', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
      { text: 'DESPESA', bold: true, fontSize: 10, colSpan: 3, alignment: 'center' },
      '',
      '',
      { text: 'SALDO', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
    ], [
      '',
      '',
      '',
      { text: 'CÂMARA MUN.', bold: true, fontSize: 10, alignment: 'center' },
      { text: 'PREFEITURA', bold: true, fontSize: 10, alignment: 'center' },
      { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
      '',
    ]);
    let acumulado_receita = 0;
    let acumulado_despesa = 0;
    let acumulado_camara = 0;
    let acumulado_total = 0;
    for (const receita of dados[1]['receita_prefeitura']) {
      for (const despesa of dados[1]['despesa_prefeitura']) {
        if (receita.referencia === despesa.referencia) {
          for (const camara_despesa of dados[1]['despesa_camara']) {
            if (receita.referencia === camara_despesa.referencia) {
              acumulado_receita += +receita.valor_previsto;
              acumulado_despesa += +despesa.valor_previsto;
              acumulado_camara += +camara_despesa.valor_previsto;
              acumulado_total += +camara_despesa.valor_previsto + +despesa.valor_previsto;
              conteudo.push([
                { text: this.globalService.obterMes(+receita.referencia), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+receita.valor_previsto), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+acumulado_receita), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+camara_despesa.valor_previsto), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+despesa.valor_previsto), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+acumulado_total), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+acumulado_receita - +acumulado_total), fontSize: 10, alignment: 'center' },
              ]);
            }
          }
        }
      }
    }

    conteudo.push([
      { text: 'Acumulado', bold: true, fontSize: 10, alignment: 'center' },
      { text: this.funcaoService.convertToBrNumber(acumulado_receita), bold: true, fontSize: 10, alignment: 'center' },
      { text: '', fontSize: 10, alignment: 'center' },
      { text: this.funcaoService.convertToBrNumber(acumulado_camara), bold: true, fontSize: 10, alignment: 'center' },
      { text: this.funcaoService.convertToBrNumber(acumulado_despesa), bold: true, fontSize: 10, alignment: 'center' },
      { text: '', fontSize: 10, alignment: 'center' },
      { text: '', fontSize: 10, alignment: 'center' },
    ]);

    return [{
      table: {
        dontBreakRows: true,
        //headerRows: 1,
        widths: [100, 102, 102, 102, 102, 102, 102],
        body: conteudo
      }
    }];
  }

  private conteudoRecursosProprios(dados, orgaos: Orgao[]): {}[] {
    const conteudo = [];
    if (dados[2]['despesa_autarquia'].length > 0) {

      conteudo.push([{ text: ``, bold: true, fontSize: 10, alignment: 'center', colSpan: 8, border: [false, false, false, false], margin: [0, 10, 0, orgaos[5] && orgaos[6]] ? 0 : 10 }, '', '', '', '', '', '', ''])
      if (orgaos[4] && orgaos[5]) {
        conteudo.push([{ text: `* Saldo final corresponde às transferências do ${orgaos[4].nome} e as transferências à ${orgaos[5].nome}`, bold: true, fontSize: 10, colSpan: 8, border: [false, false, false, false], margin: [0, 0, 0, 10] }, '', '', '', '', '', '', ''])
      }

      conteudo.push([{ text: `PREFEITURA - RECURSOS PRÓPRIOS (FONTES 01 e 08)`, bold: true, fontSize: 10, alignment: 'center', colSpan: 8, }, '', '', '', '', '', '', ''])

      conteudo.push([
        { text: 'MÊS', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
        { text: 'RECEITA', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
        { text: 'ACUMULADA', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
        { text: 'DESPESA', bold: true, fontSize: 10, colSpan: 4, alignment: 'center' },
        '',
        '',
        '',
        { text: 'SALDO', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
      ], [
        '',
        '',
        '',
        { text: 'CÂMARA', bold: true, fontSize: 10, alignment: 'center' },
        { text: 'PREFEITURA', bold: true, fontSize: 10, alignment: 'center' },
        { text: dados[2]['despesa_autarquia'][0].nome.split(' - ')[1], bold: true, fontSize: 10, alignment: 'center' },
        { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
        '',
      ]);

      let acumulado_receita = 0;
      let acumulado_despesa = 0;
      let acumulado_camara = 0;
      let acumulado_autarquia = 0;
      let acumulado_total = 0;
      for (const receita of dados[1]['receita_prefeitura']) {
        for (const despesa of dados[1]['despesa_prefeitura']) {
          if (receita.referencia === despesa.referencia) {
            for (const camara_despesa of dados[1]['despesa_camara']) {
              if (receita.referencia === camara_despesa.referencia) {
                for (const autarquia of dados[2]['despesa_autarquia']) {
                  if (receita.referencia === autarquia.referencia) {
                    acumulado_receita += +receita.valor_previsto;
                    acumulado_despesa += +despesa.valor_previsto;
                    acumulado_camara += +camara_despesa.valor_previsto;
                    acumulado_autarquia += +autarquia.valor_previsto;
                    acumulado_total += +camara_despesa.valor_previsto + +despesa.valor_previsto + +autarquia.valor_previsto;
                    conteudo.push([
                      { text: this.globalService.obterMes(+receita.referencia), fontSize: 10, alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(+receita.valor_previsto), fontSize: 10, alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(+acumulado_receita), fontSize: 10, alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(+camara_despesa.valor_previsto), fontSize: 10, alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(+despesa.valor_previsto), fontSize: 10, alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(+autarquia.valor_previsto), fontSize: 10, alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(+acumulado_total), fontSize: 10, alignment: 'center' },
                      { text: this.funcaoService.convertToBrNumber(+acumulado_receita - +acumulado_total), fontSize: 10, alignment: 'center' },
                    ]);
                  }
                }
              }
            }
          }
        }
      }

      conteudo.push([
        { text: 'Acumulado', bold: true, fontSize: 10, alignment: 'center' },
        { text: this.funcaoService.convertToBrNumber(acumulado_receita), bold: true, fontSize: 10, alignment: 'center' },
        { text: '', fontSize: 10, alignment: 'center' },
        { text: this.funcaoService.convertToBrNumber(acumulado_camara), bold: true, fontSize: 10, alignment: 'center' },
        { text: this.funcaoService.convertToBrNumber(acumulado_despesa), bold: true, fontSize: 10, alignment: 'center' },
        { text: this.funcaoService.convertToBrNumber(acumulado_autarquia), bold: true, fontSize: 10, alignment: 'center' },
        { text: '', fontSize: 10, alignment: 'center' },
        { text: '', fontSize: 10, alignment: 'center' },
      ]);

      return [{
        table: {
          dontBreakRows: true,
          //headerRows: 1,
          widths: [85, 85, 85, 90, 90, 90, 90, 90],
          body: conteudo
        }
      }];

    } else {

      conteudo.push([{ text: ``, bold: true, fontSize: 10, alignment: 'center', colSpan: 7, border: [false, false, false, false], margin: [0, 10, 0, 10] }, '', '', '', '', '', ''])
      conteudo.push([{ text: `PREFEITURA - RECURSOS PRÓPRIOS (FONTES 01 e 08)`, bold: true, fontSize: 10, alignment: 'center', colSpan: 7, }, '', '', '', '', '', ''])

      conteudo.push([
        { text: 'MÊS', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
        { text: 'RECEITA', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
        { text: 'ACUMULADA', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
        { text: 'DESPESA', bold: true, fontSize: 10, colSpan: 3, alignment: 'center' },
        '',
        '',
        { text: 'SALDO', bold: true, fontSize: 10, rowSpan: 2, alignment: 'center' },
      ], [
        '',
        '',
        '',
        { text: 'CÂMARA', bold: true, fontSize: 10, alignment: 'center' },
        { text: 'PREFEITURA', bold: true, fontSize: 10, alignment: 'center' },
        { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
        '',
      ]);
      let acumulado_receita = 0;
      let acumulado_despesa = 0;
      let acumulado_camara = 0;
      let acumulado_total = 0;
      for (const receita of dados[2]['receita_prefeitura']) {
        for (const despesa of dados[2]['despesa_prefeitura']) {
          if (receita.referencia === despesa.referencia) {
            for (const camara_despesa of dados[2]['despesa_camara']) {
              if (receita.referencia === camara_despesa.referencia) {
                acumulado_receita += +receita.valor_previsto;
                acumulado_despesa += +despesa.valor_previsto;
                acumulado_camara += +camara_despesa.valor_previsto;
                acumulado_total += +camara_despesa.valor_previsto + +despesa.valor_previsto;
                conteudo.push([
                  { text: this.globalService.obterMes(+receita.referencia), fontSize: 10, alignment: 'center' },
                  { text: this.funcaoService.convertToBrNumber(+receita.valor_previsto), fontSize: 10, alignment: 'center' },
                  { text: this.funcaoService.convertToBrNumber(+acumulado_receita), fontSize: 10, alignment: 'center' },
                  { text: this.funcaoService.convertToBrNumber(+camara_despesa.valor_previsto), fontSize: 10, alignment: 'center' },
                  { text: this.funcaoService.convertToBrNumber(+despesa.valor_previsto), fontSize: 10, alignment: 'center' },
                  { text: this.funcaoService.convertToBrNumber(+acumulado_total), fontSize: 10, alignment: 'center' },
                  { text: this.funcaoService.convertToBrNumber(+acumulado_receita - +acumulado_total), fontSize: 10, alignment: 'center' },
                ]);
              }
            }
          }
        }
      }

      conteudo.push([
        { text: 'Acumulado', bold: true, fontSize: 10, alignment: 'center' },
        { text: this.funcaoService.convertToBrNumber(acumulado_receita), bold: true, fontSize: 10, alignment: 'center' },
        { text: '', fontSize: 10, alignment: 'center' },
        { text: this.funcaoService.convertToBrNumber(acumulado_camara), bold: true, fontSize: 10, alignment: 'center' },
        { text: this.funcaoService.convertToBrNumber(acumulado_despesa), bold: true, fontSize: 10, alignment: 'center' },
        { text: '', fontSize: 10, alignment: 'center' },
        { text: '', fontSize: 10, alignment: 'center' },
      ]);

      return [{
        table: {
          dontBreakRows: true,
          //headerRows: 1,
          widths: [100, 102, 102, 102, 102, 102, 102],
          body: conteudo
        }
      }];
    }

  }

  private conteudoReceitaDespesaOrgaos(dados): {}[] {
    const conteudo = []
    if (dados[3]['despesa'].length > 0 && dados[3]['receita'].length > 0) {
      const orgaos = this.funcaoService.agrupar(dados[3]['receita'], 'nome');
      for (const orgao of orgaos) {

        conteudo.push([{ text: '', bold: true, fontSize: 10, alignment: 'center', colSpan: 6, border: [false, false, false, false], margin: [0, 10, 0, 10] }, '', '', '', '', '',])

        conteudo.push([
          { text: orgao.grupo, bold: true, fontSize: 10, alignment: 'center', colSpan: 6 },
          '',
          '',
          '',
          '',
          ''
        ]);

        conteudo.push([
          { text: 'MÊS', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'RECEITA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'DESPESA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'SALDO', bold: true, fontSize: 10, alignment: 'center' },
        ]);
        let acumulado_receita = 0;
        let acumulado_despesa = 0;
        for (const receita of dados[3]['receita']) {
          for (const despesa of dados[3]['despesa']) {
            if (receita.referencia === despesa.referencia && receita.nome === despesa.nome && receita.nome === orgao.grupo) {
              acumulado_receita += +receita.valor_previsto;
              acumulado_despesa += +despesa.valor_previsto;
              conteudo.push([
                { text: this.globalService.obterMes(+receita.referencia), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+receita.valor_previsto), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+acumulado_receita), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+despesa.valor_previsto), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+acumulado_despesa), fontSize: 10, alignment: 'center' },
                { text: this.funcaoService.convertToBrNumber(+acumulado_receita - +acumulado_despesa), fontSize: 10, alignment: 'center' },
              ]);
            }
          }
        }

        conteudo.push([
          { text: 'Acumulado', bold: true, fontSize: 10, alignment: 'center' },
          { text: this.funcaoService.convertToBrNumber(acumulado_receita), bold: true, fontSize: 10, alignment: 'center' },
          { text: '', fontSize: 10, alignment: 'center' },
          { text: this.funcaoService.convertToBrNumber(acumulado_despesa), bold: true, fontSize: 10, alignment: 'center' },
          { text: '', fontSize: 10, alignment: 'center' },
          { text: '', fontSize: 10, alignment: 'center' },
        ]);
      }

    } else {
      const orgaos = this.funcaoService.agrupar(dados[3]['receita'], 'nome');
      for (const orgao of orgaos) {

        conteudo.push([{ text: '', bold: true, fontSize: 10, alignment: 'center', colSpan: 6, border: [false, false, false, false], margin: [0, 10, 0, 10] }, '', '', '', '', '',])

        conteudo.push([
          { text: orgao.grupo, bold: true, fontSize: 10, alignment: 'center', colSpan: 6 },
          '',
          '',
          '',
          '',
          ''
        ]);

        conteudo.push([
          { text: 'MÊS', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'RECEITA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'DESPESA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'ACUMULADA', bold: true, fontSize: 10, alignment: 'center' },
          { text: 'SALDO', bold: true, fontSize: 10, alignment: 'center' },
        ]);
        let acumulado_receita = 0;
        let acumulado_despesa = 0;
        for (const receita of dados[3]['receita']) {
          if (receita.referencia && receita.nome === orgao.grupo) {
            acumulado_receita += +receita.valor_previsto;
            acumulado_despesa += 0;
            conteudo.push([
              { text: this.globalService.obterMes(+receita.referencia), fontSize: 10, alignment: 'center' },
              { text: this.funcaoService.convertToBrNumber(+receita.valor_previsto), fontSize: 10, alignment: 'center' },
              { text: this.funcaoService.convertToBrNumber(+acumulado_receita), fontSize: 10, alignment: 'center' },
              { text: this.funcaoService.convertToBrNumber(0), fontSize: 10, alignment: 'center' },
              { text: this.funcaoService.convertToBrNumber(+acumulado_despesa), fontSize: 10, alignment: 'center' },
              { text: this.funcaoService.convertToBrNumber(+acumulado_receita - +acumulado_despesa), fontSize: 10, alignment: 'center' },
            ]);
          }
        }

        conteudo.push([
          { text: 'Acumulado', bold: true, fontSize: 10, alignment: 'center' },
          { text: this.funcaoService.convertToBrNumber(acumulado_receita), bold: true, fontSize: 10, alignment: 'center' },
          { text: '', fontSize: 10, alignment: 'center' },
          { text: this.funcaoService.convertToBrNumber(acumulado_despesa), bold: true, fontSize: 10, alignment: 'center' },
          { text: '', fontSize: 10, alignment: 'center' },
          { text: '', fontSize: 10, alignment: 'center' },
        ]);
      }
    }
    return [{
      table: {
        dontBreakRows: true,
        //headerRows: 1,
        widths: [120, 120, 120, 120, 120, 120],
        body: conteudo
      }
    }];
  }

  public obterDemonstrativoAuxiliarDeGastos(exercicio: number, ppa: number, mes: number, quadrimestre: 4 | 8 | 12, acumulado: boolean): Observable<number> {
    const params = new HttpParams().appendAll({
      exercicio,
      ppa,
      mes,
      quadrimestre,
      acumulado
    });

    const options = { ...this.httpOptions(), params };
    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/demonstrativo-auxiliar-de-gastos`, options)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public empenhosNaoLiquidados(parametros: ParametroEmpenhosNaoLiquidados): Observable<number> {
    const { exercicio_id, orgao_id, data_fim, mes, funcao_governo, recurso, aplicacao } = parametros;

    let params = new HttpParams().appendAll({
      exercicio_id,
      orgao_id,
    });

    if (mes) {
      params = params.append('mes', mes);
    }

    if (data_fim) {
      params = params.append('data_fim', data_fim);
    }

    if (funcao_governo) {
      params = params.append('funcao_governo', funcao_governo);
    }

    if (recurso) {
      params = params.append('recurso', recurso);
    }

    if (aplicacao) {
      params = params.append('aplicacao', aplicacao);
    }

    const options = { ...this.httpOptions(), params };

    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/empenhos-nao-liquidados`, options)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterEvolucaoDeDespesa(orgaos: number[], ano: number): Observable<number> {
    const params = new HttpParams().appendAll({
      orgaos,
      ano
    });

    const options = { ...this.httpOptions(), params };

    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/evolucao-de-despesa`, options)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterEvolucaoDeReceita(orgaos: number[], ano: number): Observable<number> {
    const params = new HttpParams().appendAll({
      orgaos,
      ano
    });

    const options = { ...this.httpOptions(), params };

    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/evolucao-de-receita`, options)
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


  // private conteudoDespesa(despesas: any[], gastos: any[]): {}[] {
  //   const conteudo = [];

  //   conteudo.push([
  //     { text: 'ESPECIFICAÇÃO', bold: true, fontSize: 12, alignment: 'center' },
  //     { text: 'PAGA MES', bold: true, fontSize: 12, alignment: 'center' },
  //     { text: 'PAGA ACUMULADA', bold: true, fontSize: 12, alignment: 'center' }
  //   ]);

  //   let subtotal_mensal = 0;
  //   let subtotal_acumulado = 0;

  //   for (const item of despesas) {
  //     let acumulado_antigo = item.acumulado_antigo ? +item.acumulado_antigo : 0
  //     let mensal_antigo = item.mensal_antigo ? +item.mensal_antigo : 0
  //     subtotal_mensal += (+item.mensal + mensal_antigo)
  //     subtotal_acumulado += (+item.acumulado + acumulado_antigo)
  //     conteudo.push([
  //       { text: item.titulo, fontSize: 10, border: [true, false, true, false] },
  //       { text: this.funcaoService.convertToBrNumber(+item.mensal + mensal_antigo), fontSize: 10, alignment: 'right', border: [true, false, true, false] },
  //       { text: this.funcaoService.convertToBrNumber(+item.acumulado + acumulado_antigo), fontSize: 10, alignment: 'right', border: [true, false, true, false] }
  //     ]);
  //   }

  //   conteudo.push([
  //     { text: 'SUB TOTAL SAÍDAS', fontSize: 10, bold: true },
  //     { text: this.funcaoService.convertToBrNumber(subtotal_mensal), fontSize: 10, alignment: 'right', bold: true },
  //     { text: this.funcaoService.convertToBrNumber(subtotal_acumulado), fontSize: 10, alignment: 'right', bold: true }
  //   ]);

  //   conteudo.push([{ text: '', border: [false] }, { text: '', border: [false] }, { text: '', border: [false] }]);

  //   let i = 1;
  //   let valor_i = 0;
  //   let valor_ii = 0;
  //   let valor_iii = 0;
  //   let valor_iv = 0;
  //   let valor_v = 0;
  //   for (const item of gastos) {
  //     let bordas: boolean[] = [true, false, true, false];
  //     switch (i) {
  //       case 1:
  //         bordas = [true, true, true, false];
  //         break;
  //       case 3:
  //         valor_i = +item.acumulado;
  //         break;
  //       case 4:
  //         valor_ii = +item.acumulado;
  //         break;
  //       case 5:
  //         valor_iii = +item.acumulado;
  //         break;
  //       case 6:
  //         valor_iv = +item.acumulado;
  //         break;
  //       case 7:
  //         valor_v = +item.acumulado;
  //         break;
  //     }
  //     i++;
  //     conteudo.push([
  //       { text: item.titulo, fontSize: 10, border: bordas, colSpan: 2 }, '',
  //       { text: this.funcaoService.convertToBrNumber(item.acumulado), fontSize: 10, alignment: 'right', border: bordas }
  //     ]);
  //   }
  //   conteudo.push([
  //     { text: 'DISPONIBILIDADE EMPENHADA E RESTOS A PAGAR (VI)=(I-II-III-IV-V)', fontSize: 10, border: [true, true, false, true], colSpan: 2, bold: true },
  //     { text: '', border: [false, true, true, true] },
  //     { text: this.funcaoService.convertToBrNumber(valor_i - valor_ii - valor_iii - valor_iv - valor_v), fontSize: 10, alignment: 'right', border: [true, true, true, true], bold: true }
  //   ]);
  //   conteudo.push([
  //     { text: 'DISPONIBILIDADE LIQUID./PROC. E RESTOS A PAGAR (VII)=(I-III-IV-V)', fontSize: 10, border: [true, true, false, true], colSpan: 2, bold: true },
  //     { text: '', border: [false, true, true, true] },
  //     { text: this.funcaoService.convertToBrNumber(valor_i - valor_iii - valor_iv - valor_v), fontSize: 10, alignment: 'right', border: [true, true, true, true], bold: true }
  //   ]);

  //   return [{
  //     table: {
  //       dontBreakRows: true,
  //       headerRows: 1,
  //       widths: ['*', 'auto', 'auto'],
  //       body: conteudo
  //     }
  //   }];
  // }

  // private async conteudoAssinatura() {
  //   const conteudo = [];
  //   this.assinatura = await this.assinaturaService.obter({ orgao_id: this.login.orgao.id }).toPromise();

  //   conteudo.push([
  //     {
  //       text: '_____________________________________________',
  //       border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 20, 0, 0]
  //     },
  //     {
  //       text: '_____________________________________________',
  //       border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 20, 0, 0]
  //     },
  //     {
  //       text: '_____________________________________________',
  //       border: [false, false, false, false], bold: true, alignment: 'center', margin: [0, 20, 0, 0]
  //     }
  //   ]);
  //   conteudo.push([
  //     { text: this.assinatura.assinatura3, border: [false, false, false, false], bold: true, alignment: 'center' },
  //     { text: this.assinatura.assinatura2, border: [false, false, false, false], bold: true, alignment: 'center' },
  //     { text: this.assinatura.assinatura1, border: [false, false, false, false], bold: true, alignment: 'center' }
  //   ]);
  //   conteudo.push([
  //     { text: this.assinatura.cargo_assinatura3, border: [false, false, false, false], bold: true, alignment: 'center' },
  //     { text: this.assinatura.cargo_assinatura2, border: [false, false, false, false], bold: true, alignment: 'center' },
  //     { text: this.assinatura.cargo_assinatura1, border: [false, false, false, false], bold: true, alignment: 'center' }
  //   ]);

  //   return [{
  //     table: {
  //       dontBreakRows: true,
  //       headerRows: 1,
  //       widths: ['auto', 'auto', 'auto'],
  //       body: conteudo
  //     }
  //   }];
  // }
}