import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuncaoService, Retencao } from 'eddydata-lib';
import * as toastr from 'toastr';
import { RetencaoService } from '../service/retencao.service';

@Component({
  selector: 'lib-retencao-reinf',
  templateUrl: './retencao-reinf.component.html'
})
export class RetencaoReinfComponent {
  @Input() retencao: Retencao;
  @Output() callback: EventEmitter<Retencao> = new EventEmitter();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  paises: { codigo: number, nome: string }[] = [];

  constructor(private retencaoService: RetencaoService, private funcaoService: FuncaoService) { }

  salvar() {
    this.retencao.valor_aliquota = null;
    this.retencaoService.atualizar(this.retencao).subscribe((res) => {
      toastr.success('Informações adicionais para retenção salva com sucesso');
      this.retencao = res;
      this.callback.emit(this.retencao);
    }, (e) => { this.funcaoService.acaoErro(e) });
  }
}
