import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { Ajuste, Empenho, Execucao, FuncaoService, LicitacaoAudesp, listarSituacoes, RegistroPrecos } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';

@Component({
  selector: 'lib-licitacao-audesp-execucao',
  templateUrl: './licitacao-audesp-execucao.component.html'
})
export class LicitacaoAudespExecucaoComponent extends BaseLicitacaoAudesp implements OnInit, OnChanges, OnDestroy {

  @Input() public entidade: LicitacaoAudesp;
  @Input() public ajuste: LicitacaoAudesp;
  @Input() public registroPreco: LicitacaoAudesp;
  @Input() public empenho: Empenho;

  public arquivo: Execucao;
  public opcoesSituacaoAjuste: { id: number, nome: string }[];
  public opcoesRegimeContratacao: { id: number, nome: string }[];
  public opcoesFormaRecebimento: { id: number, nome: string }[];
  public opcoesSimNaoNaoObra: { id: 'S' | 'N' | 'NaoObra', nome: string }[];
  public opcoesSimNaoParcial: { id: 'ST' | 'N' | 'SP', nome: string }[];
  public listaEmpenho: Empenho[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    public funcaoService: FuncaoService,
    public empenhoService: EmpenhoService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.arquivo, this.ajuste, this.empenho, this.registroPreco)
    if (changes) {
      if (this.entidade.id) {
        this.arquivo = this.entidade.arquivo as Execucao;
      } else {
        if (!this.ajuste?.licitacao || !this.entidade) {
          this.iniciarObjeto();
        } else {
          this.importarInformacoes();
        }
        if (this.empenho) {
          this.arquivo.empenhoId = this.empenho.id;
          this.carregarEmpenhos();
        }
      }
      this.carregarEmpenhos();
    }
  }

  ngOnInit(): void {
    this.iniciarObjeto();
    this.opcoesSituacaoAjuste = listarSituacoes();
    this.opcoesRegimeContratacao = [
      { id: 1, nome: 'Entrega Imediata' }, { id: 2, nome: 'Entrega Fracionada' }
    ]
    this.opcoesFormaRecebimento = [
      { id: 1, nome: 'Provisório' }, { id: 2, nome: 'Definitivo' }
    ]
    this.opcoesSimNaoNaoObra = [
      { id: 'S', nome: 'Sim' }, { id: 'N', nome: 'Não' }, { id: 'NaoObra', nome: 'Não é obra' },
    ]
    this.opcoesSimNaoParcial = [
      { id: 'ST', nome: 'Sim - Total' }, { id: 'SP', nome: 'Sim - Parcial' }, { id: 'N', nome: 'Não' },
    ]
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  private iniciarObjeto() {
    this.entidade.arquivo = new Execucao();
    this.arquivo = this.entidade.arquivo;
  }

  private importarInformacoes() {
    if (!this.ajuste?.licitacao || !this.entidade) return;

    this.iniciarObjeto();

    this.arquivo.CodigoAjuste = +this.ajuste.codigo;
    if (this.registroPreco)
      this.arquivo.tipoObjeto = (JSON.parse(this.registroPreco.arquivo) as RegistroPrecos).tipoObjeto;
  }

  private carregarEmpenhos() {
    if (!this.ajuste) return
    const ajusteObj: Ajuste = JSON.parse(this.ajuste.arquivo) as Ajuste;
    if (!ajusteObj) return

    if (this.ajuste.tipo === 'AJUSTE_EMPENHO') {
      if (ajusteObj?.NotaEmpenho?.length > 0 && ajusteObj.NotaEmpenho[0].empenhoId) {
        this.empenhoService.obter({ id: ajusteObj.NotaEmpenho[0].empenhoId, relations: 'contrato,exercicio' }).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.listaEmpenho = [res]
            this.arquivo.ConferenciaMedicaoSim.PercentualExecucao = 100;
          })
      }
    } else {
      this.empenhoService.filtrar(0, 0, {
        'contrato.id': ajusteObj.contratoId, relations: 'contrato,exercicio',
        ignoreCondObrig: true, orgao_id: this.login.orgao.id, orderBy: 'numero',
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaEmpenho = res.content

          if (!this.entidade.id)
            this.atribuirEmpenho();
        });
    }
  }

  public atribuirEmpenho() {
    if (this.listaEmpenho) {
      if (!this.arquivo.empenhoId) this.arquivo.empenhoId = this.listaEmpenho[0].id
      this.empenho = this.listaEmpenho.find(c => c.id === this.arquivo.empenhoId)
      if (!this.empenho?.contrato) return;

      if (this.empenho.valor_empenho > 0 && this.empenho.contrato.valor_contrato > 0) {
        this.arquivo.ConferenciaMedicaoSim.PercentualExecucao = (this.empenho.valor_empenho / this.empenho.contrato.valor_contrato) * 100;
        if (this.arquivo.ConferenciaMedicaoSim.PercentualExecucao > 100)
          this.arquivo.ConferenciaMedicaoSim.PercentualExecucao = 100;
      } else
        this.arquivo.ConferenciaMedicaoSim.PercentualExecucao = 0
    }
  }
}
