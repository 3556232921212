import { BaseResourceRptComponent, Coluna, FuncaoService, PlanoContratacaoItem } from "eddydata-lib";
import { PlanoContratacaoAnualItensService } from "../../plano-contratacao-anual/service/plano-contratacao-anual-itens.service";
import { takeUntil } from "rxjs/operators";

export class PlanoContratacaoPlanejamentoRelatorioItem extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  constructor(
    protected planoItemService: PlanoContratacaoAnualItensService,
    protected funcaoService: FuncaoService
  ) {
    super()
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    // this.login
  }

  protected tituloRelatorio(): string {
    return this.login.orgao.nome
  }

  protected subTituloRelatorio(): string {
    return 'PLANO DE CONTRATACAO ANUAL - PLANEJAMENTO / POR ITEM'
  }

  protected obterColunasRelatorio(): Coluna[] {
    return [
      { titulo: 'Item', coluna: 'numero', alignment: 'center' },
      { titulo: 'Tipo', coluna: 'tipo' },
      { titulo: 'Categoria', coluna: 'categoria' },
      // { titulo: 'Subelemento', coluna: 'subelemento' },
      { titulo: 'Produto / Serviço', coluna: 'produto' },
      { titulo: 'Unidade', coluna: 'unidade_fornecimento' },
      { titulo: 'Quantidade', coluna: 'quantidade', decimais: 2 },
      { titulo: 'Valor Unit.', coluna: 'valor_unitario', decimais: 2 },
      { titulo: 'Total', coluna: 'total', decimais: 2 },
      { titulo: 'Valor Orc.', coluna: 'valor_orcamentario', decimais: 2 }
    ]
  }

  protected larguraColunas(): (string | number)[] {
    return ['*', '*', '*', 'auto', '*', '*', '*', '*', '*']
  }

  protected totalizarColunas(): (string | {})[] {
    return ['total', 'valor_orcamentario']
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve) => {
      this.planoItemService.filtrar(1, -1, { relations: 'plano', 'plano.exercicio_id': this.login.exercicio.id, orderBy: 'numero$ASC', 'plano.orgao_id': this.login.orgao.id }).pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
        this.formato = 'pdf'
        this.orientacao = "landscape";
        resolve(this.normalizarDados(lista.content))
      })
    })
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================

  public retornaCategoria(valor: number) {
    if (+valor === 1) return '1 - Material'
    if (+valor === 2) return '2 - Serviço'
    if (+valor === 3) return '3 - Obras'
    if (+valor === 4) return '4 - Serviços de Engenharia'
    if (+valor === 5) return '5 - Soluções de TIC'
    if (+valor === 6) return '6 - Locação de Imóveis'
    if (+valor === 7) return '7 - Alienação, concessão ou permissão'
    if (+valor === 8) return '8 - Obras e Serviços de Engenharia'
  }

  public retornaClassificacao(valor: number) {
    if (+valor === 1) return '1 - Material'
    if (+valor === 2) return '2 - Serviço'
  }

  public valorUnitarioMedio(item: { grupo?: string | {}; totalizadores: any; registros: any; }) {
    let numeroRegistro = item.registros.length
    return (item.totalizadores['valor_unitario'] / numeroRegistro)
  }

  public normalizarDados(lista: PlanoContratacaoItem[]) {

    const listaNormalizadaA = lista.map((item, idx) => {
      item['numero'] = idx + 1
      item['categoria'] = this.retornaCategoria(item.categoria_item)
      item['tipo'] = this.retornaClassificacao(item.classificacao_catalogo)
      item['produto'] = `${item.codigo_item} - ${item.classificacao_superior_nome}`
      item['total'] = +item.quantidade * +item.valor_unitario
      item['valor_orcamentario'] = +item.valor_orcamentario
      return item
    })

    const listaAgrupoItem = this.funcaoService.agrupar(listaNormalizadaA, ['produto', 'tipo', 'categoria', 'unidade_fornecimento'], ['quantidade', 'valor_unitario', 'total', 'valor_orcamentario'])

    const listaNormalizadaB = listaAgrupoItem.map((item, idx) => {
      let novoItem = {
        numero: idx + 1,
        tipo: item.grupo['tipo'],
        categoria: item.grupo['categoria'],
        produto: item.grupo['produto'],
        unidade_fornecimento: item.grupo['unidade_fornecimento'],
        quantidade: item.totalizadores['quantidade'],
        valor_unitario: this.valorUnitarioMedio(item),
        total: item.totalizadores['total'],
        valor_orcamentario: item.totalizadores['valor_orcamentario']
      }

      return novoItem
    })
    // console.log(listaNormalizadaB)

    return listaNormalizadaB
  }

}