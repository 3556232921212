import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpenhoService, LiquidacaoService } from 'administrativo-lib';
import { DateFormatPipe, Empenho, FuncaoService, GlobalService, Liquidacao, Login, PreLiquidacao, Retencao } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Inplace } from 'primeng/inplace';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { PreLiquidacaoService } from '../../pre-liquidacao/service/pre-liquidacao.service';
import { RetencaoService } from '../service/retencao.service';

declare var $: any;

@Component({
  selector: 'lib-liquidacao-anulacao',
  templateUrl: './liquidacao-anulacao.component.html'
})

export class LiquidacaoAnulacaoComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() login: Login;
  @Input() empNumero: number;
  @Input() idLiquidacao: number;
  @Input() data_referencia: Date;
  @Input() empenho: Empenho;
  @Input() valor_liquidacao: number
  @Input() visualizar: boolean = false;
  @Input() valorMovimento: number = 0;
  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('inplaceRet') inplaceRet: Inplace;

  protected datepipe: DatePipe;
  public ptBR: any;
  public data: Date;
  public data_referencia2: Date;
  public valor: number;
  public valorLiquidacao: number;
  public valorRetencao: number;
  public historico: string;
  public listAnulacoes: Array<Liquidacao>;
  public preliquidacaoIncluir: PreLiquidacao;
  public visualizarPre: boolean = false;
  public listaRetencoes: Array<Retencao>;
  public listaRetencoesAnuladas: Array<Retencao>;
  private liquidacao: Liquidacao;
  protected unsubscribe: Subject<void> = new Subject();

  //Totalizadores
  public liquidado: number = 0;
  public retido: number = 0;
  public pago: number = 0;

  //Aviso
  public retornoRetencoes = [];
  public carregando: boolean = false;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private funcaoService: FuncaoService,
    protected retencaoService: RetencaoService,
    private liquidacaoService: LiquidacaoService,
    protected preService: PreLiquidacaoService,
    protected confirmationService: ConfirmationService,
    protected empenhoService: EmpenhoService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.data = new Date();
    this.valor = 0;
    this.valorLiquidacao = 0;
    this.valorRetencao = 0;
    this.historico = '';
    this.data_referencia = new DateFormatPipe().transform(this.data_referencia, []);
    this.liquidado = 0;
    this.retido = 0;
    this.pago = 0;
    this.retornoRetencoes = [];

    if (this.idLiquidacao && !this.carregando) {
      this.carregando = true;
      this.obterRetencoes(this.idLiquidacao);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  public async totalizar(jaTotalizado: boolean) {
    this.valor = 0;
    if (!jaTotalizado) {
      try {
        this.liquidacao = await this.liquidacaoService.obter({ id: this.idLiquidacao, relations: 'empenho', 'orgao.id': this.login.orgao.id, 'exercicio.id': this.login.exercicio.id }).toPromise();
        const liq = this.liquidacao;
        let totalizadores = { total_liquidado: 0, total_pago: 0 }
        totalizadores.total_liquidado = (await this.liquidacaoService.obterTotalLiquidadoPorParcela(liq.empenho.numero, liq.exercicio.id, liq.orgao.id, liq.parcela).toPromise()).liquidado;
        totalizadores.total_pago = (await this.liquidacaoService.obterTotalPagoPorParcela(liq.exercicio.id, liq.orgao.id, liq.id).toPromise()).pago;
        
        if (totalizadores) {
          this.liquidado = totalizadores.total_liquidado;
          this.pago = totalizadores.total_pago;

          this.valorLiquidacao = this.funcaoService.arredondar(+((this.liquidado - this.pago) - this.retido), 2);
          this.valor = this.funcaoService.arredondar(+(this.valorLiquidacao + this.valorRetencao), 2);

          if (this.valorLiquidacao >= this.funcaoService.arredondar(+(this.liquidado - this.pago), 2)) {
            this.valorLiquidacao = this.funcaoService.arredondar(+(this.liquidado - this.retido), 2);
            this.valor = this.funcaoService.arredondar(+(this.valorLiquidacao + this.valorRetencao), 2);
          }

          if (this.pago > 0 && this.listaRetencoes.length > 0) {
            this.retornoRetencoes = [];
            this.retornoRetencoes.push(['Liquidação paga, as anulações das retenções devem ser feitas pelo pagamento dessa liquidação se necessário. '
              + `Atualmente R$${this.funcaoService.arredondar(this.retido,2)} em ${this.listaRetencoes.length} ${(this.listaRetencoes.length > 1 ? 'retenções' : 'retenção')}.`, 0]);

            this.listaRetencoes = [];
            this.valorRetencao = 0;
            this.valorLiquidacao = this.funcaoService.arredondar(+(this.liquidado - this.pago), 2);
            this.valor = this.valorLiquidacao;
          }
          this.totalizar(true);//Totaliza as retenções carregadas;
        } else {
          this.liquidado = 0;
          this.pago = 0;
          this.valorLiquidacao = 0;
          this.valor = 0;
          this.retido = 0;
          this.listaRetencoes = new Array<any>();
          this.retornoRetencoes.push(['Falha ao encontrar e contabilizar retenções e pagamento de rentenções, por favor, recarregue a pagina, se o erro persistir, entre em contato com um administrador.', 0]);
        }
      } catch (e) {
        this.liquidado = 0;
        this.pago = 0;
        this.valorLiquidacao = 0;
        this.valor = 0;
        this.retido = 0;
        this.listaRetencoes = new Array<any>();
        this.retornoRetencoes.push(['Falha ao encontrar e contabilizar retenções e pagamento de rentenções, por favor, recarregue a pagina, se o erro persistir, entre em contato com um administrador.', 0]);

      }
    } else {
      if (this.valorLiquidacao > this.funcaoService.arredondar(+(this.liquidado - this.pago - (this.listaRetencoes?.length > 0 ? this.retido : 0)), 2)) {
        this.valorLiquidacao = this.funcaoService.arredondar(+(this.liquidado - this.retido - this.pago), 2);
        toastr.warning('Valor a anular da liquidação é superior ao disponível' + (this.retido > 0 ? ' considerando as retenções,' : ',')
          + ' valor ajustado automaticamente para o limite possível');
      }
      this.valor = this.funcaoService.arredondar(+(this.valorLiquidacao + this.valorRetencao), 2);
    }
  }

  private obterRetencoes(id: number) {
    this.retencaoService.extendido(0, -1, {
      liquidacao_id: id,
      relations: 'ficha,ficha.ficha,ficha.plano,ficha.favorecido,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,'
        + 'liquidacao.orgao,liquidacao.exercicio,anulacoes'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async (data: any) => {
          this.valorRetencao = 0;
          this.retido = 0;
          const lst = data ? data.content : new Array<Retencao>();
          this.listaRetencoes = [];
          this.listaRetencoesAnuladas = [];
          
          for (const item of lst) {
            let total_anulado_retencao = 0;
            for (const retAnulacao of item?.anulacoes) {
              total_anulado_retencao += +retAnulacao.valor_retido
            }

            if (item.valor_retido < 0) {
              this.listaRetencoesAnuladas.push(item);
              continue;
            }

            if (item.anulado) {
              this.retido += +item.valor_retido + total_anulado_retencao;
              continue;
            }

            const obj = Object.assign(new Retencao(), item);
            //Valores originais para comparação
            obj['valor_original'] = item.valor_retido;
            obj['ret_codigo'] = item.id;
            obj.valor_retido = 0;

            this.valor = this.valorRetencao;
            await this.verificarPagamentoRetencao(obj);

            //Total de valor a ser anulado nas retenções
            this.valorRetencao += +obj['valor_anulando'];

            this.valorRetencao = this.funcaoService.arredondar(+(this.valorRetencao), 2); 

            this.listaRetencoes.push(obj);

            //Total de valor retido
            this.retido += +item.valor_retido;
          }
          this.carregando = false;
          this.totalizar(false);
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  private async verificarPagamentoRetencao(retencao: Retencao) {
    const retorno = await this.retencaoService.verificarPagamentoRetencao(retencao.id).toPromise();

    retencao['disponivel_anulacao'] = this.funcaoService.arredondar(+(retencao['total_retido_atual'] - +retorno[1]), 2);
    if (+retencao['disponivel_anulacao'] > +retencao['valor_original']) {
      retencao['disponivel_anulacao'] = +retencao['valor_original']
    }
    retencao['valor_anulando'] = +retencao['disponivel_anulacao'];
    retencao.valor_retido = +(+retencao['total_retido_atual'] - +retencao['valor_anulando']).toFixed(2);

    if (retorno[0] != '' && !retorno[0].includes('já está paga e precisa antes ser anulada no pagamento')) this.retornoRetencoes.push(retorno);
  }

  public anular() {
    if (this.valorMovimento > 0 && (this.valor != this.valorMovimento)) {
      this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Valor total da anulação (R$${this.valor}) é diferente do valor da movimentação ${this.valorMovimento}, liquidações de movimentação somente podem ser anuladas integralmente.` })
      return;
    }
    if (this.valor > this.funcaoService.arredondar(+(this.liquidado - this.pago), 2)) {
      this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Valor total da anulação (R$${this.valor}) ultrapassa o limite de R$${this.funcaoService.arredondar(+(this.liquidado - this.pago), 2)}` })
      return;
    }
    if (this.retornoRetencoes.length > 0 && this.listaRetencoes.length > 0) {
      this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Existem impedimentos para que a anulação seja realizada, verifique a seção "ATENÇÃO"` })
      return;
    }
    if (this.listaRetencoesAnuladas?.length > 0) {
      let soma: number = 0.0;
      for (const r of this.listaRetencoesAnuladas) {
        soma = this.funcaoService.arredondar(+(soma + (r['total_retido_atual'] * -1)), 2);
      }

      if (this.valor < soma) {
        this.messageService.add({ severity: 'error', summary: 'Atenção', detail: `Valor sendo anulado inferior aos valores de retenções já anuladas, o mínimo deve ser R$${soma * -1}` });
        return;
      }
    }

    const p = new Promise<void>((resolve, reject) => {
      const texto = '<pre>Deseja continuar? Serão anulado os valores informados acima, em caso de retenções, serão anulados os empenhos extras se houver.\n'
        + 'Anulando: R$' + this.valorLiquidacao + ' em valor da liquidação.\n'
        + 'Anulando: R$' + this.valorRetencao + ' em retenções.\n'
        + 'Totalizando R$' + this.valor + ' em anulação do valor liquidado.'
        + '</pre>';

      this.confirmationService.confirm({
        message: texto,
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Anular liquidação',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmNovaPre',
        accept: () => {
          resolve();
        }, reject: () => {
          reject();
        }
      });
    });

    p.then(() => {
      if (this.valorLiquidacao > 0) {
        let retencoes = [];
        retencoes = this.listaRetencoes;
        retencoes = retencoes.concat(this.listaRetencoesAnuladas);

        this.route.paramMap
          .pipe(switchMap(params => this.liquidacaoService.anular(
            Number(this.idLiquidacao),
            String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
            this.valor,
            this.historico,
            this.login.usuario,
            retencoes
          )))
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (dados: Liquidacao) => {
              this.preliquidacaoIncluir = dados?.liquidacao_anulada?.preliquidacao;
              if (this.preliquidacaoIncluir) {
                this.confirmationService.confirm({
                  key: 'confirmNovaPre',
                  header: 'Atenção',
                  message: 'Ao realizar a anulação da liquidação a pré-liquidação será anulada automaticamente. Deseja cadastrar uma nova pré-liquidação?',
                  acceptLabel: 'Sim',
                  rejectLabel: 'Não',
                  accept: () => this.novaPre(),
                  reject: () => this.voltar(),
                });
              } else {
                this.voltar();
              }
            }, error => {
              if (error.error && error.error.payload) {
                toastr.error(error.error.payload);
              } else {
                toastr.error('Ocorreu um erro ao processar a sua solicitação');
              }
            });
      } else if (this.valorRetencao > 0) {
        this.retencaoService.anular(this.listaRetencoes,
          String(this.funcaoService.converteDataSQL(this.data.toLocaleDateString())),
          this.historico,
          this.login.usuario).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
            this.voltar();
          }, (e) => {
            if (e.error && e.error.payload) {
              toastr.error(e.error.payload);
            } else {
              toastr.error('Ocorreu um erro ao processar a sua solicitação');
            }
          });
      }
    }).catch(() => {
      toastr.info('Anulação cancelada.');
    });
  }

  private novaPre(): void {
    this.hide();
    this.visualizarPre = true;
  }

  private voltar(): void {
    this.hide();
    this.router.navigate(['/liquidacoes-orcamentaria']);
    toastr.success('Anulação realizada com sucesso!');
    this.onFinalizar.emit();
  }

  public buscarAnulacoes() {
    this.route.paramMap
      .pipe(switchMap(params => this.liquidacaoService.filtrar(0, -1,
        {
          relations: 'usuario_cadastro',
          anulacao: true,
          exercicio_id: this.login.exercicio.id,
          orgao_id: this.login.orgao.id,
          'empenho.numero': this.empNumero
        }
      )))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (dados) => {
          this.listAnulacoes = dados.content;
        }, error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Ocorreu um erro ao processar a sua solicitação');
          }
        });
  }

  public salvarItem(retencao: Retencao) {
    if (+retencao['valor_anulando'] < 0) {
      retencao.valor_retido = retencao['total_retido_atual'];
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado da retenção não pode ser negativo!' });
      return;
    } else if (+retencao['valor_anulando'] > +retencao['total_retido_atual']) {
      retencao.valor_retido = retencao['total_retido_atual'];
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado deve ser superior no máximo o valor original da retenção. !' });
      return;
    } else if (+retencao['valor_anulando'] > +retencao['disponivel_anulacao']) {
      retencao['valor_anulando'] = 0.0;
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Valor a ser anulado é superior ao valor disponível!' });
      return;
    }

    retencao.valor_retido = this.funcaoService.arredondar(+(+retencao['total_retido_atual'] - +retencao['valor_anulando']), 2);
    this.valorRetencao = 0;
    for (const r of this.listaRetencoes) {
      this.valorRetencao += +r['valor_anulando'];
    }

    this.totalizar(true);
  }

  public focusInput(input: any) {
    let interval = setTimeout(() => {
      input.select();
      clearInterval(interval);
    }, 100);
  }

  public confirmarRemocao(item: Retencao, index: number) {
    this.confirmationService.confirm({
      message: `Deseja remover a retenção ${item.id} da anulação?`,
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção de retenção da anulação',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmNovaPre',
      accept: () => {
        this.removerItem(item, index)
      },
    });
  }

  private removerItem(item: Retencao, index: number) {
    this.listaRetencoes.splice(index, 1);
    this.valorRetencao = 0;
    for (const r of this.listaRetencoes) {
      this.valorRetencao += +r['valor_anulando'];
    }
    this.totalizar(true);
  }

  public hide() {
    $('#dialogAnular').modal('hide');
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public hidePre() {
    $('#dialogPreliquidacao').modal('hide');
    this.visualizarPre = false;
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
    $('#dialogAnular').modal('show');
  }
}
