import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LicitacaoService } from 'administrativo-lib';
import { BaseResourceFormComponent, Compra, EddyAutoComplete, FuncaoService, Licitacao, LicitacaoAudesp, Login, LoginContabil, Pagina } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { CompraService } from '../../../compra/service/compra.service';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-form-registro-preco',
  templateUrl: './licitacao-audesp-form-registro-preco.component.html'
})
export class LicitacaoAudespFormRegistroPrecoComponent extends BaseResourceFormComponent<LicitacaoAudesp, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public compraAutoComplete: EddyAutoComplete<Compra>;
  public opcoesSimNao: { id: 'S' | 'N', nome: string }[];
  public registroPrecoSim: 'S' | 'N' = 'N';
  public mensagemXML: string[];
  public xmlInvalido: string;
  public compraDireta: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    protected licitacaoService: LicitacaoService,
    protected compraService: CompraService,
    protected audespService: LicitacaoAudespService,
  ) {
    super(new LicitacaoAudesp(), injector, LicitacaoAudesp.converteJson, audespService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      tipo: ['LIC_REG_SIM', [Validators.required]],
      arquivo: [null],
      parente: [null],
      codigo: [null],
      exportado: [false, [Validators.required]],
      enviado: [false, [Validators.required]],
      armazenado: [false, [Validators.required]],
      licitacao: [null],
      compra: [null],
      pacote: [null],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'licitacao.modalidade,pacote,compra.licitacao,compra.modalidade,licitacao.itens.produto_unidade.produto,compra.itens.produto_unidade.produto,licitacao.orgao,compra.orgao' };
  }

  protected afterLoad() {
    this.registroPrecoSim = this.entidade.tipo === 'LIC_REG_SIM' ? 'S' : 'N';
    this.entidade.arquivo = JSON.parse(this.entidade.arquivo);
    this.tratarDatas('pt-br');

    // se nao for registro de preço redireciona para rota especifica
    if (this.entidade.tipo !== 'LIC_REG_SIM' && this.entidade.tipo !== 'LIC_REG_NAO1' && this.entidade.tipo !== 'LIC_REG_NAO2' && this.entidade.tipo !== 'LIC_REG_NAO3') {
      switch (this.entidade.tipo) {
        case 'AJUSTE_CONTRATO':
        case 'AJUSTE_EMPENHO':
          if (this.entidade.parente) {
            this.router.navigate([Pagina['Audesp Fase 4'].substr(1), this.entidade.parente.id, 'ajuste', this.entidade.id, 'editar'])
          } else {
            this.audespService.obter({ id: this.entidade.id, relations: 'parente' }).pipe(takeUntil(this.unsubscribe))
              .subscribe((dados) => {
                this.router.navigate([Pagina['Audesp Fase 4'].substr(1), dados.parente.id, 'ajuste', dados.id, 'editar'])
              })
          }
          break;
        case 'EXECUCAO':
        case 'DOC_FISCAL':
        case 'PAGAMENTO':
        case 'EXIGENCIA_OBRAS':
          const rota: string = this.entidade.tipo === 'EXECUCAO' ? 'execucao'
            : this.entidade.tipo === 'DOC_FISCAL' ? 'documento-fiscal'
              : this.entidade.tipo === 'PAGAMENTO' ? 'pagamento'
                : this.entidade.tipo === 'EXIGENCIA_OBRAS' ? 'exigencia-obras'
                  : '';
          if (this.entidade.parente?.parente) {
            this.router.navigate([Pagina['Audesp Fase 4'].substr(1), this.entidade.parente.parente.id, this.entidade.parente.id, rota, this.entidade.id, 'editar'])
          } else {
            this.audespService.obter({ id: this.entidade.id, relations: 'parente.parente' }).pipe(takeUntil(this.unsubscribe))
              .subscribe((dados) => {
                this.router.navigate([Pagina['Audesp Fase 4'].substr(1), dados.parente.parente.id, dados.parente.id, rota, dados.id, 'editar'])
              })
          }
          break;
        default:
          toastr.warning(`Acesso exclusivo para registros de preços, tentativa com ${this.entidade.tipo}`)
          this.router.navigate([Pagina['Audesp Fase 4'].substr(1)])
          break;
      }
    }
  }

  protected afterInit(): void {
    this.opcoesSimNao = [
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
    ]
    this.licitacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('licitacao'), this.licitacaoService, 'numero', ['numero'], {
      orgao_id: this.login.orgao.id, 'licitacao_audesp_id$null': undefined, orderBy: 'numero', relations: 'modalidade,orgao'
    }, { number: ['numero'], text: ['numero'] });
    this.compraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('compra'), this.compraService, 'id', ['numero', 'exercicio.ano'], {
      ignoreCondObrig: true, orgao_id: this.login.orgao.id, relations: 'exercicio,modalidade,orgao', orderBy: 'numero,exercicio.ano'
    }, { number: ['numero'] }, undefined, undefined, (c) => {
      return `${c.numero}/${c.exercicio?.ano}`
    });

    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (params['licitacao']) {
          this.skipRedirect = true;
          this.compraDireta = false;
          this.licitacaoService.obter({ id: +params['licitacao'], 'licitacao_audesp_id$null': undefined, relations: 'modalidade,orgao' }).pipe(takeUntil(this.unsubscribe))
            .subscribe((dados) => {
              this.entidadeForm.get('licitacao').setValue(dados);
            });
        }
        if (params['compra']) {
          this.skipRedirect = true;
          this.compraDireta = true;
          this.compraService.obter({ id: +params['compra'], 'licitacao_audesp_id$null': undefined, relations: 'exercicio,modalidade,orgao,licitacao' }).pipe(takeUntil(this.unsubscribe))
            .subscribe((dados) => {
              this.entidadeForm.get('compra').setValue(dados);
            });
        }
      });
  }

  protected campoFoco(): ElementRef {
    return null
  }

  protected beforeSubmit() {
    try {
      if (this.registroPrecoSim === 'S') {
        this.entidadeForm.get('tipo').setValue('LIC_REG_SIM');
      } else {
        if (this.entidade.arquivo?.modalidade === 2) { // concorrencia internacional
          this.entidadeForm.get('tipo').setValue('LIC_REG_NAO2');
        } else if (this.entidade.arquivo?.modalidade === 10 || this.entidade.arquivo?.modalidade === 11) { // contratação direta
          this.entidadeForm.get('tipo').setValue('LIC_REG_NAO3');
        } else {
          this.entidadeForm.get('tipo').setValue('LIC_REG_NAO1');
        }
      }
      this.tratarDatas('en');
      this.entidadeForm.get('codigo').setValue(this.entidade.arquivo?.CodigoLicitacao)
      if (String(this.entidadeForm.get('codigo').value).length !== 13) {
        throw new Error('O código da licitação deve possuir 13 caracteres');
      }
      if (!this.entidade.arquivo?.tipoObjeto) {
        throw new Error('Tipo do objeto não foi definido');
      } else {
        if (!this.entidade.arquivo?.ComprasServicosTI.ObjetoLicitacaoTI && !this.entidade.arquivo?.ComprasServicos.ObjetoLicitacao && !this.entidade.arquivo?.ObrasServicosEngenharia.ObjetoLicitacaoEN) {
          throw new Error('Objeto de licitação não foi definido');
        }
      }
      if (this.entidade.tipo === 'LIC_REG_NAO1') {
        if (this.entidade.arquivo?.ComprasServicos?.Edital?.EditalPublicacao?.some(publicacao => publicacao.VeiculoPublicacao === undefined) && this.entidade.arquivo?.ComprasServicos?.Edital?.editalPublicacaoNao === 'S' && this.entidade.arquivo.tipoObjeto === "COMPRA_SERVICO") {
          throw ('Finalize a edição da Aba Edital - Veículo de comunicação antes de concluir essa ação!');
        }
      }

      if (this.entidade?.arquivo.JulgamentoSemInversao?.NaoExisteAtaAberturaDocumentosHabilitacao === 'S') {
        this.entidade.arquivo.JulgamentoSemInversao.ExisteAtaAberturaDocumentosHabilitacao.ExisteData = 'S';
      };

      if (this.entidade?.arquivo?.JulgamentoSemInversao?.NaoExisteAtaJulgamentoDocumentosHabilitacao === 'S') {
        this.entidade.arquivo.JulgamentoSemInversao.ExisteAtaJulgamentoDocumentosHabilitacao.ExisteData = 'S';
      };

      if (this.entidade?.arquivo?.JulgamentoSemInversao?.NaoExisteAtaAberturaJulgamentoPropostas === 'S') {
        this.entidade.arquivo.JulgamentoSemInversao.ExisteAtaAberturaJulgamentoPropostas.ExisteData = 'S';
      };
  
      if (this.entidade?.arquivo?.JulgamentoSemInversao?.NaoExisteAtaJulgamentoPropostas === 'S') {
        this.entidade.arquivo.JulgamentoSemInversao.ExisteAtaJulgamentoPropostas.ExisteData = 'S';
      };

      this.entidadeForm.get('arquivo').setValue(JSON.stringify(this.entidade.arquivo))
    } catch (e) {
      toastr.error(e);
      throw e;
    }
  }

  protected afterSubmit(modelo: LicitacaoAudesp) {
    this.tratarDatas('pt-br');
    if (this.skipRedirect) {
      if (this.limparTela) { // salvar e novo
        this.router.navigate([Pagina["Audesp Fase 4"].substr(1), 'novo']);
      } else { // salvar
        this.router.navigate([Pagina["Audesp Fase 4"].substr(1), modelo.id, 'editar']);
      }
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private tratarDatas(formato: 'pt-br' | 'en'): void {
    if (typeof this.entidade.arquivo === 'string') {
      this.entidade.arquivo = JSON.parse(this.entidade.arquivo);
    }
    this.converterDatas(this.entidade.arquivo, formato)
  }

  private converterDatas(obj: {}, formato: 'pt-br' | 'en') {
    for (var k in obj) {
      if (k === 'editavel') obj[k] = false
      if (typeof obj[k] == "object" && obj[k] !== null)
        this.converterDatas(obj[k], formato);
      else if (typeof obj[k] === 'string') {
        if (formato === 'en' && obj[k].match(/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}/)) {
          const dtSplit = obj[k].split('/');
          obj[k] = `${dtSplit[2]}-${dtSplit[1]}-${dtSplit[0]}`
        } else if (formato === 'pt-br' && obj[k].match(/\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/)) {
          const dtSplit = obj[k].split('-');
          obj[k] = `${dtSplit[2]}/${dtSplit[1]}/${dtSplit[0]}`
        }
      }
    }
  }

  public async validarXML() {
    this.audespService.validarXML(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((xml) => {
        this.mensagemXML = undefined;
        this.xmlInvalido = undefined;
        toastr.success('Registro validado com sucesso!');
      }, error => {
        this.mensagemXML = String(error.error.payload).split('\n');
        if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXML').modal('show');
      });
  }

  public async gerarXML() {
    this.audespService.gerarXML(this.entidade.id).pipe(takeUntil(this.unsubscribe))
    .subscribe((xml) => {
      let numero = this.entidade.licitacao ? this.entidade.licitacao.numero : this.entidade.compra.numero
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(xml));
      element.setAttribute('download', `${this.funcaoService.removerPontos(`${numero}`)}_${this.entidade.tipo}.xml`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      this.mensagemXML = undefined;
      this.xmlInvalido = undefined;
      this.entidade.exportado = true;
      this.entidadeForm.get('exportado').setValue(true);
    }, error => {
      this.mensagemXML = String(error.error.payload).split('\n');
      if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXML').modal('show');
      });
      this.entidade.exportado = false;
      this.entidadeForm.get('exportado').setValue(false);
    // location.reload()
  }

  public baixarXMLInvalido() {
    if (!this.xmlInvalido) return;
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.xmlInvalido));
    element.setAttribute('download', `${this.funcaoService.removerPontos(this.entidade.licitacao.numero)}_${this.entidade.tipo}_INVALIDO.xml`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public atualizarStatus(tipo: 'ENVIADO' | 'ARMAZENADO', valor: boolean) {
    this.audespService
      .atualizarStatus(this.entidade.id, tipo, valor)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        toastr.success('Status do arquivo atualizado com sucesso!');
        location.reload()
      }, error => toastr.error(error.message ? error.message : error))
  }

  public remover() {
    if (!this.entidade.id) return

    this.confirmationService.confirm({
      message: 'Deseja realmente remover o arquivo?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.audespService.remover(this.entidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Arquivo removido com sucesso!', 'Exclusão');
            this.router.navigate([Pagina["Audesp Fase 4"]]);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        url = this.baseResourceService.retornarRota();
      }
      return this.acessoPagina(this.login, url)
    }
    return false;
  }

  public acessoPagina(login: Login, url: string, visualizar?: boolean) {
    if (login) {
      if (this.funcaoService.campoJsonToken(login.token, "administrador") == true) {
        return true;
      }
      // url = url.replace('/solicitacao', '-solicitacao')
      if (
        url.lastIndexOf("/") > 0 &&
        (url.includes("editar") ||
          url.includes("visualizar") ||
          url.includes("novo"))
      ) {
        const idx = url.indexOf(
          "/".concat(
            url.includes("editar")
              ? "editar"
              : url.includes("visualizar")
                ? "visualizar"
                : "novo"
          )
        );
        url = url.substring(0, idx);
        if (url.substring(1, url.length).lastIndexOf("/") > 0) {
          url = url.substring(
            0,
            url.substring(1, url.length).lastIndexOf("/") + 1
          );
        }
      }
      for (const acesso of login.acessos) {
        if (login.sistema === 'licitacao' && acesso.pagina === '/licitacoes-audesp') {
          if (url.lastIndexOf('/') > 1) {
            let verificaURL = url.split('/');
            url = '/' + verificaURL['1'];
          }
        }
        if (url === acesso.pagina) {
          return acesso.permissao === (visualizar ? 1 : 2);
        }
      }
    }
    return false;
  }
}
