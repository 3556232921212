import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { takeUntil } from 'rxjs/operators';
import {
  BaseResourceFormComponent, PagamentoResto, LoginContabil, OrgaoAssinaturaService,
  EddyAutoComplete, ContaBancaria, GlobalService, DateFormatPipe, ContaBancariaRecurso, FuncaoService, EmpenhoResto, Tabela1Reinf
} from 'eddydata-lib';
import { PagamentoRestoService, ContaBancariaService, RecursoService } from 'administrativo-lib';
import { NotaPagamentoResto } from '../../relatorio-tesouraria/notas/nota-pagamento-resto';
import { LiquidacaoRestoService } from '../../../liquidacao-resto/service/liquidacao-resto.service';
import { DatePipe } from '@angular/common';
import { ContaBancariaRecursoService } from '../../conta-bancaria/service/conta-bancaria-recurso.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-pagamento-resto-form',
  templateUrl: './pagamento-resto-form.component.html'
})
export class PagamentoRestoFormComponent extends BaseResourceFormComponent<PagamentoResto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('InputFocus') private inputField: Calendar;

  public especie: string;
  public liquidado: number;
  public saldoPagar = 0;
  public saldoBancario = 0;
  public empenhoResto: EmpenhoResto;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public contaRecursoAutoComplete: EddyAutoComplete<ContaBancariaRecurso>;
  public contaId: number;
  public contaCodigo: number;
  public anulacao = false;
  public empenhoNumero: number;
  public parcela: number = 0;
  public ano: number;
  public redirecionar = false;
  public totalRetido: number = 0;
  public pagoParcela: number = 0;
  public pagoRetencao: number = 0;
  public liquidadoParcela: number = 0;
  public desabilitar: boolean = true;
  public objetoReinfTabela1: any = null;
  public listaPagamentos: PagamentoResto[];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    protected recursoService: RecursoService,
    protected contaRecursoService: ContaBancariaRecursoService,
    protected contaService: ContaBancariaService,
    protected liquidacaoService: LiquidacaoRestoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected tabela1ReinfServico: Tabela1Reinf,
    protected pagamentoService: PagamentoRestoService) {
    super(new PagamentoResto(), injector, PagamentoResto.converteJson, pagamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: PagamentoResto): Promise<boolean> {
    return true
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      valor_pago: [0, [Validators.required]],
      valor_retido: [0, [Validators.required]],
      valor_liquido: [0, [Validators.required]],
      mes: [null],
      aux: [null],
      data_pagamento: [null, [Validators.required]],
      especie: [null],
      documento: [null, [Validators.required]],
      impresso: [false, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      anulacao: [false, [Validators.required]],
      conta: [null, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      conta_recurso: [null, [Validators.required]],
      liquidacao: [null, [Validators.required]],
      situacao: ['N'],
      pagamento_S13: ['N'],
      valor_RRA: [0],
      juridico_RRA: [null],
      indice_fci_scp: [null],
      cnpj_fci_scp: [null],
      porcentagem_scp: [0],
      pais_pagamento: [105],
      nif_tributo: [null]
    });
  }

  private inicializarVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.empenhoNumero = this.entidade.liquidacao.empenho.numero;
      this.parcela = this.entidade.liquidacao.parcela;
      this.entidade.data_pagamento = new Date();
      this.entidade.valor_retido = 0;
      this.entidade.impresso = false;
      this.entidade.historico = 'PAGAMENTO EFETUADO NO DIA';
      this.entidade.exercicio = this.login.exercicio;
      this.entidade.orgao = this.login.orgao;
      this.contaId = this.entidade.conta.id;
      this.contaCodigo = this.entidade.conta.codigo;
    }
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta,conta.banco,liquidacao,liquidacao.empenho,liquidacao.exercicio,liquidacao.empenho.favorecido,exercicio,orgao,conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio'
    };
  }

  protected campoFoco(): ElementRef {
    return this.inputField.inputfieldViewChild.nativeElement.focus();
  }

  protected async afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_pagamento)) {
      this.router.navigate(['/pagamentos-restos-pagar']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_pagamento = new DateFormatPipe().transform(this.entidade.data_pagamento, []);
    this.empenhoNumero = this.entidade.liquidacao.empenho.numero;
    this.parcela = this.entidade.liquidacao.parcela;
    this.ano = this.entidade.liquidacao.empenho.ano;
    this.contaId = this.entidade.conta.id;
    this.contaCodigo = this.entidade.conta.codigo;
    this.anulacao = this.entidade.anulacao;
    this.empenhoResto = this.entidade.liquidacao.empenho;
    this.buscarSaldoBancario();
    this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero, this.entidade.liquidacao.empenho.ano, this.entidade.liquidacao.parcela, this.entidade.liquidacao.id);
    this.inicializarVariavel();

    if (this.entidade.liquidacao?.empenho?.codigo_reinf) {
      this.objetoReinfTabela1 = this.tabela1ReinfServico.carregarPorCodigo(this.entidade.liquidacao.empenho.codigo_reinf, true);
    }
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.inicializaVariavel();
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (params: any) => {
          if (params.hasOwnProperty('liquidacao')) {
            const parametros = {};
            parametros['id'] = params['liquidacao'];
            parametros['relations'] = 'empenho,empenho.favorecido,empenho.convenio,' +
              'empenho.modalidade,exercicio,orgao';
            this.liquidacaoService.obter(parametros)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(
                (entidade) => {
                  if (entidade) {
                    this.redirecionar = true;
                    this.entidade.liquidacao = entidade;
                    this.empenhoNumero = this.entidade.liquidacao.empenho.numero;
                    this.parcela = this.entidade.liquidacao.parcela;
                    this.empenhoResto = this.entidade.liquidacao.empenho;
                    this.entidadeForm.get('liquidacao').setValue(entidade);
                    this.entidadeForm.get('valor_pago').setValue(entidade.valor_liquidado);
                    this.entidadeForm.get('historico')
                      .setValue(`PAGTO EMP. ${entidade.empenho.numero} ${entidade.empenho.favorecido.nome}`);
                    this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero, this.entidade.liquidacao.empenho.ano, this.entidade.liquidacao.parcela, this.entidade.liquidacao.id);
                  } else {
                    this.messageService.add({
                      severity: 'info', summary: 'Informação',
                      detail: 'Empenho não foi encontrado!'
                    });
                  }
                }, () => this.sair());
          }
        });
  }

  private inicializaVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.pagamentoService.ultimaDataPagamento(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dataLiq) => {
          if (dataLiq === 0) {
            this.entidade.data_pagamento = new Date();
          } else {
            this.entidade.data_pagamento = new DateFormatPipe().transform(dataLiq, []);
          }
          this.entidadeForm.get('data_pagamento').setValue(this.entidade.data_pagamento);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_pagamento)?.split('-')?.[1]);
        });
    }
  }

  protected beforeSubmit() {
    try {
      const dataPagamento = new DateFormatPipe().transform(this.entidadeForm.get('data_pagamento').value, []);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_pagamento').value)?.split('-')?.[1]);
      this.entidadeForm.get('aux').setValue(0);
      this.entidadeForm.get('especie').setValue(!this.anulacao ? 'PGR' : 'PRA');
      this.entidadeForm.get('anulacao').setValue(this.anulacao);

      if (this.anulacao) {
        if (this.entidadeForm.get('valor_pago').value >= 0) {
          throw new Error('Valor anulado deve ser negativo!');
        }
      }
      if (this.entidadeForm.get('data_pagamento').value > new Date()) {
        throw new Error('Data do pagamento está maior que a data atual');
      }
      if (this.entidade.liquidacao.data_liquidacao > dataPagamento) {
        throw new Error('Data de pagamento tem que ser maior ou igual a data de liquidação!');
      }

      const valor_liquidado = +this.entidadeForm.get('valor_pago').value - +this.entidadeForm.get('valor_retido').value;

      if ((+this.saldoPagar.toFixed(2) - +(valor_liquidado -
        (this.entidade.valor_pago ? this.entidade.valor_pago : +this.entidadeForm.get('valor_pago').value))) < 0) {
        throw new Error('Empenho já está pago!');
      }

      if (!this.entidadeForm.get('conta_recurso').value) {
        throw new Error('O recurso da despesa não esta vinculada a esta conta bancária!');
      }

    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(entidade: PagamentoResto): void {
    // processos depois de o registro for salvo
  }

  public buscarLiquidacao() {
    if (this.empenhoNumero > 0 && this.ano > 0) {
      const param = {};
      param['empenho.numero'] = this.empenhoNumero;
      param['empenho.ano'] = this.ano;
      param['anulacao'] = false;
      param['anulado_total'] = false;
      param['exercicio.id'] = this.login.exercicio.id;
      param['orgao.id'] = this.login.orgao.id;
      param['relations'] = 'empenho,empenho.favorecido,empenho.convenio,empenho.modalidade,exercicio,orgao';
      if (this.parcela > 0) {
        param['parcela'] = this.parcela;
      }
      this.liquidacaoService.obter(param).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (model) => {
            if (model) {
              const empenho_resto: EmpenhoResto = model.empenho;
              this.pagamentoService.filtrar(1, -1, {
                'liquidacao.empenho.id': empenho_resto.id,
                'exercicio.id': this.login.exercicio.id,
                'orgao.id': this.login.orgao.id,
                relations: 'liquidacao.empenho',
                orderBy: 'liquidacao.parcela'
              }).pipe(takeUntil(this.unsubscribe))
                .subscribe(async res => {
                  this.listaPagamentos = res.content;
                  if (!this.parcela) this.parcela = 0;

                  const valorPago = +this.listaPagamentos.reduce((acc, pagamento) => +pagamento.valor_pago + +acc, 0).toFixed(2);

                  if (+valorPago >= +empenho_resto.valor_empenho) {
                    toastr.warning(`O empenho de restos ${this.empenhoNumero} já foi pago!`)
                  } else {
                    const pagamentosPorParcela = this.listaPagamentos.filter(pagamento => +pagamento.liquidacao.parcela === +this.parcela)
                    const valorPagoParcela = pagamentosPorParcela.reduce((acc, pagamento) => +pagamento.valor_pago + +acc, 0)

                    if (+valorPagoParcela >= +model.valor_liquidado) {
                      toastr.warning(`A parcela ${this.parcela} já foi paga, informe outra parcela para o empenho de restos ${this.empenhoNumero}!`)
                    } else {
                      this.entidade.liquidacao = model;
                      this.empenhoResto = this.entidade.liquidacao.empenho;
                      this.entidadeForm.get('liquidacao').setValue(model);
                      this.parcela = model.parcela;
                      this.entidadeForm.get('historico').setValue(`PAGTO EMP. ${model.empenho.numero} ${model.empenho.favorecido.nome}`);
                      this.entidadeForm.get('valor_pago').setValue((+model.valor_liquidado - valorPagoParcela).toString());
                      this.buscarTotalLiquidado(this.entidade.liquidacao.empenho.numero, this.entidade.liquidacao.empenho.ano, this.entidade.liquidacao.parcela, this.entidade.liquidacao.id);
                    }
                  }
                })
            } else {
              this.messageService.add({ severity: 'info', summary: 'Informação', detail: 'Empenho de restos a pagar não foi encontrado!' });
            }
          }, (error) => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    }
  }

  public buscarConta() {
    if (this.contaCodigo > 0) {
      this.contaService.obter({ codigo: this.contaCodigo, 'orgao.id': this.login.orgao.id, relations: 'banco' }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data) {
              if (data.ativo) {
                this.contaId = data.id;
                this.entidadeForm.get('conta').setValue(data);
                this.carregarAutoCompletesContaRecurso();
                this.buscarSaldoBancario();
                this.buscarContaRecurso(this.contaId, data);
                if (!this.login.fonte_recurso_pagamento) {
                  this.desabilitar = false;
                }
              } else {
                this.contaCodigo = null;
                toastr.error('Conta bancária inativa!');
                return;
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public changeConta() {
    if (this.entidadeForm.get('conta')) {
      const conta = this.entidadeForm.get('conta').value;
      this.contaId = conta.id;
      this.contaCodigo = conta.codigo;
      this.carregarAutoCompletesContaRecurso();
      this.buscarSaldoBancario();
      // this.buscarContaRecurso(this.contaId);
      if (!this.login.fonte_recurso_pagamento) {
        this.desabilitar = false;
      }
    }
  }

  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta'), this.contaService,
      'id', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.login.orgao.id, relations: 'banco', orderBy: 'nome' }, { number: ['numero_conta'], text: ['nome'] },
      // this.buscarContaRecurso(this.contaId),
      this.buscarSaldoBancario()
    );
    this.carregarAutoCompletesContaRecurso();
  }

  private carregarAutoCompletesContaRecurso() {
    let parametros = {}
    parametros = { 'conta.codigo': this.contaCodigo, relations: 'recurso,aplicacao,convenio', orderBy: 'aplicacao.codigo' };

    if (this.login.fonte_recurso_pagamento) {
      parametros = { 'conta.codigo': this.contaCodigo, 'excluido': false, relations: 'recurso,aplicacao,convenio' };
    }

    this.contaRecursoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_recurso'), this.contaRecursoService,
      'id', ['recurso.codigo', 'aplicacao.codigo', 'aplicacao.nome'], parametros,
      { number: ['aplicacao.codigo'], text: ['aplicacao.nome'] },
      this.buscarContaRecurso(this.contaId)
    );
  }

  public buscarSaldoBancario() {
    if (this.contaId > 0) {
      let datepipe: DatePipe = new DatePipe('pt');
      const dataPagamento = this.entidadeForm.get('data_pagamento').value ? datepipe.transform(this.entidadeForm.get('data_pagamento').value, 'yyyy-MM-dd', 'GMT') :
        datepipe.transform(this.entidade.data_pagamento, 'yyyy-MM-dd', 'GMT');
      this.contaService.obterSaldoBancarioConta(this.contaId, this.login.exercicio.id, this.login.orgao.id, dataPagamento).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (saldoTotal: any) => {
            this.saldoBancario = saldoTotal;
            this.buscarSaldoRecurso()
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarSaldoRecurso() {
    if (this.contaCodigo > 0) {
      let parametros = {}
      parametros = { 'conta.codigo': this.contaCodigo, relations: 'recurso,aplicacao,convenio' };

      if (this.login.fonte_recurso_pagamento) {
        parametros = { 'conta.codigo': this.contaCodigo, 'excluido': false, relations: 'recurso,aplicacao,convenio' };
      }

      this.contaRecursoService.obter(parametros).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res: any) => {
            if (res) {
              this.carregarAutoCompletesContaRecurso();
              if (!this.entidadeForm.get('conta_recurso').value) {
                this.entidadeForm.get('conta_recurso').setValue(res);
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  private async buscarContaRecurso(id: number, contaBancaria?: ContaBancaria) {
    if (this.empenhoResto && !this.login.fonte_recurso_pagamento) {
      const recursoVariavel = !this.empenhoResto.recurso_variavel ? null
        : this.empenhoResto.recurso_variavel.length > 4 ? this.empenhoResto.recurso_variavel
          : this.empenhoResto.recurso + this.empenhoResto.aplicacao + this.empenhoResto.recurso_variavel;
      this.empenhoResto.recurso_variavel
      this.contaRecursoService.obterRecursoPorCodigo(id, this.empenhoResto.recurso, this.empenhoResto.aplicacao, recursoVariavel)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            // this.buscarSaldoBancario();
            if (data.content[0]) {
              this.entidadeForm.get('conta_recurso').setValue(data.content[0]);
            } else {
              this.entidadeForm.get('conta_recurso').setValue(null);
              this.entidadeForm.get('conta').setValue(null);
              this.contaId = 0;
              this.messageService.add({
                severity: 'warn', summary: 'Atenção',
                detail: `O recurso da despesa ${this.empenhoResto.recurso}.${this.empenhoResto.aplicacao} ${this.empenhoResto.recurso_variavel ? this.empenhoResto.recurso_variavel.length > 7 ? this.empenhoResto.recurso_variavel.substring(5) : this.empenhoResto.recurso_variavel : ''} não esta vinculado para esta conta bancária`
              });
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarTotalLiquidado(numero: number, ano: number, parcela: number, liquidacaoId: number) {
    if (this.empenhoNumero > 0) {
      this.liquidacaoService.obterTotalLiquidadoPorEmpenho(numero, ano, parcela, this.login.orgao.id, null, this.login.exercicio.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res: any) => {
            this.liquidado = res.liquidado;
            this.liquidadoParcela = +res.liquidado;
            this.totalRetido = +res.retido;
            this.buscarTotalPago(liquidacaoId);
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarTotalPago(liquidacaoId: number) {
    this.pagamentoService.obterTotalPagoPorLiquidacaoId(liquidacaoId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.pagoParcela = +res.total_pago;
          this.pagoRetencao = +res.total_pago_retencao;
          if (!this.entidade.id) {
            this.entidadeForm.get('valor_retido').setValue(+this.totalRetido - +this.pagoRetencao);
          }
          this.saldoPagar = +this.liquidadoParcela.toFixed(2) - +this.pagoParcela;
          if (!this.entidade.id) {
            this.entidadeForm.get('valor_pago').setValue(this.saldoPagar.toFixed(2));
          }

          this.atualizarLiquido();
          if (this.saldoPagar <= 0 && !this.entidade.id) {
            this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Liquidação totalmente paga' })
          }
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public atualizarLiquido() {
    this.entidadeForm.get('valor_liquido').setValue(+this.entidadeForm.get('valor_pago').value - +this.entidadeForm.get('valor_retido').value);
  }

  imprimir() {
    const parametros = {};

    let relations = 'liquidacao.empenho.contrato.licitacao.modalidade,'
    relations += 'liquidacao.empenho.contrato,liquidacao.empenho.favorecido.contas.banco,liquidacao.empenho.convenio,liquidacao.empenho.favorecido';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;

    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamentoResto(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

  atualizarReinf(event: PagamentoResto) {
    this.entidade = event;
    this.entidadeForm.patchValue(this.entidade);
  }
}
