import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContratoService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, Contrato, DateFormatPipe, EddyAutoComplete, EmpenhoExtra, Exercicio, Favorecido, FavorecidoService, FichaExtra,
  FuncaoService, GlobalService, LoginContabil, OrgaoAssinaturaService, RetencaoExtra, Tabela1Reinf
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { FichaExtraService } from '../../ficha-extra/service/ficha-extra.service';
import { NotaEmpenhoExtra } from '../../relatorio/contabil/nota-empenho-extra';
import { EmpenhoExtraService } from '../service/empenho-extra.service';
import { RetencaoExtraService } from '../service/retencao-extra.service';

@Component({
  selector: 'app-empenho-extra-form',
  templateUrl: './empenho-extra-form.component.html'
})
export class EmpenhoExtraFormComponent extends BaseResourceFormComponent<EmpenhoExtra, LoginContabil> implements OnInit, OnDestroy {

  /**
   * Declaração de variáveis
   */
  @ViewChild('tipo_') inputField: ElementRef;

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public listaRetencoes: Array<RetencaoExtra>;
  public empenhado: number;
  public pago: number;
  public empenho: EmpenhoExtra;
  public gruposReinf: Array<any> = new Array<any>();
  public grupoReinf: any;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected fichaService: FichaExtraService,
    protected contratoService: ContratoService,
    protected tabela1ReinfService: Tabela1Reinf,
    protected retencaoService: RetencaoExtraService,
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected empenhoService: EmpenhoExtraService) {
    super(new EmpenhoExtra(), injector, EmpenhoExtra.converteJson, empenhoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: EmpenhoExtra): Promise<boolean> {
    return (_entidade.especie === 'EME' || _entidade.especie === 'SEE');
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      data_empenho: [null, [Validators.required]],
      data_vencimento: [null],
      valor_empenho: [0, [Validators.required]],
      mes: [null],
      especie: ['EME'],
      processo: [null],
      documento: [null],
      impresso: [false],
      aux: [0],
      historico: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      ficha: [null, [Validators.required]],
      favorecido: [null, [Validators.required]],
      contrato: [null],
      exercicio: [this.login.exercicio, [Validators.required]],
      usuario_cadastro: [this.login.usuario],
      retencoes: [null],
      codigo_reinf: [null],
      retencao: [null],
      retencao_resto: [null],
      retencao_extra: [null],
      empenho_antigo: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'ficha,ficha.plano,ficha.recurso,ficha.aplicacao,favorecido,contrato,exercicio,orgao,retencao,retencao.liquidacao,retencao.liquidacao.empenho,retencao_resto,retencao_resto.liquidacao,retencao_resto.liquidacao.empenho,empenho_antigo' };
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.inicializaVariavel();
    this.carregarTabela1Reinf();
    if (this.currentActionRoute === 'novo') {
      this.empenhoService.proximoNumeroEmpenho(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get('numero').setValue(res);
          this.empenhoService.ultimaDataEmpenhada(this.login.exercicio.id, this.login.orgao.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((dataEmp) => {
              this.entidade.data_empenho = new DateFormatPipe().transform(dataEmp, []);
              this.entidadeForm.get('data_empenho').setValue(this.entidade.data_empenho);
              this.entidadeForm.get('mes').setValue(this.entidade.data_empenho ? +this.funcaoService.converteDataSQL(this.entidade.data_empenho)?.split('-')?.[1] : 1);
            });
        });
    }
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  private inicializaVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.listaRetencoes = new Array<RetencaoExtra>();
    }
  }

  private async loadTotalizadores() {
    if (this.entidade.id) {
      this.empenhado = 0;
      this.pago = 0;
      const exercicio: Exercicio = this.entidadeForm.get('exercicio').value
        ? this.entidadeForm.get('exercicio').value
        : this.login.exercicio;
      let totalizadores = await this.empenhoService.totalizadores(
        exercicio.id, this.login.orgao.id, this.entidade.id).toPromise();

      if (totalizadores) {
        totalizadores = totalizadores.content[0] ? totalizadores.content[0] : totalizadores.content;
        this.empenhado = totalizadores.total_empenhado;
        this.pago = totalizadores.total_pago_empenho;
      }
    }
  }

  protected async afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_empenho)) {
      this.router.navigate(['/empenhos-extra']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }
    this.entidade.data_empenho = new DateFormatPipe().transform(this.entidade.data_empenho, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
    await this.loadTotalizadores().then(() => {
      this.retencaoService.obterPorLiquidacao(this.entidade.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            this.listaRetencoes = data ? data.content : new Array<RetencaoExtra>();
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    });

    this.favorecidoAutoComplete.id = this.entidade.favorecido ? this.entidade.favorecido.id : null;
    this.fichaAutoComplete.id = this.entidade.ficha ? this.entidade.ficha.numero : null;
    this.contratoAutoComplete.id = this.entidade.contrato ? this.entidade.contrato.id : null;
    this.empenho = this.entidade
    this.carregarTabela1Reinf();
  }

  protected beforeSubmit() {
    try {
      if (!this.entidadeForm.get('numero').value) {
        throw new Error('Informe o número do empenho');
      }
      if (!this.entidadeForm.get('data_empenho').value) {
        throw new Error('Informe a data do empenho');
      }
      if (!this.entidadeForm.get('especie').value) {
        throw new Error('Informe a espécie do empenho');
      }
      if (!this.entidadeForm.get('valor_empenho').value) {
        throw new Error('Informe o valor do empenho');
      }
      if (!this.entidadeForm.get('exercicio').value) {
        throw new Error('Informe o exercício do empenho');
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error('Informe o órgão do empenho');
      }
      if (!this.entidadeForm.get('favorecido').value) {
        throw new Error('Informe o favorecido do empenho');
      }
      if (!this.entidadeForm.get('ficha').value) {
        throw new Error('Informe a ficha do empenho');
      }
      if (!this.entidadeForm.get('historico').value) {
        throw new Error('Informe o histórico do empenho');
      }
      if (this.entidadeForm.get('valor_empenho').value <= 0) {
        throw new Error('Informe o valor do empenho');
      }
      const dtEmpenho: Date = this.entidadeForm.get('data_empenho').value;
      if (dtEmpenho > new Date()) {
        throw new Error('Data do empenho está maior que a data atual');
      }
      if (dtEmpenho.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('O ano da data do empenho está diferente do exercício logado');
      }
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_empenho').value)?.split('-')?.[1]);
      let retencaoEditando = this.listaRetencoes.find(e => e.editavel);
      if(retencaoEditando){
        throw new Error('Acabe de editar as retenções');
      }
      this.entidadeForm.get('retencoes').setValue(this.listaRetencoes);
      const ficha: FichaExtra = this.entidadeForm.get('ficha').value;
      if (
        ['2188101', '2188201', '2188301', '2188401', '2188501'].includes(ficha.plano.codigo.substring(0, 7)) &&
        (!this.entidadeForm.get('retencao').value && !this.entidadeForm.get('retencao_resto').value && !this.entidadeForm.get('retencao_extra').value)
      ) {
        throw new Error('Classificação da ficha extra não permite empenho extra sem origem');
      }

      if (!this.funcaoService.podeAlterarAudesp(this.entidadeForm.get('data_empenho').value, this.login)) {
        throw new Error('O mês do registro já foi armazenado no TCE. Não é possível salvar!');
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(ent: EmpenhoExtra) {
    this.mensagemSucesso = `Registro salvo com sucesso!\nEmpenho Extra nº ${ent.numero}`;
    this.listaRetencoes = new Array<RetencaoExtra>();
    if (this.currentActionRoute === 'novo') {
      this.router.navigate(['/empenhos-extra', 'novo']);
    } else if (this.currentActionRoute === 'editar') {
      this.router.navigate(['/empenhos-extra', ent.id, 'editar'], { skipLocationChange: true }).then(() => {
        this.loadResource();
      });
    }
  }

  public verificarEmpenhoVinculado() {
    if (this.entidade.retencao || this.entidade.retencao_resto) {
      return 'disabled';
    } else {
      return null;
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


  public obterValorEmpenho() {
    const valorEmpenho = this.entidadeForm.get('valor_empenho').value;
    this.entidade.valor_empenho = +valorEmpenho;
  }

  private carregarAutoCompletes() {
    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );

    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('ficha'), this.fichaService,
      'numero', ['numero', 'nome'],
      { exercicio_id: this.login.exercicio.id, excluida: false, orgao_id: this.login.orgao.id, relations: 'favorecido,plano,recurso,aplicacao', orderBy: 'nome' },
      { number: ['numero'], text: ['nome'] },
      () => {
        this.entidadeForm.get('favorecido').setValue(this.entidadeForm.get('ficha').value.favorecido);
        this.favorecidoAutoComplete.id = this.entidadeForm.get('ficha').value.favorecido.id;
        this.entidade.codigo_reinf = this.entidadeForm.get('ficha').value.codigo_reinf;
        this.entidadeForm.get('codigo_reinf').setValue(this.entidadeForm.get('ficha').value.codigo_reinf);
        this.carregarTabela1Reinf();
      }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('contrato'), this.contratoService,
      'numero', ['numero'], { orgao_id: this.login.orgao.id, orderBy: 'numero' }, { number: ['id', 'numero'] }
    );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public imprimir() {
    const parametros = {};
    let relations = 'contrato.licitacao.modalidade,ficha.aplicacao,ficha.recurso,ficha.aplicacao_variavel,contrato,favorecido.contas.banco,convenio,retencao.liquidacao.empenho.favorecido';
    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;

    this.empenhoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaEmpenhoExtra(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public temRetencoes(): boolean {
    return !!!(this.entidade.retencao?.id || this.entidade.retencao_resto?.id)
  }

  public carregarTabela1Reinf() {
    if (this.grupoReinf == null) {
      this.gruposReinf = this.tabela1ReinfService.carregarTabela1();
    }
    if (this.entidade?.codigo_reinf) {
      this.grupoReinf = this.tabela1ReinfService.carregarPorCodigo(this.entidade.codigo_reinf, false);
      for (let i = 0; i < this.gruposReinf.length; i++) {
        if (this.gruposReinf[i].codigo == this.grupoReinf.codigo) {
          this.gruposReinf[i] = this.grupoReinf;
          break;
        }
      }
    }
  }
}
