import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { BaseResourceRptComponent, Coluna, EddyAutoComplete, Favorecido, FavorecidoService, FormatoExportacao, FuncaoService, GlobalService, Login, Relatorio } from 'eddydata-lib';

@Component({
  selector: 'app-divida-fornecedor-list',
  templateUrl: './divida-fornecedor-list.component.html'
})
export class DividaFornecedorListComponent extends BaseResourceRptComponent {

  public ascendente = true;
  public colunaOrdenacao: string;
  public paginaCorrente: number = 1;
  public paginaTotal: number;
  public filtro: string;
  limite: number = 20;

  login: Login;

  favorecido: Favorecido = new Favorecido();
  favorecidoId: number;
  favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  orcamentarios: boolean = true;
  restos: boolean = true;
  extras: boolean = true;
  exibirPagos: boolean = true;
  exibirLiquidados: boolean = true;
  exibirNaoLiquidados: boolean = true;

  listagem: Array<any> = new Array<any>();
  listaDividas: Array<any> = new Array<any>();
  listaOriginal: Array<any> = new Array<any>();
  listaImpressao: Array<any> = new Array<any>();

  totalEmpenhado: number = 0.0;
  totalLiquidado: number = 0.0;
  totalPago: number = 0.0;
  divEmpenho: number = 0.0;
  divLiq: number = 0.0;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(protected injector: Injector,
    public favorecidoService: FavorecidoService,
    public funcaoService: FuncaoService
  ) {
    super();
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.alterarExibicao();
    window.scrollTo(0, 0);
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.alterarExibicao();
    window.scrollTo(0, 0);
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.alterarExibicao();
    window.scrollTo(0, 0);
  }

  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
  }

  protected afterInit(): void {
    this.favorecidoAutoComplete.id = this.favorecido ? this.favorecido.id : null;
  }

  public ngOnInit(): void {
    this.login = GlobalService.obterSessaoLogin();
    this.carregarAutoCompletes();
  }

  public carregarAutoCompletes() {
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id'], text: ['nome', 'cpf_cnpj'] }, () => this.favorecidoId = this.favorecido.id
    );
  }

  public buscarFavorecidoId(id: number) {
    if (!id)
      return
    this.favorecidoService.obterId(id).subscribe((res) => {
      this.favorecido = res;
    });
  }

  public carregarDividas() {
    if (!this.favorecido) {
      return;
    }

    this.favorecidoService.getDividas(this.favorecido.id, this.login.exercicio.id, this.login.orgao.id).subscribe((res) => {
      // this.listaDividas = res;
      this.paginaCorrente = 1;
      this.listaOriginal = res;
      this.alterarExibicao();
    });
  }

  public async alterarExibicao() {
    if (this.listaOriginal.length > 0) {
      this.listaDividas = [];
      let empOrc = [];
      let empRes = [];
      let empExt = [];
      let empFiltrados = [];
      let naoLiqNaoPago = [];
      let liqNaoPag = [];
      let liqEPago = [];

      empOrc = this.listaOriginal.filter(item => item.tipo === 'Empenho Orçamentário');
      empRes = this.listaOriginal.filter(item => item.tipo === 'Empenho Restos');
      empExt = this.listaOriginal.filter(item => item.tipo === 'Empenho Extra');

      empFiltrados = this.orcamentarios ? [...empOrc] : [];
      empFiltrados = this.restos ? [...empRes, ...empFiltrados] : [...empFiltrados];
      empFiltrados = this.extras ? [...empExt, ...empFiltrados] : [...empFiltrados];

      naoLiqNaoPago = empFiltrados.filter(item => !item.valor_liquidado && !+item.valor_pago);
      liqNaoPag = empFiltrados.filter(item => item.valor_liquidado && !+item.valor_pago);
      liqEPago = empFiltrados.filter(item => (item.valor_liquidado || item.tipo === 'Empenho Extra') && +item.valor_pago);

      this.listaDividas = this.exibirNaoLiquidados ? [...naoLiqNaoPago] : [];
      this.listaDividas = this.exibirLiquidados ? [...liqNaoPag, ...this.listaDividas] : [...this.listaDividas];
      this.listaDividas = this.exibirPagos ? [...liqEPago, ...this.listaDividas] : [...this.listaDividas];

      await this.ordenadarDataEmpenho();
      await this.calcularTotais();

      if (this.listaDividas.length === 0) {
        this.listagem = [];
        this.totalEmpenhado = 0;
        this.totalLiquidado = 0;
        this.totalPago = 0;
        this.divEmpenho = 0;
        this.divLiq = 0;
      } else {
        this.paginaTotal = Math.ceil(this.listaDividas.length / this.limite);
        this.listagem = this.listaDividas.slice(this.limite * (this.paginaCorrente - 1), this.limite * this.paginaCorrente);
      }

      this.listagem = this.listagem.map(p => {
        const pe = { ...p };

        if (!pe.documento_pagamento) {
          pe.documento_pagamento = '';
        }

        if (!pe.documento_liquidacao) {
          pe.documento_liquidacao = '';
        }

        return pe;
      });
    } else {
      this.listaDividas = [];
      this.totalEmpenhado = 0;
      this.totalLiquidado = 0;
      this.totalPago = 0;
      this.divEmpenho = 0;
      this.divLiq = 0;
    }
  }

  public reordenar(coluna: string, toggle?: boolean) {
    if (!coluna) return;

    if (!toggle) {
      this.ascendente = true;
    } else {
      this.ascendente = !this.ascendente;
    }

    this.colunaOrdenacao = coluna;

    if (this.colunaOrdenacao === 'codigo') {
      this.listagem.sort((a, b) => (this.ascendente? a.codigo  - b.codigo : b.codigo  - a.codigo )) 
    }
    if (this.colunaOrdenacao === 'data_empenho') {
      this.listagem.sort((a, b) => (this.ascendente? new Date(a.data_empenho).getTime() - new Date(b.data_empenho).getTime() : new Date(b.data_empenho).getTime() - new Date(a.data_empenho).getTime()));
    }

    if (this.colunaOrdenacao === 'tipo') {
      this.listagem.sort((a, b) => {
        if (this.ascendente) {
          if (a.tipo > b.tipo) {
            return -1;
          } else {
            return 1;
          } 
        } else {
          if (a.tipo > b.tipo) {
            return 1;
          } else {
            return -1;
          }
        }
      });
    }

    if (this.colunaOrdenacao === 'numero') {
      this.listagem.sort((a, b) => (this.ascendente? +a.numero  - +b.numero : +b.numero  - +a.numero ));
    }
    if (this.colunaOrdenacao === 'parcela') {
      this.listagem.sort((a, b) => (this.ascendente? a.parcela  - b.parcela : b.parcela  - a.parcela ));
    }
    if (this.colunaOrdenacao === 'documento_liquidacao') {
      this.listagem.sort((a, b) => {
        if (this.ascendente) {
          return a.documento_liquidacao.localeCompare(b.documento_liquidacao);
        } else {
          return b.documento_liquidacao.localeCompare(a.documento_liquidacao);
        }
      });
    }
    if (this.colunaOrdenacao === 'documento_pagamento') {
      this.listagem.sort((a, b) => {
        if (this.ascendente) {
          return a.documento_pagamento.localeCompare(b.documento_pagamento);
        } else {
          return b.documento_pagamento.localeCompare(a.documento_pagamento);
        }
      });
    }
    if (this.colunaOrdenacao === 'valor_empenho') {
      this.listagem.sort((a, b) => (this.ascendente ? a.valor_empenho - b.valor_empenho : b.valor_empenho - a.valor_empenho));
    }
    if (this.colunaOrdenacao === 'valor_liquidado') {
      this.listagem.sort((a, b) => (this.ascendente ? a.valor_liquidado - b.valor_liquidado : b.valor_liquidado - a.valor_liquidado));
    }
    if (this.colunaOrdenacao === 'data_pagamento') {
      this.listagem.sort((a, b) => (this.ascendente ? new Date(a.data_pagamento).getTime() - new Date(b.data_pagamento).getTime() : new Date(b.data_pagamento).getTime() - new Date(a.data_pagamento).getTime()));
    }
    if (this.colunaOrdenacao === 'valor_pago') {
      this.listagem.sort((a, b) => (this.ascendente ? a.valor_pago - b.valor_pago : b.valor_pago - a.valor_pago));
    }
    if (this.colunaOrdenacao === 'vencimento_liquidacao') {
      this.listagem.sort((a, b) => (this.ascendente ? new Date(a.vencimento_liquidacao).getTime() - new Date(b.vencimento_liquidacao).getTime() : new Date(b.vencimento_liquidacao).getTime() - new Date(a.vencimento_liquidacao).getTime()));
    }
  }

  public async ordenadarDataEmpenho() {
    this.listaDividas.sort((a, b) => new Date(a.data_empenho).getTime() - new Date(b.data_empenho).getTime());
  }

  public async calcularTotais() {
    // soma dos valores do empenho sem repeti-los
    const empenhosSemRepeticao = this.listaDividas.filter((v, i, a) => a.findIndex(t => (t.numero === v.numero && t.tipo === v.tipo && t.valor_empenho === v.valor_empenho)) === i);
    this.totalEmpenhado = empenhosSemRepeticao.reduce((acc, item) => +item.valor_empenho + acc, 0);

    // soma dos valores liquidados
    const liquidacoesSemRepeticao = this.listaDividas.filter((v, i, a) => a.findIndex(t => (t.numero === v.numero && t.tipo === v.tipo && t.parcela === v.parcela && t.valor_liquidado === v.valor_liquidado && t.documento_liquidacao === v.documento_liquidacao)) === i);
    this.totalLiquidado = liquidacoesSemRepeticao.reduce((acc, item) => +item.valor_liquidado + acc, 0);

    // soma dos valores pagos
    this.totalPago = this.listaDividas.reduce((acc, item) => +item.valor_pago + acc, 0);

    // são os valores do empenho ainda não liquidados
    this.divEmpenho = this.totalEmpenhado - this.totalLiquidado;

    // são os valores de liquidação sem pagamento
    const pagamentosSemExtra = this.listaDividas.filter(e => e.tipo !== 'Empenho Extra');
    this.divLiq = this.totalLiquidado - pagamentosSemExtra.reduce((acc, item) => +item.valor_pago + acc, 0);
  }

  public exportarListagem(formato: FormatoExportacao) {
    // this.calcularTotais(true)
    //this.orientacao = 'landscape'
    this.formato = formato;
    if (formato === 'pdf' || formato === 'docx') {
      Relatorio.imprimirPersonalizado(`Dívidas por Fornecedor - ${this.favorecido.nome}`, this.login.usuario.nome, this.login.usuario.sobrenome,
        this.login.orgao.nome, this.login.brasao,
        this.montarConteudo(this.listaDividas),
        'landscape', `Dívidas por Fornecedor`,
        {
          linhas: {
            hLineWidth() {
              return 1;
            },
            vLineWidth() {
              return 1;
            },
            hLineColor() {
              return 'black';
            },
            paddingLeft() {
              return 3;
            },
            paddingRight() {
              return 3;
            }
          }
        }, false, false, formato);
    } else {
      //this.calcularTotais(true)
      this.listaImpressao = [...this.listaDividas];
      const totalizadores = {
        codigo: "",
        data_empenho: "",
        data_pagamento: "",
        documento_liquidacao: "",
        documento_pagamento: "",
        exibir: "",
        numero: "",
        parcela: "",
        tipo: "",
        valor_empenho: this.totalEmpenhado.toFixed(2),
        valor_liquidado: this.totalLiquidado.toFixed(2),
        valor_pago: this.totalPago.toFixed(2),
        vencimento_liquidacao: ""
      };

      this.listaImpressao.push(totalizadores);
      this.imprimir();
    }
  }

  public montarConteudo(lista: any[]): {}[] {
    let retorno: {}[] = [];
    const conteudo = [];
    let datepipe = new DatePipe('pt');

    conteudo.push([
      { text: 'Data Empenho', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Tipo Despesa', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Número', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Parcela', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Recurso', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Venct. Liquidação', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Doc. Liquidação', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Doc. Pagamento', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Vl. Empenho', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Vl. Liquidação', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Dt. Pagamento', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
      { text: 'Vl. Pagamento', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 0, 0, 0] },
    ]);

    for (let entidade of lista) {
      conteudo.push([
        { text: `${entidade.data_empenho ? datepipe.transform(entidade.data_empenho, 'dd/MM/yyyy') : ''}`, alignment: 'center' },
        { text: `${entidade.tipo ? entidade.tipo : ''}` },
        { text: `${entidade.numero ? entidade.numero : ''}`, alignment: 'center' },
        { text: `${entidade.parcela ? entidade.parcela : ''}`, alignment: 'center' },
        { text: `${entidade.codigo ? entidade.codigo : ''}` },
        { text: `${entidade.vencimento_liquidacao ? datepipe.transform(entidade.vencimento_liquidacao, 'dd/MM/yyyy') : ''}`, alignment: 'center' },
        { text: `${entidade.documento_liquidacao ? entidade.documento_liquidacao : ''}` },
        { text: `${entidade.documento_pagamento ? entidade.documento_pagamento : ''}` },
        { text: `${entidade.valor_empenho ? this.funcaoService.convertToBrNumber(entidade.valor_empenho) : ''}`, alignment: 'right' },
        { text: `${entidade.valor_liquidado ? this.funcaoService.convertToBrNumber(entidade.valor_liquidado) : ''}`, alignment: 'right' },
        { text: `${entidade.data_pagamento ? datepipe.transform(entidade.data_pagamento, 'dd/MM/yyyy') : ''}`, alignment: 'center' },
        { text: `${entidade.valor_pago ? this.funcaoService.convertToBrNumber(entidade.valor_pago) : ''}`, alignment: 'right' }
      ]);
    }

    conteudo.push([
      { text: `TOTAL` },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: `${this.funcaoService.convertToBrNumber(this.totalEmpenhado)}`, alignment: 'right' },
      { text: `${this.funcaoService.convertToBrNumber(this.totalLiquidado)}`, alignment: 'right' },
      { text: '' },
      { text: `${this.funcaoService.convertToBrNumber(this.totalPago)}`, alignment: 'right' }
    ]);

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*'],
        body: conteudo
      }, margin: [0, 0, 0, 15]
    });
    
    return retorno;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      resolve(this.listaImpressao);
    });
  }

  protected larguraColunas(): (string | number)[] {
    return ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto'];
  }

  protected obterColunasRelatorio(): Coluna[] {
    const retorno: Coluna[] = [];

    retorno.push({ titulo: 'Data Empenho', coluna: 'data_empenho', tipo: 'Date' });
    retorno.push({ titulo: 'Tipo Despesa', coluna: 'tipo', tipo: 'String' });
    retorno.push({ titulo: 'Número', coluna: 'numero', tipo: 'Number' });
    retorno.push({ titulo: 'Parcela', coluna: 'parcela', tipo: 'Number' });
    retorno.push({ titulo: 'Recurso', coluna: 'codigo', tipo: 'String' });
    retorno.push({ titulo: 'Venct. Liquidação', coluna: 'vencimento_liquidacao', tipo: 'Date' });
    retorno.push({ titulo: 'Doc. Liquidação', coluna: 'documento_liquidacao', tipo: 'String' });
    retorno.push({ titulo: 'Doc. Pagamento', coluna: 'documento_pagamento', tipo: 'String' });
    retorno.push({ titulo: 'Vl. Empenho', coluna: 'valor_empenho', tipo: 'Number' });
    retorno.push({ titulo: 'Vl. Liquidação', coluna: 'valor_liquidado', tipo: 'Number' });
    retorno.push({ titulo: 'Dt. Pagamento', coluna: 'data_pagamento', tipo: 'Date' });
    retorno.push({ titulo: 'Vl. Pagamento', coluna: 'valor_pago', tipo: 'Number' });

    return retorno;
  }

  protected tituloRelatorio(): string {
    return `Dívidas por Fornecedor - ${this.favorecido.nome}`;
  }

  protected totalizarColunas(): (string | {})[] {
    return;
  }

  public voltar() {
    window.history.back();
  }

}
