import { Component, Injector, OnInit } from '@angular/core';
import { ContratoService } from 'administrativo-lib';
import { BaseResourceListComponent, Coluna, Contrato, Filtro, FormatoExportacao, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

@Component({
  selector: 'app-contrato-list',
  templateUrl: './contrato-list.component.html'
})
export class ContratoListComponent extends BaseResourceListComponent<Contrato, LoginContabil> implements OnInit {

  verPendentes: boolean = false
  numeroContrato: string;

  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    private contratoService: ContratoService) {
    super(contratoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'favorecido,orgao,tipo_contratacao,licitacao,prazo,'
      + 'modalidade'
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    parametros['orgao.id'] = this.login.orgao.id;

    if (this.verPendentes) {
      parametros['inativo'] = true;
      parametros['excluido'] = false;
      parametros['data_contabilizacao$null'] = true;
    }

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_inicio$DESC'];
  }

  public beforeInit() {
    // this.usarExtendido = true;
    this.usarFiltroPersonalizado = true;
  }

  protected podeAlterar(item: Contrato): boolean {
    return !item.excluido && this.login.sistema != 'controle-interno'
  }

  adicionarAtivacao() {
    for (const i of this.lista) {
      if (i.numero == this.numeroContrato) {
        i['enviar'] = true;
        break;
      }
    }
  }

  public validarDatas(contrato: Contrato) {
      const dtContabilizacao: Date = contrato.data_contabilizacao;
      const dtAssinatura: Date = new Date(contrato.data_assinatura);
      if (dtContabilizacao && dtContabilizacao.getFullYear() !== this.login.exercicio.ano) {
        toastr.error('O ano da data de contabilização está diferente do exercício logado. Contrato/Ata: ' + contrato.numero );
        throw new Error('O ano da data de contabilização está diferente do exercício logado');
      }

      if (!this.funcaoService.verificarMesIguais(dtContabilizacao, dtAssinatura)) {
        toastr.error('A data de contabilização deve corresponder ao mês da assinatura.Contrato/Ata: ' + contrato.numero );
        throw new Error('A data de contabilização deve corresponder ao mês da assinatura.');
      }

      if (!this.funcaoService.podeAlterarAudesp(dtContabilizacao, this.login)) {
        toastr.error('Data de contabilização inválida, período na contabilidade já encerrado! Contrato/Ata: ' + contrato.numero );
        throw new Error('Data de contabilização inválida, período na contabilidade já encerrado!');
      }
  }

  ativarSelecionados() {
    let texto = 'Após a ativação, os contratos não poderão ser alterados e a data de contabilização será considerada a de <b>hoje</b>. Contratos sendo ativados:\n\n';

    const envios: Array<Contrato> = new Array<Contrato>();
    for (const c of this.lista) {
      if (c['enviar']) {
        c.data_contabilizacao = new Date();
        // c['ignoreContabilizacao'] = true;
        c.inativo = false;
        this.validarDatas(c)
        envios.push(c);
        texto += '<b>' + c.numero + '</b> ' + c.favorecido.nome + '\n';
      }
    }

    this.confirmationService.confirm({
      header: 'Ativação de contrato',
      message: `<pre>${texto}\n\n</pre>`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        texto = '';
        const promessas = [];
        for (const c of envios) {
          const p = new Promise<void>((resolve) => {
            this.contratoService.atualizar(c).subscribe(res => {
              texto += '<b>' + c.numero + '</b> ' + c.favorecido.nome + ' ativado com sucesso!\n';
              resolve();
            }, e => {
              texto += '<b>' + c.numero + '</b> ' + c.favorecido.nome + ': <b>falha</b> ao ativar!\n';
              resolve();
            });
          });
          promessas.push(p);
        }

        Promise.all(promessas).then(async () => {
          let interval = await setTimeout(() => {
            this.confirmationService.confirm({
              header: 'Ativação de contrato',
              message: `<pre>${texto}</pre>`,
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: 'OK.',
              rejectVisible: false,
              accept: async () => { }
            });
            this.preencherGrid();
            clearInterval(interval);
          }, 500);

        });
      }
    });
  }

  //***********************FILTRO PERSONALIZADO*************************//

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Favorecido', coluna: 'favorecido.nome', tipo: 'String', padrao: true });
    retorno.push({
      titulo: 'Número', coluna: 'numero', tipo: 'String',
      mask: [{ mask: '00000' }, { mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: false, padrao: true, cols: 2
    });
    retorno.push({
      titulo: 'Licitação', coluna: 'licitacao.numero', alignment: 'center', tipo: 'String', cols: 3,
      mask: [{ mask: '00000' }, { mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: false, padrao: true
    });
    retorno.push({
      titulo: 'Processo', coluna: 'processo', alignment: 'center', tipo: 'Processo', cols: 2,
      mask: [{ mask: '00000' }, { mask: '0000/0000' }, { mask: '00000/0000' }], notUnmask: true, padrao: true
    });
    retorno.push({ titulo: 'Objeto', coluna: 'objeto', tipo: 'String', padrao: true });
    retorno.push({ titulo: 'Modalidade', coluna: 'modalidade.nome', tipo: 'String' });
    retorno.push({ titulo: 'Data', coluna: 'data_inicio', tipo: 'Date', alignment: 'center' });
    retorno.push({ titulo: 'Tipo', coluna: 'tipo', tipo: 'Selection', opcoesSelecao: [{ valor: 'CONTRATO', label: 'Contrato' }, { valor: 'REGISTRO_PRECO', label: 'Registro de Preço' }] });
    retorno.push({ titulo: 'Removido', coluna: 'excluido', tipo: 'Boolean' });
    retorno.push({ titulo: 'Ativo', coluna: 'inativo$ne', tipo: 'Boolean' });
    retorno.push({ titulo: 'Possui aditivo', coluna: 'aditamentos.id$not_null', tipo: 'Boolean' });
    // retorno.push({ titulo: 'Possui saldo', coluna: 'possui_saldo', tipo: 'Boolean' });
    retorno.push({ titulo: 'Fiscal/Gestor', coluna: 'fiscais.pessoa.nome', tipo: 'String' });
    return retorno;
  }

  //***********************FILTRO PERSONALIZADO*************************//

  protected filtrosGrid(): Filtro {
    return {
      number: ['numero', 'valor_contrato'],
      date: ['data_inicio', 'data_termino'],
      text: ['numero', 'favorecido.nome', 'tipo_contratacao.nome', 'objeto'],
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Contrato): Observable<Contrato> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero', bold: true },
      { titulo: 'Fornecedor', coluna: 'favorecido.nome' },
      { titulo: 'Tipo Contratação', coluna: 'tipo_contratacao.nome' },
      { titulo: 'Objeto', coluna: 'objeto' },
      { titulo: 'Início', coluna: 'data_inicio' },
      { titulo: 'Término', coluna: 'data_termino' },
      { titulo: 'Valor', coluna: 'valor_contrato', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'orgao,favorecido,tipo_contratacao';
    this.contratoService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE CONTRATOS',
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem contratos', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // public preencherGrid(parametros?: {}): void {
  //   if (!this.contratoService) return;

  //   if (!parametros) parametros = this.obterParametros();
  //   this.contratoService
  //     .listagemContrato(
  //       this.paginaCorrente,
  //       this.limite,
  //       parametros
  //     )
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe(
  //       (dados) => {
  //         console.log(dados)
  //         this.lista = dados.content;
  //         this.paginaTotal = dados.totalPages;
  //         this.aposPreencher();
  //         this.salvarFiltros();
  //       },
  //       (error) => {
  //         if (error?.error?.payload)
  //           toastr.error("erro ao retornar lista");
  //       }
  //     );
  //   // this.salvarFiltros();
  //   console.log(this.lista)
  // }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
