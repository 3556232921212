import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { ContratoAditamento, DateFormatPipe, EddyAutoComplete, Exercicio, FichaDespesa, FuncaoService, GlobalService, Login, ReservaDotacao, ReservaDotacaoItem } from 'eddydata-lib';
import * as toastr from 'toastr';
import { FichaDespesaService } from '../../ficha-despesa/service/ficha-despesa.service';
import { ReservaDotacaoItemService } from '../service/reserva-dotacao-item.service';
import { ContratoAditamentoService } from 'compra-lib';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare var $: any;

type SaldoFicha = {
  dotacao?: number,
  reservado?: number,
  cota_reservada?: number,
  saldo?: number,
  empenhado?: number,
  compra?: number,
  rcms?: number
}

@Component({
  selector: 'app-reserva-dotacao-item',
  templateUrl: './reserva-dotacao-item.component.html'
})
export class ReservaDotacaoItemComponent implements OnInit {

  public ptBR: any;
  public itemAtual: any;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public aditamentoAutoComplete: EddyAutoComplete<ContratoAditamento>;
  public saldoFicha: SaldoFicha;
  protected unsubscribe: Subject<void> = new Subject();

  // public empenhado = 0;
  // public liquidado = 0;
  // public pago = 0;
  public cota = 0
  public dotacao = 0;
  public vlanterior = 0;
  public reservado = 0;
  public estornado = 0;
  public empenhado = 0;
  public vlrcms = 0;
  public vlcompra = 0;
  public vlEmAberto = 0;
  public saldo = 0;
  public valorOriginal = 0;
  public dataEstornoItem: Date;
  public block: boolean;

  @Input() public lista: Array<ReservaDotacaoItem>;
  @Input() public tipo: number;
  @Input() public reserva: ReservaDotacao;
  @Input() public login: Login;
  @Input() public soVisualizar: boolean;
  @Output() callback: EventEmitter<ReservaDotacaoItem[]> = new EventEmitter();
  @Input() visualizar: boolean;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected fichaService: FichaDespesaService,
    protected empenhoService: EmpenhoService,
    public reservaItemService: ReservaDotacaoItemService,
    private contratoAditamentoService: ContratoAditamentoService) {
  }

  ngOnInit() {
    this.itemAtual = new ReservaDotacaoItem();
    this.saldoFicha = {
      dotacao: 0,
      reservado: 0,
      saldo: 0,
      cota_reservada: 0,
      empenhado: 0,
      compra: 0,
      rcms: 0
    };
    this.carregarAutoCompletes();
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected async validSave(item: ReservaDotacaoItem) {
    if (!item.data_reserva) {
      toastr.warning('Informe a data de reserva!');
      return false;
    }
    if (!item.ficha) {
      toastr.warning('Informe a ficha de despesa orçamentária!');
      return false;
    }
    if (item.aditamento && !item.contrato_aditamento) {
      toastr.warning('Aditamento marcado, informe um aditamento!');
      return false;
    }

    const dataReserva: Date = item.data_reserva;
    if (dataReserva.getFullYear() !== this.login.exercicio.ano) {
      toastr.warning('O ano da data da reserva está diferente do exercício logado');
      return false;
    }

    if (+item.valor_reservado == 0) {
      toastr.warning('Informe o valor a ser reservado!');
      return false;
    }
    if (+item.valor_reservado < 0) {
      toastr.warning('Informe o valor a ser reservado deve ser maior que zero!');
      return false;
    }

    const totalizador = await this.fichaService.totalizacaoFicha(
      item.ficha.id, this.login.orgao.id, this.login.exercicio.id, this.funcaoService.converteDataSQL(new Date())
    ).toPromise();

    this.dotacao = (+totalizador.total_dotacao + +totalizador.total_creditado) - +totalizador.total_reservado;

    if (!this.itemAtual.id && (+this.itemAtual.valor_reservado > (+this.saldo + +this.valorOriginal))) {
      toastr.warning(`Sem saldo disponível para fazer essa reserva, limite de: R$${+this.saldo.toFixed(2)}`)
      return false;
    }
    if (!item.ficha) {
      toastr.warning('Ficha de despesa não foi informada!');
      return false;
    }
    if ((+totalizador['total_compra'] !== 0 || +totalizador['total_compra_empenhada'] !== 0)
      && item.valor_reservado > (+this.dotacao - (+totalizador['total_compra'] - +totalizador['total_compra_empenhada'])) && item.ativo) {
      toastr.warning('O valor da reserva é superior ao saldo em aberto da compra');
      return false;
    }
    if (item.valor_reservado > (+this.dotacao - +totalizador['total_rcms_pendente']) && item.ativo) {
      toastr.warning('O valor da reserva é superior ao saldo em aberto da rcms');
      return false;
    }

    // if ((this.reserva.tipo_reserva === '0' && !item.aditamento) && (!item.data_estorno && !item.data_adjudicacao && !item.data_estorno && !item.ficha.terceiro_setor)) {
    //   toastr.warning('Data de adjudicação, data de cancelamento ou de estorno não preenchidas!');
    //   return false;
    // }

    if (!item.data_contabilizacao && this.reserva.data_adjudicacao && !this.podeAlterarAudesp(this.reserva.data_adjudicacao)) {
      toastr.warning('Período informado para data de adjudicação, já encerrado!');
      return false;
    }
    if (item.data_contabilizacao && !this.podeAlterarAudesp(item.data_contabilizacao)) {
      toastr.warning('Período informado para data de contabilização, já encerrado!');
      return false;
    }
    if (item.data_estorno && !this.podeAlterarAudesp(item.data_estorno)) {
      toastr.warning('Período informado para data de estorno, já encerrado!');
      return false;
    }
    if (item.data_estorno && (this.reserva.data_cancelamento || this.reserva.data_adjudicacao)) {
      toastr.warning('A data de estorno só poderá ser preenchida caso as datas de cancelamento e adjudicação não forem informadas');
      return false;
    }
    if (item.data_estorno && this.funcaoService.compararDatas(item.data_estorno, item.data_reserva)) {
      toastr.warning('Data de estorno anterior a data da reserva. Reveja uma das datas!');
      return false;
    }
    if (item.aditamento && item.valor_reservado.toFixed(2) !== item.valor_adjudicacao.toFixed(2)) {
      toastr.warning('A reserva por ser um aditamento, o valor da reserva deve ser igual ao valor adjudicado!');
      return false;
    }
    if (item.aditamento && +item.valor_empenhado > 0 && item.data_estorno) {
      toastr.warning('A reserva possui empenho vinculado, se uma data de estorno for inserida poderá ocorrer inversao no saldo.');
      return true;
    }

    // if (!item.observacao) {
    //   toastr.warning(`Preencha o campo observação!`)
    //   return false;
    // }

    return true;
  }

  public async salvarReserva() {
    if(this.funcaoService.podeAlterarAudesp(this.itemAtual.data_contabilizacao, this.login)) {
      if (await this.validSave(this.itemAtual)) {
        if (!this.itemAtual.id && this.lista.indexOf(this.itemAtual) === -1) {
          this.lista.push(this.itemAtual);
          this.lista = this.lista.slice();
        }
        this.callback.emit(this.lista);
        $('#dialogReserva').modal('hide');
      }
    } else {
      this.itemAtual.data_contabilizacao = '';
      toastr.warning('Período informado para data de contabilização, já encerrado!');
      throw new Error('Período informado para data de contabilização, já encerrado!');
    }
  }

  protected beforeSave(item: ReservaDotacaoItem): void { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public adicionar() {
    this.itemAtual = new ReservaDotacaoItem();
    this.itemAtual.data_reserva = this.funcaoService.obterDataUTC(new Date());
    // if (this.tipo == 0) {
    //   this.itemAtual.data_contabilizacao = this.funcaoService.obterDataUTC(new Date());
    // }
    this.itemAtual.reserva = this.reserva;
    this.itemAtual.valor_adjudicacao = 0;
    this.itemAtual.valor_empenhado = 0;
    this.itemAtual.aditamento = false;
    this.itemAtual.ativo = true;
    this.itemAtual.deduz = false;
    this.itemAtual.valor_reservado = 0;
    $('#dialogReserva').modal('show');
    this.carregarAutoCompletes();
  }

  public async editar(item: ReservaDotacaoItem) {
    this.itemAtual = item;
    this.valorOriginal = this.itemAtual.valor_reservado;
    this.itemAtual.data_reserva = new DateFormatPipe().transform(item.data_reserva, []);
    this.itemAtual.data_estorno = new DateFormatPipe().transform(item.data_estorno, []);
    if (this.tipo == 0) {
      this.itemAtual.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
    } else {
      this.itemAtual.data_contabilizacao = '';
    }
    this.itemAtual.data_baixa = new DateFormatPipe().transform(item.data_baixa, []);
    await this.loadTotalizadores()
    $('#dialogReserva').modal('show');
    this.carregarAutoCompletes();
  }

  public async loadTotalizadores() {
    try {
      // this.empenhado = 0;
      // this.liquidado = 0;
      // this.pago = 0;
      this.dotacao = 0;
      this.vlanterior = 0;
      // this.reservado = 0;
      const exercicio: Exercicio = this.login.exercicio;

      const dataAtualTimeStamp = Date.now();
      const dataAtualIso = new Date(dataAtualTimeStamp);
      const dataReserva = dataAtualIso.toLocaleDateString();
      let totalizadores: any;
      let alternativa: any
      if (this.itemAtual.id) {
        this.buscarSaldoFicha();
        alternativa = await this.fichaService.totalizacaoFicha(this.itemAtual.ficha.id, this.login.orgao.id, this.login.exercicio.id, this.funcaoService.converteDataSQL(dataAtualIso)).toPromise()
        totalizadores = await this.empenhoService.totalizadoresFicha(exercicio.id, this.login.orgao.id, this.itemAtual.ficha.id,
          this.funcaoService.converteDataSQL(dataAtualIso)).toPromise();
        const rcmsAberto = await this.fichaService.totalRcms(this.itemAtual.ficha.id, {
          saldo_cotacao: true,
          data_limite: String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(this.itemAtual.data_reserva, ['local']))), pendente: true
        }).toPromise();
        totalizadores['rcms_em_aberto'] = +rcmsAberto.saldo;
      } else {
        const ficha: FichaDespesa = this.itemAtual.ficha;
        if (ficha && dataReserva) {
          this.buscarSaldoFicha();
          alternativa = await this.fichaService.totalizacaoFicha(this.itemAtual.ficha.id, this.login.orgao.id, this.login.exercicio.id, this.funcaoService.converteDataSQL(dataAtualIso)).toPromise()
          totalizadores = await this.empenhoService.totalizadoresFicha(
            exercicio.id, this.login.orgao.id, ficha.id,
            String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataAtualIso, ['local'])))).toPromise();
          const rcmsAberto = await this.fichaService.totalRcms(ficha.id, { saldo_cotacao: true, data_limite: String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataAtualIso, ['local']))), pendente: true }).toPromise();
          totalizadores['rcms_em_aberto'] = +rcmsAberto.saldo;
        }
      }

      if (totalizadores) {
        this.empenhado = totalizadores.total_empenhado;
        // this.liquidado = totalizadores.total_liquidado;
        // this.pago = totalizadores.total_pago;

        // this.reservado = +totalizadores.total_reservado
        this.reservado = +alternativa.total_reservado
        this.estornado = +alternativa.reserva_estorno;

        // this.dotacao = (+totalizadores.total_dotacao_ficha + +totalizadores.total_creditado_ficha) - +totalizadores.total_reservado;
        this.dotacao = (+alternativa.total_dotacao + +alternativa.total_creditado) - +alternativa.total_reservado

        // this.vlEmAberto = +totalizadores.rcms_em_aberto;
        this.vlEmAberto = +alternativa.total_rcms_pendente

        // const valorAnterior = totalizadores.total_empenhado_ficha_anterior ?
        // +totalizadores.total_empenhado_ficha_anterior : +totalizadores.total_empenhado_ficha;
        const valorAnterior = alternativa.total_compra_empenhada

        this.vlanterior = +this.dotacao - +valorAnterior;

        this.cota = alternativa.cota_reservada

        // this.saldo = ((+this.vlanterior + +(this.itemAtual.id ? +this.itemAtual.valor_reservado : 0) - +this.empenhado - +this.saldoFicha?.rcms -
        //   +this.saldoFicha?.compra - +this.itemAtual.valor_reservado - +this.vlEmAberto) > 0 ?
        //   +this.vlanterior + +(this.itemAtual.id ? +this.itemAtual.valor_reservado : 0) - +this.empenhado - +this.saldoFicha?.rcms -
        //   +this.saldoFicha?.compra - +this.itemAtual.valor_reservado - +this.vlEmAberto : 0)

        // solicitado pelo chamado 170040 que o valor do saldo disponivel seja exatamente igual ao saldo atual da Posição da ficha no requisição
        this.saldo = +(+this.saldoFicha.dotacao.toFixed(2) - +this.saldoFicha.empenhado.toFixed(2) - +this.saldoFicha.rcms.toFixed(2) - +this.saldoFicha.cota_reservada.toFixed(2)).toFixed(2)
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private buscarSaldoFicha(init?: boolean) {
    const dataAtualTimeStamp = Date.now();
    const dataAtualIso = new Date(dataAtualTimeStamp);
    const dataReserva = dataAtualIso.toLocaleDateString();
    const exercicio: Exercicio = this.reserva.exercicio;
    const ficha: FichaDespesa = this.itemAtual.ficha;
    const dataEmpenho = new Date(dataReserva);

    if (!ficha || !dataEmpenho || !exercicio)
      return;
    this.fichaService.saldoAtualFicha(ficha.id, this.login.orgao.id, this.login.exercicio.id,
      String(this.funcaoService.converteDataSQL(new DateFormatPipe().transform(dataAtualIso, ['local']))),
      null, true).subscribe((data) => {
        if (data)
          this.carregarSaldoFicha(data, init);
      });
  }

  private carregarSaldoFicha(totalizadores: any, init?: boolean) {
    if (!totalizadores)
      return;

    if (totalizadores.saldo_atual <= 0 && !init && !this.itemAtual.id) {
      toastr.warning(`Ficha de '${this.itemAtual.ficha.despesa.nome}' não possui saldo para estar realizando a reserva de dotação`);
    }

    this.saldoFicha = {};

    this.saldoFicha.empenhado = +totalizadores.total_empenhado;
    this.saldoFicha.rcms = +totalizadores.total_rcms_pendente;
    this.saldoFicha.compra = +totalizadores.total_compra_pendente;
    this.saldoFicha.cota_reservada = +totalizadores.cota_reservada

    this.saldoFicha.dotacao = (+totalizadores.total_dotacao + +totalizadores.total_creditado) - +totalizadores.total_reservado;
    this.saldoFicha.reservado = +totalizadores.total_reservado;
    this.saldoFicha.saldo = +totalizadores.saldo_atual;
  }

  private carregarAutoCompletes() {
    this.fichaAutoComplete = new EddyAutoComplete(this.itemAtual.ficha, this.fichaService,
      'numero', ['ficha.numero', 'ficha.despesa.codigo', 'ficha.despesa.nome'],
      {
        exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id,
        relations: 'exercicio,orgao,despesa,funcao,subfuncao,programa,acao,recurso,aplicacao,aplicacao_variavel,executora,executora.unidade,recurso_siconfi,complemento_siconfi',
        orderBy: 'numero'
      },
      { number: ['numero'], text: ['despesa.nome'] },
      () => {
        // this.loadTotalizadores();
      }
    );

    const contrato_ids = this.reserva?.licitacao?.contratos?.map(contrato => contrato.id).join(',');

    if (this.reserva.tipo_reserva === '0') {
      this.aditamentoAutoComplete = new EddyAutoComplete(this.itemAtual.contrato_aditamento, this.contratoAditamentoService,
        'id', ['contrato.numero', 'numero', 'contrato.favorecido.nome'],
        {
          relations: 'contrato.favorecido',
          orderBy: 'numero',
          data_termino$ge: this.funcaoService.converteDataSQL(this.itemAtual.data_reserva),
          contrato_id$in: contrato_ids || 0
        },
        {
          text: ['contrato.numero', 'numero']
        }
      );
    }
  }

  public podeAlterarAudesp(data: Date): boolean {
    if (!data) {
      return true;
    }
    if (this.login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +this.login['ultimoAudesp'].split('-')[1];
      const ano = +this.login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    if (this.login['dias_bloquear_alteracoes']) {
      return new FuncaoService().diferencaEmDias(new Date(), new Date(data)) < this.login['dias_bloquear_alteracoes'];
    } else {
      return true;
    }
  }

  public limparAutoComplete(recarregar: boolean): void {
    this.aditamentoAutoComplete.id = null;
    this.itemAtual.contrato_aditamento = null;
    if (recarregar) {
      this.carregarAutoCompletes();
    }
  }

  public disabled(data) {
    if (this.funcaoService.podeAlterarAudesp(data, this.login)) {
      return true
    } else {
      return false
    }
  }

  public dataEstorno(item: ReservaDotacaoItem): void {
    this.itemAtual = item;
    $('#reservaEstornoModal').modal('show');
  }

  public atualizarLista(): void {
    this.lista = new Array();
    this.reservaItemService.extendido(0, -1, {
      reserva_id: this.reserva.id,
      relations: 'reserva,reserva.exercicio,reserva.orgao,ficha,ficha.acao,ficha.aplicacao,ficha.aplicacao_variavel,ficha.recurso,ficha.programa,' +
        'ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.executora,ficha.executora.unidade,ficha.recurso_siconfi,ficha.complemento_siconfi,' +
        'ficha.orgao,ficha.exercicio,contrato_aditamento.contrato.favorecido,reserva.licitacao,estornos'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        // this.lista = JSON.parse(JSON.stringify(res.content));
        this.lista = res.content;
        this.callback.emit(this.lista);

        res.content.forEach(item => {
          item.total_empenhado = item?.total_empenhado_por_reserva_item ?? item?.total_empenhado ?? 0;
          item.total_anulado = item?.contrato_aditamento ? item?.total_anulado_contrato : item?.total_anulado_licitacao;
        });
      });
  }

}
