import { EmpenhoResto, FavorecidoBanco, FuncaoService, GlobalService, LicitacaoPipe, Login, OrgaoAssinaturaService, Relatorio } from "eddydata-lib";
import * as extenso from "extenso";
import JsBarcode from "jsbarcode";

export class NotaEmpenhoResto {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;

  private prefeito: string;
  private cargo_prefeito: string;
  private ordenador_despesa: string;
  private cargo_ordenador_despesa: string;
  private ordenador_despesa_funcao_08: string;
  private cargo_ordenador_despesa_funcao_08: string;
  private ordenador_despesa_funcao_10: string;
  private cargo_ordenador_despesa_funcao_10: string;
  private ordenador_despesa_funcao_12: string;
  private cargo_ordenador_despesa_funcao_12: string;
  private contador: string;
  private cargo_contador: string;
  private tesoureiro: string;
  private cargo_tesoureiro: string;

  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
  }

  public async imprimir(model: EmpenhoResto[], login: Login) {
    this.log = login;

    // const assinatura = await this.assinaturaService.obter({
    //   orgao_id: this.log.orgao.id,
    //   'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    // }).toPromise();
    // if (assinatura) {
    //   this.prefeito = assinatura.prefeito;
    //   this.cargo_prefeito = assinatura.cargo_prefeito;

    //   this.ordenador_despesa = assinatura.ordenador_despesa;
    //   this.cargo_ordenador_despesa = assinatura.cargo_ordenador_despesa;

    //   this.ordenador_despesa_funcao_08 = assinatura.ordenador_despesa_funcao_08;
    //   this.cargo_ordenador_despesa_funcao_08 = assinatura.cargo_ordenador_despesa_funcao_08;

    //   this.ordenador_despesa_funcao_10 = assinatura.ordenador_despesa_funcao_10;
    //   this.cargo_ordenador_despesa_funcao_10 = assinatura.cargo_ordenador_despesa_funcao_10;

    //   this.ordenador_despesa_funcao_12 = assinatura.ordenador_despesa_funcao_12;
    //   this.cargo_ordenador_despesa_funcao_12 = assinatura.cargo_ordenador_despesa_funcao_12;

    //   this.tesoureiro = assinatura.tesoureiro;
    //   this.cargo_tesoureiro = assinatura.cargo_tesoureiro;

    //   this.contador = assinatura.contador;
    //   this.cargo_contador = assinatura.cargo_contador;
    // }

    Relatorio.imprimirPersonalizado('NOTA DE EMPENHO DE RESTO', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      await this.montarConteudo(model),
      'portrait', 'NOTA EMPENHO RESTO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, true, true);
  }

  private async montarConteudo(lista: EmpenhoResto[]) {
    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }

      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const barCode = `018170${this.funcaoService.strZero((+entidade.valor_empenho).toFixed(2), 12).split('.').join('')}` +
        `${this.log.orgao.codigo.substring(0, 4)}${this.funcaoService.converteDataSQL(new Date(entidade.data_empenho)).split('-').join('')}` +
        `${this.funcaoService.strZero(entidade.numero, 10)}0000090`;

      JsBarcode(canvas, String(barCode), { displayValue: false, height: 70, margin: 0 });
      conteudo.push(this.dadosCabecalho(this.log, entidade)
        .concat(this.dadosEmpenho(entidade))
        .concat(this.dadosAplicacao(entidade))
        .concat(this.dadosConvenio(entidade))
        .concat(this.dadosFavorecido(entidade))
        .concat(this.dadosHistorico(entidade))
        .concat(await this.dadosAutorizacao(entidade))
        .concat(this.dadosRecibo()).concat([{
          image: canvas.toDataURL(),
          fit: [250, 70], margin: [10, 5]
        }]));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, entidade: EmpenhoResto): {}[] {
    const anulacao = entidade.especie === 'EEA';

    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'left',
        margin: [0, 0, 0, 0],
        border: [true, true, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, false, false] };
    }

    brasaoImage['rowSpan'] = 4;

    const conteudo = [
      [brasaoImage, { text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 14, border: [false, true, true, false] }],
      [
        { text: '', border: [false, false, false, false], margin: [0, 0, 0, 0] },
        {
          text: `${log.orgao.endereco} ${log.cidade?.nome} ${log.cidade?.estado?.nome} CNPJ: ${log.orgao.cnpj}`,
          alignment: 'center', fontSize: 8, border: [false, false, true, false]
        }
      ], [
        { text: '', border: [true, false, false, false], margin: [0, 0, 0, 0] },
        {
          text: !anulacao ? `EMPENHO DE RESTO ORÇAMENTÁRIO` : `ANULAÇÃO EMPENHO DE RESTO ORÇAMENTÁRIO`,
          bold: true, alignment: 'center', fontSize: 14, border: [false, false, true, false]
        }
      ], [
        { text: '', border: [true, false, false, false], margin: [0, 0, 0, 0] },
        { text: `${log.exercicio.ano}`, bold: true, alignment: 'center', fontSize: 12, border: [false, false, true, false] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        // dontBreakRows: true,
        widths: [50, '*'],
        body: conteudo
      }
    }];
  }

  private dadosEmpenho(dados: EmpenhoResto): {}[] {
    const tipoEmpenho = this.globalService.obterListaTiposEmpenhos().find(x => x.id === dados.tipo_empenho);
    const modalidade = dados.modalidade?.nome?.length > 21 ? dados.modalidade?.nome?.split(' ', 3).join(' ') : dados.modalidade?.nome;
    const conteudo = [
      [
        { text: 'EMPENHO Nº ' + this.funcaoService.strZero(dados.numero, 4), rowSpan: 2, fontSize: 11, alignment: 'left', bold: true, margin: [0, 8, 8, 8] },
        { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Data', fontSize: 7, border: [true, true, false, false] },
        { text: 'Prazo Pago', fontSize: 7, border: [true, true, false, false] },
        { text: 'Contrato', fontSize: 7, border: [true, true, false, false] },
        { text: 'Licitação', fontSize: 7, border: [true, true, false, false] },
        { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
        { text: 'Modalidade Licitação', fontSize: 7, border: [true, true, true, false], }
      ], [
        { text: '' },
        { text: tipoEmpenho ? tipoEmpenho.nome : ' - ', alignment: 'center', bold: true, border: [false, false, true, true] },
        { text: this.funcaoService.converteDataBR(dados.data_empenho), alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: this.funcaoService.converteDataBR(dados.data_vencimento), alignment: 'center', bold: true, border: [true, false, false, true] },
        {
          text: dados.contrato ? (dados.contrato?.numero?.length > 7 ? `${new LicitacaoPipe().transform(dados.contrato?.numero)}` : dados.contrato?.numero) : ' - ',
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        { text: dados.licitacao ? `${new LicitacaoPipe().transform(dados.licitacao?.numero)}` : ' - ', alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: dados.compra?.ficha?.numero, alignment: 'center', fontSize: 12, bold: true, border: [true, false, false, true] },
        { text: modalidade, alignment: 'left', bold: true, border: [true, false, true, true] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [115, 50, 45, 45, 55, 55, 30, '*'],
        body: conteudo
      }
    }];
  }

  private dadosAplicacao(dados: EmpenhoResto): {}[] {

    const aplicacao = dados?.compra?.ficha ? `${dados?.compra?.ficha?.aplicacao_variavel ?
      `${dados.compra.ficha.aplicacao_variavel?.codigo} ${dados.compra.ficha?.aplicacao_variavel.nome}` : `${dados.compra.ficha.aplicacao?.codigo} ${dados.compra.ficha.aplicacao?.nome}`}`
      : `${dados?.recurso_variavel ? dados?.aplicacao + ' ' + dados?.recurso_variavel : dados?.aplicacao} ${dados?.recurso_variavel_nome ? dados?.recurso_variavel_nome : dados?.aplicacao_nome}`;

    const conteudo = [
      [
        { text: 'ITEM:', border: [true, false, false, false], margin: [0, 15, 0, 0] },
        { text: `${dados.subelemento_nome}`, border: [false, false, true, false], margin: [0, 15, 0, 0] },
        { text: 'VALOR TOTAL', bold: true, border: [true, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(dados.valor_empenho), bold: true, border: [false, false, true, false], alignment: 'right' },
      ], [
        { text: 'RECURSO:', border: [true, false, false, false], margin: [0, 15, 0, 0] },
        { text: `${dados.recurso} ${dados.recurso_nome}`, border: [false, false, false, false], margin: [0, 15, 0, 0] },
        { text: 'ANULADO', border: [true, false, false, false], bold: true, margin: [0, -15, 0, 0] },
        { text: this.funcaoService.convertToBrNumber(dados['total_anulado']), border: [false, false, true, false], bold: true, margin: [0, -15, 0, 0], alignment: 'right' },
      ], [
        { text: 'APLICAÇÃO:', border: [true, false, false, true], margin: [0, 15, 0, 15] },
        { text: `${aplicacao}`, border: [false, false, true, true], margin: [0, 15, 0, 15] },
        { text: 'SALDO', border: [true, false, false, true], bold: true, margin: [0, -15, 0, 0] },
        { text: this.funcaoService.convertToBrNumber(+dados.valor_empenho - +dados['total_anulado']), border: [false, false, true, true], bold: true, margin: [0, -15, 0, 0], alignment: 'right' },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [60, '*', 60, 60],
        body: conteudo
      }
    }];
  }

  private dadosConvenio(dados: EmpenhoResto): {}[] {
    const conteudo = [
      [
        { text: 'Tipo de Meta', fontSize: 7, border: [true, false, false, false] },
        { text: 'Contrato', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Início', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Término', fontSize: 7, border: [true, false, true, false] },
        { text: 'Processo', fontSize: 7, border: [true, false, true, false] },
        { text: 'Data Assinatura', fontSize: 7, border: [true, false, true, false] }
      ], [
        { text: '', alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: dados.contrato?.numero ? `${dados.contrato?.numero}` : '-', alignment: 'center', border: [true, false, false, true] },
        {
          text: dados.contrato?.data_inicio ? this.funcaoService.converteDataBR(dados.contrato?.data_inicio) : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {
          text: dados.contrato?.data_termino ? this.funcaoService.converteDataBR(dados.contrato?.data_termino) : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        { text: dados.processo ? dados.processo : '-', alignment: 'center', bold: true, border: [true, false, true, true] },
        {
          text: dados.contrato?.data_assinatura ? this.funcaoService.converteDataBR(dados.contrato?.data_assinatura) : '-',
          alignment: 'center', border: [true, false, true, true]
        }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [90, 55, 100, 65, 65, '*'],
        body: conteudo
      }
    }];
  }

  private dadosFavorecido(dados: EmpenhoResto): {}[] {
    const tipoPessoa = this.globalService.obterListaTipoFornecedores().find(x => x.id === dados.favorecido?.tipo);
    let dadosBanco: FavorecidoBanco;
    if (dados.favorecido.contas) {
      dadosBanco = dados.favorecido.contas.find(conta => conta.ativo === true && conta.principal === true);
    }

    const conteudo = [
      [
        { text: 'Beneficiário:', bold: true, colSpan: 3, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ], [
        { text: `${dados.favorecido?.nome}`, colSpan: 3, fontSize: 11, bold: true, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: `CÓDIGO: ${dados.favorecido?.id}`, bold: true, border: [false, false, true, false] }
      ], [
        { text: 'CPF/CNPJ:', border: [true, false, false, false] },
        { text: `${dados.favorecido?.cpf_cnpj}`, border: [false, false, false, false] },
        { text: 'TIPO PESSOA:', border: [false, false, false, false] },
        { text: tipoPessoa ? tipoPessoa.nome : '-', border: [false, false, false, false] },
        { text: 'BANCO:', border: [false, false, false, false] },
        { text: dadosBanco ? dadosBanco.banco.nome : '', border: [false, false, true, false] },
      ], [
        { text: 'ENDEREÇO:', border: [true, false, false, false] },
        { text: `${dados.favorecido?.endereco}, ${dados.favorecido?.num_endereco} `, border: [false, false, false, false] },
        { text: 'BAIRRO:', border: [false, false, false, false] },
        { text: dados.favorecido?.bairro, border: [false, false, false, false] },
        { text: 'AGÊNCIA:', border: [false, false, false, false] },
        { text: dadosBanco ? dadosBanco.agencia : '', border: [false, false, true, false] }
      ], [
        { text: 'CIDADE:', border: [true, false, false, true] },
        { text: `${dados.favorecido?.municipio}, ${dados.favorecido?.uf} `, border: [false, false, false, true] },
        { text: 'TELEFONE:', border: [false, false, false, true] },
        { text: dados.favorecido?.telefone ? `(${dados.favorecido?.ddd_fone ? dados.favorecido?.ddd_fone : ''}) ${dados.favorecido?.telefone}` : ' - ', border: [false, false, false, true] },
        { text: 'N° CONTA:', border: [false, false, false, true] },
        { text: dadosBanco ? dadosBanco.numero_conta : '', border: [false, false, true, true] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 41, 79, 40, 80],
        body: conteudo
      }
    }];
  }

  private dadosHistorico(dados: EmpenhoResto): {}[] {
    const conteudo = [
      [
        { text: 'Histórico:', border: [true, false, true, false], bold: true },
        { text: 'Observações', border: [false, false, true, false], colSpan: 2 },
        {}
      ], [
        { text: `${dados.historico} `, border: [true, false, true, false] },
        { text: ``, border: [false, false, true, true], colSpan: 2 },
        {}
      ], [
        { text: '', border: [true, false, false, true] },
        { text: 'VALOR...', border: [true, true, false, true], bold: true, fontSize: 11 },
        { text: this.funcaoService.convertToBrNumber(dados.valor_empenho), border: [false, true, true, true], bold: true, fontSize: 11, alignment: 'right' }
      ], [
        { text: 'Fica empenhado a importância de:', bold: true, border: [true, true, true, false], colSpan: 3 },
        {},
        {}
      ], [
        {
          text: extenso(this.funcaoService.convertToBrNumber(dados.valor_empenho), { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase(),
          border: [true, false, true, false], colSpan: 3
        },
        {},
        {}
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [15, 80, 15, 15, 30],
        widths: [280, '*', '*'],
        body: conteudo
      }
    }];
  }

  private async dadosAutorizacao(dados: EmpenhoResto): Promise<{}[]> {
    let ordenador = '';
    let cargoOrdenador = '';
    const funcao = dados.funcao;

    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date(dados.data_empenho))),
      orderBy: 'data_limite'
    }).toPromise();
    if (assinatura) {
      this.prefeito = assinatura.prefeito;
      this.cargo_prefeito = assinatura.cargo_prefeito;

      this.ordenador_despesa = assinatura.ordenador_despesa;
      this.cargo_ordenador_despesa = assinatura.cargo_ordenador_despesa;

      this.ordenador_despesa_funcao_08 = assinatura.ordenador_despesa_funcao_08;
      this.cargo_ordenador_despesa_funcao_08 = assinatura.cargo_ordenador_despesa_funcao_08;

      this.ordenador_despesa_funcao_10 = assinatura.ordenador_despesa_funcao_10;
      this.cargo_ordenador_despesa_funcao_10 = assinatura.cargo_ordenador_despesa_funcao_10;

      this.ordenador_despesa_funcao_12 = assinatura.ordenador_despesa_funcao_12;
      this.cargo_ordenador_despesa_funcao_12 = assinatura.cargo_ordenador_despesa_funcao_12;

      this.tesoureiro = assinatura.tesoureiro;
      this.cargo_tesoureiro = assinatura.cargo_tesoureiro;

      this.contador = assinatura.contador;
      this.cargo_contador = assinatura.cargo_contador;
    }

    switch (funcao) {
      case '12':
        ordenador = this.ordenador_despesa_funcao_12;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_12;
        break;
      case '10':
        ordenador = this.ordenador_despesa_funcao_10;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_10;
        break;
      case '08':
        ordenador = this.ordenador_despesa_funcao_08;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_08;
        break;
      default:
        ordenador = this.ordenador_despesa;
        cargoOrdenador = this.cargo_ordenador_despesa;
    }

    if (!ordenador || !cargoOrdenador) {
      ordenador = this.ordenador_despesa;
      cargoOrdenador = this.cargo_ordenador_despesa;
    }

    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO CONTABILIZAÇÃO', bold: true, border: [true, true, true, false], margin: [0, 0, 0, 10] },
        { text: 'AUTORIZAÇÃO EMPENHO', bold: true, border: [false, true, true, false] },
        { text: 'AUTORIZAÇÃO PAGAMENTO', bold: true, border: [false, true, true, false], colSpan: 2 },
        {}
      ], [
        { text: '___________________________________', alignment: 'center', border: [true, false, true, false] },
        { text: '___________________________________', alignment: 'center', border: [false, false, true, false] },
        { text: '___________________________________', alignment: 'center', border: [false, false, false, false] },
        { text: '___________________________________', alignment: 'center', border: [false, false, true, false] },
      ], [
        { text: this.contador ? this.contador : '', fontSize: 7, alignment: 'center', border: [true, false, true, false] },
        { text: ordenador, fontSize: 7, alignment: 'center', border: [false, false, true, false] },
        { text: this.tesoureiro ? this.tesoureiro : '', fontSize: 7, alignment: 'center', border: [false, false, false, false] },
        { text: this.prefeito ? this.prefeito : '', fontSize: 7, alignment: 'center', border: [false, false, true, false] },
      ], [
        { text: this.cargo_contador ? this.cargo_contador : '', fontSize: 7, alignment: 'center', border: [true, false, false, false] },
        { text: cargoOrdenador, fontSize: 7, alignment: 'center', border: [true, false, true, false] },
        { text: this.cargo_tesoureiro ? this.cargo_tesoureiro : '', fontSize: 7, alignment: 'center', border: [false, false, false, false] },
        { text: this.cargo_prefeito ? this.cargo_prefeito : '', fontSize: 7, alignment: 'center', border: [false, false, true, false] },
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, true, true] },
        { text: 'Data: _____/_____/________', border: [true, false, true, true] },
        { text: 'Data: _____/_____/________', border: [true, false, true, true], colSpan: 2 },
        {}
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [30, 10, 5, 30],
        widths: ['*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private dadosRecibo(): {}[] {
    const conteudo = [
      [
        { text: 'RECIBO E QUITAÇÃO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, true, false], }
      ], [
        { text: 'Recebemos o valor a que se refere este empenho, ao qual damos plena e geral quitação.', border: [true, false, false, false], colSpan: 2, margin: [0, 0, 20, 0] },
        { text: '', border: [false, false, false, false], },
        { text: 'Assinatura', border: [false, false, false, false], },
        { text: 'Documento', border: [false, false, true, false], }
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, true, true], }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

}