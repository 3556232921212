import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ProdutoDlgModule } from "almoxarifado-lib";
import { IMaskModule } from "angular-imask";
import { PessoaModule, SharedModule } from "eddydata-lib";
import { AccordionModule } from "primeng/accordion";
import { MessageService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { RadioButtonModule } from "primeng/radiobutton";
import { SelectButtonModule } from "primeng/selectbutton";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { TooltipModule } from "primeng/tooltip";
import { ParametroRequisicaoModule } from "projects/requisicao/src/app/parametro-requisicao/parametro-requisicao.module";
import { FavorecidoDlgModule } from "../favorecido/favorecido-dlg.module";
import { ProdutoModule } from "../produto/produto.module";
import { SaldoFichaModule } from "../saldo-ficha/saldo-ficha.module";
import { ServicoModule } from "../servico/servico.module";
import { ExclusaoRcmsDlgComponent } from "./exclusao-rcms-dlg/exclusao-rcms-dlg.component";
import { ImprimirNotaRcmsRpt } from "./nota-rcms-rpt/imprimir-nota-rcms-rpt/imprimir-nota-rcms-rpt.component";
import { NotaRcmsRpt } from "./nota-rcms-rpt/nota-rcms-rpt.component";
import { RcmsAutorizacaoListComponent } from "./rcms-autorizacao-list/rcms-autorizacao-list.component";
import { RcmsContratosDlgComponent } from "./rcms-contratos-dlg/rcms-contratos-dlg.component";
import { RcmsCotacaoComponent } from "./rcms-cotacao/rcms-cotacao.component";
import { RcmsFavorecidoComponent } from "./rcms-favorecido/rcms-favorecido.component";
import { RcmsFavorecidosDlgComponent } from "./rcms-favorecidos-dlg/rcms-favorecidos-dlg.component";
import { RcmsLicitacoesDlgComponent } from "./rcms-licitacoes-dlg/rcms-licitacoes-dlg.component";
import { RcmsFichaReservaDotacaoDlg } from "./rcms-ficha-reserva-dotacao-dlg/rcms-ficha-reserva-dotacao-dlg.component";
import { RcmsFormComponent } from "./rcms-form/rcms-form.component";
import { RcmsItemComponent } from "./rcms-item/rcms-item.component";
import { RcmsJustificativaDesbloqueioAutomaticoComponent } from "./rcms-justificativa-desbloqueio-automatico/rcms-justificativa-desbloqueio-automatico.component";
import { RcmsListDlgComponent } from "./rcms-list-dlg/rcms-list-dlg.component";
import { RcmsListComponent } from "./rcms-list/rcms-list.component";
import { RcmsRoutingModule } from "./rcms-routing.module";
import { RcmsSelecionarItemDlgComponent } from "./rcms-selecionar-item-dlg/rcms-selecionar-item-dlg.component";
import { RcmsTabelasDescontoDlg } from "./rcms-tabela-desconto-dlg/rcms-tabela-desconto-dlg.component";
import { RcmsViewComponent } from "./rcms-view/rcms-view.component";
import { RcmsStorageComponent } from "./rcms-storage/rcms-storage.component";
import { RcmsCotacaoEmpateDlgComponent } from "./rcms-cotacao-empate-dlg/rcms-cotacao-empate-dlg.component";
import { RcmsVeiculoBuscaDlgComponent } from "./rcms-veiculo-busca-dlg/rcms-veiculo-busca-dlg.component";
import { RcmsComparativoPreco } from "./rcms-comparativo-preco/rcms-comparativo-preco.component";

const notaRcmsRelatorio = [NotaRcmsRpt, ImprimirNotaRcmsRpt];

@NgModule({
  declarations: [
    RcmsFormComponent,
    RcmsListComponent,
    RcmsItemComponent,
    RcmsFavorecidoComponent,
    RcmsCotacaoComponent,
    RcmsComparativoPreco,
    ExclusaoRcmsDlgComponent,
    RcmsViewComponent,
    RcmsAutorizacaoListComponent,
    RcmsSelecionarItemDlgComponent,
    ...notaRcmsRelatorio,
    RcmsContratosDlgComponent,
    RcmsFavorecidosDlgComponent,
    RcmsFichaReservaDotacaoDlg,
    RcmsListDlgComponent,
    RcmsLicitacoesDlgComponent,
    RcmsTabelasDescontoDlg,
    RcmsJustificativaDesbloqueioAutomaticoComponent,
    RcmsStorageComponent,
    RcmsVeiculoBuscaDlgComponent,
    RcmsCotacaoEmpateDlgComponent
  ],
  exports: [
    ExclusaoRcmsDlgComponent,
    RcmsSelecionarItemDlgComponent,
    ...notaRcmsRelatorio,
    RcmsLicitacoesDlgComponent,
    RcmsContratosDlgComponent,
    RcmsFavorecidosDlgComponent,
    RcmsViewComponent,
  ],
  imports: [
    CommonModule,
    RcmsRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    AccordionModule,
    CalendarModule,
    IMaskModule,
    ToastModule,
    PessoaModule,
    RadioButtonModule,
    FieldsetModule,
    FavorecidoDlgModule,
    SelectButtonModule,
    SaldoFichaModule,
    DialogModule,
    ButtonModule,
    DragDropModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    CheckboxModule,
    FileUploadModule,
    TooltipModule,
    ProdutoDlgModule,
    ProdutoModule,
    ServicoModule,
    ParametroRequisicaoModule,
  ],
  providers: [MessageService],
})
export class RcmsModule { }
