import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseResourceRptComponent, Coluna, DateFormatPipe, EddyAutoComplete, FuncaoService, Inventario, InventarioSetor, ParametroPatrimonioService, ProgressoService, Unidade, UnidadeService } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import { InventarioSetorService } from '../../inventario/service/inventario-setor.service';
import { InventarioService } from '../../inventario/service/inventario.service';

@Component({
  selector: 'app-inventario-rpt',
  templateUrl: './inventario-rpt.component.html'
})
export class InventarioRptComponent extends BaseResourceRptComponent {

  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public ordenacao: { nome: string, coluna: any, ordem: 'DESC' | 'ASC' };
  public listaOrdenacao: { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] = [];
  public parametros: { session?: any, filtros?: any } = {};
  public inventarioSetor: InventarioSetor;
  public statusSelecionado: any = undefined;
  public retorno: Coluna[] = [];
  public inventario: Inventario;
  public listaInventario: Inventario[] = [];
  public agrupamento: 'SEM_ENCERRADOS' | 'COM_ENCERRADOS';
  public listAgrupamento: { id: 'SEM_ENCERRADOS' | 'COM_ENCERRADOS', nome: string }[];
  public listaSecretaria: Unidade[] = [];
  public secretaria: Unidade;

  public inventarioSetorAutoComplete: EddyAutoComplete<InventarioSetor>

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected funcaoService: FuncaoService,
    protected progressoService: ProgressoService,
    protected inventarioService: InventarioService,
    private parametroPatrimonioService: ParametroPatrimonioService,
    private inventarioSetorService: InventarioSetorService,
    private secretariaService: UnidadeService) {
    super();
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaOrdenacao = this.obterColunasOrdenacoes();
    this.ordenacao = this.listaOrdenacao[0];
    this.listAgrupamento = [
      { id: 'SEM_ENCERRADOS', nome: 'Agrupar por Setores que não encerraram inventário' },
      { id: 'COM_ENCERRADOS', nome: 'Agrupar por Setores que encerraram inventário' },
    ];

    this.inventarioService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      orderBy: 'descricao'
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      return this.listaInventario = res.content;
    });
    this.secretariaService.filtrar(0, 0, {
      orgao_id: this.login.orgao.id,
      ppa_id: this.login['ppa'].id
    }).pipe(takeUntil(this.unsubscribe)).subscribe(res => {

      this.listaSecretaria = res.content;
    })
  }

  protected tituloRelatorio(): string {
    return `${this.login.orgao.nome}`;
  }

  protected subTituloRelatorio(): string {
    if (this.statusSelecionado === undefined) {
      return `CONFERÊNCIA DE INVENTÁRIOS - TODOS`;
    } else if (this.statusSelecionado === 'PENDENTE') {
      return `CONFERÊNCIA DE INVENTÁRIOS - NÃO INICIADOS`;
    } else if (this.statusSelecionado === 'INICIADO-NAO-LOCALIZADO') {
      return `CONFERÊNCIA DE INVENTÁRIOS - INICIADOS SEM BENS A LOCALIZAR`;
    } else if (this.statusSelecionado === 'INICIADO') {
      return `CONFERÊNCIA DE INVENTÁRIOS - INICIADOS E NÃO FINALIZADOS`;
    } else if (this.statusSelecionado === 'FINALIZADOS-COM-BENS-A-LOCALIZAR') {
      return `CONFERÊNCIA DE INVENTÁRIOS - FINALIZADOS SEM BENS A LOCALIZAR`;
    } else if (this.statusSelecionado === 'CONCLUIDO') {
      return `CONFERÊNCIA DE INVENTÁRIOS - FINALIZADOS`;
    }
  }

  private carregarAutoCompletes() { }

  protected obterColunasRelatorio(): Coluna[] {
    this.retorno = [];
    if (this.agrupamento === 'SEM_ENCERRADOS' && !this.statusSelecionado) {
      this.retorno.push({ titulo: 'GRUPO', coluna: 'inventario.status', agrupar: true, fontSize: 10 });
      this.retorno.push({ titulo: 'SETOR', coluna: 'setor.nome', fontSize: 8, alignment: 'left' });
      this.retorno.push({ titulo: 'DT. ABERTURA', coluna: 'data_inicio', fontSize: 8, alignment: 'center' });
      this.retorno.push({ titulo: 'HR. ABERTURA', coluna: 'hora_inicio', fontSize: 8, alignment: 'center' });
      this.retorno.push({ titulo: 'RESPONSÁVEL', coluna: 'setor.responsavel.nome', fontSize: 8, alignment: 'left' });
    } else if (this.agrupamento === 'COM_ENCERRADOS' && !this.statusSelecionado) {
      this.retorno.push({ titulo: 'GRUPO', coluna: 'inventario.status', agrupar: true, fontSize: 10 });
      this.retorno.push({ titulo: 'SETOR', coluna: 'setor.nome', fontSize: 8, alignment: 'left' });
      this.retorno.push({ titulo: 'DT. ABERTURA', coluna: 'data_inicio', fontSize: 8, alignment: 'center' });
      this.retorno.push({ titulo: 'HR. ABERTURA', coluna: 'hora_inicio', fontSize: 8, alignment: 'center' });
      this.retorno.push({ titulo: 'RESPONSÁVEL', coluna: 'setor.responsavel.nome', fontSize: 8, alignment: 'left' });
      this.retorno.push({ titulo: 'DT. FINALIZAÇÃO', coluna: 'data_finalizacao', fontSize: 8, alignment: 'center' });
    } else {
      this.retorno.push({ titulo: 'SECRETARIA', coluna: 'unidade', agrupar: true, fontSize: 10, bold: true });
      this.retorno.push({ titulo: 'CÓDIGO', coluna: 'setor.codigo', fontSize: 8, alignment: 'center' });
      this.retorno.push({ titulo: 'SETOR', coluna: 'setor.nome', fontSize: 8, alignment: 'left' });

      if (this.statusSelecionado === 'INICIADO' || this.statusSelecionado === 'INICIADO-NAO-LOCALIZADO') {
        this.retorno.push({ titulo: 'DT. ABERTURA', coluna: 'data_inicio', fontSize: 8, alignment: 'center' });
        this.retorno.push({ titulo: 'HR. ABERTURA', coluna: 'hora_inicio', fontSize: 8, alignment: 'center' });
      }
      if (this.statusSelecionado === 'PENDENTE') {
        this.retorno.push({ titulo: 'RESPONSÁVEL', coluna: 'setor.responsavel.nome', fontSize: 8, alignment: 'left' });
      }
      if (this.statusSelecionado === 'CONCLUIDO' || this.statusSelecionado === 'FINALIZADOS-COM-BENS-A-LOCALIZAR') {
        this.retorno.push({ titulo: 'DT. ABERTURA', coluna: 'data_inicio', fontSize: 8, alignment: 'center' });
        this.retorno.push({ titulo: 'HR. ABERTURA', coluna: 'hora_inicio', fontSize: 8, alignment: 'center' });
        this.retorno.push({ titulo: 'DT. FINALIZAÇÃO', coluna: 'data_finalizacao', fontSize: 8, alignment: 'center' });
        this.retorno.push({ titulo: 'HR. FINALIZAÇÃO', coluna: 'hora_finalizacao', fontSize: 8, alignment: 'center' });
      }
    }

    return this.retorno;
  }

  protected larguraColunas(): (string | number)[] {
    let qtdeColunas;
    if (this.agrupamento === 'SEM_ENCERRADOS' && !this.statusSelecionado) {
      qtdeColunas = ['*', 60, 60, 150];
    } else if (this.agrupamento === 'COM_ENCERRADOS' && !this.statusSelecionado) {
      qtdeColunas = ['*', 60, 60, '*', 80];
    } else if (this.retorno.length === 7) {
      qtdeColunas = ['auto', '*', 60, 60, 80, 80];
    } else if (this.retorno.length === 5) {
      qtdeColunas = ['auto', '*', 60, 60];
    } else if (this.retorno.length === 4) {
      qtdeColunas = ['auto', '*', 150];
    } else {
      qtdeColunas = ['auto', '*'];
    }
    return qtdeColunas;
  }

  protected totalizarColunas(): (string | {})[] {
    return null;
  }

  protected carregarLista(): Promise<any[]> {
    return new Promise((resolve) => {
      let parametros = {
        relations: 'inventario,setor.responsavel,setor.unidade', orderBy: this.ordenacao.coluna
      };

      if (this.agrupamento === 'SEM_ENCERRADOS' && !this.statusSelecionado) {
        parametros['orderBy'] = 'inventario.status$ASC';
      } else if (this.agrupamento === 'COM_ENCERRADOS' && !this.statusSelecionado) {
        parametros['orderBy'] = 'inventario.status$ASC';
        parametros['inventario.status'] = 'CONCLUIDO';
      } else {
        parametros['orderBy'] = this.ordenacao.coluna;
      }

      if (this.statusSelecionado)
        parametros['status'] = this.statusSelecionado === 'INICIADO-NAO-LOCALIZADO' ? 'INICIADO' : this.statusSelecionado;

      if (this.statusSelecionado)
        parametros['status'] = this.statusSelecionado === 'FINALIZADOS-COM-BENS-A-LOCALIZAR' ? 'CONCLUIDO' : this.statusSelecionado;

      if (this.inventario)
        parametros['inventario.id'] = this.inventario.id;

      if (this.statusSelecionado === 'INICIADO-NAO-LOCALIZADO')
        parametros['tombos.localizado'] = true

      if (this.statusSelecionado === 'FINALIZADOS-COM-BENS-A-LOCALIZAR')
        parametros['tombos.localizado'] = true
      if (this.statusSelecionado === 'FINALIZADOS-SEM-BENS-A-LOCALIZAR')
        parametros['tombos.situacao_atual'] = 'NAO_LOCALIZADO'

      if (this.parametros?.filtros)
        parametros = Object.assign(parametros, this.parametros.filtros);

      if (this.secretaria)
        parametros['setor.unidade.id'] = this.secretaria.id;

      parametros['setor.ativo'] = true;

      this.inventarioSetorService.filtrar(0, 0, parametros)
        .subscribe((data) => {
          if (!data?.content)
            resolve(null);
          else
            resolve(data.content.map((element) => {
              element.hora_finalizacao = this.funcaoService.converteHoraBr(element.data_finalizacao)
              element.data_finalizacao = this.funcaoService.converteDataBR(element.data_finalizacao)
              element.hora_inicio = this.funcaoService.converteHoraBr(element.data_inicio)
              element.data_inicio = this.funcaoService.converteDataBR(element.data_inicio)
              element.unidade = `${element?.setor?.unidade?.codigo} - ${element?.setor?.unidade?.nome}`
              return element;
            }));
        });
    });
  }

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================


  public obterColunasOrdenacoes(): { nome: string, coluna: string, ordem: 'DESC' | 'ASC' }[] {
    return [
      { nome: 'Código', coluna: 'setor.codigo', ordem: 'ASC' },
      { nome: 'Nome setor', coluna: 'setor.nome', ordem: 'ASC' },
    ];
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

}
