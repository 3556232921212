import {
  AfterViewInit,
  Directive,
  Injector,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as toastr from "toastr";
import { Relatorio } from "../components/report";
import { Coluna, FormatoExportacao } from "../components/types";
import { Login } from "../entidade/login/login";
import { FuncaoService } from "../util/funcao.service";
import { GlobalService } from "../util/global.service";
import { BaseResourceModel } from "./base-resource.model";
import { BaseResourceService } from "./services/base-resource.service";

@Directive()
export abstract class BaseResourceListComponent<
  T extends BaseResourceModel,
  U extends Login
> implements OnInit, OnDestroy, AfterViewInit {
  // ========================================================================
  // ----------------------- DECLARAÇÃO DE VARIAVEIS ------------------------
  // ========================================================================
  public login: U;
  public lista: T[] = [];
  public paginaCorrente: number;
  public paginaTotal: number;
  public filtro: string;
  public filtroPersonalizado?: { session?: any; filtros?: any } = {};
  protected router: Router;
  protected confirmationService: ConfirmationService =
    new ConfirmationService();
  public usarExtendido = false;
  public usarFiltroSessao = true;
  public usarFiltroPersonalizado = false;
  public limite: number;

  public col = 1;
  public colunaOrdenacao: string;
  public ascendente = true;
  public imagem = "sort_asc_az.png";
  private admin: boolean;

  protected unsubscribe: Subject<void> = new Subject();

  // ========================================================================
  // ------------------------------ CONSTRUTOR ------------------------------
  // ========================================================================
  constructor(
    protected baseResourceService: BaseResourceService<T>,
    protected injector: Injector
  ) {
    this.login = GlobalService.obterSessaoLogin() as U;
    this.router = this.injector.get(Router);
  }

  // ========================================================================
  // -------------------------- MÉTODOS ABSTRAÍDOS --------------------------
  // ========================================================================
  /**
   * Método com as condições padrões da listagem
   * @example {
   * ['orgao.id']: this.login.orgao.id
   * }
   */
  protected abstract condicoesGrid(): {};
  /**
   * Método com as ordenações da listagem
   * @example ['nome', 'sobrenome$DESC']
   */
  protected abstract ordenacaoGrid(): string[];
  /**
   * Método com os campos de filtro da listagem de acordo com o valor digitado na pesquisa
   * @example {
   *  number: ['id', 'numero'],
   *  date: ['data'],
   *  text: ['nome', 'descricao'],
   * }
   */
  protected abstract filtrosGrid(): Filtro;
  /**
   * Método executado após `preencherGrid()` antes de finalizar o `ngOnInit()`
   */
  protected abstract afterInit(): void;
  /**
   * Ação que será executada no `accept` do método `delete()`
   * @example entidade.situacao = 'B';
   * this.service.atualizar(entidade);
   */
  protected abstract acaoRemover(model: T): Observable<T>;
  /**
   * Método com as colunas para geração do relatório em PDF
   * @example [
   * { coluna: 'numero', titulo: 'Número' },
   * { coluna: 'data', titulo: 'Cadastro', bold: true, alignment: 'left' }
   * ]
   */
  protected abstract colunasRelatorio(): string[] | Coluna[];
  /**
   * Método para exportação de listagens em todos formatos (será obrigatória na próxima atualização de estrutura)
   */
  // protected abstract exportarListagem(formato: FormatoExportacao): void;
  public exportarListagem(formato: FormatoExportacao) { }
  /**
   * Método com os relations necessários para a listagem
   * @example 'orgao,orgao.cidade,usuario'
   */
  protected abstract relations(): string;

  // ========================================================================
  // -------------------------- MÉTODOS DA CLASSE ---------------------------
  // ========================================================================
  /**
   * @see `ngOnInit`
   */
  public ngOnInit() {
    toastr.options.positionClass = "toast-top-left";
    this.beforeInit();
    if (!this.paginaCorrente) {
      this.paginaCorrente = 1;
    }
    this.carregarFiltros();
    window.scrollTo(0, 0);
    if (!this.usarFiltroPersonalizado) this.preencherGrid();
    this.salvarFiltros();
    this.afterInit();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
    if (this.usarFiltroPersonalizado) this.preencherGrid();
  }

  /**
   * Método usado para carregar opções atuais dos filtros após o `beforeInit()`
   *
   * @see `beforeInit`
   */
  private carregarFiltros(): void {
    if (
      !this.usarFiltroSessao ||
      !this.baseResourceService ||
      this.usarFiltroPersonalizado
    )
      return;
    //paginaCorrente
    if (
      this.baseResourceService
        .retornarRota()
        .match(GlobalService.TRANSPARENCIA_REGEXP)
    ) {
      if (
        this.baseResourceService
          .retornarRota()
          .match(GlobalService.TRANSPARENCIA_REGEXP)[2]
      ) {
        this.paginaCorrente = +this.baseResourceService
          .retornarRota()
          .match(GlobalService.TRANSPARENCIA_REGEXP)[2];
      } else {
        this.paginaCorrente = +this.baseResourceService
          .retornarRota()
          .match(GlobalService.TRANSPARENCIA_REGEXP)[5];
      }
    } else {
      this.paginaCorrente = +sessionStorage.getItem(
        `${this.retornarRota()}_paginaCorrente`
      );
      this.limite = +sessionStorage.getItem(
        `${this.retornarRota()}_limite`
      ) || this.login.limite;
    }
    this.paginaCorrente = this.paginaCorrente ? this.paginaCorrente : 1;

    //filtro
    if (
      this.baseResourceService
        .retornarRota()
        .match(GlobalService.TRANSPARENCIA_REGEXP)
    ) {
      this.filtro = this.baseResourceService
        .retornarRota()
        .match(GlobalService.TRANSPARENCIA_REGEXP)[3];
      if (this.filtro === undefined) {
        this.filtro = this.baseResourceService
          .retornarRota()
          .match(GlobalService.TRANSPARENCIA_REGEXP)[8];
      }
      if (this.filtro) {
        this.filtro = this.filtro.startsWith("/")
          ? this.filtro.substring(1)
          : this.filtro;
      }
    } else {
      this.filtro = sessionStorage.getItem(`${this.retornarRota()}_filtro`);
    }
    this.filtro = this.filtro ? this.filtro : "";
  }

  /**
   * Método usado para salvar as opções atuais dos filtros após o `preencherGrid()`
   *
   * @see `preencherGrid`
   */
  private salvarFiltros(): void {
    if (!this.limite) {
      this.limite = +sessionStorage.getItem(
        `${this.injector.get(Router).url}_limite`
      ) || 20
    } else {
      sessionStorage.setItem(
        `${this.retornarRota()}_limite`,
        String(this.limite)
      );
    }
    if (
      !this.usarFiltroSessao ||
      !this.baseResourceService ||
      this.usarFiltroPersonalizado
    )
      return;

    if (this.paginaCorrente > this.paginaTotal && this.paginaTotal !== 0) {
      this.paginaCorrente = 1
      this.preencherGrid()
    }

    if (!this.paginaCorrente || this.paginaCorrente <= 0)
      this.paginaCorrente = 1;
    sessionStorage.setItem(
      `${this.retornarRota()}_paginaCorrente`,
      String(this.paginaCorrente)
    );


    if (!this.filtro) this.filtro = "";
    sessionStorage.setItem(`${this.retornarRota()}_filtro`, this.filtro);
  }

  private retornarRota(): string {
    if (!this.baseResourceService) return "";
    const url = this.baseResourceService.retornarRota();
    if (url.match(GlobalService.TRANSPARENCIA_REGEXP)) {
      return url.match(GlobalService.TRANSPARENCIA_REGEXP)[1]
        ? url.match(GlobalService.TRANSPARENCIA_REGEXP)[1]
        : url.match(GlobalService.TRANSPARENCIA_REGEXP)[4];
    } else {
      return url;
    }
  }

  /**
   * Método executado ao iniciar o `ngOnInit()`
   *
   * @see `ngOnInit`
   */
  public beforeInit(): void { }

  /**
   * Método utilizado para retornar os parâmetros usados na listagem,
   * incluindo parametros de ordenação e filtros da listagem
   */
  public obterParametros(): {} {
    // inclui parametros da listagem
    let parametros = this.condicoesGrid();

    // incluir parametros de busca da grid
    if (!this.usarFiltroPersonalizado)
      parametros = Object.assign(
        {},
        parametros,
        new FuncaoService().obterParametros(this.filtrosGrid(), this.filtro)
      );
    else
      parametros = Object.assign(
        {},
        parametros,
        this.filtroPersonalizado.filtros
      );
    // incluir ordenação
    parametros["orderBy"] = this.ordenacaoGrid().join(",");

    // retorna parâmetros
    return parametros;
  }

  /**
   * Método para preencher a listagem de acordo com os parâmetros em `obterParametros()` e com os `relations()`
   *
   * @see `obterParametros`
   * @see `relations`
   */
  public preencherGrid(parametros?: {}) {
    if (!this.baseResourceService) return;

    if (!parametros) parametros = this.obterParametros();

    if (!this.usarExtendido) {
      this.baseResourceService
        .filtrar(
          this.paginaCorrente,
          this.limite,
          this.relations()
            ? Object.assign({}, { relations: this.relations() }, parametros)
            : parametros
        )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (lista) => {
            this.lista = lista.content;
            this.paginaTotal = lista.totalPages;
            this.aposPreencher();
            this.salvarFiltros();
          },
          (error) => toastr.error("erro ao retornar lista")
        );
    } else {
      this.baseResourceService
        .extendido(
          this.paginaCorrente,
          this.limite,
          this.relations()
            ? Object.assign({}, { relations: this.relations() }, parametros)
            : parametros
        )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (dados) => {
            this.lista = dados.content;
            this.paginaTotal = dados.totalPages;
            this.aposPreencher();
            this.salvarFiltros();
          },
          (error) => {
            if (error?.error?.payload)
              toastr.error("erro ao retornar lista");
          }
        );
    }
    // this.salvarFiltros();
  }

  /**
   * Método usado para ser executado após o `preencherGrid()`
   */
  public aposPreencher() { }

  /**
   * Método para recarregar a listagem após alteração de um objeto combo
   * @see `preencherGrid`
   */
  public onChange() {
    this.paginaCorrente = 1;
    this.preencherGrid();
    this.salvarFiltros();
  }

  /**
   * Método para recarregar a listagem após clique em um botão
   * @see `preencherGrid`
   */
  public onClick(valueEmitted?: string) {
    if (!this.usarFiltroPersonalizado) this.filtro = valueEmitted;
    this.paginaCorrente = 1;
    this.preencherGrid();
    this.salvarFiltros();
  }

  /**
   * Método para ordernar os registros no grid
   * @see `preencherGrid`
   * @deprecated
   */
  public ordenar(idx: number) {
    if (this.col === idx) {
      this.ascendente = !this.ascendente;
      this.imagem = this.ascendente ? "sort_asc_az.png" : "sort_desc_az.png";
    }
    this.col = idx;
    this.preencherGrid();
    this.salvarFiltros();
  }

  public reordenar(coluna: string, toggle?: boolean) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    this.colunaOrdenacao = coluna;
    const parametros = this.obterParametros();

    if (!parametros["orderBy"]) parametros["orderBy"] = []
    else parametros["orderBy"] = parametros["orderBy"].split(',')

    const posicao = parametros["orderBy"].findIndex(item => item.includes(this.colunaOrdenacao))

    if (posicao !== -1) {
      parametros["orderBy"].splice(posicao, 1)
      parametros["orderBy"].unshift(`${this.colunaOrdenacao}$${this.ascendente ? "ASC" : "DESC"}`)
      // parametros["orderBy"] = parametros["orderBy"].join(',')
    } else {
      parametros["orderBy"].unshift(`${this.colunaOrdenacao}$${this.ascendente ? "ASC" : "DESC"}`)
      // parametros["orderBy"] = parametros["orderBy"].join(',')
    }

    this.preencherGrid(parametros);
  }

  /**
   * Método para filtrar a listagem após a tecla `Enter` for pressionada no input `button - addon2`
   * @see `onClick`
   */
  public onKeydown(event: any) {
    if (event.key === "Enter") {
      document.getElementById("button-addon2").click();
    }
  }

  /**
   * Percorre a listagem para a próxima página, caso seja a última página permanece na mesma
   * @see `preencherGrid`
   */
  public proximaPagina() {
    this.paginaCorrente =
      this.paginaCorrente === this.paginaTotal
        ? this.paginaTotal
        : this.paginaCorrente + 1;
    this.preencherGrid();
    this.salvarFiltros();
    window.scrollTo(0, 0);
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.preencherGrid();
    this.salvarFiltros();
    window.scrollTo(0, 0);
  }

  /**
   * Percorre a listagem para a página anterior, caso seja a primeira página permanece na mesma
   * @see `preencherGrid`
   */
  public paginaAnterior() {
    this.paginaCorrente =
      this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.preencherGrid();
    this.salvarFiltros();
    window.scrollTo(0, 0);
  }

  /**
   * Altera limite de paginação, máximo 1000.
   * @param limite 
   */
  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
    this.salvarFiltros();
  }

  /**
   * Verifica se o login atual tem a opção de incluir a página informada no parâmetro `url`
   * @param url url da pagina a ser verificada, caso não informado será usada a rota atual em `retornarRota()`
   */
  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        if (!this.baseResourceService) return false;
        url = this.baseResourceService.retornarRota();
      }
      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  /**
 * Verifica se a data informada permite alteração de acordo com parâmetro `Dias para bloquear alterações`
 * @param data data a ser verificada com a data atual
 */
  public podeAlterarAudesp(data: Date): boolean {
    if (this.login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +this.login['ultimoAudesp'].split('-')[1];
      const ano = +this.login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    if (this.login["dias_bloquear_alteracoes"]) {
      return (
        new FuncaoService().diferencaEmDias(new Date(), new Date(data)) <
        this.login["dias_bloquear_alteracoes"]
      );
    } else {
      return true;
    }
  }

  protected podeAlterar(_entidade: T): boolean {
    return true;
  }

  public podeVisualizar(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        url = this.baseResourceService.retornarRota();
      }
      return new FuncaoService().podeIncluir(this.login, url) || new FuncaoService().podeIncluir(this.login, url, true)
    }
    return false;
  }

  administrador() {
    if (this.admin === null || this.admin === undefined) {
      this.admin =
        (new FuncaoService().campoJsonToken(this.login.token, "administrador") ==
          true) && this.login.usuario.sistemas_administrador?.includes(this.login.usuario.sistema);
    }
    return this.admin;
  }

  /**
   * Método para remover o objeto passado via parâmetro
   * Ao aceitar a mensagem de confirmação será executado o método `acaoRemover()`
   * @see `acaoRemover`
   * @param resource entidade a ser removida
   */
  public delete(resource: T) {
    this.confirmationService.confirm({
      message: "Deseja realmente remover esse registro?",
      header: "Exclusão",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.acaoRemover(resource)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              toastr.success("Registro excluído com sucesso!");
              this.lista = this.lista.filter((element) => element !== resource);
            },
            () => toastr.error("erro ao tentar excluir registro")
          );
      },
      reject: () => {
        toastr.info("Operação foi cancelada!");
      },
    });
  }

  /**
   * Método para imprimir os registros da listagem de acordo com o método `colunasRelatorio()`
   * @see `colunasRelatorio`
   */
  public imprimir(
    titulo: string,
    usuario: string,
    sobrenome: string,
    orgao: string,
    brasao: string,
    orientacao: "landscape" | "portrait",
    nomepdf?: string,
    largura?: (string | number)[],
    lista?: any[],
    totalizar?: (string | {})[],
    tamanhoFonteTotalizador?: number
  ) {
    Relatorio.imprimir(
      titulo,
      usuario,
      sobrenome,
      orgao,
      brasao,
      lista ? lista : this.lista,
      this.colunasRelatorio(),
      orientacao,
      nomepdf,
      largura,
      totalizar,
      [],
      null,
      null,
      false,
      tamanhoFonteTotalizador
    );
  }

  public exportar(formato: FormatoExportacao, lista: any[], nome?: string) {
    if (!this.baseResourceService) return;
    if (!nome) {
      nome = this.baseResourceService.retornarRota().startsWith("/")
        ? this.baseResourceService.retornarRota().substr(1)
        : this.baseResourceService.retornarRota();
    }
    const colunas = this.colunasRelatorio() as Coluna[];

    new FuncaoService().exportar(formato, lista, nome, colunas);
  }

  /**
   * Método para verificação de objetos, usados em combos `<select>`
   */
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  /**
   * Método para retornar a página inicial do sistema logado em `login.usuario.sistema`
   */
  public sair() {
    new FuncaoService().navegarPara(this.login.usuario.sistema, this.router);
  }
}

export class Filtro {
  number?: string[];
  date?: string[];
  text?: string[];
}
