import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LicitacaoService } from 'administrativo-lib';
import { FuncaoService, Licitacao, LicitacaoStorage, Login, PncpRegistro, PNCPService, ProgressoService, RegistroPncpService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { LicitacaoStorageService } from '../service/licitacao-storage.service';

declare var $: any;

@Component({
  selector: 'lib-licitacao-storage',
  templateUrl: './licitacao-storage.component.html'
})
export class LicitacaoStorageComponent implements OnChanges {
  @Input() public login: Login;
  @Input() public entidade: Licitacao;
  @Input() public arquivos: LicitacaoStorage[];
  @Input() public tipoPrincipal: number = 0;

  public uploadedFiles: any[] = [];
  public descricao: string = null;
  public visivelTransparenciaTodos: boolean = true;
  public sequencialTodos: boolean = false;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    protected licitacaoService: LicitacaoService,
    private storageService: LicitacaoStorageService,
    private pncpService: PNCPService,
    public funcaoService: FuncaoService,
    protected progressoService: ProgressoService,
    private pncpRegistroService: RegistroPncpService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sequencialTodos = false;
    this.visivelTransparenciaTodos = true;

    let visivelTrans: number = 0;
    let invisivelTrans: number = 0;

    if (this.arquivos) {
      for (let arquivo of this.arquivos) {
        if (arquivo.sequencial) this.sequencialTodos = true;

        if (arquivo.visivel_transparencia) {
          visivelTrans++
        } else {
          invisivelTrans++
        }
      }

      if (invisivelTrans > visivelTrans) this.visivelTransparenciaTodos = false;
    }
  }

  public async onUpload(event: any, fileUpload: any) {
    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      fileUpload.clear();
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
  }

  public removerArquivoLista(file: any) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(file), 1);
  }

  async atualizarDocumento(item: LicitacaoStorage) {
    if (item.sequencial || !item.visivel_transparencia) return;

    if (this.arquivos.filter(a => a.documento_pncp == true).length > 1) {
      item.documento_pncp = false;
      toastr.warning('Já existe um documento marcado como Principal');
      return;
    }

    await this.storageService.atualizar(item).toPromise();
    toastr.info('Lembre-se que é necessário fazer o envio/remoção do documento no painel do PNCP');
  }

  aplicarTipoDocumento(item: LicitacaoStorage, idx: number) {
    this.confirmationService.confirm({
      message: 'Deseja utilizar o mesmo tipo de documento para os demais itens?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      key: 'tipoDocumento',
      accept: async () => {
        for (const arquivo of this.arquivos) {
          if (arquivo.sequencial || arquivo.documento_pncp || !arquivo.visivel_transparencia) {
            continue;
          }
          arquivo.tipo_documento = item.tipo_documento;
          await this.storageService.atualizar(arquivo).toPromise();
        }
        toastr.info('Lembre-se que é necessário fazer o envio/remoção do documento no painel do PNCP');
      }, reject: () => {
        this.atualizarDocumento(item);
      }
    });
  }

  public async confirmarUpload() {
    this.storageService
      .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.id, this.descricao).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        for (const item of res) {
          item.data_cadastro = new Date();
          this.arquivos.unshift(item);
        }
        this.descricao = undefined;
        this.uploadedFiles = []
        $('#dialogUpload').modal('hide');
        toastr.success('Arquivo adicionado com sucesso!')
      }, (res) => {
        toastr.error('Erro ao salvar arquivo.');
        this.uploadedFiles.length = 0;
      });
  }

  public async remover(item: LicitacaoStorage) {
    if (!item) return;

    if (item.sequencial) {
      toastr.warning('Item cadastrado no PNCP, não pode ser removido')
      return;
    }

    this.confirmationService.confirm({
      message: 'Deseja realmente remover o item?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.storageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.arquivos.splice(this.arquivos.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  public async toggleTransparencia(item: LicitacaoStorage) {
    if (!item) return;
    item.visivel_transparencia = !item.visivel_transparencia;
    item.tipo_documento = null;
    await new Promise((resolve, reject) => {
      this.storageService
        .atualizar(item).subscribe(res => {
          toastr.success('Arquivo atualizado com sucesso');
          resolve(true)
        }, error => {
          toastr.error(error.error.payload)
          reject(error)
        });
    });
  }

  public async removerTodos() {
    this.confirmationService.confirm({
      message: `Tem certeza de que deseja remover todos os arquivos? <br>
        Todos os arquivos serão permanentemente excluídos!<br>
        (Essa ação pode demorar um pouco)`,
      header: 'Remover Todos',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.storageService.removerTodos(this.entidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((idTransacao) => {
            this.progressoService.show(idTransacao, (retorno) => {
              this.arquivos = [];
            })
          }, (err) => toastr.error(err.error.payload));
      }, reject: () => {
        toastr.info('Cancelado!');
      }
    });
  }

  public async toggleTransparenciaTodos() {
    this.confirmationService.confirm({
      message: `Tem certeza de que deseja ${this.visivelTransparenciaTodos ? 'Desmarcar' : 'Marcar'} 
        todos os arquivos como visíveis no portal de transparência? <br>
        Esta ação afetará todos os arquivos listados neste processo! <br>
        (Essa ação pode demorar um pouco)`,
      header: 'Marcar/Desmarcar Transparência',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        this.storageService.visuTransparenciaTodos(this.entidade.id, !this.visivelTransparenciaTodos)
          .pipe(takeUntil(this.unsubscribe)).subscribe((idTransacao) => {
            this.progressoService.show(idTransacao, (retorno) => {
              this.visivelTransparenciaTodos = !this.visivelTransparenciaTodos;

              for (let arquivo of this.arquivos) {
                arquivo.visivel_transparencia = this.visivelTransparenciaTodos;
              }
            })
          }, (err) => {
            console.log(err)
            toastr.error(err.error.payload)
          });
      }, reject: () => {
        toastr.info('Cancelado!');
      }
    });
  }

  public baixarTodos() {
    try {
      this.confirmationService.confirm({
        message: `Tem certeza de que deseja baixar todos os arquivos? <br>
          Esta ação pode levar algum tempo dependendo da quantidade de arquivos!<br>
          (Essa ação pode demorar um pouco)`,
        header: 'Download',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: async () => {
          this.storageService.baixarTodos(this.entidade.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async (idTransacao) => {
              this.progressoService.show(idTransacao, async (retorno) => {
                this.storageService.downloadZip()
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((res) => {
                    const blob = new Blob([res]);
                    const downloadURL = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadURL;
                    link.download = 'arquivos-licitacao.zip';
                    link.click();
                    window.URL.revokeObjectURL(downloadURL);
                  });
              });
            }, (error) => this.funcaoService.acaoErro(error))
        }, reject: () => {
          toastr.info('Cancelado!');
        }
      });
    } catch (err) {
      console.log(err)
    }
  }

  public apagarArquivo(arquivo: LicitacaoStorage) {
    this.confirmationService.confirm({
      header: 'Confirmar Remoção',
      message: `<pre>Confirma a remoção desse documento do PNCP? ${arquivo.nome} como ${this.tipoDocumento(arquivo.documento_pncp ? -1 : arquivo.tipo_documento)}.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmar remoção.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.apagarArquivoLicitacao(this.entidade.sequencial_pncp, this.entidade.exercicio.ano, arquivo.sequencial).subscribe(async (res) => {
          arquivo.sequencial = null;
          const aux = Object.assign({}, this.entidade);
          aux.arquivos = null;
          arquivo.licitacao = aux;
          await this.storageService.atualizar(arquivo).toPromise();
          await this.salvarLogExclusao('licitacao_storage');
          toastr.success('Documento removido do PNCP com sucesso!');
        }, e => {
          toastr.error(e.error.message);
        });
      }
    });
  }

  public enviarArquivo(arquivo: LicitacaoStorage) {
    if (arquivo.documento_pncp) {
      const instrumento = this.calculaInstrumento(this.entidade);
      arquivo.tipo_documento = (instrumento == 1 ? 2 : instrumento == 2 ? 1 : instrumento == 3 ? 20 : 2);
    }
    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Deseja realmente enviar este arquivo? ${arquivo.nome}, como ${this.tipoDocumento(arquivo.documento_pncp ? -1 : arquivo.tipo_documento)}?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, enviar.',
      rejectLabel: 'Não',
      accept: async () => {
        arquivo.licitacao = new Licitacao();
        arquivo.licitacao.sequencial_pncp = this.entidade.sequencial_pncp;
        this.pncpService.enviarArquivoLicitacao(arquivo, this.entidade.exercicio).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, async (retorno) => {
            const a = await this.storageService.obterId(arquivo.id).toPromise();
            arquivo.sequencial = a.sequencial;
            toastr.success('Documento enviado ao PNCP com sucesso!');
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  private calculaInstrumento(lic: Licitacao): number {
    if (lic.modo_disputa == 4) {
      return 2;
    } else if ((lic.modalidade.codigo_pncp == 8 || lic.modalidade.codigo_pncp == 9) && lic.modo_disputa != 4) {
      //Prioridade 2
      //Quando é Dispensa de licitação ou Inexigibilidade, entra como Ato que autoriza contratação direta, código PNCP 3
      return 3;
    } else if (lic.chamamento_publico) {
      //Prioridade 3
      //Quando é selecionado chamamento público vai entrar como chamamento público código pncp 4
      return 4;
    } else if (lic.modalidade.codigo_pncp == 13 || (lic.modalidade.codigo_pncp >= 1 && lic.modalidade.codigo_pncp <= 7)) {
      return 1;
    } else {
      return -1;
    }
  }

  public tipoDocumento(cod: number): string {
    switch (cod) {
      case -1: return 'Doc. Processo Licitatório';
      case 3: return 'Minuta do Contrato';
      case 4: return 'Termo de Referência';
      case 5: return 'Anteprojeto';
      case 6: return 'Projeto Básico';
      case 7: return 'Estudo Técnico Preliminar';
      case 8: return 'Projeto Executivo';
      case 9: return 'Mapa de Riscos';
      case 10: return 'DFD';
      default: return 'inválido'
    }
  }

  async salvarLogExclusao(tabela?: string) {
    const relations = 'modalidade.tipos_contratacao.tipo_contratacao,orgao,setor,setor.unidade,exercicio,comissao,'
      + 'tipo_contratacao,fiscais.pessoa,licitacao_audesp,orgao_audesp,prazo_pagamento,prazo_entrega,usuario_remocao'
    const lic = await this.licitacaoService.obter({
      relations: relations, id: this.entidade.id, 'exercicio.id': this.login.exercicio.id,
      'orgao.id': this.login.orgao.id
    }).toPromise();

    lic.excluido_pncp = true;
    lic.cadastrado_pncp = false;
    lic['excluindo_pncp'] = true;

    const reg: PncpRegistro = new PncpRegistro();
    reg.excluido = true;
    reg.exercicio = this.login.exercicio;
    reg.id_alvo = this.entidade.id;
    reg.id_usuario_pncp = this.login.dados_pncp.id;
    reg.orgao = this.login.orgao;
    reg.ratificado = true;
    reg.sequencial = this.entidade.sequencial_pncp;
    reg.tabela_alvo = tabela ? tabela : 'licitacao';

    await this.pncpRegistroService.inserir(reg).toPromise();

    // if (!tabela || tabela == 'licitacao') {
    //   await this.licitacaoService.atualizar(lic).toPromise();
    //   this.limparModificacao();
    //   this.tipoFiltro = -1;
    //   this.filtrarLicitacoes();
    // }

    toastr.success('Excluido com sucesso do PNCP');
  }
}