import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContratoAditamento, ContratoItem, FuncaoService, GlobalService, Login, TipoAditivo } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { TipoAditivoService } from '../../tipo-aditivo/service/tipo-aditivo.service';
import { ContratoAditamentoService } from '../service/contrato-aditamento.service';
import { ContratoItemService } from '../service/contrato-item.service';

declare var $: any;

@Component({
  selector: 'app-contrato-aditamento-dlg',
  templateUrl: './contrato-aditamento-dlg.component.html'
})
export class ContratoAditamentoDlgComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  protected datepipe: DatePipe;
  public ptBR: any;
  protected unsubscribe: Subject<void> = new Subject();
  public manualEditValue = true;
  public confirmSave = false;


  @Input() visualizar_item: boolean;
  @Input() entidade: ContratoAditamento;
  @Input() lista: Array<any>;
  @Input() login: Login;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public opcoesSimNao: any[] = [{ id: true, nome: 'Sim' }, { id: false, nome: 'Não' }];
  public listaTipo: TipoAditivo[];

  public imaskQtd = {
    mask: Number,
    scale: 4,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  imaskConfigValor = {
    mask: Number,
    scale: 4,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  }

  imaskConfig = {
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    public funcaoService: FuncaoService,
    public aditamentoService: ContratoAditamentoService,
    public tipoService: TipoAditivoService,
    private globalService: GlobalService,
    private contratoItemService: ContratoItemService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['entidade'] && this.entidade && !this.entidade.id && this.entidade.contrato?.id) {
      this.aditamentoService.proximoNumero(this.entidade.contrato.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidade.numero = String(res)
        })
    }
    this.calcularValorParcela();
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();

    this.tipoService.todos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaTipo = res.content
      }, error => this.funcaoService.acaoErro(error));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => this.globalService.calendarMascara(), 500);
  }

  public verificaTipo(entidade: ContratoAditamento){
    if(entidade.tipo.nome === 'Alteração de fornecedor por unificação ou venda da empresa.' && !entidade?.id){
      entidade.tipo = null;
      toastr.warning('Opção inválida para o usuário. Deve-se alterar o beneficiário pela aba alteração.')
    }
  }

  public async salvar(): Promise<void> {
    let tipo;
    const tipos = [];
    try {
      if (!this.entidade.tipo) {
        throw new Error('Informe o tipo do aditamento!');
      } else {
        tipos.push(this.entidade.tipo);
        for (const t of tipos) {
          tipo = t.codigo;
        }
      }

      if (!this.entidade.numero) {
        throw new Error('Informe o número do aditamento!');
      }
      if (!this.entidade.tipo) {
        throw new Error('Informe o tipo do aditamento!');
      }
      if (!this.entidade.data_aditamento) {
        throw new Error('Informe a data de aditamento!');
      }
      if (!this.entidade.data_inicio) {
        throw new Error('Informe a data de início!');
      }
      if (!this.entidade.data_termino) {
        throw new Error('Informe a data de término!');
      }
      if (!this.entidade.finalidade) {
        throw new Error('Informe a finalidade do aditamento!');
      }
      if (!this.funcaoService.podeAlterarAudesp(this.entidade.data_contabilizacao, this.login)) {
        throw new Error('Data de contabilização inválida, período na contabilidade já está fechado, entre em contato com o contador.');
      }
      const dtContabilizacao: Date = this.entidade.data_contabilizacao;
      if (dtContabilizacao.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('O ano da data de contabilização está diferente do exercício logado');
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      return;
    }
    // envia a entidade para ser salva no banco -----
    this.entidade.usuario = this.login.usuario;
    this.entidade.editavel = false;
    if (!this.manualEditValue) {
      this.entidade.valor_total = this.obterValorTotal();
      if (this.entidade.numero_parcelas) {
        this.entidade['valor_parcela'] = +(this.entidade.valor_total / this.entidade.numero_parcelas).toFixed(2);
      }
    }

    if ((tipo === 3 || tipo === 5 || tipo === 9 || tipo === 11) && !this.entidade.valor_total) {
      toastr.warning(`Informe o valor total do aditamento!`)
      throw new Error('Informe o valor total do aditamento!');
    }

    if (!this.lista) {
      this.lista = new Array();
    }
    const contratoItemPromises: Promise<ContratoItem>[] = [];
    this.entidade.itens.forEach(item => {
      item.contrato_item.contrato = { ...this.entidade.contrato };
      delete item.contrato_item.contrato.itens;
      item.contrato_item.vl_unit_atualizado = item.aditivo_valor;
      contratoItemPromises.push(this.contratoItemService.atualizar(item.contrato_item).toPromise());
    });

    if (!this.entidade.id) {
      try {
        const dados = await this.aditamentoService.atualizar(this.entidade).toPromise();
        toastr.success('Aditamento inserido com sucesso!');
        $('#dialogAditamento').modal('hide');
        this.confirmSave = false;
        this.lista.unshift(dados);
        await Promise.all(contratoItemPromises);
      } catch (error) {
        this.funcaoService.acaoErro(error)
      }
    } else {
      try {
        this.entidade.cadastrado_pncp = false;
        await this.aditamentoService.atualizar(this.entidade).toPromise();
        toastr.success('Aditamento atualizado com sucesso!');
        $('#dialogAditamento').modal('hide');
        this.confirmSave = false;
        await Promise.all(contratoItemPromises);
      } catch (error) {
        this.funcaoService.acaoErro(error)
      }
    }
    this.visualizarChange.emit(true);
  }

  public obterValorTotal(): number {
    if (!this.entidade?.itens) return 0
    return this.entidade.itens.reduce((acc, cur) => acc + cur.aditivo_qtd * cur.aditivo_valor, 0)
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public onFocusDate() {
    this.globalService.calendarMascara();
  }

  public visualizarItem(visualizar_item) {
    if (visualizar_item === true) {
      return true
    } else {
      return false
    }
  }

  public zerarQuantidades(): void {
    this.entidade.itens?.forEach(item => item.aditivo_qtd = 0);
    this.obterValorTotal();
  }

  calcularValorParcela() {
    if (this.entidade?.numero_parcelas) {
      this.entidade['valor_parcela'] = +(this.entidade.valor_total / this.entidade.numero_parcelas).toFixed(2);
    }
  }

  public toggleManualEdit(): void {
    this.manualEditValue = !this.manualEditValue;
  }

  public antesSalvar() {
    let aviso = 'Verifique a data de contabilização, você não poderá editar o aditamento após essa data. Deseja salvar ?</pre>';
    if (this.entidade && this.entidade.id) {
      aviso = '<pre>Aditamento já cadastrado no PNCP, ao continuar, saiba que ele será marcado para retificação.\n\n' + aviso;
    } else {
      aviso = '<pre>' + aviso;
    }
    this.confirmationService.confirm({
      message: aviso,
      key: 'aditamento',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.salvar();
      },
    });
  }

}
