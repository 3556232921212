import { Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { ArquivoXml } from '../../entidade/comum/arquivo-xml.model';

@Injectable({
  providedIn: 'root'
})
export class AudespService extends BaseResourceService<ArquivoXml> {

  constructor(
    protected injector: Injector
  ) {
    super(`arquivos-xml`, injector);
  }

  public upload(entidade: ArquivoXml, mes: number): Observable<any> {
    const formData = new FormData();
    entidade.orgao = this.login.orgao;
    entidade.descricao = '-';
    formData.append('orgao', this.login.orgao.id + '');
    formData.append('arquivo', entidade.documento);
    return this.http.post(`${this.login.cidade.id}/${this.api}/upload/${mes}`, formData, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    });
  }

  public divergenciaContaCorrenteConciliacao(arquivos: any, orgaoId: number, apenasInconsistencias: boolean): Observable<any> {
    const formData = new FormData();

    for (let arquivo of arquivos) {
      formData.append('files', arquivo);
    }

    formData.append('orgaoId', `${orgaoId}`);
    formData.append('apenasInconsistencias', `${apenasInconsistencias}`)

    return this.http.post(`${this.login.cidade.id}/${this.api}/divergencia-conta-corrente-conciliacao`, formData, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    });
  }

  public importar(mes: number, ano: number, xml: string): Observable<number> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/importar/${mes}/${ano}/${xml}/${this.login.orgao.id}`, null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public gerarAquivo(url: string, quadri: number): Observable<any> {
    return this.http.post<any>(
      // tslint:disable-next-line: max-line-length
      `${this.login.cidade.id}/${this.api}/${url.toLowerCase()}/${quadri}/${this.login.exercicio.ano}/${this.login['ppa']['id']}/${this.login.orgao.id}`,
      null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public gerarArquivo(tipo: string, mes: number, contas?: string): Observable<number> {
    return this.http.post<any>(
      // tslint:disable-next-line: max-line-length
      `${this.login.cidade.id}/${this.api}/gerar-arquivo/${mes}/${this.login.exercicio.id}/${this.login.orgao.id}/${tipo}?contas=${contas}`,
      null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public gerarPDFPlan(tipo: string, mes: number): Observable<number> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/gerar-arquivo/${mes}/${this.login.exercicio.id}/${this.login.orgao.id}/${tipo}?imprimir=true`,
      null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }



  public imprimirArquivo(tipo: string, mes: number): Observable<number> {
    return this.http.post<any>(
      `${this.login.cidade.id}/${this.api}/imprimir-arquivo/${mes}/${this.login.exercicio.id}/${this.login.orgao.id}/${tipo}`,
      null,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public download(arquivo: string): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download/${arquivo}/${arquivo}`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public armazenarAudesp(id: number, orgao: number, armazenado: boolean, usuario: number, exercicio_ano: number, removerRegistros?: Boolean): Observable<any> {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/armazenar/${id}/${orgao}/${armazenado}/${usuario}/${exercicio_ano}/${removerRegistros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public gerarRelatorioXML(tipoXML: 'CONTA-CONTABIL' | 'CONTA-CORRENTE', idArquivo: number, tipoRazao: number) {
    return this.http.get<any>(
      `${this.login.cidade.id}/${this.api}/gerar-relatorio-xml/${tipoXML}/${idArquivo}/${tipoRazao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
