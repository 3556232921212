import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, Credito, CreditoItem, Decreto, FormatoExportacao, Login, OrgaoAssinatura, OrgaoAssinaturaService, PpaLei } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditoService } from '../../credito/service/credito.service';
import { DecretoService } from 'projects/contabil/src/app/planejamento/decreto/service/decreto.service';
import * as extenso from "extenso";
import * as toastr from 'toastr';
import { PpaLeiService } from 'administrativo-lib';


@Component({
  selector: 'app-transferencia-interna-rpt',
  templateUrl: './transferencia-interna-rpt.component.html'
})
export class TransferenciaInternaRpt extends BaseResourceRptPersonalizadoComponent {

  protected datepipe: DatePipe;
  public ptBR: any;

  public ppaLei: PpaLei;
  public totalCredito: number = 0;
  public creditoItens: CreditoItem[] = [];
  public creditoItensAnulados: CreditoItem[] = [];

  public decreto: Decreto;
  public decretoSelected: string;
  public decretoList: { key: string, value: string }[] = [];
  public listFormato: FormatoExportacao[] = ['pdf', 'docx'];
  public formato: FormatoExportacao;

  private creditos: Credito[] = [];
  private assinaturaVigente: OrgaoAssinatura;

  private count: number = 0;
  private dataDecreto: Date;

  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: Login;

  constructor(
    private creditoService: CreditoService,
    private decretoService: DecretoService,
    protected assinaturaService: AssinaturaService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService,
    protected ppaLeiService: PpaLeiService,
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
    this.removerCabecalho = true;
    this.removerRodape = true;
  }

  protected afterInit(): void {
    this.ppaLeiService.filtrar(1, -1, {
      'tipo': 'loa',
      'ano': this.login?.exercicio?.ano,
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.ppaLei = res.content[0];
      });

    this.creditoService.filtrar(1, -1, {
      'relations': 'itens.ficha.executora,itens.ficha.recurso,itens.ficha.aplicacao,itens.ficha.aplicacao_variavel,itens.ficha.programa,itens.ficha.acao,itens.ficha.despesa,itens.ficha.funcao,itens.ficha.subfuncao',
      'exercicio.id': this.login?.exercicio?.id,
      'orgao.id': this.login?.orgao?.id,
      'ativo': true
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.creditos = res.content;

        res.content.forEach((val: Credito) => {
          if (!this.decretoList.find(x => x.value === val.decreto_interno?.replace('/', '')) && val.decreto_interno) {
            this.decretoList.push({ key: val.decreto_interno, value: val.decreto_interno?.replace('/', '') });
          }
        });
        this.decretoList.sort((d1, d2) => +d1.value - +d2.value);
      });

    this.orientacao = 'portrait';
    this.formato = 'pdf';
  }

  public async imprimir(): Promise<void> {
    if (!this.decretoSelected) {
      toastr.warning(`Selecione o decreto!`);
      return;
    }

    this.decreto = await this.decretoService.obter({ 'codigo': this.decretoSelected }).toPromise();

    if (!this.decreto?.id) {
      toastr.warning(`Decreto não encontrado!`);
      return;
    }

    this.dataDecreto = new Date(this.decreto.data_decreto);

    this.assinaturaVigente = await (await this.orgaoAssinaturaService.filtrar(1, -1, {
      'orgao.id': this.login?.orgao?.id,
      'data_limite$ge': this.funcaoService.converteDataSQL(this.dataDecreto),
      'orderBy': 'data_limite'
    }).toPromise())?.content[0];

    if (!this.assinaturaVigente) {
      toastr.warning(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      return;
    }

    super.imprimir();
  }

  protected modalRelatorio(): string {
    return null;
  }

  protected tituloRelatorio(): string {
    return '';
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo: {}[] = [];
    this.count = 0;

    let dados = this.creditos.filter((credito: Credito) => credito.decreto_interno === this.decretoSelected);

    if (!dados) {
      toastr.warning('Não foi localizado minutas com os filtros informados!');
      return;
    }

    this.totalCredito = 0;
    this.creditoItens = [];
    this.creditoItensAnulados = [];

    for (const credito of dados) {
      for (const item of credito.itens) {
        if (item.especie === 'N') {
          this.totalCredito += +item.valor_credito;
          this.creditoItens.push(item)
        } else if (item.especie === 'A') {
          this.creditoItensAnulados.push(item)
        }
      }
    }

    conteudo.push(this.dadosCabecalho());

    conteudo.push(this.dadosCreditos(this.creditoItens, false));

    this.count = 0;
    conteudo.push(this.dadosCreditos(this.creditoItensAnulados, true));

    conteudo.push(this.dadosAssinatura());

    return conteudo;
  }

  protected dadosCabecalho(): {}[] {
    const decreto = this.decreto.codigo ? (this.decreto.codigo.replace('/', '').length === 8 ? '0' + this.decreto.codigo.replace('/', '') : this.decreto.codigo.replace('/', '')).slice(0, 5) : '';
    return [
      {
        layout: 'linhas',
        unbreakable: true,
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [130, '*'],
          body: [
            [
              { text: '', border: [false] },
              {
                text: `DECRETO Nº ${this.funcaoService.mascarar('00.000', decreto)}, DE ${this.funcaoService.formatarDataExtenso(this.dataDecreto).toUpperCase()}`,
                bold: true, decoration: 'underline', alignment: 'left', fontSize: 12, border: [false], margin: [-100, 50, 0, 0]
              }
            ],
            [
              { text: '', border: [false] },
              {
                text: `Dispõe sobre alterações no Orçamento de ${this.dataDecreto.getFullYear()}, através de transferências de recursos entre dotações de classificações aprovadas na Lei n°. ${this.ppaLei.lei?.split('/')[0]}, de ${this.funcaoService.converteDataBR(this.ppaLei.data_vigencia)} - Lei Orçamentária Anual, e dá outras providências.`,
                alignment: 'justify', fontSize: 12, border: [false], margin: [-100, 0, 0, 14]
              }
            ]
          ]
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              {
                text: `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t${this.assinaturaVigente?.prefeito ? this.assinaturaVigente.prefeito.toUpperCase() : ''}, Prefeito Municipal de ${this.login?.cidade?.nome ? this.login?.cidade?.nome : ''}, Estado de ${this.login?.cidade?.estado?.nome ? this.login?.cidade?.estado?.nome : ''}, no exercício de suas atribuições legais,`,
                alignment: 'justify', fontSize: 12, border: [false], margin: [-35, 0, 0, 25]
              }
            ]
          ]
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [130, '*'],
          body: [
            [
              { text: '', border: [false] },
              {
                text: `D E C R E T A`, alignment: 'left', fontSize: 12, border: [false], margin: [-30, 5, 0, 0]
              }
            ]
          ]
        }
      }
    ];
  }

  protected dadosCreditos(credito: CreditoItem[], anulacao: boolean): {}[] {
    let numArtigo: string = '';
    let artigo: string = '';
    this.count++;

    if (credito.length === 0) return;

    let creditosOrdenados = credito.sort((a, b) => +a.ficha.executora.codigo - +b.ficha.executora.codigo);

    var grupoExecutora = this.funcaoService.agrupar(creditosOrdenados, ['ficha.executora.codigo', 'ficha.executora.nome'], ['valor_credito']);
    let creditos: {}[] = [];

    for (const executora of grupoExecutora) {
      creditos.push([
        { text: '', border: [false], margin: [0, 0, 0, 0] },
        {
          text: `${executora.grupo['ficha.executora.codigo'] ? this.funcaoService.mascarar('##.##.##', executora.grupo['ficha.executora.codigo']) + ' ' : ''}${executora.grupo['ficha.executora.nome'] ? executora.grupo['ficha.executora.nome'] : ''}`,
          fontSize: 8, bold: true, border: [false], margin: [-95, 0, 0, 0]
        },
        { text: '', border: [false], margin: [0, 0, 0, 0] }
      ]);

      let programasOrdenados = executora.registros.sort((a, b) => +a.ficha.programa.id - +b.ficha.programa.id);

      const grupoPrograma = this.funcaoService.agrupar(programasOrdenados, ['ficha.funcao.codigo', 'ficha.subfuncao.codigo', 'ficha.programa.codigo', 'ficha.programa.nome']);
      for (const programa of grupoPrograma) {
        creditos.push([
          { text: '', border: [false] },
          {
            text:
              `${programa.grupo['ficha.funcao.codigo'] ? programa.grupo['ficha.funcao.codigo'] + '.' : ''}${programa.grupo['ficha.subfuncao.codigo'] ? programa.grupo['ficha.subfuncao.codigo'] + '.' : ''}${programa.grupo['ficha.programa.codigo'] ? programa.grupo['ficha.programa.codigo'] + ' ' : ''}${programa.grupo['ficha.programa.nome'] ? programa.grupo['ficha.programa.nome'] : ''}`,
            fontSize: 8, border: [false], margin: [-95, 1, 0, 0]
          },
          { text: '', border: [false] }
        ]);

        const grupoAcao = this.funcaoService.agrupar(programa.registros, ['ficha.acao.codigo', 'ficha.acao.nome']);
        for (const acao of grupoAcao) {
          creditos.push([
            { text: '', border: [false] },
            {
              text: `${acao.grupo['ficha.acao.codigo'] ? acao.grupo['ficha.acao.codigo'] + ' ' : ''}${acao.grupo['ficha.acao.nome'] ? acao.grupo['ficha.acao.nome'] : ''}`,
              fontSize: 8, border: [false], margin: [-95, 0, 0, 0]
            },
            { text: '', border: [false] }
          ]);

          acao.registros.forEach(registro => {
            creditos.push(
              registro['ficha']['despesa'] ? [
                { text: '', border: [false] },
                {
                  text: `${registro['ficha']['despesa']['codigo'] + ' ' + registro['ficha']['despesa']['nome']}`,
                  fontSize: 8, border: [false], margin: [-95, 0, 0, 0]
                }, {
                  text: '', border: [false]
                }
              ] : [],
              registro['ficha']['aplicacao_variavel'] ? [
                { text: '', border: [false] },
                {
                  text: `Fonte / Aplicação: ${registro['ficha']['aplicacao_variavel']['codigo'] + ' ' + registro['ficha']['aplicacao_variavel']['nome']}`, bold: true,
                  fontSize: 8, border: [false], margin: [-95, 0, 0, 0]
                },
                { text: '', border: [false] }
              ] : [],
              [
                { text: '', border: [false] },
                {
                  text: `Ficha      ${registro['ficha']['numero']}`, bold: true,
                  fontSize: 8, border: [false], margin: [-95, 0, 0, 0]
                }, {
                  text: `${registro['valor_credito'] > 0 ? this.funcaoService.convertToBrNumber(registro['valor_credito']) : '0,00' ? this.funcaoService.convertToBrNumber(-registro['valor_credito']) : '0,00'}`,
                  fontSize: 8, alignment: 'right', border: [false]
                }
              ]);
          });
        }
      }

      creditos.push([
        { text: '', border: [false], margin: [0, 0, 0, 0] },
        { text: 'TOTAL.....', fontSize: 8, bold: true, border: [false], margin: [-95, 0, 0, 30] },
        {
          text: `${executora.totalizadores['valor_credito'] > 0 ? this.funcaoService.convertToBrNumber(executora.totalizadores['valor_credito']) : '0,00' ? this.funcaoService.convertToBrNumber(-executora.totalizadores['valor_credito']) : '0,00'}`,
          fontSize: 9, bold: true, alignment: 'right', border: [false], margin: [0, 0, 0, 0]
        }
      ]);
    }

    if (this.count === 1) {
      if (anulacao) {
        numArtigo = `Art. 2º `;
        artigo = `A origem dos recursos para cobertura das transferências referidas no artigo anterior provém das seguintes classificações:`;
      } else {
        numArtigo = `Art. 1º `;
        const valorPorExtenso = extenso(this.funcaoService.convertToBrNumber(this.totalCredito), { mode: 'currency', currency: { type: 'BRL' } });
        const valorPorExtensoComVirgula = valorPorExtenso.replace(/\bmil\b/g, 'mil,');

        artigo = `Ficam efetuadas, através da Secretaria Municipal de Finanças, as transferências de recursos orçamentários, respeitada a Lei n°. ${this.ppaLei.lei?.split('/')[0]}, de ${this.funcaoService.converteDataBR(this.ppaLei.data_vigencia)} - Lei Orçamentária Anual, no valor total de R$ ${this.funcaoService.convertToBrNumber(this.totalCredito)} (${valorPorExtensoComVirgula.replace('de reais', 'reais')}), destinadas às seguintes classificações:`;
      }
    }

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [85, '*'],
          body: [
            [
              {
                text: '', alignment: 'justfy',
                fontSize: 10, border: [false], margin: [0, 0, 0, 0]
              },
              {
                text: anulacao && this.count === 1 ? 'Parágrafo único. As transferências referidas no caput não alteram as classificações funcional-programáticas, categorias e fontes de recursos aprovadas Lei Orçamentária Anual.' : '',
                alignment: 'justify', fontSize: 10, border: [false], margin: [-130, 0, 0, 10]
              }
            ],
            [
              { text: `${numArtigo}`, alignment: 'justify', fontSize: 10, border: [false], margin: [-35, 0, 0, 0] },
              { text: `${artigo}`, alignment: 'justify', fontSize: 10, border: [false], margin: [-95, 0, 0, 10] }
            ]
          ]
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: [85, '*', 100],
          body: creditos
        }
      }
    ];
  }

  protected dadosAssinatura(): {} {
    return {
      layout: 'linhas',
      unbreakable: true,
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*'],
        body: [
          [
            {
              text: `Art. 3º  Este Decreto entra em vigor na data de sua publicação, surtindo seus efeitos a partir de ${this.funcaoService.formatarDataExtenso(this.dataDecreto)}`,
              alignment: 'justify', fontSize: 10, border: [false], margin: [-30, 20, 0, 8]
            }
          ], [
            {
              text: `Art. 4º  Revogam-se as disposições em contrário.`,
              alignment: 'justify', fontSize: 10, border: [false], margin: [-30, 0, 0, 8]
            }
          ], [
            {
              text: `${this.funcaoService.formatarDataExtenso(this.dataDecreto, this.funcaoService.capitalizeFirstLetter(this.login?.cidade?.nome))}`,
              alignment: 'center', fontSize: 9, border: [false], margin: [0, 15, 0, 10]
            }
          ], [
            {
              text: '_______________________________________________',
              alignment: 'center', fontSize: 10, bold: true, border: [false], margin: [0, 15, 0, 0]
            }
          ], [
            {
              text: `${this.assinaturaVigente.prefeito ? this.assinaturaVigente.prefeito.toUpperCase() : ''}`,
              alignment: 'center', fontSize: 9, border: [false]
            }
          ], [
            {
              text: this.assinaturaVigente.cargo_prefeito,
              alignment: 'center', fontSize: 9, border: [false]
            }
          ]
        ]
      }
    };
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function () {
          return 1;
        },
        vLineWidth: function () {
          return 0;
        },
        hLineColor: function () {
          return 'black';
        },
        paddingLeft: function () {
          return 3;
        },
        paddingRight: function () {
          return 3;
        },
        paddingTop: function () {
          return 8;
        },
        paddingBottom: function () {
          return 8;
        }
      }
    };
  }

  protected margemRelatorio(): number[] {
    return [120, 40, 60, 40];
  }

}
