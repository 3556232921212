import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FuncaoService, InventarioSetor, InventarioIdentificacao, Login, Setor } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioIdentificacaoService } from '../service/inventario-identificacao.service';

declare var $: any;

@Component({
  selector: 'app-inventario-identificacao',
  templateUrl: './inventario-identificacao.component.html'
})
export class InventarioIdentificacaoComponent implements OnInit, OnChanges {

  /**
   * Declaração de variáveis
   */
  @Input() public inventarioSetor: InventarioSetor;
  @Input() public login: Login;
  @Input() visualizar: boolean;

  public lista: InventarioIdentificacao[];
  public identificacao: InventarioIdentificacao;

  public setor: Setor;

  protected unsubscribe: Subject<void> = new Subject();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    public funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    private identificacaoService: InventarioIdentificacaoService,
  ) {
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.inventarioSetor) {
      this.carregarListagem();
    }
  }

  public carregarListagem() {
    this.setor = this.login.setor.find(setor => {
      return this.inventarioSetor?.setor.id === setor.id
    });

    let params = { orderBy: 'data_cadastro$DESC' };
    params['relations'] = 'inventario_setor,tombamento.produto,tombamento.orgao';
    params['inventario_setor.id'] = this.inventarioSetor.id;

    this.identificacaoService.filtrar(0, 0, params).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => this.lista = res.content, error => toastr.error(error));
  }

  public abrirInventarioIdentificacao(item?: InventarioIdentificacao) {
    this.identificacao = item;
    if (this.inventarioSetor.status === 'PENDENTE') {
      this.confirmationService.confirm({
        message: 'Verifique se há pendências para iniciar seu termo de inventário. Deseja prosseguir?',
        header: 'Atenção!',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          $('#dialogInventarioIdentificacao').modal('show');
        },
      });
    } else {
      $('#dialogInventarioIdentificacao').modal('show');
    }
  }

  public confirmar() {
    if (this.inventarioSetor.status === 'PENDENTE') this.inventarioSetor.status = 'INICIADO';

    this.carregarListagem();
  }

  public async remover(item: InventarioIdentificacao) {
    this.confirmationService.confirm({
      message: 'Deseja realmente remover o item?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.identificacaoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Registro removido com sucesso!', 'Exclusão');
            this.lista.splice(this.lista.indexOf(item), 1);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

}
