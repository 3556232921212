import { DatePipe } from '@angular/common';
import { FuncaoService, Login, Relatorio, GlobalService, OrgaoAssinaturaService, TransferenciaBancaria, ContaBancariaEntidade } from 'eddydata-lib';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';
import { ChequeEmpenho } from 'contabil-lib';
import { TransferenciaBancariaService } from 'administrativo-lib';

export class TransferenciaBancariaModalRpt {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;
  private assinatura1: string = ''
  private cargo_assinatura1: string = ''
  private assinatura2: string = ''
  private cargo_assinatura2: string = ''
  public listaChequesRpt: ChequeEmpenho;
  public contaEntidade: ContaBancariaEntidade;
  totalOPo: number = 0;
  totalValor: any;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(protected assinaturaService: OrgaoAssinaturaService, protected transferenciaService: TransferenciaBancariaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: TransferenciaBancaria[], login: Login) {

    this.log = login;
    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    }).toPromise();

    if (assinatura) {

      this.assinatura1 = assinatura.ordenador_pagto;
      this.cargo_assinatura1 = assinatura.cargo_ordenador_pagto;

      this.assinatura2 = assinatura.tesoureiro;
      this.cargo_assinatura2 = assinatura.cargo_tesoureiro;
    }

    Relatorio.imprimirPersonalizado('AUTORIZAÇÃO DE TRANSFERÊNCIA', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'portrait', 'AUTORIZAÇÃO DE TRANSFERÊNCIA',
      {
        linhas: {
          hLineWidth(i, node) {
            return 1;
          },
          vLineWidth(i) {
            return 1;
          },
          hLineColor(i) {
            return 'black';
          },
          paddingLeft(i) {
            return 3;
          },
          paddingRight(i, node) {
            return 3;
          }
        }
      }, true);
  }

  public montarConteudo(lista: TransferenciaBancaria[]) {

    const conteudo = [];
    let listaLimpa;
    let data = [];

    for (const item of lista) {
      let rept: number;

      if (data != undefined)
        rept = data.find((i) => i.id == item.id);

      if (!rept)
        data.push(item);


      listaLimpa = data;

    }

    if (!listaLimpa) {
      toastr.warning('Nenhuma transferencia encontrada!');
      throw new Error('Nenhuma trasferencia encontrada!');
    }

    for (const entidade of listaLimpa) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }

      conteudo.push(this.dadosCabecalho(this.log, entidade)
        .concat(this.dadosTransferencia(entidade))
        .concat(this.dadosAssinatura(entidade)));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, dados: TransferenciaBancaria): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        border: [true, true, true, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '' };
    }
    const conteudo = [
      [brasaoImage],
      [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false] }],
      [{
        text: `AUTORIZAÇÃO DE TRANSFERÊNCIA FINANCEIRA N˚ ${this.funcaoService.strZero(dados.id, 5)}`,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosTransferencia(dados: TransferenciaBancaria): {}[] {


    let valorAplicacaoOrigem;
    let valorAplicacaoCreditada;
    let i_1 = 0;
    let i_2 = 0;

    for (let origem of dados.conta_pagadora.recursos) {
      for (i_1; i_1 < 1; i_1++) {
        valorAplicacaoOrigem = origem.aplicacao.codigo
      }
    }
    for (let creditada of dados.conta_recebedora.recursos) {
      for (i_2; i_2 < 1; i_2++) {
        valorAplicacaoCreditada = creditada.aplicacao.codigo
      }
    }
    const conteudo = [
      [
        { text: `DATA: ${this.datepipe.transform(dados?.data_transfere, 'dd/MM/yyyy')} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: 'Sr. Gerente,', border: [true, false, true, false], fontSize: 10 }
      ],
      [
        {
          text: [
            { text: 'Autorizamos a transferência bancária na importância de ' },
            { text: `R$ ${this.funcaoService.convertToBrNumber(dados.valor_transferido)}`, bold: true },
            { text: ' das contas abaixo relacionadas.' }
          ], border: [true, false, true, false], fontSize: 10
        }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 5] }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 4] }
      ],
      [
        { text: '', border: [true, true, true, false], margin: [0, 5, 0, 4] }
      ],
      [
        { text: 'CONTA DE ORIGEM A SER DEBITADA:', border: [true, false, true, false], fontSize: 12, margin: [0, 5, 0, 10], alignment: 'center' }
      ],
      [
        { text: `Fonte de recurso:  ${dados.conta_pagadora.codigo ? dados.conta_pagadora.codigo : '-'} / Codigo da aplicação: ${valorAplicacaoOrigem ? valorAplicacaoOrigem : '-'}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `BANCO: ${dados.conta_pagadora?.banco?.nome} ${(dados.conta_pagadora?.numero_conta ? (dados.conta_pagadora?.numero_conta + '  ' + (dados.conta_pagadora?.digito_conta ? dados.conta_pagadora?.digito_conta + ' - ' : '-')) : '') + (dados.conta_pagadora?.codigo ? dados.conta_pagadora?.codigo : ' - ')}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `AGÊNCIA: ${dados.conta_pagadora?.agencia_nome}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `Nº AGÊNCIA: ${(dados.conta_pagadora?.agencia + ' ' + (dados.conta_pagadora?.digito_agencia ? dados.conta_pagadora?.digito_agencia : ''))}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `C/C: ${(dados.conta_pagadora?.numero_conta + ' ' + (dados.conta_pagadora?.
          digito_conta ? dados.conta_pagadora?.digito_conta : ''))} ${''}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `PAGADOR: ${dados.conta_pagadora?.nome} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `CNPJ: ${dados.conta_pagadora?.conta_entidade?.cnpj ? dados.conta_pagadora?.conta_entidade?.cnpj : '-'} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 4], fontSize: 10 }
      ],
      [
        { text: '', border: [true, true, true, false], margin: [0, 5, 0, 4], fontSize: 10 }
      ],
      [
        { text: 'CONTA DE DESTINO A SER CREDITADA:', border: [true, false, true, false], fontSize: 12, margin: [0, 5, 0, 10], alignment: 'center' }
      ],
      [
        { text: `Fonte de recurso:  ${dados.conta_recebedora.codigo ? dados.conta_recebedora.codigo : '-'} / Codigo da aplicação: ${valorAplicacaoCreditada ? valorAplicacaoCreditada : '-'}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `BANCO: ${dados.conta_recebedora?.banco?.nome} ${(dados.conta_recebedora?.numero_conta ? (dados.conta_recebedora?.numero_conta + '  ' + (dados.conta_recebedora?.digito_conta ? dados.conta_recebedora?.digito_conta + ' - ' : '-')) : '') + (dados.conta_recebedora?.codigo ? dados.conta_recebedora?.codigo : ' - ')}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `AGÊNCIA: ${dados.conta_recebedora?.agencia_nome}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `Nº AGÊNCIA: ${((dados.conta_recebedora?.agencia ? dados.conta_recebedora?.agencia : '') + ' ' + (dados.conta_recebedora?.digito_agencia ? dados.conta_recebedora?.digito_agencia : ''))} ${''}`, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `C/C: ${((dados.conta_recebedora?.numero_conta? dados.conta_recebedora?.numero_conta : '') + ' ' + (dados.conta_recebedora?.digito_conta ? dados.conta_recebedora?.digito_conta : ''))} ${''}`, border: [true, false, true, false], bold: true, fontSize: 10 }
      ],
      [
        { text: `RECEBEDOR: ${dados.conta_recebedora?.nome} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: `CNPJ: ${dados.conta_recebedora?.conta_entidade?.cnpj ? dados.conta_recebedora?.conta_entidade?.cnpj : '-'} `, border: [true, false, true, false], fontSize: 10 }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 10] }
      ],
      [
        { text: '', border: [true, true, true, false], margin: [0, 2, 0, 2] }
      ],
      [
        { text: `${(dados.historico ? 'Historico ' : '')}`, fontSize: 9, alignment: 'center', border: [true, false, true, false], margin: [0, 0, 0, 0], bold: true }
      ],
      [
        { text: `${(dados.historico ? dados.historico : '')}`, fontSize: 9, alignment: 'center', border: [true, false, true, false], margin: [0, 0, 0, 0], bold: true }
      ],
      [
        { text: '', border: [true, false, true, false], margin: [0, 0, 0, 0] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(transf: TransferenciaBancaria): {}[] {

    if (transf?.conta_pagadora?.assinatura && transf?.conta_pagadora?.cargo) {
      this.assinatura1 = transf.conta_pagadora.assinatura
      this.cargo_assinatura1 = transf.conta_pagadora.cargo
    }

    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO', border: [true, false, false, false], margin: [20, 0, 0, 0], bold: true },
        { text: '', border: [false, false, true, false], margin: [0, 10, 0, 0] },
      ],
      [
        { text: '______________________________________', border: [true, false, false, false], margin: [70, 60, 0, 0] },
        { text: '______________________________________', border: [false, false, true, false], margin: [70, 60, 0, 0] },
      ],
      [
        { text: this.assinatura1 ? this.assinatura1 : '', fontSize: 7, border: [true, false, false, false], margin: [70, 0, 0, 0] },
        { text: this.assinatura2 ? this.assinatura2 : '', fontSize: 7, border: [false, false, true, false], margin: [70, 0, 0, 0] },
      ],
      [
        { text: this.cargo_assinatura1 ? this.cargo_assinatura1 : '', fontSize: 7, border: [true, false, false, true], margin: [70, 0, 0, 80] },
        { text: this.cargo_assinatura2 ? this.cargo_assinatura2 : '', fontSize: 7, border: [false, false, true, true], margin: [70, 0, 0, 80] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*'],
        body: conteudo
      }
    }];

  }

}
