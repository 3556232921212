import { Component, Input } from '@angular/core';
import { ContratoService } from 'administrativo-lib';
import { Contrato, ContratoPncp, ContratoPncpService, FuncaoService, Login, PNCPService } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'lib-contrato-pncp',
  templateUrl: './contrato-pncp.component.html'
})
export class ContratoPncpComponent {

  @Input() contrato: Contrato;
  @Input() public login: Login;

  public imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected contratoPncpService: ContratoPncpService,
    private contratoService: ContratoService,
    private funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    if (this.contrato && !this.contrato?.contrato_pncp) {
      this.contrato.contrato_pncp = new ContratoPncp();
      this.contrato.contrato_pncp.categoria_processo = this.contrato.licitacao.categoria_processo
    }
  }

  ngOnChanges(): void {
    if (this.contrato && !this.contrato?.contrato_pncp) {
      this.contrato.contrato_pncp = new ContratoPncp();
      this.contrato.contrato_pncp.categoria_processo = this.contrato?.licitacao?.categoria_processo
    }
  }

  efetivar() {
    //Evitando erro de circular structure.
    const pncp = Object.assign({}, this.contrato.contrato_pncp);
    const ctr = Object.assign({}, this.contrato);
    ctr.contrato_pncp = null;
    pncp.contrato = ctr;
    pncp['atualizarPNCP'] = true;

    if (pncp.id) {
      this.contratoPncpService.atualizar(pncp).subscribe(() => {
        toastr.success('Dados para o PNCP atualizados com sucesso!');
      }, e => {
        toastr.error('Falha ao atualizar dados do PNCP: ' + this.funcaoService.obterMensagemErro(e));
      });
    } else {
      this.contratoPncpService.inserir(pncp).subscribe(() => {
        toastr.success('Dados para o PNCP salvos com sucesso!');
      }, e => {
        toastr.error('Falha ao inserir dados do PNCP: ' + this.funcaoService.obterMensagemErro(e));
      });
    }
  }

  salvar() {
    if (this.contrato.contrato_pncp.id && this.contrato.sequencial_pncp) {
      this.confirmationService.confirm({
        message: `Atenção, esse contrato já foi enviado ao PNCP, ao modifica-lo você precisará retificar as informações no PNCP, deseja continuar?`,
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        rejectVisible: true,
        closeOnEscape: false,
        header: 'Atenção!',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.contrato.cadastrado_pncp = false;
          await this.contratoService.atualizar(this.contrato).toPromise();
          this.efetivar();
        }
      });
    } else {
      this.efetivar();
    }
  }

  tipoContrato(): string {
    switch (this.contrato.tipo_contratacao.tipo_pncp) {
      case 1: return 'Contrato (Termo Inicial)';
      case 2: return 'Comodato';
      case 3: return 'Arrendamento';
      case 4: return 'Concessão';
      case 5: return 'Termo de Adesão';
      case 6: return 'Convênio';
      case 7: return 'Empenho';
      case 8: return 'Outros';
      case 9: return 'Termo de Execução Descentralizada';
      case 10: return 'Acordo de Cooperação Técnica';
      case 11: return 'Termo de Compromisso';
      case 12: return 'Carta Contrato';
      default: return '';
    }
  }

  copiarValor() {
    this.calcularParcela();
    this.contrato.contrato_pncp.valor_acumulado = this.contrato.valor_contrato;
    this.contrato.contrato_pncp.valor_global = this.contrato.valor_contrato;
  }

  calcularParcela() {
    this.contrato.contrato_pncp.valor_parcela = +(this.contrato.valor_contrato / this.contrato.contrato_pncp.parcelas).toFixed(2);
  }

}
