import { Injectable, Injector } from '@angular/core';
import { Acesso } from '../../entidade/comum/acesso.model';
import { AlmoxarifadoPag, CompraPag, ComumPag, ContabilidadePag, ControleInternoPag, DiarioOficialPag, FaturaPag, FolhaPag, FrotaPag, LegislativoPag, LicitacaoPag, Pagina, PatrimonioPag, PlanejamentoPag, PortalEntidadePag, ProtocoloPag, RequisicaoPag, TerceiroSetorPag, TesourariaPag, TransparenciaPag } from '../../entidade/comum/pagina';
import { BaseResourceService } from '../../models/services/base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class AcessoService extends BaseResourceService<Acesso> {

  constructor(
    protected injector: Injector
  ) {

    super(`acessos`, injector);
  }

  public retornaTodasPaginas(): string[] {
    let paginas = new ComumPag().paginas();
    paginas = paginas.concat(new AlmoxarifadoPag().paginas());
    paginas = paginas.concat(new CompraPag().paginas());
    paginas = paginas.concat(new ContabilidadePag().paginas());
    paginas = paginas.concat(new ControleInternoPag().paginas());
    paginas = paginas.concat(new DiarioOficialPag().paginas());
    paginas = paginas.concat(new FolhaPag().paginas());
    paginas = paginas.concat(new FrotaPag().paginas());
    paginas = paginas.concat(new RequisicaoPag().paginas());
    paginas = paginas.concat(new LegislativoPag().paginas());
    paginas = paginas.concat(new LicitacaoPag().paginas());
    paginas = paginas.concat(new PatrimonioPag().paginas());
    paginas = paginas.concat(new PlanejamentoPag().paginas());
    paginas = paginas.concat(new PortalEntidadePag().paginas());
    paginas = paginas.concat(new ProtocoloPag().paginas());
    paginas = paginas.concat(new TerceiroSetorPag().paginas());
    paginas = paginas.concat(new TesourariaPag().paginas());
    paginas = paginas.concat(new TransparenciaPag().paginas());
    paginas = paginas.concat(new FaturaPag().paginas())

    return paginas;
  }

  public agruparAcessosPorSistema(acessosTotal: Acesso[], sistemas_administrador: string): any[] {
    let acessos = [];
    let acessosSistema: Acesso[];

    // Comum
    acessosSistema = [];
    for (const pagina of new ComumPag().paginas()) {
      for (const acesso of acessosTotal) {
        if (acesso.pagina === Pagina[pagina]) {
          acessosSistema.push(acesso);
          break;
        }
      }
    }
    acessos.push({ sistema: 'comum', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });

    // Almoxarifado
    if (sistemas_administrador.includes('almoxarifado')) {
      acessosSistema = [];
      for (const pagina of new AlmoxarifadoPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'almoxarifado', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Compra
    if (sistemas_administrador.includes('compras')) {
      acessosSistema = [];
      for (const pagina of new CompraPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'compras', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Contabilidade
    if (sistemas_administrador.includes('contabil')) {
      acessosSistema = [];
      for (const pagina of new ContabilidadePag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'contabil', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)).sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // ControleInterno
    if (sistemas_administrador.includes('controle-interno')) {
      acessosSistema = [];
      for (const pagina of new ControleInternoPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'controle-interno', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // DiarioOficial
    if (sistemas_administrador.includes('diario-oficial')) {
      acessosSistema = [];
      for (const pagina of new DiarioOficialPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'diario-oficial', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    //Fatura
    if (sistemas_administrador.includes('fatura')) {
      acessosSistema = []
      for (const pagina of new FaturaPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso)
            break
          }
        }
      }
      acessos.push({ sistema: 'fatura', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) })
    }

    // Frota
    if (sistemas_administrador.includes('frota')) {
      acessosSistema = [];
      for (const pagina of new FrotaPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'frota', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Requisição
    if (sistemas_administrador.includes('requisicao')) {
      acessosSistema = [];
      for (const pagina of new RequisicaoPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'requisicao', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Legislativo
    if (sistemas_administrador.includes('legislativo')) {
      acessosSistema = [];
      for (const pagina of new LegislativoPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'legislativo', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Licitacao
    if (sistemas_administrador.includes('licitacao')) {
      acessosSistema = [];
      for (const pagina of new LicitacaoPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'licitacao', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Patrimonio
    if (sistemas_administrador.includes('patrimonio')) {
      acessosSistema = [];
      for (const pagina of new PatrimonioPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'patrimonio', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Planejamento
    if (sistemas_administrador.includes('planejamento')) {
      acessosSistema = [];
      for (const pagina of new PlanejamentoPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'planejamento', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // PortalEntidade
    if (sistemas_administrador.includes('transparencia')) {
      acessosSistema = [];
      for (const pagina of new PortalEntidadePag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'portal-entidade', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Protocolo
    if (sistemas_administrador.includes('protocolo')) {
      acessosSistema = [];
      for (const pagina of new ProtocoloPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'protocolo', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Folha
    if (sistemas_administrador.includes('recursos-humanos')) {
      acessosSistema = [];
      for (const pagina of new FolhaPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'recursos-humanos', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // TerceiroSetor
    if (sistemas_administrador.includes('terceiro-setor')) {
      acessosSistema = [];
      for (const pagina of new TerceiroSetorPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'terceiro-setor', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Tesouraria
    if (sistemas_administrador.includes('tesouraria')) {
      acessosSistema = [];
      for (const pagina of new TesourariaPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'tesouraria', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    // Transparência
    if (sistemas_administrador.includes('transparencia')) {
      acessosSistema = [];
      for (const pagina of new TransparenciaPag().paginas()) {
        for (const acesso of acessosTotal) {
          if (acesso.pagina === Pagina[pagina]) {
            acessosSistema.push(acesso);
            break;
          }
        }
      }
      acessos.push({ sistema: 'transparencia', acessos: acessosSistema.sort((a, b) => a.nomePagina.localeCompare(b.nomePagina)) });
    }

    return acessos;
  }

  public retornaPaginasPorSistema(): { sistema: string, nome: string, pagina: string }[] {
    const retorno: { sistema: string, nome: string, pagina: string }[] = []

    for (const pagina of new ComumPag().paginas())
      retorno.push({ sistema: 'Geral', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new AlmoxarifadoPag().paginas())
      retorno.push({ sistema: 'Almoxarifado', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new ControleInternoPag().paginas())
      retorno.push({ sistema: 'Controle Interno', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new CompraPag().paginas())
      retorno.push({ sistema: 'Compra', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new PatrimonioPag().paginas())
      retorno.push({ sistema: 'Patrimônio', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new DiarioOficialPag().paginas())
      retorno.push({ sistema: 'Diário Oficial', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new LegislativoPag().paginas())
      retorno.push({ sistema: 'Legislativo', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new ProtocoloPag().paginas())
      retorno.push({ sistema: 'Protocolo', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new FolhaPag().paginas())
      retorno.push({ sistema: 'Folha', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new TerceiroSetorPag().paginas())
      retorno.push({ sistema: 'Terceiro Setor', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new PortalEntidadePag().paginas())
      retorno.push({ sistema: 'Portal Entidade', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new PlanejamentoPag().paginas())
      retorno.push({ sistema: 'Planejamento', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new TesourariaPag().paginas())
      retorno.push({ sistema: 'Tesouraria', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new ContabilidadePag().paginas())
      retorno.push({ sistema: 'Contabilidade', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new TransparenciaPag().paginas())
      retorno.push({ sistema: 'Transparência', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new LicitacaoPag().paginas())
      retorno.push({ sistema: 'Licitação', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new FrotaPag().paginas())
      retorno.push({ sistema: 'Frota', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new RequisicaoPag().paginas())
      retorno.push({ sistema: 'Requisição', nome: pagina, pagina: Pagina[pagina] })
    for (const pagina of new FaturaPag().paginas())
      retorno.push({ sistema: 'Fatura', nome: pagina, pagina: Pagina[pagina] })

    return retorno;
  }
}
