import { Component, Injector } from '@angular/core';
import { LicitacaoService } from 'administrativo-lib';
import { ProdutoService } from 'almoxarifado-lib';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, GlobalService, Licitacao, LoginContabil, Produto } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// declare var $: any;

@Component({
  selector: 'lib-produto-list',
  templateUrl: './produto-list.component.html'
})

export class ProdutoListComponent extends BaseResourceListComponent<Produto, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public filtroSelecionado: 'T' | 'A' | 'I' | 'DC' | 'L' = 'T';
  public mes: string;
  private idsProdutosLicitados = []
  seguir: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private produtoService: ProdutoService,
    protected globalService: GlobalService,
    public licitacaoService: LicitacaoService,
  ) {
    super(produtoService, injector);
    // this.usarExtendido = true;
    this.filtroSelecionado = sessionStorage.getItem(
      `/produtos_filtroSelecionado`
    ) as 'T' | 'A' | 'I' | 'DC' | 'L' || 'T'
    this.seguir = (sessionStorage.getItem('/produtos_seguir') === 'true' ? true : false)
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(item: Produto): boolean {
    return true;
  }

  protected relations(): string {
    return 'material.sub_grupo.grupo';
  }

  protected condicoesGrid(): {} {
    const parametros = {
      'material.servico': false,
      'orgao.id': this.login.orgao.id,
    };


    if (this.filtroSelecionado === 'DC') {
      parametros['desabilita_compra'] = true;
    } else if (this.filtroSelecionado === 'L') {
      if (this.idsProdutosLicitados.length > 0) {
        parametros['id$in'] = this.idsProdutosLicitados.join(',');
      } else {
        parametros['id$null'] = true;
      }
    } else if (['A', 'I'].includes(this.filtroSelecionado)) {
      parametros['inativo'] = this.filtroSelecionado === 'I';
    }
    sessionStorage.setItem(
      `/produtos_filtroSelecionado`,
      String(this.filtroSelecionado)
    );

    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      // number: ['codigo_comp'],
      // text: ['nome', 'material.nome', 'codigo', 'codigo_comp'],
      text: ['nome', 'material.nome', 'codigo'],
    };
  }

  protected afterInit(): void {
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
  }

  protected acaoRemover(model: Produto): Observable<Produto> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Material', coluna: 'material.nome' },
      { titulo: 'Código sub-grupo', coluna: 'material.sub_grupo.codigo', alignment: 'left' },
      { titulo: 'Nome sub-grupo', coluna: 'material.sub_grupo.nome' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'material.sub_grupo.grupo';
    parametros['orgao_id'] = this.login.orgao.id;

    this.produtoService
      .filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE PRODUTOS`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem produtos', ['auto', '*', '*', '*', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public async onClick(valueEmitted?: string) {
    if (this.filtroSelecionado === 'L') {
      const licitacoesNaoJulgadas = (await this.licitacaoService.filtrar(0, 0, {
        'proponentes.propostas.situacao$ne': 'VENCEDOR',
        'itens.propostas.situacao$ne': 'VENCEDOR',
        'itens.id$not_null': true,
        'itens.produto_unidade.produto.id$not_null': true,
        'exercicio_id': this.login.exercicio.id,
        'orgao_id': this.login.orgao.id,
        relations: 'itens.propostas,itens.produto_unidade.produto,proponentes.propostas',
      }).toPromise()).content as Licitacao[];

      this.idsProdutosLicitados = licitacoesNaoJulgadas
        .map(l => l.itens.map(m => m.produto_unidade.produto.id))
        .reduce((acc, curr) => acc.concat(curr), []);
    }

    super.onClick(valueEmitted)
  }

  public salvarSessionStorage() {
    sessionStorage.setItem(
      `/produtos_seguir`,
      String(this.seguir)
    );
  }
}
