import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { DespesaPrevista, FichaDespesa, FuncaoService, LoginContabil } from "eddydata-lib";
import { Router } from "@angular/router";
import { DespesaPrevistaService } from "administrativo-lib";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-acerto-previsao-ficha-despesa-dlg',
  templateUrl: './acerto-previsao-ficha-despesa-dlg.component.html'
})
export class AcertoPrevisaoFichaDespesaDlgComponent implements OnChanges, OnDestroy {

  @Input() login: LoginContabil;
  @Input() ficha: FichaDespesa;
  @Input() saldo_faltante: number;
  @Input() mes: number;
  @Input() previsaoAnular: DespesaPrevista;

  public listaPrevisoes: DespesaPrevista[] = [];
  protected unsubscribe: Subject<void> = new Subject();
  protected previsoesAjustar: DespesaPrevista[] = [];

  public valor_anulado: number = 0;
  public valor_adicional: number = 0;

  public imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    FadFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private despesaPrevistaService: DespesaPrevistaService,
    private funcaoService: FuncaoService,
    private route: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['ficha'] && changes['ficha']['currentValue']) {
      this.despesaPrevistaService.filtrar(1, -1, {
        ficha_id: this.ficha.id,
        relations: 'ficha.exercicio,ficha.orgao'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.listaPrevisoes = res.content;
        });
    }

    if (changes && changes['previsaoAnular'] && changes['previsaoAnular']['currentValue']) {
      this.previsoesAjustar.push(this.previsaoAnular);
      const aux = this.previsoesAjustar.map(item => {
        return {
          ...item,
          id: null,
          mes: null,
          editavel: true,
          evento: 'FED',
          valor_previsto: +item.valor_previsto * -1,
        };
      });

      this.previsoesAjustar.push(...aux);
      this.previsoesAjustar.push({
        editavel: true,
        evento: 'FAD',
        ficha: this.previsoesAjustar[0]['ficha']
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  continuar() {
    this.previsoesAjustar = this.listaPrevisoes.filter(previsao => previsao['anular']);
    const aux = this.previsoesAjustar.map(item => {
      return {
        ...item,
        id: null,
        editavel: true,
        evento: 'FED',
        mes: this.mes,
        valor_previsto: +item.valor_previsto * -1
      };
    });

    this.previsoesAjustar.push(...aux);
    this.previsoesAjustar.push({
      editavel: true,
      evento: 'FAD',
      mes: this.mes,
      referencia: this.mes,
      ficha: this.previsoesAjustar[0]['ficha']
    });

    this.valor_anulado = this.previsoesAjustar.filter(item => item.evento === 'FED').reduce((total, item) => total += +item.valor_previsto, 0);
    this.valor_adicional = this.previsoesAjustar.filter(item => item.evento === 'FAD').reduce((total, item) => total += +item.valor_previsto, 0);
  }

  fechar() {
    this.voltar();
    $('#dialogAcertoPrevisaoFichaDespesa').modal('hide');
  }

  voltar() {
    this.previsoesAjustar = [];
  }

  salvar() {
    if (+this.valor_adicional.toFixed(2) !== +(+this.valor_anulado.toFixed(2) * -1)) {
      toastr.warning(`Valores adicionais e de anulação não são correspondentes!`);
      return;
    }

    if (this.saldo_faltante && +this.valor_adicional.toFixed(2) < +(this.saldo_faltante * -1).toFixed(2)) {
      toastr.warning(`Saldo ajustado insuficiente!\nValor Necessário: R$ ${this.funcaoService.convertToBrNumber((this.saldo_faltante * -1))}\nValor Ajustado: R$ ${this.funcaoService.convertToBrNumber(this.valor_adicional)}`);
      return;
    }

    const data = this.formatarData(this.mes)
    if (!this.funcaoService.podeAlterarAudesp(new Date(data), this.login)) {
      toastr.warning('Não é possível salvar previsão. Prazo esgotado!');
      return;
    }

    this.despesaPrevistaService.inserirLista(this.previsoesAjustar.filter(item => !item.id))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        toastr.info('Previsões salvas com sucesso!');
        this.previsoesAjustar = [];
        $('#dialogAcertoPrevisaoFichaDespesa').modal('hide');
        return;
      });
  }

  calcularValores(item: DespesaPrevista) {
    const aux = this.previsoesAjustar.find(previsao => previsao.evento === 'FOD' && previsao.referencia === item.referencia);
    if (item.evento === 'FED') {
      if ((+item.valor_previsto * -1) > aux.valor_previsto) {
        item.valor_previsto = (+aux.valor_previsto * -1);
        toastr.warning(`Valor ultrapassa saldo inicial referente ao mês ${item.referencia}!`);
        return;
      }

      if (+item.valor_previsto > 0) {
        toastr.warning('O valor de anulação deve ser menor que zero!');
        return;
      }
    } else {
      const anulado = this.previsoesAjustar.filter(item => item.evento === 'FED').reduce((total, item) => total += +item.valor_previsto, 0);
      if (+item.valor_previsto.toFixed(2) > +(+anulado * -1).toFixed(2)) {
        item.valor_previsto = 0;
        toastr.warning(`Valor adicional ultrapassa o valor anulado!`);
        return;
      }

      if (+item.valor_previsto < 0) {
        toastr.warning('O valor adicional deve ser maior que zero!');
        return;
      }
    }
    this.valor_anulado = this.previsoesAjustar.filter(item => item.evento === 'FED').reduce((total, item) => total += +item.valor_previsto, 0);
    this.valor_adicional = this.previsoesAjustar.filter(item => item.evento === 'FAD').reduce((total, item) => total += +item.valor_previsto, 0);
  }

  atualizarCampos(item: DespesaPrevista) {
    item.mes = item['mes_'] ?? item.mes;
    item.referencia = item['referencia_'] ?? item.referencia;
    Reflect.deleteProperty(item, 'mes_');
    Reflect.deleteProperty(item, 'referencia_');

    const data = this.formatarData(item.mes)
    if (!this.funcaoService.podeAlterarAudesp(new Date(data), this.login)) {
      toastr.warning('Não é possível salvar previsão. Prazo esgotado!');
      item.mes = null;
      return;
    }
    this.mes = item.mes;
  }

  formatarData(mes: number): string {
    const data = new Date();
    data.setMonth(mes - 1); // Define o mês corretamente
    data.setDate(data.getDate() + 1); // Adiciona 1 ao dia, ajustando o mês se necessário

    const ano = data.getFullYear();
    const mesFormatado = String(mes).padStart(2, '0'); // Usa o mês que você passou, formatando com zero à esquerda
    const dia = String(data.getDate()).padStart(2, '0'); // Garante que o dia tenha zero à esquerda

    return `${ano}-${mesFormatado}-${dia}`;
  }
}