import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, Modalidade } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalidadeService } from '../service/modalidade.service';

@Component({
  selector: 'lib-modalidade-list',
  templateUrl: './modalidade-list.component.html'
})
export class ModalidadeListComponent extends BaseResourceListComponent<Modalidade, LoginContabil> {

  /**
   * Declaração de variáveis
   */


  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private modalidadeService: ModalidadeService) {
    super(modalidadeService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'cidade';
  }

  protected condicoesGrid(): {} {
    return {
      ['cidade.id']: this.login.cidade.id
    };
  }

  protected ordenacaoGrid(): string[] {
    return ['codigo_pncp'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['id', 'codigo', 'valor_limite', 'prazo_limite'],
      text: ['nome', 'codigo']
    };
  }

  protected afterInit(): void {
  }

  protected acaoRemover(model: Modalidade): Observable<Modalidade> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'ID', coluna: 'id' },
      { titulo: 'TCE', coluna: 'codigo' },
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Valor limite', coluna: 'valor_limite', decimais: 2 },
      { titulo: 'Prazo limite', coluna: 'prazo_limite', decimais: 0 },
      { titulo: 'Exige Licitação', coluna: 'exige_licitacao' },
      { titulo: 'Exige Contrato', coluna: 'exige_contrato' },
      { titulo: 'Exige Convênio', coluna: 'exige_convenio' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = 'cidade';
    this.modalidadeService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE MODALIDADES `,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'portrait',
              'Listagem modalidade', ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public retornar() {
    this.router.navigate([`/cadastros-auxiliares`]);
  }
}
