import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { Agenda } from '../../entidade/comum/agenda.model';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgendaService extends BaseResourceService<Agenda> {

  constructor(
    protected injector: Injector
  ) {
    super(`agendas`, injector);
  }

  public gerarEventosAgendaLicitacaoMarcadas(orgaoId: number, exercicioId: number): Observable<Agenda[]> {
    return this.http.get<Agenda[]>(
      `${this.login.cidade.id}/${this.api}/licitacoes-agenda/${orgaoId}/${exercicioId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  };

}
