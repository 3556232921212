import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BaseResourceFormComponent, Credito, CreditoItem, DateFormatPipe, Decreto, EddyAutoComplete, FichaDespesa, FuncaoService, GlobalService, Lei, LoginContabil,
  OrgaoSuplementacao, OrgaoSuplementacaoService, ParametroContabil, ParametroContabilService
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DecretoService } from 'projects/contabil/src/app/planejamento/decreto/service/decreto.service';
import { CotaMensalService } from 'projects/contabil/src/app/planejamento/ficha-despesa/service/cota-mensal.service';
import { LeiService } from 'projects/contabil/src/app/planejamento/lei/service/lei.service';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { FichaDespesaService } from '../../../ficha-despesa/service/ficha-despesa.service';
import { CreditoItemService } from '../service/credito-item.service';
import { CreditoService } from '../service/credito.service';

@Component({
  selector: 'app-credito-form',
  templateUrl: './credito-form.component.html'
})
export class CreditoFormComponent extends BaseResourceFormComponent<Credito, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  @ViewChild('myInput') inputField: ElementRef;

  public listaTipos: Array<any>;
  public listaOcorrencias: Array<any>;
  public listaCreditoItens: Array<CreditoItem>;
  public totalEmpenhado: number;
  public orgaoSuplementacao: OrgaoSuplementacao;
  public totalDotacao: number;
  public totalCredito: number;
  public vlLimitePrudencial: number;
  public vlLimiteSuplementacao: number;
  public isTransferencia: boolean = false;
  public totalCreditoItem = 0;
  public totalAnuladoItem = 0;
  public listaExclusao = [];
  public leiN: Lei;
  public decretoN: Decreto;
  public leiAutoComplete: EddyAutoComplete<Lei>
  public decretoAutoComplete: EddyAutoComplete<Decreto>
  public ocorrencia: number = 0;
  public data_credito: Date = new Date();

  public decretoMask = {
    mask: [
      {
        mask: '00000/0000'
      },
      {
        mask: '0000/0000'
      }
    ]
  };

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected fichaService: FichaDespesaService,
    protected orgaoSuplementacaoService: OrgaoSuplementacaoService,
    protected creditoItemService: CreditoItemService,
    protected creditoService: CreditoService,
    protected decretoService: DecretoService,
    protected leiService: LeiService,
    protected route: Router,
    public cotaMensalService: CotaMensalService,
    public confirmationService: ConfirmationService,
    private parametroContabilService: ParametroContabilService
  ) {
    super(new Credito(), injector, Credito.converteJson, creditoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Credito): Promise<boolean> {
    return this.login.sistema != 'controle-interno'
  }
  
  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_credito: [null, [Validators.required]],
      data_publicacao: [null],
      data_vigencia: [null],
      mes: [null],
      tipo: [null],
      ocorrencia: [null],
      decreto: [null],
      decreto_interno: [null],
      lei: [null],
      ativo: [true, [Validators.required]],
      motivo: [null],
      justificativa: [null],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      itens: []
    });
  }

  protected afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_credito)) {
      this.router.navigate(['/creditos']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    if (this.entidade.data_credito) {
      this.data_credito = new Date(this.entidade.data_credito);
    }

    this.entidade.data_credito = new DateFormatPipe().transform(this.entidade.data_credito, []);
    this.entidade.data_publicacao = new DateFormatPipe().transform(this.entidade.data_publicacao, []);
    this.entidade.data_vigencia = new DateFormatPipe().transform(this.entidade.data_vigencia, []);
    this.entidade.data_credito = new DateFormatPipe().transform(this.entidade.data_credito, []);
    this.ocorrencia = +this.entidade.ocorrencia;
    this.totalEmpenhado = 0;
    this.obterItens(this.entidade.id);
    this.obterLimites();
  }

  obterLimites() {
    // obtém os percentuais de limites de suplementação
    this.orgaoSuplementacaoService.obter({
      orgao_id: this.login.orgao.id,
      exercicio_id: this.login.exercicio.id
    }).subscribe(res => {
      this.orgaoSuplementacao = res;

      if (!this.orgaoSuplementacao) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Os limites de suplementação não foram cadastrados para o exercício' });
        return;
      }
      if (!this.entidadeForm.get('lei').value && !this.entidade.id) {
        this.entidadeForm.get('lei').setValue(this.funcaoService.strZero(this.orgaoSuplementacao.lei.replace('/', ''), 9));
      }
      if (!this.entidadeForm.get('decreto').value && !this.entidade.id) {
        this.entidadeForm.get('decreto').setValue('00000/0000');
      }
      // busca o total de orçado no exercício
      this.creditoService.totalCreditado(this.entidadeForm.get('ocorrencia').value,
        this.entidadeForm.get('tipo').value, this.login.orgao.id, this.login.exercicio.id)
        .subscribe(res => {
          const total = res[0];
          if (total) {
            this.totalDotacao = +total.total_dotacao;
            this.totalCredito = +total.total_creditado;
            this.vlLimitePrudencial = (this.totalDotacao * +this.orgaoSuplementacao.limite_prudencial) / 100;
            this.vlLimiteSuplementacao = (this.totalDotacao * +this.orgaoSuplementacao.percentual_suplementacao) / 100;
          }
        });
    });
  }

  obterItens(credito_id: number) {
    this.creditoItemService.obterPorCredito(credito_id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((dados) => {
        this.listaCreditoItens = dados ? dados.content : new Array<CreditoItem>();
        for (const item of this.listaCreditoItens) {
          item.data_contabilizacao = new DateFormatPipe().transform(item.data_contabilizacao, []);
          this.fichaService.saldoAtualFicha(item.ficha.id, this.login.orgao.id, this.login.exercicio.id, this.funcaoService.converteDataSQL(new Date()), {}, true)
            .subscribe((res) => {
              if (res) {
                item['rcms'] = +res.total_rcms_pendente;
                item['compra'] = +res.total_compra_pendente;
                item.empenhado = +res.total_empenhado + +res.total_compra_pendente + +res.total_rcms_pendente;
                item.dotacao = (+res.total_dotacao + +res.valor_creditado) - (+res.total_reservado + +res.total_empenhado + +res.total_compra_pendente + +res.total_rcms_pendente);
                this.totalEmpenhado += item.empenhado;
              } else {
                item.empenhado = 0;
                this.totalEmpenhado = 0;
              }
            });

          this.fichaService.totalizadores(this.entidade.exercicio.id, this.login.orgao.id, item.ficha.id).toPromise().then(
            (res) => {
              if (res.content.length) {
                item.empenhado = +res.content[0].total_empenhado;
                item.dotacao = (+res.content[0].valor_orcado + +res.content[0].total_creditado)
                  - (+res.content[0].total_reservado + +res.content[0].total_empenhado);
                this.totalEmpenhado += item.empenhado;
              } else {
                item.empenhado = 0;
                this.totalEmpenhado = 0;
              }
            }
          );
        }
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  protected parametrosExtras(): {} {
    return {
      relations:
        'exercicio,orgao,itens.ficha.despesa,itens.ficha.funcao,itens.ficha.subfuncao,itens.ficha.acao,itens.ficha.programa,itens.previsoes,itens.credito'
    };
  }

  protected afterInit(): void {
    this.isTransferencia = this.router.url.includes('transferencias-internas');
    this.listaTipos = this.globalService.obterListaTiposCreditos();
    this.listaOcorrencias = this.globalService.obterListaOcorrenciasCreditos();
    if (this.currentActionRoute === 'novo') {
      this.obterLimites();
      this.listaCreditoItens = new Array<CreditoItem>();
    }

    if (this.isTransferencia) {
      this.entidadeForm.get('tipo').setValue(1);
      this.entidadeForm.get('tipo').disable();
      this.entidadeForm.get('ocorrencia').setValue(99);
      this.entidadeForm.get('ocorrencia').disable();
      this.entidadeForm.get('ativo').setValue(true);
      this.entidadeForm.get('data_publicacao').removeValidators([Validators.required]);
      this.entidadeForm.get('data_vigencia').removeValidators([Validators.required]);
      this.entidadeForm.get('lei').removeValidators([Validators.required]);
    } else {
      this.listaOcorrencias.pop();
      this.leiAutoComplete = new EddyAutoComplete(null, this.leiService, 'codigo', ['codigo'], null, { text: ['codigo$like'] }, null, null, (entidade) => this.funcaoService.mascarar('00000/0000', '' + entidade.codigo));
      this.decretoAutoComplete = new EddyAutoComplete(null, this.decretoService, 'codigo', ['codigo'], null, { text: ['codigo$like'] }, null, null, (entidade) => this.funcaoService.mascarar('00000/0000', '' + entidade.codigo));
    }
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected totalizador(list: Array<any>) {
    if (list) {
      this.totalAnuladoItem = 0;
      this.totalCreditoItem = 0;
      for (const item of list) {
        if (+item.valor_credito > 0) {
          this.totalCreditoItem += +item.valor_credito;
        } else {
          this.totalAnuladoItem += +item.valor_credito;
        }
      }
    }
  }

  protected async beforeSubmit() {
    try {
      if (this.isTransferencia) {
        this.entidadeForm.get('lei').setValue('0001');
        this.entidadeForm.get('decreto').setValue('0001');
        if (this.entidadeForm.get('data_credito').value === null) {
          throw new Error('É necessário informar a data da transferência');
        }
        if (new Date(this.entidadeForm.get('data_credito').value).getFullYear() !== this.login.exercicio.ano) {
          throw new Error('A data informada deve ser do exercício de trabalho');
        }
        if (this.entidadeForm.get('decreto_interno').value === null) {
          throw new Error('É necessário informar o número do decreto');
        }
        if (this.entidadeForm.get('justificativa').value === null) {
          throw new Error('É necessário informar a justificativa da transferência');
        }
        if (this.listaCreditoItens.length > 2) {
          throw new Error('É necessário informar somente uma ficha de origem e uma de destino');
        }
        if (!this.fichasValidas()) {
          throw new Error('As fichas informadas não possuem as mesmas classificações');
        }
        if (this.entidadeForm.get('data_publicacao').value === null) {
          this.entidadeForm.get('data_publicacao').setValue(this.entidadeForm.get('data_credito').value);
        }
      } else {
        if (!this.entidadeForm.get('ativo').value) {
          if (this.entidadeForm.get('data_credito').value === null) {
            throw new Error('É necessário informar a data do crédito');
          }
          if (this.entidadeForm.get('ocorrencia').value === null) {
            throw new Error('É necessário informar a ocorrência para o crédito');
          }
          if (this.entidadeForm.get('tipo').value === null) {
            throw new Error('É necessário informar o tipo de crédito');
          }
          if (this.entidadeForm.get('ativo').value === true && this.entidadeForm.get('motivo').value === null) {
            throw new Error('Informe o motivo pelo qual o crédito esta sendo inativado');
          }
          if (this.entidadeForm.get('decreto').value === null) {
            throw new Error('É necessário informar o decreto');
          }
          if (this.totalEmpenhado > 0) {
            const dt1 = new Date(new DateFormatPipe().transform(this.entidadeForm.get('data_credito').value, []));
            const dt2 = new Date(this.entidade.data_credito);
            if (dt1.toISOString().slice(0, 10) !== dt2.toISOString().slice(0, 10)) {
              throw new Error('Não é possível alterar a data do crédito, pois já existem fichas empenhadas');
            }
          }
          if (!this.entidadeForm.get('ativo').value) {
            this.entidadeForm.get('lei').removeValidators([Validators.required]);
          }
        }

        if (this.leiN) {
          this.entidadeForm.get('lei').setValue(this.funcaoService.strZero(this.leiN.codigo, 9));
        }

        if (this.decretoN) {
          this.entidadeForm.get('decreto').setValue(this.funcaoService.strZero(this.decretoN.codigo, 9));
        }

        if (`${this.entidadeForm.get('lei').value}`.length < 8) {
          throw new Error(`Lei ${this.formataLeiOuDecreto('' + this.entidadeForm.get('lei').value)} com tamanho inválido!`);
        }

        const lei: Lei = await this.leiService.obter({ codigo: this.entidadeForm.get('lei').value.replace('/', '') }).pipe(takeUntil(this.unsubscribe)).toPromise();

        if (!lei && this.entidadeForm.get('ativo').value) {
          throw new Error(`Lei ${this.formataLeiOuDecreto(this.entidadeForm.get('lei').value)} não encontrada!`);
        }

        if (`${this.entidadeForm.get('decreto').value}`.length !== 9) {
          throw new Error(`Decreto ${this.formataLeiOuDecreto(this.entidadeForm.get('decreto').value)} com tamanho inválido!`);
        }
      }

      let totalSuplmentado = 0;
      for (const item of this.listaCreditoItens) {
        totalSuplmentado += item.valor_credito > 0 ? item.valor_credito : 0;
      }
      if ((this.entidade.id ? +this.totalCredito : +this.totalCredito + +totalSuplmentado) > this.vlLimitePrudencial) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'O limite prudencial foi excedido!' })
      }

      if (this.entidadeForm.get('ocorrencia').value === '1' && this.entidadeForm.get('ativo').value) {
        let fichasComCotaRecalcular: FichaDespesa[] = []
        let fichasComCotaExcluidas: FichaDespesa[] = []
        for (const item of this.listaCreditoItens) {
          if (item.ficha.tipo_cota && (await this.cotaMensalService.filtrar(1, -1, { ficha_id: item.ficha.id }).toPromise()).content.length > 0) {
            if (item.valor_credito > 0) fichasComCotaRecalcular.push(item.ficha)
            else fichasComCotaExcluidas.push(item.ficha)
          }
        }
        let mensagem = `
        Foi identificado nas fichas movimentadas que algumas possuem cotas reservada. Será necessário:.
        <br/>
        <br/>
        ${fichasComCotaRecalcular.length > 0
            ? `<strong>Recalcular Cotas para usar credito:</strong>&nbsp;
          ${fichasComCotaRecalcular.map(ficha => ` ${ficha.numero}`)}
          <br/><br/>`
            : ''
          }

      ${fichasComCotaExcluidas.length > 0
            ? `<strong>Recalcular Cotas pois serão excluidas das seguintes fichas:</strong>&nbsp;
        ${fichasComCotaExcluidas.map(item => ` ${item.numero}-${item.tipo_cota}-${+item.valor_cota}%`)}`
            : ''
          }
        `
        if ((fichasComCotaRecalcular.length > 0 || fichasComCotaExcluidas.length > 0) && await this.mensagemConfirmacao(mensagem)) {
          throw new Error('Operação abortada pelo usuário.')
        }
      }

      if(this.entidadeForm.get('justificativa').value.length < 5) {
        throw new Error('É necessário informar ao menos 5 caracteres para a justificativa!');
      }

      this.entidadeForm.get('itens').setValue(this.listaCreditoItens);

      this.totalizador(this.entidadeForm.get('itens').value);
      const currentOcorrencia = this.entidadeForm.get('ocorrencia').value;
      const currentTipo = this.entidadeForm.get('tipo').value;
      const parametros = (await this.parametroContabilService.filtrar(1, -1, { orgao_id: this.login.orgao.id }).toPromise()).content as ParametroContabil[];

      if (+this.totalAnuladoItem.toFixed(2) != (+this.totalCreditoItem.toFixed(2) * -1) && currentOcorrencia !== '2' && currentOcorrencia !== '3' && !parametros[0]?.credito_adicional_sem_anulacao) {
        throw new Error('Verifique as fichas, pois o valor do credíto esta diferente do anulado!');
      } else {
        if (this.listaExclusao.length > 0) {
          for (const item of this.listaExclusao) {
            await this.creditoItemService.remover(item.id).toPromise();
          }
        }
      }

      if (!this.funcaoService.podeAlterarAudesp(this.entidadeForm.get('data_credito').value, this.login)) {
        throw new Error('O mês do registro já foi armazenado no TCE. Não é possível salvar!');
      }

      const dataCredito = new DateFormatPipe().transform(this.entidadeForm.get('data_credito').value, []);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dataCredito)?.split('-')?.[1]);
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(ent: Credito) {
    this.obterItens(this.entidade.id);
    this.listaExclusao = [];
  }


  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public formatarLei() {
    const lei = this.entidadeForm.get('lei').value;
    if (lei.length !== 9) {
      this.entidadeForm.get('lei').setValue(this.funcaoService.strZero(lei.replace('/', ''), 9));
    }
  }

  public formatarDecreto() {
    const decreto = this.entidadeForm.get('decreto').value;
    if (decreto.length !== 9) {
      this.entidadeForm.get('decreto').setValue(this.funcaoService.strZero(decreto.replace('/', ''), 9));
    }
  }

  private fichasValidas(): Boolean {

    const credito1: CreditoItem = this.listaCreditoItens[0];
    const credito2: CreditoItem = this.listaCreditoItens[1];

    if (!credito1 || !credito2) {
      throw new Error('Informe a ficha de origem e uma de destino para esta transferência interna');
    }

    if ((!credito1 || !credito2) && (credito1.valor_credito > 0 && credito2.valor_credito > 0) || (credito1.valor_credito < 0 && credito2.valor_credito < 0)) {
      throw new Error('É necessário informar somente uma ficha de origem e uma de destino');
    }

    var vl_credito: number, vl_debito: number;
    vl_credito = +credito1.valor_credito < 0 ? +credito1.valor_credito * -1 : +credito1.valor_credito;
    vl_debito = +credito2.valor_credito < 0 ? +credito2.valor_credito * -1 : +credito2.valor_credito;

    if (vl_credito !== vl_debito) {
      throw new Error('Os valores de credito e anulação não são correspondentes');
    }

    if (credito1?.ficha?.despesa?.codigo !== credito2?.ficha?.despesa?.codigo) return false;
    if (credito1?.ficha?.executora?.codigo !== credito2?.ficha?.executora?.codigo) return false;
    if (credito1?.ficha?.programa?.codigo !== credito2?.ficha?.programa?.codigo) return false;
    if (credito1?.ficha?.recurso?.codigo !== credito2?.ficha?.recurso?.codigo) return false;
    if (credito1?.ficha?.funcao?.codigo !== credito2?.ficha?.funcao?.codigo) return false;
    if (credito1?.ficha?.subfuncao?.codigo !== credito2?.ficha?.subfuncao?.codigo) return false;
    if (credito1?.ficha?.acao?.codigo !== credito2?.ficha?.acao?.codigo) return false;

    return true;
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/planejamento']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public updateVariavel() {
    this.ocorrencia = this.entidadeForm.get('ocorrencia').value;
    this.data_credito = this.entidadeForm.get('data_credito').value;
  }

  public onDeletar(item) {
    this.listaExclusao.push(item)
  }

  public async mensagemConfirmacao(mensagem?: string, titulo?: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: mensagem,
        header: 'Atenção! Essa ação é irreversível.',
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Continuar",
        rejectLabel: "Cancelar",
        accept: () => {
          resolve(false);
        },
        reject: () => {
          resolve(true);
        },
      });
    });
  }

  private formataLeiOuDecreto(valor: string): string {
    const numero = valor.length !== 9 ? 4 : 5;
    return valor.substring(0, numero) + "/" + valor.substring(valor.length - 4);
  }
}
