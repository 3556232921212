import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService, OrgaoAssinaturaService, Orgao, FormatoExportacao } from 'eddydata-lib';
import { BalancoService } from '../service/balanco.service';
import { BaseResourceNotaExplicativa } from '../base-resource-nota-explicativa';
import { NotaExplicativaService } from '../service/nota-explicativa.service';

@Injectable({
  providedIn: 'root'
})
export class Anexo13ABalanco extends BaseResourceNotaExplicativa implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();
  protected globalService: GlobalService;

  constructor(
    protected anexoServico: BalancoService,
    protected notaService: NotaExplicativaService,
    protected assinaturaService: OrgaoAssinaturaService
  ) {
    super('B13A', notaService, assinaturaService)
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(exercicio: Exercicio, orgaos: number[], listaOrgaos: Orgao[], mes?: number, dtInicial?: string, dtFinal?: string, formato?: FormatoExportacao) {
    formato = formato ?? 'pdf';
    this.anexoServico.obterBalancoFinanceiroA(exercicio.id, orgaos, mes, dtInicial, dtFinal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        const orgao = orgaos.length === 1 ? listaOrgaos.filter(o => o.id === orgaos[0])[0] : listaOrgaos.filter(o => o.id === 1)[0];
        const consolidado = orgaos.length === 1 ? orgao.nome : 'Consolidado';
        let orgaoNomes: string[] = [];
        for (const o of listaOrgaos) {
          if (orgaos.some(orgao => orgao === o.id)) {
            orgaoNomes.push(`${o.codigo} - ${o.nome}`);
          }
        }
        if (formato === 'pdf') {
          const dataLimiteAssinatura = `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
          Relatorio.imprimirPersonalizado(
            'ANEXO 13 A - BALANÇO FINANCEIRO',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            this.cabecalhoRelatorio(orgao, exercicio, mes, dtInicial, dtFinal)
              .concat(this.conteudo(dados, exercicio))
              .concat(await this.conteudoNotaExplicativa())
              .concat(await this.conteudoAssinatura(this.login.orgao, orgaoNomes, 32, mes ? dataLimiteAssinatura : null)),
            'portrait', 'ANEXO 13 A - BALANÇO FINANCEIRO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, 'pdf', `Unidade Gestora: ${consolidado.toUpperCase()}`);
        } else if (formato === 'csv') {
          this.exportacaoCsv(dados);
        }
      });
  }

  private cabecalhoRelatorio(orgao: Orgao, exercicio: Exercicio, mes?: number, dtInicial?: string, dtFinal?: string): {}[] {
    let periodo = '';
    if (mes) {
      periodo = this.globalService.obterMes(+mes);
    } else if (dtInicial && dtFinal) {
      let dt = dtInicial.split("-");
      periodo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
      dt = dtFinal.split("-");
      periodo += dt[2] + '/' + dt[1] + '/' + dt[0];
    } else {
      periodo = 'ANUAL';
    }
    const registros = [
      [
        { text: `Município:`, alignment: 'left', fontSize: 8 },
        { text: orgao.cidade?.nome ? orgao.cidade.nome : this.login.cidade.nome, alignment: 'center', fontSize: 8 },
        { text: `Exercício:`, alignment: 'left', fontSize: 8 },
        { text: exercicio.ano, alignment: 'center', fontSize: 8 }
      ], [
        { text: `Poder:`, alignment: 'left', fontSize: 8 },
        { text: orgao.especie === 'C' ? 'PODER LEGISLATIVO' : 'PODER EXECUTIVO', alignment: 'center', fontSize: 8 },
        { text: dtInicial && dtFinal ? 'Período:' : `Mês:`, alignment: 'left', fontSize: 8 },
        { text: periodo, alignment: 'center', fontSize: 8 }
      ], [
        { text: `Órgão:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: orgao.nome, alignment: 'center', fontSize: 8, border: [true, true, true, false] },
        { text: `Acumulado/Mensal:`, alignment: 'left', fontSize: 8, border: [true, true, true, false] },
        { text: mes ? 'MENSAL' : 'ACUMULADO', alignment: 'center', fontSize: 8, border: [true, true, true, false] }
      ], [
        { text: 'Em R$ 1,00', bold: true, alignment: 'right', colSpan: 4, border: [false, true, false, false] },
        '',
        '',
        '',
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*', '*'],
        body: registros
      }
    }];
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {
    const registros: {}[] = [
      [{
        text: 'DISCRIMINAÇÃO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'MOVIMENTO DO PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 8, colSpan: 2
      },
      { text: '' },
      {
        text: 'RESULTADO DO PERÍODO',
        alignment: 'center',
        bold: true, fontSize: 8, colSpan: 2
      },
      { text: '' }
      ],
      [{
        text: '',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DÉBITO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'CRÉDITO',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'RECEITA',
        alignment: 'center',
        bold: true, fontSize: 8
      }, {
        text: 'DESPESA',
        alignment: 'center',
        bold: true, fontSize: 8
      }]
    ];

    // monta o agrupamento do relatório
    const grupos = this.funcaoService.agrupar(dados, ['grupo'], ['valor_debito', 'valor_credito']);
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    for (const item of grupos) {
      let val1 = 0;
      let val2 = 0;
      for (const it of item.registros) {
        val1 += +it['valor_credito'] > +it['valor_debito'] ? +it['valor_credito'] - +it['valor_debito'] : 0;
        val2 += +it['valor_debito'] > +it['valor_credito'] ? +it['valor_debito'] - +it['valor_credito'] : 0;
      }
      registros.push([
        {
          text: item.grupo['grupo'], fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_debito']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_credito']), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(val1), alignment: 'right',
          fontSize: 8, border: [true, false, false, false]
        },
        {
          text: this.funcaoService.convertToBrNumber(val2), alignment: 'right',
          fontSize: 8, border: [true, false, true, false]
        }
      ]);

      total1 += item.totalizadores['valor_debito']
      total2 += item.totalizadores['valor_credito']

      for (const it of item.registros) {
        total3 += +it['valor_credito'] > +it['valor_debito'] ? +it['valor_credito'] - +it['valor_debito'] : 0;
        total4 += +it['valor_debito'] > +it['valor_credito'] ? +it['valor_debito'] - +it['valor_credito'] : 0;

        if (it['nome'] !== '') {
          let val1 = +it['valor_credito'] > +it['valor_debito'] ? +it['valor_credito'] - +it['valor_debito'] : 0;
          let val2 = +it['valor_debito'] > +it['valor_credito'] ? +it['valor_debito'] - +it['valor_credito'] : 0;
          registros.push([
            {
              text: it['nome'], fontSize: 8, border: [true, false, false, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(it['valor_debito']), alignment: 'right',
              fontSize: 8, border: [true, false, false, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(it['valor_credito']), alignment: 'right',
              fontSize: 8, border: [true, false, false, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(val1), alignment: 'right',
              fontSize: 8, border: [true, false, false, false]
            },
            {
              text: this.funcaoService.convertToBrNumber(val2), alignment: 'right',
              fontSize: 8, border: [true, false, true, false]
            }

          ]);
        }
      }
    }

    registros.push([{
      text: 'TOTAL', border: [true, true, false, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total1), alignment: 'right',
      border: [true, true, false, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total2), alignment: 'right',
      border: [true, true, false, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total3), alignment: 'right',
      border: [true, true, false, true], fontSize: 8, bold: true
    },
    {
      text: this.funcaoService.convertToBrNumber(total4), alignment: 'right',
      border: [true, true, true, true], fontSize: 8, bold: true
    }
    ]);
    return [{
      layout: 'linhas',
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 70, 70, 70, 70],
        body: registros
      }
    }];
  }

  private exportacaoCsv(dados: any[]) {

    // monta o cabecalho
    const registros: {}[][] = [
      [
        { text: 'DISCRIMINAÇÃO' },
        { text: 'MOVIMENTO DO PERÍODO DÉBITO' },
        { text: 'MOVIMENTO DO PERÍODO CRÉDITO' },
        { text: 'RESULTADO DO PERÍODO RECEITA' },
        { text: 'RESULTADO DO PERÍODO DESPESA' }
      ]
    ];

    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    const grupos = this.funcaoService.agrupar(dados, ['grupo'], ['valor_debito', 'valor_credito']);
    for (const item of grupos) {
      let val1 = 0;
      let val2 = 0;
      for (const it of item.registros) {
        val1 += +it['valor_credito'] > +it['valor_debito'] ? +it['valor_credito'] - +it['valor_debito'] : 0;
        val2 += +it['valor_debito'] > +it['valor_credito'] ? +it['valor_debito'] - +it['valor_credito'] : 0;
      }

      registros.push([
        { text: item.grupo['grupo'] },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_debito']) },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor_credito']) },
        { text: this.funcaoService.convertToBrNumber(val1) },
        { text: this.funcaoService.convertToBrNumber(val2) }
      ]);

      total1 += item.totalizadores['valor_debito'];
      total2 += item.totalizadores['valor_credito'];
      for (const it of item.registros) {
        total3 += +it['valor_credito'] > +it['valor_debito'] ? +it['valor_credito'] - +it['valor_debito'] : 0;
        total4 += +it['valor_debito'] > +it['valor_credito'] ? +it['valor_debito'] - +it['valor_credito'] : 0;

        if (it['nome'] !== '') {
          let val1 = +it['valor_credito'] > +it['valor_debito'] ? +it['valor_credito'] - +it['valor_debito'] : 0;
          let val2 = +it['valor_debito'] > +it['valor_credito'] ? +it['valor_debito'] - +it['valor_credito'] : 0;

          registros.push([
            { text: it['nome'] },
            { text: this.funcaoService.convertToBrNumber(it['valor_debito']) },
            { text: this.funcaoService.convertToBrNumber(it['valor_credito']) },
            { text: this.funcaoService.convertToBrNumber(val1) },
            { text: this.funcaoService.convertToBrNumber(val2) }
          ]);
        }
      }
    }

    registros.push([
      { text: 'TOTAL' },
      { text: this.funcaoService.convertToBrNumber(total1) },
      { text: this.funcaoService.convertToBrNumber(total2) },
      { text: this.funcaoService.convertToBrNumber(total3) },
      { text: this.funcaoService.convertToBrNumber(total4) }
    ]);

    dados = null;
    let csv = '';
    for (let i=0; i<registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x=0; x<linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `ANEXO 13 A - BALANÇO FINANCEIRO.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
