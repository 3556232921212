import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FichaReceitaService, ReceitaPrevistaService } from 'administrativo-lib';
import {
  BaseResourceListComponent,
  Coluna, Exercicio, ExercicioService, FichaReceita, Filtro, LoginPublico, ReceitaPrevista,
  TransparenciaParametro
} from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import { TransparenciaParametroService } from '../transparencia-parametro/service/transparencia-parametro.service';

@Component({
  selector: 'app-transparencia-receita-prevista',
  templateUrl: './transparencia-receita-prevista.component.html'
})
export class TransparenciaReceitaPrevistaComponent extends BaseResourceListComponent<ReceitaPrevista, LoginPublico> implements OnInit {

  /**
   * Declaração de variáveis
   */
  public tipo: string;
  public programacaoId: number;
  public listaPlanilha: Array<any>;
  public listaPrevisao: Array<ReceitaPrevista>;
  public ficha: FichaReceita;
  public usarExtendido: boolean;
  public exercicio: Exercicio;
  public parametro: TransparenciaParametro;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private fichaService: FichaReceitaService,
    private exercicioService: ExercicioService,
    protected parametroService: TransparenciaParametroService,
    private previstoService: ReceitaPrevistaService) {
    super(previstoService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  public preencherGrid() {
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.exercicioService.obterPorAno(params['ano'], this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              this.exercicio = res.content[0];
              this.fichaService.planilhaPrevisao(this.paginaCorrente,
                this.limite, this.exercicio.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
                .subscribe((lista) => {
                  this.listaPlanilha = lista.content;
                  this.paginaTotal = lista.totalPages;
                });
            }
          );
      });
  }

  protected relations(): string {
    return 'receita';
  }

  protected condicoesGrid(): {} {
    return { ['exercicio.id']: this.login.exercicio.id };
  }

  protected ordenacaoGrid(): string[] {
    return ['r_numero$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {};
  }

  protected afterInit(): void {
    this.parametroService.obter({ orgao_id: this.login.orgao.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.parametro = res;
        });
  }

  protected acaoRemover(model: ReceitaPrevista): Observable<ReceitaPrevista> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Código', coluna: 'r_codigo' },
      { titulo: 'Descrição', coluna: 'r_nome' },
      { titulo: 'Janeiro', coluna: 'previsto_jan', decimais: 2, alignment: 'right' },
      { titulo: 'Fevereiro', coluna: 'previsto_fev', decimais: 2, alignment: 'right' },
      { titulo: 'Março', coluna: 'previsto_mar', decimais: 2, alignment: 'right' },
      { titulo: 'Abril', coluna: 'previsto_abr', decimais: 2, alignment: 'right' },
      { titulo: 'Maio', coluna: 'previsto_mai', decimais: 2, alignment: 'right' },
      { titulo: 'Junho', coluna: 'previsto_jun', decimais: 2, alignment: 'right' },
      { titulo: 'Julho', coluna: 'previsto_jul', decimais: 2, alignment: 'right' },
      { titulo: 'Agosto', coluna: 'previsto_ago', decimais: 2, alignment: 'right' },
      { titulo: 'Setembro', coluna: 'previsto_set', decimais: 2, alignment: 'right' },
      { titulo: 'Outubro', coluna: 'previsto_out', decimais: 2, alignment: 'right' },
      { titulo: 'Novembro', coluna: 'previsto_nov', decimais: 2, alignment: 'right' },
      { titulo: 'Dezembro', coluna: 'previsto_dez', decimais: 2, alignment: 'right' }
    ];
  }

  public imprimirPDF() {
    this.fichaService
      .planilhaPrevisao(1, 0,
        this.login.exercicio.id, this.login.orgao.id,true
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.imprimir('PREVISÃO INICIAL DE RECEITAS ORÇAMENTÁRIAS',
            this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
            'Listagem previsao receita', ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto',
            'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content, ['previsto_jan', 'previsto_fev', 'previsto_mar', 'previsto_abr', 'previsto_mai', 'previsto_jun',
            'previsto_jul', 'previsto_ago', 'previsto_set', 'previsto_out', 'previsto_nov', 'previsto_dez'],7);
        });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public exportarXLSX() {
    this.fichaService
      .planilhaPrevisao(1, 9999,
        this.login.exercicio.id, this.login.orgao.id
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          const listaItens = new Array();
          for (const item of lista.content) {
            const entity = {
              codigo: item.r_codigo,
              descricao: item.r_nome,
              janeiro: item.previsto_jan,
              fevereiro: item.previsto_fev,
              marco: item.previsto_mar,
              abril: item.previsto_abr,
              maio: item.previsto_mai,
              junho: item.previsto_jun,
              julho: item.previsto_jul,
              agosto: item.previsto_ago,
              setembro: item.previsto_set,
              outubro: item.previsto_out,
              novembro: item.previsto_nov,
              dezembro: item.previsto_dez
            };
            listaItens.push(entity);
          }
          tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('planilha-previsao-receita');
        });
  }

}
