import { DatePipe } from '@angular/common';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContaBancariaService, PagamentoExtraService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, OrgaoAssinaturaService,
  ContaBancaria, ContaBancariaRecurso, DateFormatPipe, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil, PagamentoExtra
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { takeUntil } from 'rxjs/operators';
import { NotaPagamentoExtra } from '../../relatorio-tesouraria/notas/nota-pagamento-extra';
import { EmpenhoExtraService } from '../../../empenho-extra/service/empenho-extra.service';
import { ContaBancariaRecursoService } from '../../conta-bancaria/service/conta-bancaria-recurso.service';
import * as toastr from 'toastr';

@Component({
  selector: 'lib-pagamento-extra-form',
  templateUrl: './pagamento-extra-form.component.html'
})
export class PagamentoExtraFormComponent extends BaseResourceFormComponent<PagamentoExtra, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('InputFocus') private inputField: Calendar;

  public especie: string;
  public liquidado: number;
  public saldoPagar = 0;
  public saldoBancario = 0;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public contaRecursoAutoComplete: EddyAutoComplete<ContaBancariaRecurso>;
  public contaId: number;
  public contaCodigo: number;
  public empenhoId: number;
  public anulacao = false;
  public parcela: number;
  public desabilitar: boolean = true;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected contaService: ContaBancariaService,
    protected contaRecursoService: ContaBancariaRecursoService,
    protected globalService: GlobalService,
    protected empenhoService: EmpenhoExtraService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected pagamentoService: PagamentoExtraService) {
    super(new PagamentoExtra(), injector, PagamentoExtra.converteJson, pagamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: PagamentoExtra): Promise<boolean> {
    return true
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      valor_pago: [null, [Validators.required]],
      valor_retido: [null, [Validators.required]],
      valor_liquido: [null, [Validators.required]],
      mes: [null],
      aux: [0, [Validators.required]],
      data_pagamento: [null, [Validators.required]],
      especie: [null],
      documento: [null, [Validators.required]],
      impresso: [false, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      anulacao: [false, [Validators.required]],
      conta: [null, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      conta_recurso: [null, [Validators.required]],
      retido: [false, [Validators.required]],
      empenho: [null, [Validators.required]],
      usuario_cadastro: [this.login.usuario, [Validators.required]],
      situacao: ['N'],
      pagamento_S13: ['N'],
      valor_RRA: [0],
      juridico_RRA: [null],
      indice_fci_scp: [null],
      cnpj_fci_scp: [null],
      porcentagem_scp: [0],
      pais_pagamento: [105],
      nif_tributo: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta,conta.banco,empenho,empenho.favorecido,exercicio,orgao,' +
        'empenho.ficha,empenho.ficha.recurso,empenho.ficha.aplicacao,empenho.ficha.aplicacao_variavel,' +
        'empenho.retencao.liquidacao.pagamentos,empenho.retencao_resto,empenho.retencao_extra,' +
        'conta_recurso,conta_recurso.recurso,conta_recurso.aplicacao,conta_recurso.convenio'
    };
  }

  protected async afterLoad() {
    if (this.entidade.id) {
      this.entidadeForm.get('valor_liquido').setValue(this.entidade.empenho.valor_empenho)
    }
    if (!this.podeAlterarAudesp(this.entidade.data_pagamento)) {
      this.router.navigate(['/pagamentos-extras']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }
    this.entidade.data_pagamento = new DateFormatPipe().transform(this.entidade.data_pagamento, []);
    if (this.currentActionRoute === 'editar') {
      this.anulacao = this.entidade.anulacao;
      this.contaId = this.entidade.conta.id;
      this.contaCodigo = this.entidade.conta.codigo;
      this.empenhoId = this.entidade.empenho.numero;
      this.buscarTotalEmpenhado(this.entidade.empenho.numero, this.entidade.empenho.parcela);
      this.buscarSaldoBancario();
      this.buscarContaRecurso(this.contaId);
    } else {
      this.inicializarVariavel();
    }
  }

  private inicializarVariavel() {
    if (this.currentActionRoute === 'novo') {
      this.entidade.valor_retido = 0;
      this.parcela = 0;
      this.entidade.impresso = false;
      this.entidade.historico = 'PAGAMENTO EXTRA EFETUADO NO DIA';
      this.entidade.exercicio = this.login.exercicio;
      this.entidade.orgao = this.login.orgao;
      this.contaId = this.entidade.conta ? this.entidade.conta.id : null;
      this.contaCodigo = this.entidade.conta ? this.entidade.conta.codigo : null;
      this.pagamentoService.ultimaDataPagamento(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dataLiq) => {
          if (dataLiq === 0) {
            this.entidade.data_pagamento = new Date();
          } else {
            this.entidade.data_pagamento = new DateFormatPipe().transform(dataLiq, []);
          }
          this.entidadeForm.get('data_pagamento').setValue(this.entidade.data_pagamento);
          this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_pagamento)?.split('-')?.[1]);
        });
    }
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.inicializarVariavel();
  }

  protected campoFoco(): ElementRef {
    return this.inputField.inputfieldViewChild.nativeElement.focus();
  }

  protected beforeSubmit() {
    try {
      const dataPagamento = new Date(this.entidadeForm.get('data_pagamento').value);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidadeForm.get('data_pagamento').value)?.split('-')?.[1]);
      this.entidadeForm.get('aux').setValue(0);
      this.entidadeForm.get('especie').setValue(!this.anulacao ? 'PGE' : 'PEA');
      this.entidadeForm.get('anulacao').setValue(this.anulacao);

      if (this.anulacao) {
        if (this.entidadeForm.get('valor_pago').value >= 0) {
          throw new Error('Valor anulado deve ser negativo!');
        }
      }
      if (dataPagamento > new Date()) {
        throw new Error('Data do pagamento está maior que a data atual');
      }
      if (this.entidade.empenho?.data_empenho > dataPagamento) {
        throw new Error('Data de pagamento tem que ser maior ou igual a data do empenho!');
      }
      if ((+this.saldoBancario - +this.entidadeForm.get('valor_pago').value) < 0) {
        throw new Error('Saldo de banco insuficiente para efetuar o pagamento.');
      }
      const valor_liquidado = +this.entidadeForm.get('valor_pago').value - +this.entidadeForm.get('valor_retido').value;
      if ((+this.saldoPagar - +(valor_liquidado - (this.entidade.valor_pago ? this.entidade.valor_pago : 0.0))) < 0) {
        throw new Error('Não há saldo para efeturar o pagto. Verifique se o empenho já está pago ou foi cancelado!');
      }
      // if ((this.entidade.empenho?.retencao?.liquidacao?.pagamentos.length === 0 || this.entidade.empenho?.retencao_resto?.liquidacao?.pagamentos.length === 0) && !this.anulacao) {
      //   throw new Error('Pagamento do empenho origem não realizado, necessário realizar o pagamento do empenho origem antes do empenho extra-orçamentário.');
      // }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  protected afterSubmit(entidade: PagamentoExtra): void {
    // Método para executar depois que salvar
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public buscarEmpenho(event: any) {
    if (this.empenhoId > 0) {
      this.empenhoService.extendido(1, 1, {
        numero: this.empenhoId,
        parcela: this.parcela,
        'orgao.id': this.login.orgao.id,
        'exercicio.id': this.login.exercicio.id,
        relations: 'favorecido,ficha,exercicio,orgao,ficha.recurso,ficha.aplicacao,retencao.liquidacao.pagamentos.pagamento_anulado,retencao_resto.liquidacao.pagamentos.pagamento_anulado'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res) => {
            const [model] = res.content;
            if (model) {
              this.entidade.empenho = model;
              this.entidadeForm.get('empenho').setValue(model);
              this.entidadeForm.get('historico').setValue(`PAGTO EMP. ${model.numero} ${model.favorecido.nome}`);
              this.buscarTotalEmpenhado(this.entidade.empenho.numero, this.entidade.empenho.parcela);
              this.estaParcelado();
            } else {
              this.messageService.add({ severity: 'warn', summary: 'Informação', detail: 'Empenho não foi encontrado!' });
            }
          }, (error) => this.messageService.add({ severity: 'error', summary: 'Erro', detail: error }));
    }
  }

  public buscarConta(event: any) {
    if (this.contaCodigo > 0) {
      this.contaService.obter({ codigo: this.contaCodigo, 'orgao.id': this.login.orgao.id, relations: 'banco' }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data) {
              if (data.ativo) {
                this.contaId = data.id;
                this.entidadeForm.get('conta').setValue(data);
                this.carregarAutoCompletesContaRecurso();
                this.buscarSaldoBancario();
                this.buscarContaRecurso(this.contaId, data);
                if (!this.login.fonte_recurso_pagamento) {
                  this.desabilitar = false;
                }
              } else {
                this.contaCodigo = null;
                toastr.error('Conta bancária inativa!');
                return;
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Erro', detail: error })
        );
    }
  }

  public changeConta() {
    if (this.entidadeForm.get('conta')) {
      const conta = this.entidadeForm.get('conta').value;
      this.contaId = conta.id;
      this.contaCodigo = conta.codigo;
      this.carregarAutoCompletesContaRecurso();
      this.buscarSaldoBancario();
      if (!this.login.fonte_recurso_pagamento) {
        this.desabilitar = false;
      }
    }
  }

  private carregarAutoCompletes() {
    // autocomplete para conta
    this.contaAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta'), this.contaService,
      'id', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.login.orgao.id, relations: 'banco', orderBy: 'nome' }, { number: ['numero_conta'], text: ['nome'] },
      this.buscarSaldoBancario()
    );
    this.carregarAutoCompletesContaRecurso();
  }

  private carregarAutoCompletesContaRecurso() {
    let parametros = {}
    parametros = { 'conta.codigo': this.contaCodigo, relations: 'recurso,aplicacao,convenio', orderBy: 'aplicacao.codigo' };

    if (this.login.fonte_recurso_pagamento) {
      parametros = { 'conta.codigo': this.contaCodigo, 'excluido': false, relations: 'recurso,aplicacao,convenio' };
    }

    this.contaRecursoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_recurso'), this.contaRecursoService,
      'id', ['recurso.codigo', 'aplicacao.codigo', 'aplicacao.nome'], parametros,
      { number: ['aplicacao.codigo'], text: ['aplicacao.nome'] },
      this.buscarContaRecurso(this.contaId)
    );
  }

  public buscarSaldoBancario() {
    if (this.contaId > 0) {
      let datepipe: DatePipe = new DatePipe('pt');
      const dataPagamento = this.entidadeForm.get('data_pagamento').value ? datepipe.transform(this.entidadeForm.get('data_pagamento').value, 'yyyy-MM-dd', 'GMT') :
        datepipe.transform(this.entidade.data_pagamento, 'yyyy-MM-dd', 'GMT');
      this.contaService.obterSaldoBancarioConta(this.contaId, this.login.exercicio.id, this.login.orgao.id, dataPagamento).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (saldoTotal: any) => {
            this.saldoBancario = saldoTotal;
            this.buscarSaldoRecurso();
            // this.buscarContaRecurso(this.contaId);
          }, error => this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarSaldoRecurso() {
    // calculo do saldo de banco por recurso
    if (this.contaCodigo > 0) {
      let parametros = {}
      parametros = { 'conta.codigo': this.contaCodigo, relations: 'recurso,aplicacao,convenio', 'conta.orgao.id': this.login.orgao.id };

      if (this.login.fonte_recurso_pagamento) {
        parametros = { 'conta.codigo': this.contaCodigo, 'excluido': false, relations: 'recurso,aplicacao,convenio', 'conta.orgao.id': this.login.orgao.id };
      }

      this.contaRecursoService.obter(parametros).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res: any) => {
            if (res) {
              this.carregarAutoCompletesContaRecurso();
              if (!this.entidadeForm.get('conta_recurso').value) {
                this.entidadeForm.get('conta_recurso').setValue(res);
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public estaParcelado() {
    this.empenhoService.obterUltimaParcela(
      this.entidade.empenho.numero, this.entidade.empenho.exercicio ? this.entidade.empenho.exercicio.id : this.login.exercicio.id, this.entidade.empenho.orgao ? this.entidade.empenho.orgao.id : this.login.orgao.id
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          if (!this.entidade.id && +res > 1 && +this.entidade.parcela === 0) {
            this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Empenho esta parcelado, informe o número da parcela para pagamento!' });
            this.limparCampos();
          }
        }, error => this.messageService.add({ severity: 'error', summary: 'Erro', detail: error })
      );
  }

  private buscarContaRecurso(id: number, contaBancaria?: ContaBancaria) {
    if (this.entidade.empenho && this.entidade.empenho.ficha && !this.login.fonte_recurso_pagamento) {
      this.contaRecursoService.obterRecursoPorCodigo(id, this.entidade.empenho.ficha.recurso.codigo, this.entidade.empenho.ficha.aplicacao.codigo,
        this.entidade.empenho.ficha.aplicacao_variavel ? this.entidade.empenho.ficha.aplicacao_variavel.codigo : null)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            if (data.content[0]) {
              this.entidadeForm.get('conta_recurso').setValue(data.content[0]);
            } else {
              if (contaBancaria) {
                this.confirmationService.confirm({
                  message: `Deseja cadastrar o recurso vincular ${this.entidade.empenho.ficha.recurso.codigo}.${this.entidade.empenho.ficha.aplicacao.codigo} ${this.entidade.empenho.ficha.aplicacao_variavel ? '.' + this.entidade.empenho.ficha.aplicacao_variavel.codigo : ''} para esta conta bancária ?`,
                  header: `Conta bancária sem recurso cadastrado`,
                  icon: 'pi pi-exclamation-triangle',
                  acceptLabel: 'Sim',
                  rejectLabel: 'Não',
                  key: 'contarecurso',
                  accept: async () => {
                    const cr = new ContaBancariaRecurso();
                    cr.recurso = this.entidade.empenho.ficha.recurso;
                    cr.aplicacao = this.entidade.empenho.ficha.aplicacao;
                    cr.convenio = this.entidade.empenho.ficha.aplicacao_variavel;
                    cr.conta = contaBancaria;
                    cr.recurso_pagto = false;
                    this.contaRecursoService.inserir(cr).subscribe(res => {
                      this.entidadeForm.get('conta_recurso').setValue(res);
                      this.carregarAutoCompletesContaRecurso();
                    });
                  }
                });
              }
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  public buscarTotalEmpenhado(numero: number, parcela: number) {
    if (this.empenhoId > 0) {
      this.empenhoService.obterTotalEmpenhadoPorNumero(numero, parcela, this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res: any) => {
            this.liquidado = res.empenhado;
            this.saldoPagar = +res.empenhado - res.pago_total
            if (!this.entidade.id) {
              if (+this.liquidado <= 0) {
                this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Empenho extra já esta pago ou foi anulado, favor verificar!' });
                this.limparCampos();
                return;
              }
              if (+this.saldoPagar === 0) {
                this.messageService.add({ severity: 'error', summary: 'Atenção', detail: 'Empenho extra já esta pago, favor verificar!' });
                this.limparCampos();
                return;
              }

              if (!this.entidade.retido) {
                this.entidadeForm.get('valor_retido').setValue(+res.retido - +res.pago_retencao);
              }
              this.entidadeForm.get('valor_liquido').setValue((+res.empenhado - res.pago_total) - (+res.retido - res.pago_retencao));
              this.entidadeForm.get('valor_pago').setValue(this.saldoPagar);
            } else {
              this.entidadeForm.get('valor_pago').setValue(this.entidade.valor_pago);
              this.entidadeForm.get('valor_retido').setValue(this.entidade.valor_retido);
              this.entidadeForm.get('valor_liquido').setValue(this.entidade.valor_pago - this.entidade.valor_retido);
            }
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
        );
    }
  }

  private limparCampos() {
    this.parcela = 0;
    this.empenhoId = null;
    this.liquidado = null;
    this.saldoBancario = 0;
    this.saldoPagar = 0;
    this.entidade.empenho = null;
    this.entidadeForm.get('empenho').setValue(null);
    this.entidadeForm.get('valor_pago').setValue(0);
    this.entidadeForm.get('valor_liquido').setValue(0);
    this.entidadeForm.get('valor_retido').setValue(0);
    this.entidadeForm.get('historico').setValue('');
    this.campoFoco();
  }

  imprimir() {
    const parametros = {};

    let relations = 'empenho.contrato.licitacao.modalidade,empenho.ficha.aplicacao,empenho.ficha.recurso,empenho.ficha.aplicacao_variavel,empenho.contrato,empenho.favorecido.contas.banco,empenho.';
    relations += 'convenio,empenho.retencao.liquidacao.empenho.favorecido,empenho.retencao.liquidacao.empenho';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidade.id;

    this.pagamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        new NotaPagamentoExtra(this.assinaturaService).imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }
}
