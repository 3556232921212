import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent, Coluna, Filtro, FormatoExportacao, LoginContabil, ReservaDotacao } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReservaDotacaoService } from '../service/reserva-dotacao.service';

declare var $: any;

@Component({
  selector: 'app-reserva-dotacao-list',
  templateUrl: './reserva-dotacao-list.component.html'
})
export class ReservaDotacaoListComponent extends BaseResourceListComponent<ReservaDotacao, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public usarExtendido: boolean;
  public listaOrgaos: Array<any>;
  public listaOpcoes: Array<any>;
  public listaTipos: Array<any>;
  public tipo: { id: number, nome: string };
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private reservaService: ReservaDotacaoService) {
    super(reservaService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(_entidade: ReservaDotacao): boolean {
    return this.login?.sistema !== 'compras'
  }

  protected relations(): string {
    return 'licitacao,licitacao.modalidade,itens,itens.ficha,itens.ficha.aplicacao,itens.ficha.aplicacao_variavel,itens.ficha.recurso,itens.ficha.executora,itens.ficha.executora.unidade';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;
    if (this.tipo) {
      parametros['tipo_reserva'] = this.tipo.id;
    }
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['id$DESC'];
  }

  public beforeInit() {
    this.usarExtendido = true;
    this.usarFiltroPersonalizado = true;
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['processo', 'observacao', 'licitacao.objeto'],
      date: ['data_adjudicacao', 'data_cancelamento'],
      number: ['id', 'itens.valor_reservado', 'itens.valor_empenhado', 'itens.valor_adjudicacao', 'itens.ficha.numero', 'total_empenhado', 'total_reservado', 'total_adjudicacao']
    };
  }

  protected afterInit(): void {
    this.listaTipos = [
      { nome: 'PRÉ EMPENHO', id: '0' },
      { nome: 'SEM PRÉ EMPENHO', id: '1' }
    ];
  }

  protected acaoRemover(model: ReservaDotacao): Observable<ReservaDotacao> {
    return null;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    const colunas: Coluna[] = [];

    colunas.push({ titulo: 'Id.', coluna: 'id' });
    colunas.push({ titulo: 'Processo', coluna: 'processo_reserva' });
    colunas.push({ titulo: 'Tipo', coluna: 'tipo_reserva', funcao: [{
      se: {
        condicao: 'tipo_reserva=1',
        verdadeiro: ['\'SEM PRÉ EMPENHO\''],
        falso: ['\'PRÉ EMPENHO\'']
      }
    }] });
    colunas.push({ titulo: 'Total reservado', coluna: 'total_reservado', decimais: 2, alignment: 'right' });
    colunas.push({ titulo: 'Total empenhado', coluna: 'total_empenhado', decimais: 2, alignment: 'right' });
    colunas.push({ titulo: 'Total adjudicação', coluna: 'total_adjudicacao', decimais: 2, alignment: 'right' });
    colunas.push({ titulo: 'Objeto', coluna: 'objeto_reserva', alignment: 'left' });

    return colunas;
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    parametros['relations'] = this.relations();
    this.reservaService.extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir(`LISTAGEM DE RESERVAS DOTAÇÃO: EXERCÍCIO - ${this.login.exercicio.ano}`,
              this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem reserva orçamentaria', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*'], lista.content);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  public abrirDlgImpressao() {
    $('#rptReservaDotacao').modal('show');
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public reload() {
    this.preencherGrid();
  }

  public obterColunasFiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];

    // retorno.push({ titulo: 'ID', coluna: 'id', alignment: 'center', tipo: 'Number', padrao: true, cols: 3 });
    // retorno.push({ titulo: 'Processo', coluna: 'processo', tipo: 'String', alignment: 'center', padrao: true, cols: 3, mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }] });
    // retorno.push({ titulo: 'Processo de licitação', coluna: 'licitacao.processo', tipo: 'String', alignment: 'center', padrao: true, cols: 3 });
    // retorno.push({ titulo: 'Total reservado', coluna: 'total_reservado', tipo: 'Number', cols: 3, padrao: true });
    // retorno.push({ titulo: 'Total empenhado', coluna: 'total_empenhado', alignment: 'center', tipo: 'Number', cols: 3, padrao: true });
    // retorno.push({ titulo: 'Total adjudicação', coluna: 'total_adjudicacao', alignment: 'center', tipo: 'Number', cols: 3 });
    // retorno.push({ titulo: 'Licitação objeto', coluna: 'licitacao.objeto', alignment: 'center', tipo: 'String', cols: 3 });
    // retorno.push({ titulo: 'Objeto', coluna: 'observacao', alignment: 'center', tipo: 'String', cols: 3 });
    // retorno.push({ titulo: 'Ficha nº', coluna: 'itens.ficha.numero', alignment: 'center', tipo: 'Number', cols: 3, padrao: true });

    retorno.push({ titulo: 'Número reserva', coluna: 'id', tipo: 'Number', padrao: true });
    retorno.push({ titulo: 'Processo número', coluna: 'processo', tipo: 'String', alignment: 'center', padrao: true, cols: 3, mask: [{ mask: '0000/0000' }, { mask: '00000/0000' }] });
    retorno.push({ titulo: 'Orgão', coluna: 'orgao.nome', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Unidade Executora', coluna: 'itens.ficha.executora.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Número da ficha', coluna: 'itens.ficha.numero', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Valor reservado', coluna: 'itens.valor_reservado', alignment: 'center', tipo: 'Number' });
    retorno.push({ titulo: 'Número da despesa', coluna: 'itens.ficha.despesa.codigo', alignment: 'center', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Fonte de recurso', coluna: 'itens.ficha.recurso.codigo', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Aplicação', coluna: 'itens.ficha.aplicacao.codigo', tipo: 'String', cols: 3 });
    retorno.push({ titulo: 'Aplicação variável', coluna: 'itens.ficha.aplicacao_variavel.variavel', tipo: 'String', cols: 4 });
    // retorno.push({ titulo: 'Tipo do empenho', coluna: 'tipo_reserva', tipo: 'Selection', opcoesSelecao: [{ valor: '1', label: 'Pré empenho' }, { valor: '0', label: 'Sem pré emepenho' }] });
    retorno.push({ titulo: 'Ativo', coluna: 'ativo', tipo: 'Boolean', cols: 1 });
    retorno.push({ titulo: 'Inativo', coluna: 'ativo$ne', tipo: 'Boolean', cols: 1 });
    retorno.push({ titulo: 'Data', coluna: 'itens.data_reserva', tipo: 'Date', padrao: true });

    return retorno;
  }
}
