import { DatePipe } from '@angular/common';
import * as extenso from 'extenso';
import { FuncaoService, Login, Relatorio, GlobalService, Liquidacao, OrgaoAssinaturaService, Retencao } from 'eddydata-lib';
import JsBarcode from 'jsbarcode';

export class NotaLiquidacao {

  protected datepipe: DatePipe;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;
  private ordenador_despesa: string;
  private cargo_ordenador_despesa: string;
  private ordenador_despesa_funcao_08: string;
  private cargo_ordenador_despesa_funcao_08: string;
  private ordenador_despesa_funcao_10: string;
  private cargo_ordenador_despesa_funcao_10: string;
  private ordenador_despesa_funcao_12: string;
  private cargo_ordenador_despesa_funcao_12: string;
  private contador: string;
  private cargo_contador: string;
  private tesoureiro: string;
  private cargo_tesoureiro: string;

  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.datepipe = new DatePipe('pt');
  }

  public async imprimir(model: Liquidacao[], login: Login) {
    this.log = login;

    // const assinatura = await this.assinaturaService.obter({
    //   orgao_id: this.log.orgao.id,
    //   'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    // }).toPromise();
    // if (assinatura) {
    //   this.ordenador_despesa = assinatura.ordenador_despesa;
    //   this.cargo_ordenador_despesa = assinatura.cargo_ordenador_despesa;

    //   this.ordenador_despesa_funcao_08 = assinatura.ordenador_despesa_funcao_08;
    //   this.cargo_ordenador_despesa_funcao_08 = assinatura.cargo_ordenador_despesa_funcao_08;

    //   this.ordenador_despesa_funcao_10 = assinatura.ordenador_despesa_funcao_10;
    //   this.cargo_ordenador_despesa_funcao_10 = assinatura.cargo_ordenador_despesa_funcao_10;

    //   this.ordenador_despesa_funcao_12 = assinatura.ordenador_despesa_funcao_12;
    //   this.cargo_ordenador_despesa_funcao_12 = assinatura.cargo_ordenador_despesa_funcao_12;

    //   this.tesoureiro = assinatura.tesoureiro;
    //   this.cargo_tesoureiro = assinatura.cargo_tesoureiro;

    //   this.contador = assinatura.contador;
    //   this.cargo_contador = assinatura.cargo_contador;
    // }

    Relatorio.imprimirPersonalizado('NOTA DE LIQUIDAÇÃO', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      await this.montarConteudo(model),
      'portrait', 'NOTA LIQUIDAÇÃO',
      {
        linhas: {
          hLineWidth(i, node) {
            return 1;
          },
          vLineWidth(i) {
            return 1;
          },
          hLineColor(i) {
            return 'black';
          },
          paddingLeft(i) {
            return 3;
          },
          paddingRight(i, node) {
            return 3;
          }
        }
      }, true);
  }

  private async montarConteudo(lista: Liquidacao[]) {
    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const barCode = `018170${this.funcaoService.strZero((+entidade.valor_liquidado).toFixed(2), 12).split('.').join('')}` +
        `${this.log.orgao.codigo.substring(0, 4)}${this.funcaoService.converteDataSQL(new Date(entidade.data_liquidacao)).split('-').join('')}` +
        `${this.funcaoService.strZero(entidade.empenho.numero, 10)}${this.funcaoService.strZero(entidade.parcela, 3)}0090`;
      JsBarcode(canvas, String(barCode), { displayValue: false, height: 70, margin: 0 });
      conteudo.push(this.dadosCabecalho(this.log, entidade)
        .concat(this.dadosEmpenho(entidade))
        .concat(this.dadosClassificacao(entidade, lista))
        .concat(this.dadosFavorecido(entidade))
        .concat(this.dadosHistorico(entidade))
        .concat(this.dadosBanco(entidade))
        .concat(await this.dadosAssinatura(this.log, entidade))
        .concat(this.dadosRecibo()).concat([{
          image: canvas.toDataURL(),
          fit: [250, 70], margin: [10, 5]
        }]));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, dados: Liquidacao): {}[] {
    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 60,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        border: [true, true, true, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '' };
    }
    const conteudo = [
      [brasaoImage],
      [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false] }],
      [{ text: `${log.orgao.endereco} ${log.cidade?.nome} ${log.cidade?.estado?.nome} CNPJ: ${log.orgao.cnpj}`, alignment: 'center', fontSize: 8, border: [true, false, true, false] }],
      [{
        text: dados.anulacao ? `ANULAÇÃO NOTA DE LIQUIDAÇÃO N˚ ${this.funcaoService.strZero(dados.id, 5)}` : `NOTA DE LIQUIDAÇÃO N˚ ${this.funcaoService.strZero(dados.id, 5)}`,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }],
      [{
        text: dados.exercicio.ano, bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosEmpenho(dados: Liquidacao): {}[] {
    const tipoEmpenho = this.globalService.obterListaTiposEmpenhos().find(x => x.id === dados.empenho.tipo_empenho);
    const conteudo = [
      [
        {
          text: 'EMPENHO N˚', fontSize: 7, border: [true, true, false, false]
        },
        { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
        { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Data', fontSize: 7, border: [true, true, false, false] },
        {
          text: 'Contrato', fontSize: 7, border: [true, true, false, false],
        },
        {
          text: 'Licitação', fontSize: 7, border: [true, true, false, false],
        },
        {
          text: 'Processo', fontSize: 7, border: [true, true, false, false],
        },
        {
          text: 'Modalidade',
          alignment: 'center', fontSize: 7, bold: true, border: [true, true, true, false],
        }
      ], [
        {
          text: this.funcaoService.strZero(dados.empenho.numero, 4)
            + (dados.parcela > 0 ? '/'
              + this.funcaoService.strZero(dados.parcela, 2) : ''),
          alignment: 'center', fontSize: 10, bold: true, border: [true, false, true, true]
        },
        { text: dados.empenho.ficha.numero, alignment: 'center', bold: true, border: [false, false, false, true] },
        {
          text: tipoEmpenho ? tipoEmpenho.nome : '-',
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: this.datepipe.transform(dados.data_liquidacao, 'dd/MM/yyyy'),
          alignment: 'center', bold: false, border: [true, false, false, true]
        },
        {
          text: dados.empenho.contrato ? dados.empenho.contrato.numero : '-',
          alignment: 'center', bold: false, border: [true, false, false, true]
        },
        {
          text: dados.empenho.licitacao ? dados.empenho.licitacao.numero : '-',
          alignment: 'center', bold: false, border: [true, false, false, true]
        },
        {
          text: dados.empenho.processo, alignment: 'center', bold: false, border: [true, false, false, true]
        },
        {
          text: dados.empenho.modalidade ? dados.empenho.modalidade.nome : '-',
          alignment: 'center', bold: false, border: [true, false, true, true]
        },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [80, 40, 60, 50, 50, 50, 50, '*'],
        body: conteudo
      }
    }];
  }

  private dadosClassificacao(dados: any, lista: any): {}[] {
    let saldo = 0;
    let valorLiquidar = 0;

    valorLiquidar = +dados.total_liq_parcela
    saldo = +dados.total_empenhado - +dados.total_liq_parcela;
    const conteudo = [
      [
        { text: 'UNIDADE', border: [true, false, false, false] },
        {
          text: `${dados.empenho.ficha.executora.unidade.codigo} ${dados.empenho.ficha.executora.unidade.nome} `,
          border: [false, false, true, false]
        },
        { text: 'EMPENHADO', border: [true, false, false, false], fontSize: 7 },
        {
          text: this.funcaoService.convertToBrNumber(dados.total_empenhado),
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'EXECUTORA', border: [true, false, false, false] },
        {
          text: `${dados.empenho.ficha.executora.codigo} ${dados.empenho.ficha.executora.nome} `,
          border: [false, false, true, false]
        },
        { text: 'LIQUIDADO', border: [true, false, false, false], fontSize: 7 },
        {
          text: this.funcaoService.convertToBrNumber(+valorLiquidar),
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'NATUREZA', border: [true, false, false, false] },
        { text: `${dados.empenho.ficha.despesa.codigo} ${dados.empenho.ficha.despesa.nome} `, border: [false, false, true, false] },
        { text: 'SALDO A LIQUIDAR', border: [true, false, false, false], fontSize: 7 },
        {
          text: this.funcaoService.convertToBrNumber(+saldo),
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'SUBELEMENTO', border: [true, false, false, false] },
        { text: `${dados.empenho.subelemento.codigo} ${dados.empenho.subelemento.nome} `, border: [false, false, true, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'FUNCIONAL', border: [true, false, false, false] },
        { text: `${dados.empenho.ficha.funcao.codigo} ${dados.empenho.ficha.funcao.nome} `, border: [false, false, true, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'AÇÃO', border: [true, false, false, false] },
        { text: `${dados.empenho.ficha.acao.codigo} ${dados.empenho.ficha.acao.nome} `, border: [false, false, true, false] },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'RECURSO', border: [true, false, false, true] },
        {
          text: `${dados.empenho?.ficha?.aplicacao_variavel?.codigo ? dados.empenho?.ficha?.aplicacao_variavel?.codigo : dados.empenho.ficha.recurso?.codigo + '' + dados.empenho?.ficha?.aplicacao?.codigo} ${dados.empenho?.ficha?.aplicacao_variavel?.nome ? dados.empenho?.ficha?.aplicacao_variavel?.nome : dados.empenho?.ficha?.aplicacao?.nome} `,
          border: [false, false, false, true]
        },
        { text: '', border: [true, false, false, true] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [60, '*', 60, 100],
        body: conteudo
      }
    }];
  }

  private dadosFavorecido(dados: Liquidacao): {}[] {
    let bancoPrincipal = dados.empenho.favorecido.contas?.find((e) => e.principal && this.log.orgao?.id === e.orgao?.id);
    console.log(dados, bancoPrincipal)

    const conteudo = [
      [
        { text: 'DEVERÁ SER PAGO À:', colSpan: 3, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ], [
        {
          text: `${dados.empenho.favorecido.nome}`, colSpan: 3,
          fontSize: 11, bold: true, border: [true, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, false, false] },
        { text: `CÓDIGO: ${dados.empenho.favorecido?.id}`, bold: true, border: [false, false, true, false] },
      ], [
        { text: 'CPF/CNPJ', border: [true, false, false, false] },
        {
          text: `${dados.empenho.favorecido.cpf_cnpj}`, border: [false, false, false, false]
        },
        { text: 'TIPO PESSOA', border: [false, false, false, false] },
        { text: dados.empenho.favorecido?.tipo?.nome, border: [false, false, false, false] },
        { text: `BANCO: ${bancoPrincipal?.banco.nome ? bancoPrincipal?.banco.nome : ''}`, border: [false, false, true, false] },
      ], [
        { text: 'ENDEREÇO', border: [true, false, false, false] },
        {
          // tslint:disable-next-line: max-line-length
          text: `${dados.empenho.favorecido.endereco}, ${dados.empenho.favorecido.num_endereco}`,
          border: [false, false, false, false]
        },
        { text: 'BAIRRO:', border: [false, false, false, false] },
        { text: dados.empenho.favorecido.bairro, border: [false, false, false, false] },
        { text: `AGÊNCIA: ${bancoPrincipal?.agencia ? bancoPrincipal?.agencia : ''}`, border: [false, false, true, false] },
      ], [
        { text: 'CIDADE', border: [true, false, false, true] },
        { text: `${dados.empenho.favorecido.municipio}, ${dados.empenho.favorecido.uf} `, border: [false, false, false, true] },
        { text: 'TELEFONE', border: [false, false, false, true] },
        { text: `(${dados.empenho.favorecido.ddd_fone ? dados.empenho.favorecido.ddd_fone : ''}) ${dados.empenho.favorecido.telefone ? dados.empenho.favorecido.telefone : ''}`, border: [false, false, false, true] },
        { text: `Nº CONTA: ${bancoPrincipal?.numero_conta ? bancoPrincipal?.numero_conta : ''}`, border: [false, false, true, true] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 60, 100, '*'],
        body: conteudo
      }
    }];
  }

  private dadosHistorico(dados: Liquidacao): {}[] {
    const conteudo = [
      [{ text: 'PROVENIENTE DE:', border: [true, false, true, false] }],
      [{
        text: `${dados.historico}`, border: [true, false, true, true], fontSize: dados?.historico?.length > 415 ? 6 : 8
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 15],
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosBanco(dados: any): {}[] {
    const conteudo = [
      [
        {
          text: '',
          bold: true, border: [true, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: 'LIQUIDADO:', border: [true, false, false, false] },
        {
          text: this.funcaoService.convertToBrNumber((+dados.valor_liquidado + +dados.total_anulado)),
          bold: true, fontSize: 9, alignment: 'right', border: [false, false, true, false]
        },
      ], [
        { text: 'VENCIMENTO:', border: [true, false, false, false] },
        {
          text: this.datepipe.transform(dados.data_vencimento, 'dd/MM/yyyy'), border: [false, false, false, false]
        },
        { text: 'TOTAL RETIDO:', border: [true, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(dados.total_retido), fontSize: 9, alignment: 'right', border: [false, false, true, false] }
      ], [
        { text: 'DOCUMENTO:', border: [true, false, false, true], margin: [0, 0, 0, 10] },
        {
          text: dados.documento, border: [false, false, false, true], margin: [0, 0, 0, 10]
        },
        { text: 'VALOR A PAGAR', border: [true, false, false, true], margin: [0, 4, 0, 10] },
        {
          text: this.funcaoService.convertToBrNumber((+dados.valor_liquidado + +dados.total_anulado) - +dados.total_retido),
          bold: true, fontSize: 13, alignment: 'right', border: [false, false, true, true]
        },
      ], [
        {
          text: `Fica liquidado a importância de: \n ${extenso(this.funcaoService.convertToBrNumber(dados.valor_liquidado),
            { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase()}`, margin: [0, 10, 0, 0], colSpan: 2, rowSpan: 1, border: [true, false, true, false]
        },
        { text: '', border: [false, false, false, false] },
        {
          layout: `noBorders`,
          table: {
            // style: `noBorders`,
            widths: [110, 'auto', '*'],
            body: [
              [{ text: 'Retenções', bold: true, fontSize: 10, border: [true, false, true, false] }, '', ''],
              ...this.montarConteudoRetencoes(dados?.retencoes)
            ]
          }, border: [false, false, false, true], rowSpan: 3
        },
        { text: '', border: [false, false, true, false] }
      ], [
        {
          text: '', border: [true, false, false, false], margin: [0, 0, 0, 10],
        },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, false, true] },
        { text: '', border: [false, false, true, true] },
        { text: '', border: [false, false, false, true] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 60, 100],
        body: conteudo
      }
    }];
  }

  private async dadosAssinatura(log: Login, dados: Liquidacao): Promise<{}[]> {
    let ordenador = '';
    let cargoOrdenador = '';
    const funcao = dados.empenho?.ficha?.funcao?.codigo;

    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date(dados.data_liquidacao))),
      orderBy: 'data_limite'
    }).toPromise();
    if (assinatura) {
      this.ordenador_despesa = assinatura.ordenador_despesa;
      this.cargo_ordenador_despesa = assinatura.cargo_ordenador_despesa;

      this.ordenador_despesa_funcao_08 = assinatura.ordenador_despesa_funcao_08;
      this.cargo_ordenador_despesa_funcao_08 = assinatura.cargo_ordenador_despesa_funcao_08;

      this.ordenador_despesa_funcao_10 = assinatura.ordenador_despesa_funcao_10;
      this.cargo_ordenador_despesa_funcao_10 = assinatura.cargo_ordenador_despesa_funcao_10;

      this.ordenador_despesa_funcao_12 = assinatura.ordenador_despesa_funcao_12;
      this.cargo_ordenador_despesa_funcao_12 = assinatura.cargo_ordenador_despesa_funcao_12;

      this.tesoureiro = assinatura.tesoureiro;
      this.cargo_tesoureiro = assinatura.cargo_tesoureiro;

      this.contador = assinatura.contador;
      this.cargo_contador = assinatura.cargo_contador;
    }

    switch (funcao) {
      case '12':
        ordenador = this.ordenador_despesa_funcao_12;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_12;
        break;
      case '10':
        ordenador = this.ordenador_despesa_funcao_10;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_10;
        break;
      case '08':
        ordenador = this.ordenador_despesa_funcao_08;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_08;
        break;
      default:
        ordenador = this.ordenador_despesa;
        cargoOrdenador = this.cargo_ordenador_despesa;
    }

    if (!ordenador || !cargoOrdenador) {
      ordenador = this.ordenador_despesa;
      cargoOrdenador = this.cargo_ordenador_despesa;
    }

    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO PAGAMENTO', border: [true, false, false, false] },
        { text: '', border: [false, false, false, false], margin: [0, 20, 0, 10] },
        { text: '', border: [false, false, true, false], margin: [0, 20, 0, 10] },
        { text: 'BANCO:', border: [false, false, true, false], margin: [10, 15, 0, 0] },
      ], [
        { text: '______________________________________', border: [true, false, false, false], margin: [10, 15, 0, 0] },
        { text: '______________________________________', border: [false, false, false, false], margin: [10, 15, 0, 0] },
        { text: '', border: [false, false, true, false], margin: [10, 0, 0, 0] },
        { text: 'CHEQUE:', border: [false, false, true, false], margin: [10, 0, 0, 0] },
      ], [
        { text: this.tesoureiro ? this.tesoureiro : '', fontSize: 7, border: [true, false, false, false], margin: [10, 0, 0, 0] },
        { text: ordenador, fontSize: 7, border: [false, false, false, false], margin: [10, 0, 0, 0] },
        { text: '', fontSize: 7, border: [false, false, true, false], margin: [10, 0, 0, 0] },
        { text: 'RECURSO:', border: [false, false, true, false], margin: [10, 0, 0, 0] },
      ], [
        { text: this.cargo_tesoureiro ? this.cargo_tesoureiro : '', border: [true, false, false, false], margin: [10, 0, 0, 0] },
        { text: cargoOrdenador, fontSize: 7, border: [false, false, false, false], margin: [10, 0, 0, 0] },
        { text: '', fontSize: 7, border: [false, false, true, false], margin: [10, 0, 0, 0] },
        { text: '', border: [false, false, true, false] }
      ], [
        { text: '', border: [true, false, false, true], margin: [10, 0, 0, 30] },
        { text: '', fontSize: 7, border: [false, false, false, true], margin: [10, 0, 0, 30] },
        { text: '', fontSize: 7, border: [false, false, true, true], margin: [10, 0, 0, 30] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: [160, 160, 5, '*'],
        body: conteudo
      }
    }];
  }

  private dadosRecibo(): {}[] {
    const conteudo = [
      [
        { text: 'RECIBO E QUITAÇÃO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, true, false], },
      ], [
        {
          text: 'Recebemos o valor a que se refere este empenho, ao qual damos plena e geral quitação.',
          border: [true, false, false, false], colSpan: 2, margin: [0, 0, 20, 0]
        },
        { text: '', border: [false, false, false, false], },
        { text: 'Assinatura', border: [false, false, false, false], },
        { text: 'Documento', border: [false, false, true, false], },
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, true, true], },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  montarConteudoRetencoes(retencoes: Retencao[]) {
    const linhas = [];
    if (!retencoes) return linhas;
    for (const retencao of retencoes) {
      const linha = [
        { text: `${retencao.ficha.nome}`, border: [true, false, false, false], colspan: 2 },
        '',
        { text: `${this.funcaoService.convertToBrNumber(+retencao.valor_retido)}`, alignment: 'right', border: [true, false, true, false] }
      ]
      linhas.push(linha)
    }
    return linhas;
  }

}
