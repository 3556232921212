import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DividaFornecedorListComponent } from './divida-fornecedor-list/divida-fornecedor-list.component';
import { AuthGuard } from 'eddydata-lib';

const routes: Routes = [
  { path: '', component: DividaFornecedorListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DividaFornecedorRoutingModule { }
