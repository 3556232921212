import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContratoService, DespesaService, EmpenhoService, FuncaoGovernoService, RecursoService } from 'administrativo-lib';
import { FichaDespesaService } from 'contabil-lib';
import { Contrato, Despesa, EddyAutoComplete, Executora, ExecutoraService, ExercicioService, Favorecido, FavorecidoService, FichaDespesa, FuncaoGoverno, FuncaoService, GlobalService, LoginContabil, ProgressoService, Recurso, Relatorio } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import * as toastr from 'toastr';
import { EmpenhosSubElemento } from './relatorios-empenho-rpt/empenhos-sub-elemento';
import { EmpenhosNaoLiquidados } from './relatorios-empenho-rpt/EmpenhosNaoLiquidados';
import { ProgramaFinanceiraService } from 'projects/contabil/src/app/planejamento/relatorio-gerenciais/service/programacao-financeira.service';

@Component({
  selector: 'lib-empenho-rpt',
  templateUrl: './empenho-rpt.component.html'
})
export class EmpenhoRptComponent implements OnInit, OnDestroy {

  public login: LoginContabil = new LoginContabil();
  public funcaoGoverno: FuncaoGoverno;
  public subFuncaoGoverno: FuncaoGoverno;
  public despesa: Despesa;
  public recurso: Recurso;
  public aplicacao: Recurso;
  public contrato: Contrato;
  public favorecido: Favorecido;
  public fichaInicial: FichaDespesa;
  public fichaFinal: FichaDespesa;
  public unidade: Executora;
  public filtroDespesa: boolean;
  public filtroFuncao: boolean;
  public filtroSubFuncao: boolean;
  public filtroRecurso: boolean;
  public filtroAplicacao: boolean;
  public filtroContrato: boolean;
  public filtroFavorecido: boolean;
  public filtroFichaInicialFinal: boolean;
  public filtroUnidade: boolean;
  public orcados: boolean = true;
  public anulados: boolean = true;
  public selectedOrdem: string;
  public selectUsuario: boolean;
  public selectSemLicitacao: boolean;
  public ptBR: any;
  public relatorio = '1';
  public dataInicial: Date;
  public dataFinal: Date;
  public mesSelecionado: string;
  public opcao: string = '1';
  public filtroPeriodoOf: boolean
  public dataOfInicial: Date;
  public dataOfFinal: Date;
  public filtroEmpenhoNumero: boolean;
  public numeroInicio: number;
  public numeroFinal: number;
  public filtroTipoEmpenho: boolean;
  public documento: boolean = false;
  public tipoEmpenhoSelecionado: string;
  public selectAgrupamento: string = "1";
  public listaTipos: { id: string, nome: string }[];
  public listMeses: { id: string, nome: string }[];
  public listaRelatorio: Array<any>;
  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public funcaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public subFuncaoAutoComplete: EddyAutoComplete<FuncaoGoverno>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public despesaAutoComplete: EddyAutoComplete<Despesa>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public fichaDespesaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public unidadeAutoComplete: EddyAutoComplete<Executora>;
  protected unsubscribe: Subject<void> = new Subject();
  filtroFicha: boolean;
  public ficha: FichaDespesa;

  constructor(
    private router: Router,
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected funcaoGovernoService: FuncaoGovernoService,
    protected recursoService: RecursoService,
    protected despesaService: DespesaService,
    protected contratoService: ContratoService,
    protected favorecidoService: FavorecidoService,
    protected fichaDespesaService: FichaDespesaService,
    private executoraService: ExecutoraService,
    protected empenhoService: EmpenhoService,
    protected progFinanceiraService: ProgramaFinanceiraService,
    protected progressoService: ProgressoService,
    protected exercicioService: ExercicioService
  ) { }

  public ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.login = GlobalService.obterSessaoLogin();
    this.selectedOrdem = 'ord1';
    this.selectUsuario = false;
    this.selectSemLicitacao = false;
    this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
    this.dataFinal = new Date();
    this.listaRelatorio = [];
    this.listaRelatorio.push({ label: 'EMPENHOS ORÇAMENTÁRIOS', value: 1 });
    this.listaRelatorio.push({ label: 'EMPENHOS ORÇAMENTÁRIOS ANULADOS', value: 2 });
    this.listaRelatorio.push({ label: 'EMPENHOS ORÇAMENTÁRIOS EM ABERTO - NÃO PAGO', value: 3 });
    this.listaRelatorio.push({ label: 'EMPENHOS ORÇAMENTÁRIOS POR SUB-ELEMENTO', value: 4 });
    this.listaRelatorio.push({ label: 'EMPENHOS ORÇAMENTÁRIOS NÃO LIQUIDADOS', value: 5 });
    this.carregarAutoCompletes();
    let interval = setInterval(() => { new GlobalService().calendarMascara(); clearInterval(interval) }, 1000);
    this.listMeses = this.globalService.obterListaMeses();
    this.listaTipos = [];
    this.listaTipos.push(
      { id: 'G', nome: 'Geral' },
      { id: 'O', nome: 'Ordinário' },
      { id: 'E', nome: 'Estimativa ou Global' }
    );
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public parametrosRelatorio(): {} {
    const parametros = {};

    parametros['relations'] = 'favorecido,subelemento,ficha,ficha.funcao,ficha.subfuncao,ficha.recurso,ficha.aplicacao,ficha.aplicacao_variavel,ficha.programa,ficha.acao,ficha.executora';
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao.id'] = this.login.orgao.id;

    if (this.opcao === '1') {
      if (this.dataInicial && this.dataFinal) {
        if (this.relatorio === '3') {
          parametros['data_empenho$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
          parametros['data_fim'] = this.funcaoService.converteDataSQL(this.dataFinal);
        } else {
          parametros['data_empenho$ge'] = this.funcaoService.converteDataSQL(this.dataInicial);
          parametros['data_empenho$le'] = this.funcaoService.converteDataSQL(this.dataFinal);
        }
      } else {
        toastr.warning(`Informe o período!`);
        throw new Error(`Informe o período!`);
      }
    } else {
      if (!this.mesSelecionado) {
        toastr.warning(`Selecione o mês desejado!`);
        throw new Error(`Selecione o mês desejado!`);
      }
      if (this.relatorio === '3') {
        parametros['mes$le'] = this.mesSelecionado;
      } else {
        parametros['mes'] = this.mesSelecionado;
      }
    }

    if (this.filtroContrato) {
      parametros['contrato.numero'] = this.contrato?.numero;
    }
    if (this.filtroRecurso) {
      parametros['ficha.recurso.id'] = this.recurso?.id;
    }
    if (this.filtroAplicacao) {
      parametros['ficha.aplicacao.id'] = this.aplicacao?.id;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro.id'] = this.login.usuario.id;
    }
    if (this.selectSemLicitacao) {
      parametros['OR'] = `licitacao.id$null=${true}%;!;!;modalidade$in=${[6, 10]}`
    }
    if (this.filtroFavorecido && this.favorecido) {
      parametros['favorecido.id'] = this.favorecido?.id;
    }
    if (this.filtroFicha) {
      parametros['ficha.id'] = this.ficha?.id;
    }

    if (this.filtroFichaInicialFinal) {
      if (+this.fichaFinal?.numero < +this.fichaInicial?.numero) {
        toastr.warning(`Ficha final informada menor que ficha inicial!`)
        throw new Error(`Ficha final informada menor que ficha inicial!`);
      }

      parametros['ficha.numero$ge'] = this.fichaInicial?.numero;
      parametros['ficha.numero$le'] = this.fichaFinal?.numero;
    }

    if (this.filtroUnidade) {
      parametros['ficha.executora.codigo'] = this.unidade.codigo;
    }
    if (this.filtroFuncao) {
      parametros['ficha.funcao.id'] = this.funcaoGoverno.id;
    }
    if (this.filtroSubFuncao) {
      parametros['ficha.subfuncao.id'] = this.subFuncaoGoverno.id;
    }
    if (this.filtroDespesa) {
      parametros['subelemento.id'] = this.despesa.id;
    }

    if (this.selectedOrdem === 'ord1') {
      parametros['orderBy'] = 'data_empenho';
    } else if (this.selectedOrdem === 'ord2') {
      parametros['orderBy'] = 'numero';
    } else if (this.selectedOrdem === 'ord3') {
      parametros['orderBy'] = 'favorecido.nome';
    } else if (this.selectedOrdem === 'ord4') {
      parametros['orderBy'] = 'ficha.executora.codigo';
    } else if (this.selectedOrdem === 'ord5' && this.relatorio == '4') {
      parametros['orderBy'] = 'ficha.recurso.codigo';
    }

    if (this.filtroPeriodoOf) {
      if (!this.dataOfInicial || !this.dataOfFinal) {
        toastr.warning(`Informe o período da OF!`)
        throw new Error(`Informe o período da OF!`);
      }

      parametros['compra.data_compra$ge'] = this.funcaoService.converteDataSQL(this.dataOfInicial);
      parametros['compra.data_compra$le'] = this.funcaoService.converteDataSQL(this.dataOfFinal);
    }

    if (this.filtroEmpenhoNumero) {
      if (!this.numeroInicio && !this.numeroFinal) {
        toastr.warning(`Informe o numero do empenho!`)
        throw new Error(`Informe o numero do empenho!`)
      }

      if (this.numeroInicio && this.numeroFinal) {
        parametros['numero$ge'] = this.numeroInicio;
        parametros['numero$le'] = this.numeroFinal
      } else if (this.numeroInicio) {
        parametros['numero'] = this.numeroInicio;
      } else if (this.numeroFinal) {
        parametros['numero'] = this.numeroFinal;
      }
    }

    if (this.filtroTipoEmpenho) {
      if (!this.tipoEmpenhoSelecionado) {
        toastr.warning(`Informe o tipo do empenho!`)
        throw new Error(`Informe o tipo do empenho!`);
      }

      parametros['tipo_empenho'] = this.tipoEmpenhoSelecionado;
    }

    if (this.selectAgrupamento === 'ficha.recurso.codigo' || this.selectAgrupamento === 'ficha.executora.codigo') {
      if (parametros?.['orderBy']) {
        parametros['orderBy'] = `${this.selectAgrupamento},` + parametros['orderBy'];
      } else {
        parametros['orderBy'] = this.selectAgrupamento;
      }
    }

    if (this.selectAgrupamento == 'sub_elemento' && this.relatorio == '4') {
      parametros['orderBy'] = 'subelemento.codigo';
    }
    if (this.selectAgrupamento == 'sub_elemento_fornecedor' && this.relatorio == '4') {
      parametros['orderBy'] = 'subelemento.codigo,favorecido.nome';
    }

    return parametros;
  }

  public gerarRelatorio() {
    if (this.opcao === '1' && (!this.dataInicial || !this.dataFinal)) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    const parametros = this.parametrosRelatorio();

    let titulo = '';
    if (this.relatorio === '1') {
      titulo = 'EMPENHOS ORÇAMENTÁRIOS';
      parametros['especie$in'] = 'EMO,EOA';
    } else if (this.relatorio === '2') {
      titulo = 'EMPENHOS ORÇAMENTÁRIOS ANULADOS';
      parametros['especie'] = 'EOA';
    } else if (this.relatorio === '3') {
      titulo = 'EMPENHOS ORÇAMENTÁRIOS EM ABERTO - NÃO PAGO';
      parametros['especie$in'] = 'EMO';
      parametros['mes'];
      parametros['em_aberto'] = true;
    }

    if (this.relatorio === '4') {
      const emp = new EmpenhosSubElemento(this.empenhoService);
      emp.montarRelatorio('pdf', parametros);
    } else if (this.relatorio === '5') {
      const empNaoLiq = new EmpenhosNaoLiquidados(this.progFinanceiraService, this.progressoService, this.funcaoService, this.globalService, this.exercicioService)
      empNaoLiq.montarRelatorio({
        exercicio_id: this.login.exercicio.id,
        orgao_id: this.login.orgao.id,
        data_fim: this.dataFinal ? this.funcaoService.converteDataSQL(this.dataFinal) : null,
        mes: this.mesSelecionado ? +this.mesSelecionado : null,
        funcao_governo: this.filtroFuncao ? +this.funcaoGoverno.id : null,
        recurso: this.filtroRecurso ? +this.recurso.id : null,
        aplicacao: this.filtroAplicacao ? +this.aplicacao.id : null,
      }, this.selectAgrupamento, 'pdf');
    } else {
      if (this.selectAgrupamento === '3') {
        parametros['orderBy'] = 'codigo_variavel,nome_variavel,' + parametros['orderBy'];
      }
      this.empenhoService.extendido(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((lista) => {
          if (lista.content.length == 0) {
            toastr.info('Sem itens para imprimir');
            return;
          }
          Relatorio.imprimirPersonalizado(titulo, this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.selectAgrupamento !== "1" ? this.montarConteudoAgrupado(lista.content, this.relatorio) : this.montarConteudo(lista.content, this.relatorio),
            'landscape', titulo,
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false);
        });
    }
  }

  private montarConteudo(lista: any[], relatorio: string) {
    let retorno: {}[] = [];
    let datepipe = new DatePipe('pt');
    const conteudo = [];
    const referencia = [];

    referencia.push({
      text: this.opcao === '1' ? `REFERÊNCIA: ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}` : `REFERÊNCIA: ${this.globalService.obterMes(+this.mesSelecionado)}`,
      alignment: 'center', fontSize: 10, lineHeight: 1.5, colSpan: relatorio === '3' ? this.documento ? 13 : 12 : 9, border: [false, false, false, false]
    }, '', '', '', '', '', '', '', '');

    if (relatorio === '3') {
      this.documento ? referencia.push('') : '';
      referencia.push('');
      referencia.push('');
      referencia.push('');
    }

    conteudo.push(referencia);

    const cabecalho = [
      { text: 'EMPENHO Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
      { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'UNIDADE', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FUNCIONAL', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'DESPESA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] }
    ];

    if (relatorio === '3') {
      if (this.documento) {
        cabecalho.push(
          { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] }
        );
      }
      cabecalho.push(
        { text: 'EMPENHADO\n(a)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'LIQUIDADO\n(b)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'PAGO\n(c)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'ABERTO\n(a-c)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] }
      );
    } else {
      cabecalho.push({ text: 'VALOR', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] });
    }

    conteudo.push(cabecalho);

    let soma = 0;
    let somaEmpenhado = 0;
    let somaLiquidado = 0;
    let somaPago = 0;
    for (const entidade of lista) {
      const registro = [];
      registro.push({
        text: `${this.funcaoService.strZero(entidade.numero, 5)}`,
        fontSize: 8, alignment: 'center', bold: false
      });
      registro.push({ text: datepipe.transform(entidade.data_empenho, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center' });
      registro.push({ text: entidade.favorecido.nome, fontSize: 8 });
      registro.push({ text: `${entidade.ficha.executora.codigo}`, fontSize: 8, alignment: 'center' });
      registro.push({ text: `${entidade.ficha.funcao.codigo}${entidade.ficha.subfuncao.codigo}${entidade.ficha.programa.codigo}.${entidade.ficha.acao.codigo}`, fontSize: 8, alignment: 'center' });
      registro.push({ text: `${entidade.subelemento.codigo.substr(0, 8)}`, fontSize: 8, alignment: 'center' });
      registro.push({ text: entidade.ficha.numero, fontSize: 8, alignment: 'center' });
      registro.push({ text: `${entidade.ficha.recurso.codigo} ${entidade.ficha.aplicacao.codigo}  ${entidade.ficha.aplicacao_variavel ? entidade.ficha.aplicacao_variavel.codigo : ''}`, fontSize: 8, alignment: 'center' });

      if (relatorio === '3') {
        const saldo = (+entidade.total_empenhado - +entidade.total_pago).toFixed(2);
        this.documento ? registro.push({ text: entidade?.liquidacoes[0]?.documento, fontSize: 8, alignment: 'center', bold: false }) : ''
        if (+saldo !== 0) {
          registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_empenhado), fontSize: 8, alignment: 'right', bold: false })
          registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_liquidado), fontSize: 8, alignment: 'right', bold: false })
          registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_pago), fontSize: 8, alignment: 'right', bold: false })
          registro.push({ text: this.funcaoService.convertToBrNumber(+saldo, 2), fontSize: 8, alignment: 'right', bold: false });
          soma += +saldo;
          somaEmpenhado += +entidade?.total_empenhado;
          somaLiquidado += +entidade?.total_liquidado;
          somaPago += +entidade?.total_pago;
          conteudo.push(registro);
        }
      } else {
        registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.valor_empenho, 2), fontSize: 8, alignment: 'right', bold: false });
        soma += +entidade.valor_empenho;
        conteudo.push(registro);
      }
    }

    const total = [];
    total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: relatorio === '3' ? this.documento ? 9 : 8 : 7, margin: [0, 5, 0, 5] });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');

    if (relatorio === '3') {
      this.documento ? total.push('') : '';
      total.push({ text: this.funcaoService.convertToBrNumber(somaEmpenhado, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
      total.push({ text: this.funcaoService.convertToBrNumber(somaLiquidado, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
      total.push({ text: this.funcaoService.convertToBrNumber(somaPago, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
    }

    total.push({ text: this.funcaoService.convertToBrNumber(soma, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
    conteudo.push(total);

    const larguras = (relatorio === '3' ? this.documento ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 80])

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: larguras,
        body: conteudo
      }
    });

    return retorno;
  }

  public exportarXlsx(relatorio: any) {
    if (this.opcao === '1' && (!this.dataInicial || !this.dataFinal)) {
      this.messageService.add({ severity: 'warn', summary: 'Mensagem', detail: 'Informe a data inicial e final!' });
      return;
    }

    const parametros = this.parametrosRelatorio();

    let titulo = '';
    if (this.relatorio === '1') {
      titulo = 'EMPENHOS ORÇAMENTÁRIOS';
      parametros['especie$in'] = 'EMO,EOA';
    } else if (this.relatorio === '2') {
      titulo = 'EMPENHOS ORÇAMENTÁRIOS ANULADOS';
      parametros['especie'] = 'EOA';
    } else if (this.relatorio === '3') {
      titulo = 'EMPENHOS ORÇAMENTÁRIOS EM ABERTO - NÃO PAGO';
      parametros['especie$in'] = 'EMO';
      parametros['mes'];
      parametros['em_aberto'] = true;
    } else if (this.relatorio === '4') {
      const emp = new EmpenhosSubElemento(this.empenhoService);
      emp.montarRelatorio('xlsx', parametros)
    } else if (this.relatorio === '5') {
      const empNaoLiq = new EmpenhosNaoLiquidados(this.progFinanceiraService, this.progressoService, this.funcaoService, this.globalService, this.exercicioService)
      empNaoLiq.montarRelatorio({
        exercicio_id: this.login.exercicio.id,
        orgao_id: this.login.orgao.id,
        data_fim: this.dataFinal ? this.funcaoService.converteDataSQL(this.dataFinal) : null,
        mes: this.mesSelecionado ? +this.mesSelecionado : null
      }, this.selectAgrupamento, 'xlsx');
    }

    if (this.relatorio !== '4' && this.relatorio !== '5') {
      let datepipe = new DatePipe('pt');
      this.empenhoService.extendido(1, -1, parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          lista => {
            if (lista.content.length === 0) {
              toastr.info('Sem itens para gerar Excel');
              return;
            }
            const listaItens = new Array();
            for (const item of lista.content) {
              const entity = {
                'EMPENHO Nº': this.funcaoService.strZero(item.numero, 5),
                'DATA': datepipe.transform(item.data_empenho, 'dd/MM/yyyy'),
                'FAVORECIDO': item.favorecido.nome,
                'UNIDADE': item.ficha.executora.codigo,
                'FUNCIONAL': `${item.ficha.funcao.codigo}${item.ficha.subfuncao.codigo}${item.ficha.programa.codigo}.${item.ficha.acao.codigo}`,
                'DESPESA': item.subelemento.codigo.substr(0, 8),
                'FICHA': item.ficha.numero,
                'RECURSO': `${item.ficha.recurso.codigo} ${item.ficha.aplicacao.codigo}  ${item.ficha.aplicacao_variavel ? item.ficha.aplicacao_variavel.codigo : ''}`
              };

              if (relatorio === '3') {
                const saldo = (+item.total_empenhado - +item.total_pago).toFixed(2);
                this.documento ? entity['DOCUMENTO'] = item?.documento : ''
                if (+saldo !== 0) {
                  entity['EMPENHADO'] = this.funcaoService.convertToBrNumber(+item.total_empenhado, 2);
                  entity['LIQUIDADO'] = this.funcaoService.convertToBrNumber(+item.total_liquidado, 2);
                  entity['PAGO'] = this.funcaoService.convertToBrNumber(+item.total_pago, 2);
                  entity['ABERTO'] = this.funcaoService.convertToBrNumber(+saldo, 2);
                  listaItens.push(entity);
                }
              } else {
                entity['VALOR'] = this.funcaoService.convertToBrNumber(+item.valor_empenho, 2);
                listaItens.push(entity);
              }
            }
            tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile(titulo);
          },
          () => alert('erro ao retornar lista')
        );
    }
  }

  private carregarAutoCompletes() {
    // autocomplete para funcao governo
    this.funcaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para subfuncao governo
    this.subFuncaoAutoComplete = new EddyAutoComplete(null, this.funcaoGovernoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para despesa
    this.despesaAutoComplete = new EddyAutoComplete(null, this.despesaService,
      'id', ['codigo', 'nome'], { nivel: 6, exercicio_id: this.login.exercicio.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
    );

    // autocomplete para contrato
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      'id', ['numero', 'favorecido.nome'], {
        orgao_id: this.login.orgao.id, relations: 'favorecido',
        orderBy: 'favorecido.nome'
      }, { number: ['numero'], text: ['favorecido.nome'] }
    );

    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );

    // autocomplete para ficha despesa
    this.fichaDespesaAutoComplete = new EddyAutoComplete(null, this.fichaDespesaService,
      'numero', ['ficha.numero', 'ficha.despesa.nome'],
      {
        exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id,
        relations: 'despesa,recurso,aplicacao,aplicacao_variavel', orderBy: 'despesa.nome'
      },
      { number: ['numero'] })

    // autocomplete para unidade (executora)
    this.unidadeAutoComplete = new EddyAutoComplete(null, this.executoraService,
      'codigo', ['nome'], { 'unidade.ppa_id': this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] });
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  public changeOpcao() {
    if (this.opcao === '1') {
      this.mesSelecionado = undefined;
      this.dataInicial = new Date(this.login.exercicio.ano, 0, 1);
      this.dataFinal = new Date();
    } else {
      this.dataInicial = null;
      this.dataFinal = null;
      this.mesSelecionado = undefined;
    }

    setTimeout(() => {
      new GlobalService().calendarMascara();
    }, 100);
  }

  private montarConteudoAgrupado(lista: any[], relatorio: string) {
    const camposAgrupamento = this.selectAgrupamento === 'ficha.recurso.codigo'
      ? ['ficha.recurso.codigo', 'ficha.recurso.nome']
      : this.selectAgrupamento === '3'
        ? ['codigo_variavel', 'nome_variavel']
        : ['ficha.executora.codigo', 'ficha.executora.nome'];

    const agrupamento = this.funcaoService.agrupar(lista, camposAgrupamento);
    let retorno: {}[] = [];
    let datepipe = new DatePipe('pt');

    const conteudo = [];
    const referencia = [];

    referencia.push({
      text: this.opcao === '1' ? `REFERÊNCIA: ${datepipe.transform(this.dataInicial, 'dd/MM/yyyy')} à ${datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}` : `REFERÊNCIA: ${this.globalService.obterMes(+this.mesSelecionado)}`,
      alignment: 'center', fontSize: 10, lineHeight: 1.5, colSpan: relatorio === '3' ? this.documento ? 13 : 12 : 9, border: [false, false, false, false]
    }, '', '', '', '', '', '', '', '');

    if (relatorio === '3') {
      this.documento ? referencia.push('') : '';
      referencia.push('');
      referencia.push('');
      referencia.push('');
    }

    conteudo.push(referencia);

    const cabecalho = [
      { text: 'EMPENHO Nº', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true], margin: [0, 10, 0, 10] },
      { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FAVORECIDO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'UNIDADE', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FUNCIONAL', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'DESPESA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
      { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] }
    ];

    if (relatorio === '3') {
      if (this.documento) {
        cabecalho.push(
          { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        );
      }
      cabecalho.push(
        { text: 'EMPENHADO\n(a)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'LIQUIDADO\n(b)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'PAGO\n(c)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] },
        { text: 'ABERTO\n(a-c)', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] }
      );
    } else {
      cabecalho.push({ text: 'VALOR', fontSize: 8, alignment: 'center', bold: true, margin: [0, 10, 0, 10] });
    }

    conteudo.push(cabecalho);

    let soma = 0;
    let somaEmpenhado = 0;
    let somaLiquidado = 0;
    let somaPago = 0;
    for (const grupo of agrupamento) {
      const agrupamento = [];
      const registros = []
      agrupamento.push({
        text: `${grupo.grupo[camposAgrupamento[0]]} ${grupo.grupo[camposAgrupamento[1]]}`,
        colSpan: relatorio === '3' ? this.documento ? 11 : 10 : 8, fontSize: 10, bold: true, margin: [0, 5, 0, 5]
      });

      if (relatorio === '3') {
        this.documento ? agrupamento.push('', '', '', '', '', '', '', '', '', '', '', '') : agrupamento.push('', '', '', '', '', '', '', '', '', '', '')
      } else {
        agrupamento.push('', '', '', '', '', '', '', '');
      }

      let empenhado: number = 0;
      let liquidado: number = 0;
      let pago: number = 0;
      for (const entidade of grupo.registros) {
        const registro = [];
        registro.push({
          text: `${this.funcaoService.strZero(entidade.numero, 5)}`,
          fontSize: 8, alignment: 'center', bold: false
        });
        registro.push({ text: datepipe.transform(entidade.data_empenho, 'dd/MM/yyyy'), fontSize: 8, alignment: 'center' });
        registro.push({ text: entidade.favorecido.nome, fontSize: 8 });
        registro.push({ text: `${entidade.ficha.executora.codigo}`, fontSize: 8, alignment: 'center' });
        registro.push({ text: `${entidade.ficha.funcao.codigo}${entidade.ficha.subfuncao.codigo}${entidade.ficha.programa.codigo}.${entidade.ficha.acao.codigo}`, fontSize: 8, alignment: 'center' });
        registro.push({ text: `${entidade.subelemento.codigo.substr(0, 8)}`, fontSize: 8, alignment: 'center' });
        registro.push({ text: entidade.ficha.numero, fontSize: 8, alignment: 'center' });
        registro.push({ text: `${entidade.ficha.recurso.codigo} ${entidade.ficha.aplicacao.codigo}  ${entidade.ficha.aplicacao_variavel ? entidade.ficha.aplicacao_variavel.variavel : ''}`, fontSize: 8, alignment: 'center' });

        if (relatorio === '3') {
          const saldo = (+entidade.total_empenhado - +entidade.total_pago).toFixed(2);
          this.documento ? registro.push({ text: entidade?.liquidacoes[0]?.documento, fontSize: 8, alignment: 'center' }) : ''
          if (+saldo !== 0) {
            empenhado += +entidade?.total_empenhado;
            liquidado += +entidade?.total_liquidado;
            pago += +entidade?.total_pago;
            registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_empenhado), fontSize: 8, alignment: 'right', bold: false })
            registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_liquidado), fontSize: 8, alignment: 'right', bold: false })
            registro.push({ text: this.funcaoService.convertToBrNumber(entidade?.total_pago), fontSize: 8, alignment: 'right', bold: false })
            registro.push({ text: this.funcaoService.convertToBrNumber(+saldo, 2), fontSize: 8, alignment: 'right', bold: false });
            soma += +entidade.total_empenhado - +entidade.total_pago;
            somaEmpenhado += +entidade.total_empenhado;
            somaLiquidado += +entidade.total_liquidado;
            somaPago += +entidade.total_pago;

            registros.push(registro);
          }
        } else {
          registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.valor_empenho, 2), fontSize: 8, alignment: 'right', bold: false });
          soma += +entidade.valor_empenho;
          empenhado += +entidade?.total_empenhado;
          registros.push(registro);
        }
      }

      if (relatorio === '3') {
        const saldo = +empenhado - +pago;
        if (saldo > 0) {
          const registro = [
            { text: 'SOMA', colSpan: 8, fontSize: 9, bold: true },
            '', '', '', '', '', '', '',
            { text: this.funcaoService.convertToBrNumber(+empenhado), fontSize: 9, alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(+liquidado), fontSize: 9, alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(+pago), fontSize: 9, alignment: 'right', bold: true },
            { text: this.funcaoService.convertToBrNumber(saldo, 2), fontSize: 9, alignment: 'right', bold: true },
          ];
          registros.push(registro);
        }
      }

      if (relatorio === '1') {
          const registro = [
            { text: 'SOMA', colSpan: 8, fontSize: 9, bold: true },
            '','','','','','','',
            { text: this.funcaoService.convertToBrNumber(+empenhado), fontSize: 9, alignment: 'right', bold: true },
          ];
          registros.push(registro); 
      }

      if (registros?.length > 0) {
        conteudo.push(agrupamento);
        registros.forEach((arrayRegistro) => conteudo.push(arrayRegistro));
      }
    }

    const total = [];
    total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: relatorio === '3' ? this.documento ? 9 : 8 : 7, margin: [0, 5, 0, 5] });
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');
    total.push('');

    if (relatorio === '3') {
      this.documento ? total.push('') : ''
      total.push({ text: this.funcaoService.convertToBrNumber(somaEmpenhado, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
      total.push({ text: this.funcaoService.convertToBrNumber(somaLiquidado, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
      total.push({ text: this.funcaoService.convertToBrNumber(somaPago, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
    }

    total.push({ text: this.funcaoService.convertToBrNumber(soma, 2), fontSize: 8, alignment: 'right', bold: true, margin: [0, 5, 0, 5] });
    conteudo.push(total);

    const larguras = (relatorio === '3' ? this.documento ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 80])

    retorno.push({
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: larguras,
        body: conteudo
      }
    });

    return retorno;
  }
}

