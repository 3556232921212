import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ContratoService, EmpenhoService, LicitacaoService, MemorialService } from 'administrativo-lib';
import { BaseResourceFormComponent, Contrato, Empenho, FuncaoService, LicitacaoAudesp, Login, LoginContabil, Pagina, RegistroPrecos, TipoArquivoFase4, TipoObjetoLicitacao, Ajuste } from 'eddydata-lib';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/api';
import { CompraItemService } from '../../../compra/service/compra-item.service';
import { CompraTabelaDesconto } from '../../../compra/service/compra-tabela-desconto.service';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';

declare var $: any;

@Component({
  selector: 'lib-licitacao-audesp-form-ajuste',
  templateUrl: './licitacao-audesp-form-ajuste.component.html'
})
export class LicitacaoAudespFormAjusteComponent extends BaseResourceFormComponent<LicitacaoAudesp, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public registroPreco: LicitacaoAudesp;
  public tipoAjuste: TipoArquivoFase4 = 'AJUSTE_CONTRATO';
  public tipoObjeto: TipoObjetoLicitacao = 'COMPRA_SERVICO';
  public opcoesTipoAjuste: { id: TipoArquivoFase4, nome: string }[];
  public contrato: Contrato;
  public empenho: Empenho;
  public mensagemXML: string[];
  public xmlInvalido: string;

  private isRegPreco: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private funcaoService: FuncaoService,
    protected licitacaoService: LicitacaoService,
    protected confirmationService: ConfirmationService,
    protected contratoService: ContratoService,
    protected empenhoService: EmpenhoService,
    protected compraItemService: CompraItemService,
    protected memorialService: MemorialService,
    protected compraTabelaDescontoService: CompraTabelaDesconto,
    protected audespService: LicitacaoAudespService,
  ) {
    super(new LicitacaoAudesp(), injector, LicitacaoAudesp.converteJson, audespService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      tipo: ['AJUSTE_CONTRATO', [Validators.required]],
      arquivo: [null],
      parente: [null],
      codigo: [null],
      exportado: [false, [Validators.required]],
      enviado: [false, [Validators.required]],
      armazenado: [false, [Validators.required]],
      licitacao: [null, [Validators.required]],
      pacote: [null],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'licitacao.modalidade,pacote,licitacao.itens.produto_unidade.produto,parente,licitacao.orgao' };
  }

  protected afterLoad() {
    this.tipoAjuste = this.entidade.tipo;
    this.entidade.arquivo = JSON.parse(this.entidade.arquivo);
    this.tratarDatas('pt-br');
  }

  protected afterInit(): void {
    this.opcoesTipoAjuste = [
      { id: 'AJUSTE_CONTRATO', nome: 'Contrato' },
      { id: 'AJUSTE_EMPENHO', nome: 'Nota de Empenho' },
    ]

    this.validarIsRegPreco();
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(async params => {
        if (params['registroPreco']) {
          this.audespService.obter({ id: +params['registroPreco'], relations: 'licitacao.modalidade,licitacao.contratos,licitacao.orgao' }).pipe(takeUntil(this.unsubscribe))
            .subscribe(async (dados) => {

              dados.licitacao.itens = (await this.memorialService.extendido(1, -1, { 'licitacao_id': dados.licitacao.id, relations: 'produto_unidade.produto' }).toPromise()).content;

              this.registroPreco = dados;
              this.tipoObjeto = (JSON.parse(this.registroPreco.arquivo) as RegistroPrecos)?.tipoObjeto;
              this.entidadeForm.get('licitacao').setValue(this.registroPreco.licitacao);
              if ((this.registroPreco.licitacao.modalidade.codigo === 5 || this.registroPreco.licitacao.modalidade.codigo === 6)) {
                if (this.registroPreco?.licitacao?.contratos?.length === 0) {
                  this.tipoAjuste = 'AJUSTE_EMPENHO'
                  if (!params['empenho']) {
                    this.empenho = await this.empenhoService.obter({
                      licitacao_id: this.registroPreco.licitacao.id, 'licitacao_audesp_id$null': undefined, ignoreCondObrig: true, orderBy: 'numero',
                      relations: 'ficha.recurso,favorecido,exercicio,licitacao.modalidade,compra,contrato.responsaveis.responsavel'
                    }).toPromise();
                  }
                } else {
                  this.tipoAjuste = this.registroPreco.licitacao.formato_contratacao === 'REGISTRO_PRECO' ? 'AJUSTE_EMPENHO' : 'AJUSTE_CONTRATO';
                  if (this.tipoAjuste === 'AJUSTE_CONTRATO' && !this.contrato?.id) {
                    this.contratoService.obter({ licitacao_id: this.registroPreco.licitacao.id, ignoreCondObrig: true, orderBy: 'numero', 'licitacao_audesp_id$null': undefined, relations: 'favorecido' }).pipe(takeUntil(this.unsubscribe))
                      .subscribe((dados) => this.contrato = dados);
                  } else if (this.tipoAjuste === 'AJUSTE_EMPENHO') {
                    this.empenho = await this.empenhoService.obter({
                      licitacao_id: this.registroPreco.licitacao.id, 'licitacao_audesp_id$null': undefined, ignoreCondObrig: true, orderBy: 'numero',
                      relations: 'ficha.recurso,favorecido,exercicio,licitacao.modalidade,compra,contrato.responsaveis.responsavel'
                    }).toPromise();
                  }
                }
              } else {
                if(!params['id']){
                  this.tipoAjuste = this.registroPreco.licitacao.formato_contratacao === 'REGISTRO_PRECO' ? 'AJUSTE_EMPENHO' : 'AJUSTE_CONTRATO';
                  if (this.contrato?.id && this?.contrato?.tipo !== this.registroPreco.licitacao.formato_contratacao) {
                    this.tipoAjuste = this.contrato.tipo === 'REGISTRO_PRECO' ? 'AJUSTE_EMPENHO' : 'AJUSTE_CONTRATO';
                  }

                }
              }
            });
        }
        if (params['contrato']) {
          this.skipRedirect = true;
          this.contratoService.obter({ id: +params['contrato'], 'licitacao_audesp_id$null': undefined, relations: 'favorecido' }).pipe(takeUntil(this.unsubscribe))
            .subscribe((dados) => {
              let contrato = dados;
              this.contrato = undefined;
              this.contrato = contrato
            });
        }
        if (params['empenho']) {
          this.skipRedirect = true;
          this.tipoAjuste = 'AJUSTE_EMPENHO'
          this.empenho = await this.empenhoService.obter({
            id: +params['empenho'], 'licitacao_audesp_id$null': undefined,
            relations: 'ficha.recurso,favorecido,exercicio,licitacao.modalidade,compra,contrato.responsaveis.responsavel'
          }).toPromise();

          if (this.empenho?.compra?.id) {
            this.empenho.compra.itens = await (await this.compraItemService.filtrar(1, -1, { 'compra.id': this.empenho.compra.id, relations: 'produto_unidade.produto' }).toPromise()).content
            this.empenho.compra.tabelas_desconto = await (await this.compraTabelaDescontoService.filtrar(1, -1, { 'compra.id': this.empenho.compra.id, relations: 'memorial' }).toPromise()).content
          }

          if (this.empenho?.licitacao?.id) {
            this.empenho.licitacao.itens = await (await this.memorialService.filtrar(1, -1, { 'licitacao.id': this.empenho.licitacao.id, relations: 'produto_unidade.produto' }).toPromise()).content
          }
        }
      });

    if (this.currentActionRoute === 'novo') {
      this.atribuirCodigo();
    }
  }

  private validarIsRegPreco() {
    this.route.queryParams.subscribe((params) => {
      this.isRegPreco = params.isRegPreco === 'true';
    });
  }

  protected campoFoco(): ElementRef {
    return null
  }

  public remover() {
    if (!this.entidade.id) return

    this.confirmationService.confirm({
      message: 'Deseja realmente remover o arquivo?',
      header: 'Exclusão',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.audespService.remover(this.entidade.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            toastr.info('Arquivo removido com sucesso!', 'Exclusão');
            this.router.navigate([Pagina["Audesp Fase 4"]]);
          }, (err) => toastr.error(err.error.payload));
      }
    });
  }

  protected beforeSubmit() {
    this.entidadeForm.get('tipo').setValue(this.tipoAjuste);
    this.entidadeForm.get('parente').setValue(this.registroPreco);
    this.tratarDatas('en');
    this.entidadeForm.get('codigo').setValue(this.entidade.codigo)
    this.entidade.arquivo['CodigoAjuste'] = '' + this.entidade.codigo
    this.entidadeForm.get('arquivo').setValue(JSON.stringify(this.entidade.arquivo))

    try {
      let arquivo: Ajuste = this.entidade.arquivo
      if (this.tipoAjuste === 'AJUSTE_EMPENHO') {
        if (!arquivo?.NotaEmpenho[0]?.NumNotaEmpenho || !arquivo?.NotaEmpenho[0]?.NotaEmpenhoDt || !arquivo?.NotaEmpenho[0]?.NotaEmpenhoValor || !arquivo?.NotaEmpenho[0]?.FonteRecursos) {
          throw new Error('Não é possível salvar ajuste de empenho sem informar os dados do Empenho!')
        }
      }
    } catch (error) {
      toastr.warning(error.message)
      throw error
    }
  }

  protected afterSubmit(modelo: LicitacaoAudesp) {
    this.tratarDatas('pt-br');
    if (this.skipRedirect) {
      if (this.limparTela) { // salvar e novo
        this.router.navigate([Pagina["Audesp Fase 4"].substr(1), this.registroPreco.id, 'ajuste', 'novo']);
      } else { // salvar
        this.router.navigate([Pagina["Audesp Fase 4"].substr(1), this.registroPreco.id, 'ajuste', modelo.id, 'editar']);
      }
    } else {
      if (!this.entidade.id) {
        this.router.navigateByUrl(this.audespService.retornarRota().replace(/\/novo/gm, `\/${modelo.id}/editar`));
      }
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================
  private tratarDatas(formato: 'pt-br' | 'en'): void {
    if (typeof this.entidade.arquivo === 'string') {
      this.entidade.arquivo = JSON.parse(this.entidade.arquivo);
    }
    this.converterDatas(this.entidade.arquivo, formato)
  }

  private converterDatas(obj: {}, formato: 'pt-br' | 'en') {
    for (var k in obj) {
      if (k === 'editavel') obj[k] = false
      if (typeof obj[k] == "object" && obj[k] !== null)
        this.converterDatas(obj[k], formato);
      else if (typeof obj[k] === 'string') {
        if (formato === 'en' && obj[k].match(/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}/)) {
          const dtSplit = obj[k].split('/');
          obj[k] = `${dtSplit[2]}-${dtSplit[1]}-${dtSplit[0]}`
        } else if (formato === 'pt-br' && obj[k].match(/\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/)) {
          const dtSplit = obj[k].split('-');
          obj[k] = `${dtSplit[2]}/${dtSplit[1]}/${dtSplit[0]}`
        }
      }
    }
  }

  public async validarXML() {
    this.audespService.validarXML(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((xml) => {
        this.mensagemXML = undefined;
        this.xmlInvalido = undefined;
        toastr.success('Registro validado com sucesso!');
      }, error => {
        this.mensagemXML = String(error.error.payload).split('\n');
        if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXML').modal('show');
      });
  }

  public async gerarXML() {
    this.audespService.gerarXML(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(async (xml) => {
        let numeroContratro = this.entidade.identificacao.split(' ')[1].replace(',', '')
        if (this.entidade.tipo === 'EMPENHO') {
          let parente: LicitacaoAudesp[] = (await this.audespService.filtrar(1, -1, { id: this.entidade.id, relations: 'parente' }).toPromise()).content
          let contratos = parente.map(item => item.parente.identificacao.split(' ')[1])
          numeroContratro = contratos.join('_').replace(',', '')
        } else if (this.entidade.tipo === 'AJUSTE_EMPENHO') {
          numeroContratro = this.entidade.arquivo.NumContrato
            ? this.entidade.arquivo.NumContrato
            : (await this.empenhoService.filtrar(1, -1, { id: this.entidade.arquivo.NotaEmpenho[0].empenhoId, relations: 'contrato' }).toPromise()).content[0]?.contrato?.numero
        }
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(xml)));
        element.setAttribute('download', `${this.entidade.tipo}_${numeroContratro ?? this.entidade.arquivo?.NotaEmpenho[0]?.NumNotaEmpenho?.split('/').join('')}.xml`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.mensagemXML = undefined;
        this.xmlInvalido = undefined;
      }, error => {
        this.mensagemXML = String(error.error.payload).split('\n');
        if (this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))) {
          this.xmlInvalido = this.mensagemXML.splice(this.mensagemXML.indexOf(this.mensagemXML.find(str => str.startsWith('ARQUIVO_XML:'))), 1)[0];
          this.xmlInvalido = this.xmlInvalido.substring('ARQUIVO_XML:'.length, this.xmlInvalido.length);
        }
        $('#dialogMensagensXML').modal('show');
      });
  }

  public baixarXMLInvalido() {
    if (!this.xmlInvalido) return;
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/xml;charset=ISO-8859-1,' + encodeURIComponent(this.funcaoService.formatXml(this.xmlInvalido)));
    element.setAttribute('download', `${this.funcaoService.removerPontos(this.entidade.licitacao.numero)}_${this.entidade.tipo}_INVALIDO.xml`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public atribuirCodigo() {
    this.audespService.proximoCodigo('AJUSTE', this.login.exercicio.ano, this.login.orgao.id).subscribe(res => {
      if (res) this.entidade.codigo = String(res);
    })
  }

  public atualizarStatus(tipo: 'ENVIADO' | 'ARMAZENADO', valor: boolean) {
    if (!this.entidade?.arquivo?.NotaEmpenho || this.entidade?.arquivo?.NotaEmpenho.length < 1) {
      toastr.warning(`Ajustes sem notas de empenho não podem ser armazenados ou enviados!`)
      return
    }
    this.audespService
      .atualizarStatus(this.entidade.id, tipo, valor)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        toastr.success('Status do arquivo atualizado com sucesso!');
        location.reload()
      }, error => toastr.error(error.message ? error.message : error))
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (!url) {
        url = this.baseResourceService.retornarRota();
      }
      return this.acessoPagina(this.login, url)
    }
    return false;
  }

  public acessoPagina(login: Login, url: string, visualizar?: boolean) {
    if (login) {
      if (this.funcaoService.campoJsonToken(login.token, "administrador") == true) {
        return true;
      }
      // url = url.replace('/solicitacao', '-solicitacao')
      if (
        url.lastIndexOf("/") > 0 &&
        (url.includes("editar") ||
          url.includes("visualizar") ||
          url.includes("novo"))
      ) {
        const idx = url.indexOf(
          "/".concat(
            url.includes("editar")
              ? "editar"
              : url.includes("visualizar")
                ? "visualizar"
                : "novo"
          )
        );
        url = url.substring(0, idx);
        if (url.substring(1, url.length).lastIndexOf("/") > 0) {
          url = url.substring(
            0,
            url.substring(1, url.length).lastIndexOf("/") + 1
          );
        }
      }
      for (const acesso of login.acessos) {
        if (login.sistema === 'licitacao' && acesso.pagina === '/licitacoes-audesp') {
          if (url.lastIndexOf('/') > 1) {
            let verificaURL = url.split('/');
            url = '/' + verificaURL['1'];
          }
        }
        if (url === acesso.pagina) {
          return acesso.permissao === (visualizar ? 1 : 2);
        }
      }
    }
    return false;
  }

  public close(): any[] {
    if (this.isRegPreco && this.registroPreco?.id)
      return ['/licitacoes-audesp', this.registroPreco.id, 'editar'];
    return ['/licitacoes-audesp'];
  }

  protected acaoSucesso(entidade: any) {
    this.afterSubmit(entidade);
    if (this.mensagemSucesso) toastr.success(this.mensagemSucesso);
    if (!this.skipRedirect) {
      let url: string = this.baseResourceService.retornarRota();
      if (this.limparTela) { // salvar e novo
        if (url.includes('novo')) {
          const rota = this.baseResourceService.retornarRota();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([rota]));
        } else {
          this.router.navigate([this.baseResourceService.retornarRota().replace(/[0-9].*\/editar/g, 'novo')]);
        }
      } else { // salvar
        if (url.includes('novo')) {
          let params = '';
          if (url.lastIndexOf('/novo') > 0) {
            params = url.substring(url.indexOf('/novo') + +'/novo'.length);
            url = url.substring(0, url.substring(1, url.length).indexOf('/novo') + 1);
          }

          url = url + '/' + entidade.id + '/editar'

          this.router.navigate([url]);
        }
      }
    }
    this.limparTela = false;
  }
}
