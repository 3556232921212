import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Login } from '../../entidade/login/login';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-paginacao',
  templateUrl: './paginacao.component.html'
})
export class PaginacaoComponent implements OnInit {

  @Input() paginaCorrente: number;
  @Input() paginaTotal: number;

  @Output() paginaAnterior = new EventEmitter();
  @Output() proximaPagina = new EventEmitter();
  @Output() paginaDigitada = new EventEmitter<number>();
  @Output() limiteEvent = new EventEmitter<number>();

  login: Login = new Login();
  @Input() limite: number;

  constructor(
    protected injector: Injector
  ) { }

  ngOnInit() {
    this.login = GlobalService.obterSessaoLogin();
    if (!this.limite)
      this.limite = +sessionStorage.getItem(
        `${this.injector.get(Router).url}_limite`
      ) || 20
  }

  clickPaginaAnterior() {
    this.paginaAnterior.emit();
  }

  clickProximaPagina() {
    this.proximaPagina.emit();
  }

  clickPaginaCorrente() {
    if (this.paginaCorrente > this.paginaTotal)
      this.paginaCorrente = this.paginaTotal;
    this.paginaDigitada.emit(this.paginaCorrente);
  }

  limiteLista() {
    this.limiteEvent.emit(this.limite);
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 == c2;
      }
    } else {
      return false;
    }
  }

  administrador() {
    return new FuncaoService().campoJsonToken(this.login.token, 'administrador') == true;
  }
}
