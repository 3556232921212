import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArquivoTransparencia, BaseResourceListComponent, Coluna, Filtro, GlobalService, GrupoArquivoTransparencia, LoginContabil } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tsXLXS } from 'ts-xlsx-export';
import { ArquivoTransparenciaService } from './service/arquivo-transparencia.service';
import { GrupoArquivoTransparenciaService } from './service/grupo-arquivo-transparencia.service';
import { TransparenciaStorageService } from './service/transparencia-storage.service';

import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-transparencia-arquivo',
  templateUrl: './transparencia-arquivo.component.html',
  styleUrls: ['./transparencia-arquivo.component.css']
})
export class TransparenciaArquivoComponent extends BaseResourceListComponent<ArquivoTransparencia, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public ptBR: any;
  public especie: string;
  public datepipe: DatePipe;

  public listaGrupos: Array<GrupoArquivoTransparencia> = [];
  public listaAnexos = new Array<any>();

  public entidade = new ArquivoTransparencia();
  public grupo = new GrupoArquivoTransparencia();
  public grupoAtualizado: GrupoArquivoTransparencia;
  public grupoAnterior: GrupoArquivoTransparencia;
  public uploadedFiles: any[] = [];

  public exibirFooter: boolean = true;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private messageService: MessageService,
    private globalService: GlobalService,
    private anexoService: ArquivoTransparenciaService,
    private grupoService: GrupoArquivoTransparenciaService,
    private transparenciaStorageService: TransparenciaStorageService) {
    super(anexoService, injector);
    this.route.params.pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        this.especie = params['especie'];
      });
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected relations(): string {
    return 'grupo.orgao';
  }

  protected condicoesGrid(): {} {
    const parametros = {};
    this.datepipe = new DatePipe('pt');
    parametros['ignoreCondObrig'] = true;
    parametros['grupo.orgao.id'] = this.login.orgao.id;
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    return ['data_anexo'];
  }

  protected filtrosGrid(): Filtro {
    return {
      number: ['referencia', 'tamanho'],
      text: ['descricao', 'nome', 'tipo', 'referencia', 'grupo.nome']
    };
  }

  public beforeInit(): void {
    this.entidade.grupo = new GrupoArquivoTransparencia();
  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
    this.preencherGrupos();
  }

  protected acaoRemover(model: ArquivoTransparencia): Observable<ArquivoTransparencia> {
    return null;
  }

  public obterParametros() {
    const parametros = super.obterParametros();
    parametros['relations'] = this.relations();

    switch (this.col) {
      case 1:
        parametros['orderBy'] = `referencia$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 2:
        parametros['orderBy'] = `descricao$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 3:
        parametros['orderBy'] = `nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 4:
        parametros['orderBy'] = `grupo.nome$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      case 5:
        parametros['orderBy'] = `tamanho$${this.ascendente ? 'ASC' : 'DESC'}`;
        break;
      default:
        break;
    }
    return parametros;
  }

  public preencherGrid() {
    const parametros = this.obterParametros();
    this.anexoService
      .filtrar(
        this.paginaCorrente,
        this.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = lista.content;
          this.paginaTotal = lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
    this.transparenciaStorageService
      .filtrar(
        this.paginaCorrente,
        this.limite,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          this.lista = this.lista.concat(lista.content);
          this.paginaTotal += lista.totalPages;
        },
        () => alert('erro ao retornar lista')
      );
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Nome', coluna: 'nome' },
      { titulo: 'Descrição', coluna: 'descricao' },
      { titulo: 'Referência', coluna: 'referencia' },
      { titulo: 'Tamanho', coluna: 'tamanho' },
      { titulo: 'Grupo', coluna: 'grupo.nome' },
      { titulo: 'Caminho', coluna: 'caminho' }
    ];
  }

  public imprimirPDF() {
    this.imprimir('LISTAGEM DE ANEXOS',
      this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, 'landscape',
      'Listagem anexos', ['auto', '*', 'auto', 'auto', 'auto', 'auto'], this.lista);
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public exportarXLSX() {
    const listaItens = new Array();
    for (const item of this.lista) {
      const entity = {
        nome: item.nome,
        descricao: item.descricao,
        tamanho: item.tamanho,
        referencia: item.referencia,
        grupo: item.grupo.nome,
        caminho: item.caminho
      };
      listaItens.push(entity);
    }
    tsXLXS().exportAsExcelFile(listaItens).saveAsExcelFile('anexos');
  }

  public onSelectPeriodo() {
    this.preencherGrid();
  }

  public inserirArquivo() {
    this.entidade = new ArquivoTransparencia();
    this.entidade.grupo = new GrupoArquivoTransparencia();
    this.uploadedFiles = [];
    $('#dialogUpload').modal('show');
  }

  public cadastrarGrupo() {
    this.grupo = new GrupoArquivoTransparencia();
    this.grupo.orgao = this.login.orgao;
    $('#dialogGrupo').modal('show');
  }

  public async onUpload(event: any, fileUpload: any) {

    if (!this.entidade.descricao || this.entidade.descricao === '') {
      toastr.warning('Informe a descrição do arquivo!');
      return;
    }
    
    if (!this.entidade.grupo.id) {
      toastr.warning('Selecione o grupo!');
      return;
    }

    if (!event.files || event.files.length === 0) {
      toastr.warning('Selecione o(s) arquivo(s)');
      return;
    }
    try {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      }
      this.transparenciaStorageService
        .upload(this.uploadedFiles, this.login.orgao.id, this.entidade.grupo.id, this.entidade.descricao, true, this.entidade.referencia).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          for (const item of res) {
            item.data_cadastro = new Date();
          }
          this.preencherGrid();
        }, (res) => {
          toastr.error(res.error.payload);
          this.uploadedFiles.length = 0;
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
    this.uploadedFiles = []
    fileUpload.clear();
    $('#dialogUpload').modal('hide');
  }

  public async salvarGrupo() {
    if (!this.grupo.nome) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe o nome' });
      return;
    }
    try {
      this.grupoService
        .inserir(this.grupo).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          $('#dialogGrupo').modal('hide');
          this.preencherGrupos();
          this.grupo = new GrupoArquivoTransparencia();
          this.messageService.add({ severity: 'success', summary: 'Informação', detail: 'Grupo salvo com sucesso!' });
        }, (res) => {
          this.messageService.add({ severity: 'error', summary: 'Validação', detail: res.error.payload });
        });
    } catch (ex) {
      toastr.error(ex.message ? ex.message : ex);
    }
  }

  public preencherGrupos() {
    this.grupoService.filtrar(1, -1, { 'orgao.id': this.login.orgao.id, relations: 'orgao' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaGrupos = res.content;
        for (const item of this.listaGrupos) {
          item['editavel'] = false;
        }
      });
  }

  public download(arquivo: any) {
    this.anexoService
      .download(arquivo.documento).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const blob = new Blob([res], { type: arquivo.tipo });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = arquivo.nome;
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      });
  }

  public remover(item: any) {
    if (item.id && item.caminho) {
      this.anexoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
          this.preencherGrid()
        });
    }
    if (item.id && item.url) {
      this.transparenciaStorageService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          toastr.info('Registro removido com sucesso!', 'Exclusão');
          this.lista.splice(this.lista.indexOf(item), 1);
        }, (err) => toastr.error(err.error.payload));
    }
  }

  public editar(item: GrupoArquivoTransparencia) {
    item['editavel'] = true;
    this.grupoAtualizado = item;
    this.grupoAnterior = item;
  }

  public cancelar(item: GrupoArquivoTransparencia) {
    item['editavel'] = false;
    this.grupoService.filtrar(1, -1, { 'orgao.id': this.login.orgao.id, relations: 'orgao' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaGrupos = res.content;
        for (const item of this.listaGrupos) {
          item['editavel'] = false;
        }
      });
  }

  public salvar(item: GrupoArquivoTransparencia) {
    if (!this.grupoAtualizado.nome || this.grupoAtualizado.nome === '') {
      toastr.warning('Informe o nome do grupo!');
      throw new Error('Informe o nome do grupo!');
    }
    if (!this.grupoAtualizado.descricao || this.grupoAtualizado.descricao === '') {
      toastr.warning('Informe a descrição do grupo!');
      throw new Error('Informe a descrição do grupo!');
    }
    this.grupoService.atualizar(item).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        item.nome = this.grupoAtualizado.nome;
        item.descricao = this.grupoAtualizado.descricao;
        toastr.success('Grupo atualizado com sucesso!');
      })
    item['editavel'] = false;
  }

  public mostrarBotoes() {
    this.exibirFooter = true;
  }

  public esconderBotoes() {
    this.exibirFooter = false;
  }
}
