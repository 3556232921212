import { Recebimento } from 'eddydata-lib';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Usuario } from '../comum/usuario.model';
import { ConvenioSituacao } from './convenio-situacao.model';

// tslint:disable: variable-name
export class ConvenioPrestacao extends BaseResourceModel {
  constructor(
    public parcela?: number,
    public valor_prestacao?: number,
    public data_prestacao?: Date,
    public valor_quitacao?: number,
    public data_quitacao?: Date,
    public convenio_situacao?: ConvenioSituacao,
    public usuario?: Usuario,
    public exercicio?: Exercicio,
    public data_contabilizacao?: Date,
    public editavel?: boolean,
    public empenho_numero?: number,
    public empenho_parcela?: number,
    public empenho_ano?: number,
    public empenho_tipo?: string,
    public receita_ficha?: number,
    public receita_codigo?: string,
    public recebimento?: Recebimento,
  ) {
    super();
  }
  static converteJson(json: any): ConvenioPrestacao {
    return Object.assign(new ConvenioPrestacao(), json);
  }
}
