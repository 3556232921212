import { BaseResourceModel } from '../../models/base-resource.model';
import { InventarioSetor } from './inventario-setor.model';
import { Tombamento } from './tombamento.model';
import { ConservacaoPatrimonio } from '../../components/types';
import { Usuario } from '../comum/usuario.model';

export class InventarioIdentificacao extends BaseResourceModel {
  constructor(
    public id?: number,
    public usuario?: Usuario,
    public descricao?: string,
    public conservacao?: ConservacaoPatrimonio,
    public inventario_setor?: InventarioSetor,
    public marca?: string,
    public tombamento?: Tombamento,
    public observacao?: string,
    public pendente?: boolean,
    public conjunto?: boolean
  ) {
    super();
  }

  static converteJson(json: any): InventarioIdentificacao {
    return Object.assign(new InventarioIdentificacao(), json);
  }
}
