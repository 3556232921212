import { Component, Injector, ElementRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CreditoItemService } from '../service/credito-item.service';
import { CreditoService } from '../service/credito.service';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {
  BaseResourceFormComponent, Credito, LoginContabil,
  CreditoItem, FuncaoService, GlobalService, DateFormatPipe
} from 'eddydata-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credito-view',
  templateUrl: './credito-view.component.html'
})
export class CreditoViewComponent extends BaseResourceFormComponent<Credito, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public listaItens: Array<CreditoItem>;
  public isTransferencia: boolean = false;
  public totalCredito = 0;
  public totalAnulado = 0; 

  public colunaOrdenacao: string;
  public ascendente = true;

  public decretoMask = {
    mask: [
      {
        mask: '00000/0000'
      },
      {
        mask: '0000/0000'
      }
    ]
  };
  
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected itemService: CreditoItemService,
    protected creditoService: CreditoService,
    protected router: Router
  ) {
    super(new Credito(), injector, Credito.converteJson, creditoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_credito: [null, [Validators.required]],
      data_publicacao: [null, [Validators.required]],
      mes: [null, [Validators.required]],
      tipo: [null, [Validators.required]],
      ocorrencia: [null, [Validators.required]],
      decreto: [null, [Validators.required]],
      decreto_interno: [null, [Validators.required]],
      ativo: [true, [Validators.required]],
      lei: [null, [Validators.required]],
      justificativa: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      itens: [null]
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'exercicio,orgao' };
  }

  protected afterInit(): void {
    this.isTransferencia = this.router.url.includes('transferencias-internas');
    this.entidade.data_credito = new Date();
    this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(this.entidade.data_credito)?.split('-')?.[1]);
    this.listaItens = new Array<CreditoItem>();
    console.log(this.entidadeForm.get('decreto_interno').value);
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected afterLoad() {
    this.entidade.data_credito = new DateFormatPipe().transform(this.entidade.data_credito, []);
    this.entidade.ocorrencia = this.globalService.obterOcorrenciasCreditos(this.entidade.ocorrencia);
    this.entidade.tipo = this.globalService.obterTiposCreditos(this.entidade.tipo);
    this.itemService.obterPorCredito(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaItens = data ? data.content : new Array<CreditoItem>();
          this.totalizador(this.listaItens);
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
  }

  public reordenar(coluna: string, toggle?: boolean) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    this.colunaOrdenacao = coluna;
    
    if(this.colunaOrdenacao === 'ficha.numero') {
      this.listaItens.sort((a, b) => (this.ascendente? a.ficha.numero - b.ficha.numero: b.ficha.numero - a.ficha.numero)) 
    }
    if(this.colunaOrdenacao === 'valor_credito ') {
      this.listaItens.sort((a, b) => (this.ascendente? a.valor_credito  - b.valor_credito : b.valor_credito  - a.valor_credito )) 
    }
  }

  protected beforeSubmit(): void {
  }

  protected afterSubmit(ent: Credito) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private totalizador(list: Array<any>) {
    if (list) {
      this.totalAnulado = 0;
      this.totalCredito = 0;
      for (const item of list) {
        if (item.valor_credito > 0) {
          this.totalCredito += +item.valor_credito;
        } else {
          this.totalAnulado += +item.valor_credito;
        }
      }
    }
  }

}
