import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConservacaoPatrimonio, EddyAutoComplete, EmailService, InventarioSetor, InventarioIdentificacao, Login, ParametroPatrimonioService, ParametroPatrimonio, Tombamento, Usuario } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { InventarioIdentificacaoService } from '../service/inventario-identificacao.service';
import { TombamentoService } from 'administrativo-lib';

declare var $: any;

@Component({
  selector: 'app-inventario-identificacao-dlg',
  templateUrl: './inventario-identificacao-dlg.component.html'
})
export class InventarioIdentificacaoDlgComponent implements OnChanges {

  /**
   * Declaração de variáveis
   */
  @Input() public item: InventarioIdentificacao;
  @Input() public origem: InventarioSetor;
  @Input() public login: Login;
  @Input() entity: InventarioIdentificacao = new InventarioIdentificacao;
  @Output() callback: EventEmitter<boolean> = new EventEmitter();

  public usuario: Usuario;
  public descricao: string;
  public marca: string;
  public conservacao: ConservacaoPatrimonio;
  public observacao: string;
  public possuiPlacaBem: boolean;
  public pendente: boolean = true;
  public conjunto: boolean = false;
  public tombamento: Tombamento;

  public tombamentoAutoComplete: EddyAutoComplete<Tombamento>;
  public entidade: InventarioIdentificacao;
  public tipoEmail: 'T' | 'S' | 'SA' | 'SR' | 'I' = 'I';

  protected unsubscribe: Subject<void> = new Subject();

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private tombamentoService: TombamentoService,
    protected parametroPatrimonioService: ParametroPatrimonioService,
    private emailService: EmailService,
    protected confirmationPriService: ConfirmationService,
    private identificacaoService: InventarioIdentificacaoService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tombamentoAutoComplete = new EddyAutoComplete(null, this.tombamentoService,
      'id', ['placa', 'produto.nome'], { 'orgao.id': this.login.orgao.id, 'setor.id': this.login.usuario.setor.id, relations: 'produto,orgao' },
      { number: ['placa'], text: ['produto.nome'] }
    );

    if (changes && this.entity) {
      this.descricao = this.entity.descricao;
      this.marca = this.entity.marca;
      this.observacao = this.entity.observacao;
      this.conservacao = this.entity.conservacao;
      this.tombamento = this.entity.tombamento;
      this.pendente = this.entity.pendente;
      this.conjunto = this.entity.conjunto;

      if (this.conjunto) {
        this.possuiPlacaBem = true;
      } else {
        this.possuiPlacaBem = false;
      }
    } else {
      this.limparCampos();
    }
  }

  public async confirmar() {
    if (!this.conservacao) {
      toastr.warning('Informe a conservação do bem');
      return;
    }
    if (!this.descricao) {
      toastr.warning('Informe a descrição do bem');
      return;
    }
    if (!this.observacao) {
      toastr.warning('Informe a observação do bem');
      return;
    }

    this.entidade = new InventarioIdentificacao();
    this.entidade.usuario = this.login.usuario;
    this.entidade.descricao = this.descricao;
    this.entidade.marca = this.marca;
    this.entidade.observacao = this.observacao;
    this.entidade.conservacao = this.conservacao;
    this.entidade.tombamento = this.tombamento;
    this.entidade.inventario_setor = this.origem;
    this.entidade.pendente = this.pendente;
    this.entidade.conjunto = this.conjunto && this.possuiPlacaBem && !!this.tombamento?.id;

    if (this.entity) {
      this.entidade.id = this.entity.id;
      try {
        await new Promise((resolve, reject) => {
          this.identificacaoService.atualizar(this.entidade).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              this.email(this.entidade);
              resolve(true);
            }, error => {
              toastr.error(error.error.payload)
              reject(error)
            });
        });

        this.limparCampos();
        this.callback.emit(true);
        $('#dialogInventarioIdentificacao').modal('hide');
      } catch (e) {
        throw e;
      }
    } else {
      try {
        await new Promise((resolve, reject) => {
          this.identificacaoService.inserir(this.entidade).subscribe(res => {
            this.email(this.entidade);
            resolve(true);
          }, error => {
            toastr.error(error.error.payload)
            reject(error)
          });
        });

        this.limparCampos();
        this.callback.emit(true);
        $('#dialogInventarioIdentificacao').modal('hide');
      } catch (e) {
        throw e;
      }
    }
  }

  public email(entidade: InventarioIdentificacao) {
    this.parametroPatrimonioService.obter({
      'orgao.id': this.login.orgao.id
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(parametroPatrimonio => {
        let mensagem = '';
        if (this.tipoEmail.includes('I'))
          mensagem += `<br />Setor - ${entidade.inventario_setor.setor.nome}(${entidade.inventario_setor.setor.email})`;

        if (mensagem.length === 0)
          return;

        this.enviarEmail(entidade, parametroPatrimonio);
      });
  }

  public async enviarEmail(identificacao: InventarioIdentificacao, parametro?: ParametroPatrimonio) {
    let subject = `Identificação de Bem Móvel: ${identificacao.descricao}`;
    let mensagem = `<p>${parametro.mensagem_identificacao}</p>`;
    let destinatarios: { nome: string; email: string; }[] = [];

    if (this.tipoEmail.includes('I')) {
      destinatarios.push({ nome: identificacao.inventario_setor.setor.nome, email: identificacao.inventario_setor.setor.email });
    }

    if (destinatarios.length) {
      this.emailService.enviar({ titulo: subject, corpo: mensagem, destinos: destinatarios })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => toastr.success('E-mail encaminhado com sucesso!'),
          error => toastr.error(`Não foi possivel encaminhar o e-mail de confirmação!`));
    }
  }

  public limparCampos() {
    this.descricao = '';
    this.marca = '';
    this.observacao = '';
    this.conservacao = null;
    this.tombamento = null;
    this.possuiPlacaBem = false;
    this.conjunto = false;
    this.pendente = true;
  }

  public preencherCampos() {
    // if(this.tombamento.setor.id !== this.login.setorAlmoxarifado.setor.id){
    //   toastr.info('Bem não pertencente ao setor!');
    // } else {
      this.descricao = this.tombamento.produto.nome;
      this.marca = this.tombamento.marca;
      this.conservacao = this.tombamento.conservacao;
    // }
  }
}
